import { Component, ViewChild } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, FormArray, Validators } from '@angular/forms';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, first } from 'rxjs/operators';
import { EnvService } from '../../_services/env.service';
import { SiteService, UserService, CastingService, ChemistryService, ArticleService } from '@/_services';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { AlertService } from '@/shared/alerts';
import { User, site, meltinggroup, furnace, casting, articleStock, materialcharge, ladle, chemistry, ladlecasting } from '../../_models/index';
import { forEach } from '@angular/router/src/utils/collection';
import { Utils } from '../../shared/utils/utils'
import { utils } from 'protractor';
import { ActivatedRoute } from '@angular/router';


@Component({ templateUrl: 'casting.component.html' })

export class Casting {
  formNameAddUpdate: string = "New Heat";
  idCasting: number = 0;
  idSite: number;
  idMeltingGroup: number;
  errorInfo = '';
  error2 = '';
  sites = [];
  meltinggroups = [];
  furnaces = [];
  castingChemistries = [];
  articleStocks = [];
  ladles: any[] = [];
  selectedLadleCapacity: string = "";
  totalVolume: number = null;
  totalLadle: number = null;
  ladleChemistries = [];
  idChemistryType = 5;//id de la Family = Casting;
  infoTable: casting[] = [];;
  infoTableMaterial: materialcharge[] = [];
  infoTableLadleCasting: ladlecasting[] = [];
  selectedSite: site;
  selectedMeltingGroup: meltinggroup;
  selectedFurnace: furnace;
  selectedChemistryCasting: chemistry;
  selectedArticleStock: articleStock;
  selectedLadle: ladle;
  selectedChemistryLadle: chemistry;
  castingResultado: casting;  
  currentUser: User;

  selectedRowMaterial: number;
  selectedMaterial: materialcharge;
  selectedRowLadleCasting: number;
  selectedLadleCasting: ladlecasting;
  
  castingForm = this.fb.group({     
    sites: [null, Validators.required],    
    meltinggroups: [null, Validators.required],
    furnaces: [null, Validators.required],
    referenceCasting: ['', Validators.required],
    heatTotalVolume: [''],
    meltTemperature: ['', Validators.required],
    meltDate: ['', Validators.required],
    holdTime: ['', Validators.required],
    castingChemistries: [null],
    materialCharge: this.fb.group({
      articleStocks: [null],
      quantityMaterial: ['']
    }),    
    ladle: this.fb.group({
      ladles: [null],
      ladleCapacity: [''],
      referenceLadle: [''],
      ladleDate: [''],
      quantityLadle: [''],
      ladleChemistries: [null]
    })
  });

   

  constructor(private fb: FormBuilder, private http: HttpClient, private env: EnvService,
    private siteService: SiteService, private castingService: CastingService, private chemistryService: ChemistryService, private articleService: ArticleService,
    private user: UserService, private alertService: AlertService, private route: ActivatedRoute) {
    this.castingResultado = new casting();
    this.currentUser = this.user.currentUserInfoValue;
  }

  //Controlar las ventanas popup de editar charged materials y ladles
  @ViewChild('warningModal', { read: false }) public warningModal: ModalDirective;
  @ViewChild('warningModal2', { read: false }) public warningModal2: ModalDirective;

  iniciarControlesHeat() {
    this.castingForm.patchValue({
      meltinggroups: null,
      furnaces:null,
      referenceCasting: '',
      totalVolume: 0,
      totalLadle: 0,
      meltTemperature: '',
      meltDate: null,
      holdTime: '',
      castingChemistries: null      
    });
  }

  iniciarControlesMaterialCharge() {
    this.castingForm.patchValue({
      materialCharge: {
        articleStocks: null,
        quantityMaterial: ''
      }      
    });
  }


  iniciarControlesLadle() {
    this.castingForm.patchValue({
      ladle: {
        ladles: null,
        ladleCapacity: '',
        referenceLadle: '',
        ladleDate: null,
        quantityLadle: '',
        ladleChemistries: null
      }
    });
    this.selectedLadleCapacity = "Ladle Capacity";
  }  
  
  
  RowSelectedSite() {
    if (this.selectedSite != null)
    {
      this.getMeltingGroup();
      this.getArticleStock();
      this.getChemsitry();
      this.getLadle();

      this.selectedMeltingGroup = null;
      this.selectedFurnace = null;
      this.selectedChemistryCasting = null;
      this.selectedArticleStock = null;
      this.selectedLadle = null;
      this.selectedChemistryLadle = null;
    }
    else
    {
      this.meltinggroups = null;
      this.furnaces = null;
      this.castingChemistries = null;
      this.articleStocks = null;
      this.ladles = null;
      this.ladleChemistries = null;
      
      this.selectedMeltingGroup = null;
      this.selectedFurnace = null;
      this.selectedChemistryCasting = null;
      this.selectedArticleStock = null;
      this.selectedLadle = null;
      this.selectedChemistryLadle = null;
    }
  }

  getSite() {
    this.siteService.site(this.currentUser.idUserApplication)
      .pipe(first())
      .subscribe(
        data => {
          if (data == false) {
            this.errorInfo = "Consulta errónea.";
          }
          this.sites = data;
          if (this.idSite > 0) {
            this.selectedSite = this.sites.find(s => s.idSite == this.idSite);
            this.getMeltingGroup();
            this.getArticleStock();
            this.getLadle();
            this.getChemsitry();
          }
          else {
            //Se selecciona por defecto el site del usuario
            if (this.currentUser.idSiteDefault > 0)
              this.selectedSite = this.sites.find(x => x.idSite == this.currentUser.idSiteDefault);            
          }
            
        },
        error => {
          this.errorInfo = error;

        });

  }

  getMeltingGroup() {
    this.castingService.getMeltingGroup(this.selectedSite.idSite)
      .pipe(first())
      .subscribe(
        data => {
          if (data == false) {
            this.error2 = "Consulta errónea.";
          }
          this.meltinggroups = data;
          if (this.idMeltingGroup > 0) {
            this.selectedMeltingGroup = this.meltinggroups.find(s => s.idMeltingGroup == this.idMeltingGroup);
            this.getFurnace();
          }
            
        },
        error => {
          this.error2 = error;
        });

  }

  getArticleStock() {
    this.articleService.getStockBySite(this.selectedSite.idSite)
      .pipe(first())
      .subscribe(
        data => {
          if (data == false) {
            this.error2 = "Consulta errónea.";
          }
          this.articleStocks = data;
        },
        error => {
          this.error2 = error;
        });
  }


  getChemsitry() {
    this.chemistryService.getChemistryBySiteType(this.selectedSite.idSite, this.idChemistryType)
      .pipe(first())
      .subscribe(
        data => {
          if (data == false) {
            this.error2 = "Consulta errónea.";
          }
          this.castingChemistries = data;
          this.ladleChemistries = data;

          if (this.castingResultado.idCasting != undefined)
            this.selectedChemistryCasting = this.castingChemistries.find(s => s.idChemistry == this.castingResultado.idChemistry);
          
        },
        error => {
          this.error2 = error;
        });
  }


  getLadle() {
    this.castingService.getLadle(this.selectedSite.idSite)
      .pipe(first())
      .subscribe(
        data => {
          if (data == false) {
            this.error2 = "Consulta errónea.";
          }
          this.ladles = data;
        },
        error => {
          this.error2 = error;
        });
  }

  RowSelectedLadle() {
    if (this.selectedLadle != null) {
      this.selectedLadleCapacity = this.castingForm.controls["ladle"].value.ladles.capacity;
    }
    else {
      this.selectedLadleCapacity = "Ladle Capacity";
    }
  }

  RowSelectedMeltingGroup() {
    if (this.selectedMeltingGroup != null) {
      this.getFurnace();
      this.selectedFurnace = null
    }      
    else {
      this.furnaces = null;

      this.selectedFurnace = null;
    }
  }

  getFurnace() {
    this.castingService.getFurnace(this.selectedMeltingGroup.idMeltingGroup)
      .pipe(first())
      .subscribe(
        data => {
          if (data == false) {
            this.error2 = "Consulta errónea.";
          }
          this.furnaces = data;
          if (this.castingResultado.idCasting != undefined)
            this.selectedFurnace = this.furnaces.find(s => s.idFurnace == this.castingResultado.idFurnace);
        },
        error => {
          this.error2 = error;
        });

  }



  addMaterialCharge() {

    this.clear('alertMaterialCharge');
    if (this.selectedArticleStock != null
      && this.castingForm.controls["materialCharge"].value.quantityMaterial != null) {

      //Si ya se ha añadido el Test Type una vez, se muestra el aviso de que ya existe y no se añade de nuevo
      if (this.infoTableMaterial.find((x => x.articleStock == this.castingForm.controls["materialCharge"].value.articleStocks.reference))) {
        this.error('This material has already been added', 'alertMaterialCharge');
        return;
      }

      var aux = new materialcharge();
      aux.idMaterialCharge = 0;
      aux.idArticleStock = this.castingForm.controls["materialCharge"].value.articleStocks.idArticleStock;
      aux.articleStock = this.castingForm.controls["materialCharge"].value.articleStocks.reference;
      aux.quantity = this.castingForm.controls["materialCharge"].value.quantityMaterial;

      this.infoTableMaterial.push(aux);
      this.iniciarControlesMaterialCharge();
      this.totalVolume += aux.quantity;
    }
    else {
      this.error('Any of the values entered is not correct', 'alertMaterialCharge');
    }
  }

  RowSelectedMaterial(u: any, i: number) {    
    this.selectedMaterial = u;  
    this.selectedRowMaterial = i;

  }

  CargarMaterial(u: any) {

    this.castingForm.patchValue({
      materialCharge: {
        quantityMaterial: u.quantity
      }
    });
  }

  RowDeleteMaterial(u: any, i: number) {
    this.infoTableMaterial.splice(i, 1);
    this.totalVolume -= u.quantity;
  }
  
  
  updateMaterial() {

    this.clear('alertEditMaterialCharge');

    if (this.castingForm.controls["materialCharge"].value.quantityMaterial > 0) {

      this.infoTableMaterial.forEach(item => {
        if (item.idArticleStock == this.selectedMaterial.idArticleStock) {

          this.totalVolume -= this.selectedMaterial.quantity;

          item.quantity = this.castingForm.controls["materialCharge"].value.quantityMaterial;

          this.totalVolume += this.castingForm.controls["materialCharge"].value.quantityMaterial;
        }
      });

      this.warningModal.hide();
      this.iniciarControlesMaterialCharge();
    }
    else {
      this.error('The quantity entered is not correct', 'alertEditMaterialCharge');
    }
  }



  addLadleCasting() {

    this.clear('alertLadleCasting');

    if (this.selectedLadle != null && this.castingForm.controls["ladle"].value.referenceLadle != null
      && this.castingForm.controls["ladle"].value.ladleDate != null && this.castingForm.controls["ladle"].value.quantityLadle != null) {

      if (this.castingForm.controls["ladle"].value.quantityLadle > this.totalVolume) {
        this.error('The quantity of the ladle can not be higher than the melt total volume', 'alertLadleCasting');
        return;
      }

      if (this.castingForm.controls["ladle"].value.quantityLadle > this.selectedLadleCapacity) {
        this.error('The quantity of the ladle can not be higher than the ladle capacity', 'alertLadleCasting');
        return;
      }

      if (this.castingForm.controls["ladle"].value.ladleDate < this.castingForm.value.meltDate) {
        this.error('The ladle creation date can not be previous to the melt date', 'alertLadleCasting');
        return;
      }


      this.totalLadle += this.castingForm.controls["ladle"].value.quantityLadle;

      if (this.totalLadle > this.totalVolume) {
        this.error('The sum of ladle quantities is higher than the heat total volumen', 'alertCasting');
        this.totalLadle -= this.castingForm.controls["ladle"].value.quantityLadle;
        return;
      }

      var aux = new ladlecasting();
      aux.idLadleCasting = 0;
      aux.reference = this.castingForm.controls["ladle"].value.referenceLadle;
      aux.idLadle = this.castingForm.controls["ladle"].value.ladles.idLadle;
      aux.ladleReference = this.castingForm.controls["ladle"].value.ladles.reference;
      aux.qtCasting = this.castingForm.controls["ladle"].value.quantityLadle;
      aux.dtCreation = this.castingForm.controls["ladle"].value.ladleDate;
      if (this.selectedChemistryLadle != null) {
        aux.idChemistry = this.castingForm.controls["ladle"].value.ladleChemistries.idChemistry;
        aux.chemistry = this.castingForm.controls["ladle"].value.ladleChemistries.reference;
      }
      
      this.infoTableLadleCasting.push(aux);
      this.iniciarControlesLadle();

    }
    else {
      this.error('Any of the values entered is not correct', 'alertLadleCasting');
    }
  }

  RowSelectedLadleCasting(u: any, i: number) {
    this.selectedLadleCasting = u;
    this.selectedRowLadleCasting = i;

  }

  CargarLadleCasting(u: any) {

    this.ladleChemistries.forEach(ladleChemistry => {
      if (ladleChemistry.reference == this.selectedLadleCasting.chemistry) {
        this.selectedChemistryLadle = ladleChemistry;
      }
    });

    this.ladles.forEach(ladle => {
      if (ladle.reference == this.selectedLadleCasting.ladleReference) {
        this.selectedLadle = ladle;

        this.selectedLadleCapacity = this.selectedLadle.capacity.toString();
      }
    });

    this.castingForm.patchValue({
      ladle: {
        quantityLadle: u.qtCasting,
        ladleDate: u.dtCreation,
        ladleChemistries: this.selectedChemistryLadle
      }
    });
    
    
  }

  RowDeleteLadleCasting(u: any, i: number) {
    this.infoTableLadleCasting.splice(i, 1);
    this.totalLadle -= u.qtCasting;
  }


  updateLadleCasting() {
    this.clear('alertEditLadleCasting');

    if (this.castingForm.controls["ladle"].value.quantityLadle > 0 && this.castingForm.controls["ladle"].value.ladleDate != null ) {

      this.infoTableLadleCasting.forEach(item => {
        if (item.reference == this.selectedLadleCasting.reference) {
          this.totalLadle -= this.selectedLadleCasting.qtCasting;
          this.totalLadle += this.castingForm.controls["ladle"].value.quantityLadle;
          if (this.totalLadle > this.totalVolume) {
            this.error('The sum of ladle quantities is higher than the heat total volumen', 'alertEditLadleCasting');
            this.totalLadle -= this.castingForm.controls["ladle"].value.quantityLadle;
            this.totalLadle += this.selectedLadleCasting.qtCasting;
            return;
          }



          item.qtCasting = this.castingForm.controls["ladle"].value.quantityLadle;
          item.dtCreation = this.castingForm.controls["ladle"].value.ladleDate;
          if (this.selectedChemistryLadle != null) {
            item.idChemistry = this.castingForm.controls["ladle"].value.ladleChemistries.idChemistry;
            item.chemistry = this.castingForm.controls["ladle"].value.ladleChemistries.reference;
          }
          else {
            item.idChemistry = null;
            item.chemistry = '';
          }

          this.warningModal2.hide();
          this.iniciarControlesLadle();
          
        }
      });


    }
    else {
      this.error('Any of the values entered is not correct', 'alertEditLadleCasting');
    }
  }

  
 
  onSubmit() {    
    //Se limpia el mensaje de enviado correctamente si se estaba mostrando
    this.clear('SaveCorrect');
    this.clear('alertCasting');

    this.infoTable = [];
    
    //Si el formulario es válido se envía la información
    if (this.castingForm.valid) {

      this.clear('alertCasting');

      if (this.selectedFurnace != null && this.castingForm.value.referenceCasting != ""
          && this.castingForm.value.meltTemperature != null && this.castingForm.value.meltDate != null && this.totalVolume != 0) {


              var aux = new casting();

              aux.idCasting = this.idCasting;
              aux.reference = this.castingForm.value.referenceCasting;
              aux.qtCastingTotal = this.totalVolume;
              aux.meltTemperature = this.castingForm.value.meltTemperature;
              aux.dtCasting = this.castingForm.value.meltDate;
              aux.holdTime = this.castingForm.value.holdTime;

              if (this.castingForm.value.castingChemistries != null) {
                aux.idChemistry = this.castingForm.value.castingChemistries.idChemistry;
                aux.chemistry = this.castingForm.value.castingChemistries.reference;

              }
              if (this.castingForm.value.furnaces != null) {
                aux.idFurnace = this.castingForm.value.furnaces.idFurnace;
                aux.furnace = this.castingForm.value.furnaces.reference;
              }

              aux.materialCharges = [];
              this.infoTableMaterial.forEach(item => {
                aux.materialCharges.push(item);
              });

              aux.ladleCastings = [];
              this.infoTableLadleCasting.forEach(item => {
                aux.ladleCastings.push(item);
              });
              

              this.infoTable.push(aux);

              Utils.remove_empty(this.infoTable);

              var body = JSON.stringify(this.infoTable);
              console.log(body);
              console.log(this.infoTable);

              this.castingService.SaveCasting(body)
                .pipe(first())
                .subscribe(
                  result => {
                    if (result == true) {
                      this.cambiosGuardadosCorrectamente();
                    }
                  },
                  error => {
                    this.errorInfo = error;
                    this.error(error, 'alertCasting');
                  });


        }
        else {
          this.error('Any of the values entered is not correct', 'alertCasting');
        }


    }
  }


  


  ngOnInit()
  {
    //Inicializar valores controles del formulario
    this.iniciarControlesHeat();
    this.iniciarControlesMaterialCharge();
    this.iniciarControlesLadle();

        
    this.getParameter();

    this.selectedChemistryCasting = null;
    this.selectedArticleStock = null;
    this.selectedLadle = null;
    this.selectedChemistryLadle = null;

    this.selectedLadleCapacity = "Ladle Capacity";
    this.totalVolume = 0;
    this.totalLadle = 0;
  }


  getParameter() {

    if (this.route.snapshot.paramMap.get('idCasting') != null) {
           
      this.idCasting = (parseInt)(this.route.snapshot.paramMap.get('idCasting'));
      this.idSite = (parseInt)(this.route.snapshot.paramMap.get('idSite'));
      this.idMeltingGroup = (parseInt)(this.route.snapshot.paramMap.get('idMeltingGroup'));

      this.formNameAddUpdate = "Update Heat";

      this.getSite();      

      this.getCastingById();

    }
    else {

      this.getSite();

      this.selectedSite = null;
      this.selectedMeltingGroup = null;
      this.selectedFurnace = null;
      this.selectedMaterial = null;
      this.selectedLadle = null;
      this.selectedChemistryCasting = null;
      this.selectedChemistryLadle = null;
    }
  }

  getCastingById() {
    this.castingService.getCastingById(this.idCasting)
      .pipe(first())
      .subscribe(
        data => {
          if (data == false) {
            this.error2 = "Consulta errónea.";
          }
          this.castingResultado = data;

          if (this.castingResultado != null) {
            this.castingForm.patchValue({
              referenceCasting: this.castingResultado.reference,
              meltTemperature: this.castingResultado.meltTemperature,
              meltDate: this.castingResultado.dtCasting,
              holdTime: this.castingResultado.holdTime,
              castingChemistries: [null]
            });
            this.totalVolume = this.castingResultado.qtCastingTotal,

            this.getMaterials();
            this.getLadleCastings();

          }
        },
        error => {
          this.error2 = error;
        });
  }

  getMaterials() {
    this.castingService.getMaterialByCasting(this.idCasting)
      .pipe(first())
      .subscribe(
        data => {
          if (data == false) {
            this.error2 = "Consulta errónea.";
          }
          this.infoTableMaterial = data;
        },
        error => {
          this.error2 = error;
        });
  }

  getLadleCastings() {
    this.castingService.getLadleCastingByCasting(this.idCasting)
      .pipe(first())
      .subscribe(
        data => {
          if (data == false) {
            this.error2 = "Consulta errónea.";
          }
          this.infoTableLadleCasting = data;
        },
        error => {
          this.error2 = error;
        });
  }


  //Cuando se envía correctamente la información, se muestra el mensaje y se resetea el formulario
  cambiosGuardadosCorrectamente() {
    this.success('data saved correctly', 'SaveCorrect');
    //Se resetean todas la variables por si se desean insertar más datos
    if (this.idCasting == 0) this.resetFormulario();
  }

  //Limpia todos los campos del formulario y las variables que se muestran en las tablas
  resetFormulario() {
    this.castingForm.reset();
    this.formNameAddUpdate = "New Heat";
    this.selectedSite = null;
    this.selectedMeltingGroup = null;
    this.selectedFurnace = null;
    this.selectedArticleStock = null;
    this.selectedLadle = null;
    this.selectedLadleCapacity = "Ladle Capacity";
    this.totalVolume = 0;
    this.totalLadle = 0;

    this.infoTableMaterial = [];
    this.infoTableLadleCasting = [];

    this.iniciarControlesHeat();
    this.iniciarControlesLadle();
    this.iniciarControlesMaterialCharge();
  }


  success(message: string, id:string) {
    this.alertService.success(message, id);
  }

  error(message: string, id: string) {
    this.alertService.error(message, id);
  }

  info(message: string) {
    this.alertService.info(message);
  }

  warn(message: string) {
    this.alertService.warn(message);
  }

  clear(id: string) {
    this.alertService.clear(id);
  }

}

