import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { EnvService } from './env.service';

import { Message } from '@angular/compiler/src/i18n/i18n_ast';
import { templatelab } from '../_models/templatelab';
import { test } from '../_models/test';
import { forEach } from '@angular/router/src/utils/collection';

@Injectable({ providedIn: 'root' })

export class ImageGalleryService {
    private currentImageGallerySubject: BehaviorSubject<templatelab[]>;
    public currentImageGallery: Observable<templatelab[]>;

  constructor(private http: HttpClient, private env: EnvService) {
      this.currentImageGallerySubject = new BehaviorSubject<templatelab[]>(JSON.parse(localStorage.getItem('imageGallery')));
      this.currentImageGallery = this.currentImageGallerySubject.asObservable();
  }

    returnImageGalleryById(idImageGallery: Number) {
        return this.http.get<any>(this.env.apiUrl + `/api/File/` + idImageGallery.toString())
            .pipe(map(gallery => {
                // store user details and jwt token in local storage to keep user logged in between page refreshes      

                //this.currentTemplateLabSubject.next(temp);
                return gallery;
            }));
    }

}


