import { Component, ViewChild } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, FormArray, Validators } from '@angular/forms';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, first } from 'rxjs/operators';
import { EnvService } from '../../_services/env.service';
import { SiteService,  PieceTypeService, UserService } from '@/_services';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { AlertService } from '@/shared/alerts';
import { site, User, piecepart, pieceType } from '../../_models/index';
import { forEach } from '@angular/router/src/utils/collection';
import { Utils } from '../../shared/utils/utils'
import { utils } from 'protractor';
import { all } from 'q';
import { ActivatedRoute } from '@angular/router';



@Component({ templateUrl: 'pieceType.component.html' })

export class PieceType {
    formNameAddUpdate: string = "New Part Number";
    idPieceType: number = 0;
    idSite: number = 0;
    idArticle: number = 0;
    errorInfo = '';
    cant: number = 0;
    sites:  any[] = [];
    sections: piecepart[] = [];
    selectedSection: piecepart;
    selectedRow: number = 0;
    selectedSite: site;
    currentUser: User;
    pieceTypeResultado: pieceType;   
    

    pieceTypeForm = this.fb.group({
        sites: [null, Validators.required],        
        reference: ['', Validators.required],
        description: [''],
        sections: this.fb.group({
            referenceSection: [''],
            descriptionSection: ['']
        }),

           //EditSection: this.fb.group({
           //    referenceModal: [''],
           //    descriptionModal: ['']
           //})
    });



    constructor(private fb: FormBuilder, private http: HttpClient, private env: EnvService,
        private siteService: SiteService, private pieceTypeService: PieceTypeService,
        private alertService: AlertService, private user: UserService, private route: ActivatedRoute) {
        this.pieceTypeResultado = new pieceType();
        this.currentUser = this.user.currentUserInfoValue;
    }

    //Controlar la ventana popup de editar componentes
    @ViewChild('warningModal', { read: false }) public warningModal: ModalDirective;
    @ViewChild('warningModal2', { read: false }) public warningModal2: ModalDirective;



  
    addSection() {
      this.clear('alertSection');
        if (this.pieceTypeForm.controls["sections"].value.referenceSection != null
          && this.pieceTypeForm.controls["sections"].value.descriptionSection != null) {

            //Si ya se ha añadido el Test Type una vez, se muestra el aviso de que ya existe y no se añade de nuevo
            if (this.sections.find((x => x.reference == this.pieceTypeForm.controls["sections"].value.referenceSection))) {
              this.error('This section reference has already been added', 'alertSection');
              return;
            }

            var aux = new piecepart();
            aux.idPiecePart = 0;
            aux.idPieceType = this.idPieceType;
            aux.pieceType = this.pieceTypeForm.value.reference;
            aux.reference = this.pieceTypeForm.controls["sections"].value.referenceSection;
            aux.description = this.pieceTypeForm.controls["sections"].value.descriptionSection;

            this.sections.push(aux);

            this.iniciarControles();
        }
        else {
          this.error('Any of the values entered is not correct', 'alertSection');
        }

    }


    iniciarControles() {
      this.pieceTypeForm.patchValue({
          sections: {
                referenceSection: '',
                descriptionSection: ''
          }
      });
    }

    

  //Limpia todos los campos del formulario y las variables que se muestran en las tablas
  resetFormulario() {
    this.pieceTypeForm.reset();
    this.formNameAddUpdate = "New Part Number";
      this.sections = [];
      this.selectedSite = null;
  }


  //Cuando se envía correctamente la información, se muestra el mensaje y se resetea el formulario
  cambiosGuardadosCorrectamente() {
    this.success('data saved correctly', 'SaveCorrect');
    //Se resetean todas la variables por si se desean insertar más datos
    if (this.idPieceType == 0) this.resetFormulario();
  }

   

    

  onSubmit() {    
      this.clear('SaveCorrect');     

    //Si el formulario es válido se envía la información
    if (this.pieceTypeForm.valid) {

      this.pieceTypeResultado.idPieceType = this.idPieceType;
      this.pieceTypeResultado.idArticle = this.idArticle;
      this.pieceTypeResultado.reference = this.pieceTypeForm.value.reference;
      this.pieceTypeResultado.description = this.pieceTypeForm.value.description;
      this.pieceTypeResultado.idSite = this.pieceTypeForm.value.sites.idSite;
      console.log(this.pieceTypeForm.value.sites.idSite);
      console.log(this.pieceTypeForm.value.description);
      this.pieceTypeResultado.pieceParts = [];
      this.sections.forEach(item => {
        this.pieceTypeResultado.pieceParts.push(item);
      });
      Utils.remove_empty(this.pieceTypeResultado);

      var body = JSON.stringify(this.pieceTypeResultado);


      this.pieceTypeService.SavePieceType(body)
        .pipe(first())
        .subscribe(
          result => {
            if (result == true) {
              this.cambiosGuardadosCorrectamente();
            }
          },
          error => {
            this.errorInfo = error;
            this.error(error, 'SaveCorrect');
          });

    }
  } 

  ngOnInit()
  {
    this.getParameter();
    this.siteService.site(this.currentUser.idUserApplication)
      .pipe(first())
      .subscribe(
        data => {
          if (data == false) {
            this.errorInfo = "Consulta errónea.";            
          }          
              this.sites = data;
          if (this.idSite && this.idSite > 0)
            this.selectedSite = this.sites.find(x => x.idSite == this.idSite);
          else {
            //Se selecciona por defecto el site del usuario
            if (this.currentUser.idSiteDefault > 0) {
              this.selectedSite = this.sites.find(x => x.idSite == this.currentUser.idSiteDefault);
               
            }
            else
              this.selectedSite = null;
          }
        },
        error => {
          this.errorInfo = error;
        });
    
      
      this.iniciarControles();
      
    }

    getParameter() {

        if (this.route.snapshot.paramMap.get('idPieceType') != null) {
            this.idPieceType = (parseInt)(this.route.snapshot.paramMap.get('idPieceType'));
            this.idSite = (parseInt)(this.route.snapshot.paramMap.get('idSite'));
            //this.idArticle = (parseInt)(this.route.snapshot.paramMap.get('idArticle'));
            console.log("ID Site " + this.idSite /*+ "  " + this.router.snapshot.params.idTemplateLabTest*/);
            this.formNameAddUpdate = "Update Part Number";
            this.getPiceTypeById();
            //this.myBtn = true;
            //this.myTab = true;
        }
    }

    getPiceTypeById() {
        this.pieceTypeService.getPieceTypeById(this.idPieceType, this.idSite)
                .pipe(first())
                .subscribe(
                    data => {
                        if (data == false) {
                            this.errorInfo = "Consulta errónea.";
                        }
                        this.pieceTypeResultado = data;
                        this.idArticle = this.pieceTypeResultado.idArticle;
                        console.log(data);
                        this.getSection();
                        this.iniciarControlesPiceType();
                    },
                    error => {
                        this.error = error;
                    });
       
    }

    getSection() {
        console.log(this.idPieceType);
        if (this.idPieceType > 0) {
            this.pieceTypeService.getPiecePart(this.idPieceType)
                .pipe(first())
                .subscribe(
                    data => {
                        if (data == false) {
                            this.errorInfo = "Consulta errónea.";
                        }
                        this.sections = data;
                        console.log(data);
                    },
                    error => {
                        this.error = error;
                    });
        }
    }

    iniciarControlesPiceType() {
        this.pieceTypeForm.patchValue({
            reference: this.pieceTypeResultado.reference,
            description: this.pieceTypeResultado.description,
        });
    }

    updateEvaluation(ref: string, desc: string) {
        this.selectedSection.description = desc;
        this.selectedSection.reference = ref;
        console.log("Index " + this.selectedSection.description)
        this.warningModal.hide();
    }

    RowSelected(u: any, i: number) {
        console.log("Selected Section"+ u.reference);
        this.selectedSection = u;   // declare variable in component.
        this.selectedRow = i;
    }
    

    RowDelete(u: any, i: number) {
        this.sections.splice(i, 1);
    }

  success(message: string, id:string) {
    this.alertService.success(message, id);
  }

  error(message: string, id: string) {
    this.alertService.error(message, id);
  }

  info(message: string) {
    this.alertService.info(message);
  }

  warn(message: string) {
    this.alertService.warn(message);
  }

  clear(id: string) {
    this.alertService.clear(id);
  }

}

