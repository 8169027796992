import { Component, ViewChild } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, FormArray, Validators } from '@angular/forms';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, first } from 'rxjs/operators';
import { EnvService } from '../../_services/env.service';
import { SiteService, UserService, CastingService } from '@/_services';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { AlertService } from '@/shared/alerts';
import { site, casting, User, meltinggroup, furnace, ladlecasting, moldladle } from '../../_models/index';
import { forEach } from '@angular/router/src/utils/collection';
import { Utils } from '../../shared/utils/utils'
import { Utils2 } from '../../shared/utils/utils2'
import { utils } from 'protractor';
import { Router } from "@angular/router";


@Component({ templateUrl: 'castingList.component.html' })

export class CastingList {
    errorInfo = '';
  error2 = '';
  sites = [];
  meltingGroups = [];
  furnaces = [];
  heats = [];
  materials = [];
  ladles = [];
  molds = [];
  pieces = [];
  selectedRowCasting: number;
  selectedRowLadle: number;
  selectedRowMold: number;
  selectedSite: site;
  selectedMeltingGroup: meltinggroup;
  selectedFurnace: furnace;
  selectedCasting: casting;
  selectedLadle: ladlecasting;
  selectedMoldLadle: moldladle;
  currentUser: User;
    mytimer: any;

  castingListForm = this.fb.group({
    sites: [null],
    meltingGroups: [null],
    furnaces: [null],
    referenceCasting: [''],
    meltDate: [''],
    meltTemperature: [''],
    holdTime: [''],
    materials: this.fb.group({
      referenceMaterial: [''],
      quantity: ['']
    }),
    ladles: this.fb.group({
      referenceLadle: [''],
      quantity: [''],
      qtCasting: [''],
      dtCreation: [''],
      description: [''],
      molds: this.fb.group({
        referenceMold: [''],
        quantity: [''],
        pourTemperature: [],
        pourDuration: [],
        fillDate: [],
        pieces: this.fb.group({
          referencePiece: [''],
          pieceTypeReference: [''],
          timeUntilShakeout: [''],
          dtArticleStock: [''],
          area: [''],
          description: [''],
          moldComponents:[]
        })
      })
    })     
  });

  constructor(private fb: FormBuilder, private http: HttpClient, private env: EnvService,
     private siteService: SiteService, private castingService: CastingService, private util: Utils2,
     private user: UserService, private alertService: AlertService, private router: Router) {
      this.currentUser = this.user.currentUserInfoValue;      

      this.mytimer = new util.timer(() => {
          this.clearAllAlerts();
      }, 10000);
  }

    clearAllAlerts() {
        this.clear('HeatAlert');
        this.clear('FillAlert');
    }

  iniciarControlesCasting() {
    this.castingListForm.patchValue({
      heats: {
        referenceCasting: null,
        meltDate: null,
        meltTemperature: 0,
        holdTime: 0
       }
    });
  }

  iniciarControlesMaterial() {
    this.castingListForm.patchValue({
      materials: {
        referenceMaterial: null,
        quantity: 0
      }
    });
  }
  iniciarControlesLadle() {
    this.castingListForm.patchValue({
      ladles: {
        referenceLadle: null,
        quantity: 0,
        qtCasting: 0,
        ladleDate: null,
        description: null
      }
    });
  }
  iniciarControlesMold() {
    this.castingListForm.patchValue({
      molds: {
        moldReference: null,
        quantityLadle: 0,
        realVolumen: 0,
        pourTemperature: 0,
        pourTime: 0,
        dtFillTime: null
      }
    });
  }
  iniciarControlesPiece() {
    this.castingListForm.patchValue({
      pieces: {
        referencePiece: null,
        pieceTypeReference: null,
        timeUntilShakeout: 0,
        dtArticleStock: null,
        area: null,
        description: null,
        moldComponents: []
      }
    });
  }

  RowSelectedSite() {
    if (this.selectedSite != null)
      this.getMeltingGroup();
    else
    {
      this.meltingGroups = null;
      this.furnaces = null;
      this.heats = null;
      this.materials = null;
      this.ladles = null;
      this.molds = null;
      this.pieces = null;

      this.selectedMeltingGroup = null;
      this.selectedFurnace = null;
      this.selectedMoldLadle = null;
      this.selectedCasting = null;
    }
  }

  getMeltingGroup() {
    this.castingService.getMeltingGroup(this.selectedSite.idSite)
        .pipe(first())
        .subscribe(
          data => {
            if (data == false) {
              this.error2 = "Consulta errónea.";
            }
            this.meltingGroups = data;
          },
          error => {
            this.error = error;
          });
    
  }


  RowSelectedMeltingGroup() {
    if (this.selectedMeltingGroup != null)
      this.getFurnace();
    else
    {
      this.furnaces = null;
      this.heats = null;
      this.materials = null;
      this.ladles = null;
      this.molds = null;
      this.pieces = null;

      this.selectedFurnace = null;
      this.selectedMoldLadle = null;
      this.selectedCasting = null;
    }
  }

  getFurnace() {
    this.castingService.getFurnace(this.selectedMeltingGroup.idMeltingGroup)
      .pipe(first())
      .subscribe(
        data => {
          if (data == false) {
            this.error2 = "Consulta errónea.";
          }
          this.furnaces = data;
        },
        error => {
          this.error = error;
        });
    
  }

  RowSelectedFurnace() {
    if (this.selectedFurnace != null)
      this.getHeat();
    else
    {
      this.heats = null;
      this.materials = null;
      this.ladles = null;
      this.molds = null;
      this.pieces = null;
      this.selectedMoldLadle = null;
      this.selectedCasting = null;
    }
  }

   RowSelectedCasting(u: any, i: number) {      
      this.selectedCasting = u;   // declare variable in component.
      this.selectedRowCasting = i;      

      this.materials = null;
      this.ladles = null;
      this.molds = null;
      this.pieces = null;

      this.selectedMoldLadle = null;      

      this.getMaterial();
      this.getLadle();      
  }

  RowSelectedLadle(u: any, i: number) {
    this.selectedLadle = u;   // declare variable in component.
    this.selectedRowLadle = i;
    this.getMold();

    this.pieces = null;
  }
  

  getHeat() {

      if (this.selectedFurnace != null) {
          this.castingService.getCasting(this.selectedFurnace.idFurnace)
              .pipe(first())
              .subscribe(
                  data => {
                      if (data == false) {
                          this.error2 = "Consulta errónea.";
                      }
                      this.heats = data;
                  },
                  error => {
                      this.error = error;
                  });
      }    
  }


  getMaterial() {
    if (this.selectedCasting != null && this.selectedCasting.idCasting > 0)
    {
      this.castingService.getMaterialByCasting(this.selectedCasting.idCasting)
        .pipe(first())
        .subscribe(
          data => {
            if (data == false) {
              this.error2 = "Consulta errónea.";
            }
            this.materials = data;
          },
          error => {
            this.error = error;
          });
    }
  }

  getLadle() {
     if (this.selectedCasting != null && this.selectedCasting.idCasting > 0) {
      this.castingService.getLadleCastingByCasting(this.selectedCasting.idCasting)
        .pipe(first())
        .subscribe(
          data => {
            if (data == false) {
              this.error2 = "Consulta errónea.";
            }
            this.ladles = data;
          },
          error => {
            this.error = error;
          });
    }
  }


  getMold() {
    if (this.selectedLadle.idLadleCasting > 0) {
      this.castingService.getMoldByLadle(this.selectedLadle.idLadleCasting)
        .pipe(first())
        .subscribe(
          data => {
            if (data == false) {
              this.error2 = "Consulta errónea.";
            }
            this.molds = data;
          },
          error => {
            this.error = error;
          });
    }
  }


  RowSelectedMold(u: any, i: number) {
    this.selectedMoldLadle = u;   // declare variable in component.
    this.selectedRowMold = i;

    this.pieces = null;
    this.getPiece();
  }


  getPiece() {
    if (this.selectedMoldLadle.idMoldLadle > 0) {
      this.castingService.getPieceByMold(this.selectedMoldLadle.idMold)
        .pipe(first())
        .subscribe(
          data => {
            if (data == false) {
              this.error2 = "Consulta errónea.";
            }
            this.pieces = data;
          },
          error => {
            this.error = error;
          });
    }
  }




  ngOnInit()
  {
    //Inicializar valores controles del formulario
    this.iniciarControlesCasting();
    this.iniciarControlesMaterial();
    this.iniciarControlesLadle();
    this.iniciarControlesMold();
    this.iniciarControlesPiece();

    this.siteService.site(this.currentUser.idUserApplication)
      .pipe(first())
      .subscribe(
        data => {
          if (data == false) {
            this.error2 = "Consulta errónea.";
          }
          this.sites = data;

          //Se selecciona por defecto el site del usuario
          if (this.currentUser.idSiteDefault > 0) {
            this.selectedSite = this.sites.find(x => x.idSite == this.currentUser.idSiteDefault);
            this.RowSelectedSite();
          }
            
          else
            this.selectedSite = null;
        },
        error => {
          this.error = error;
        });
    
    this.selectedMeltingGroup = null;
    this.selectedFurnace = null;
  }

  redirectionHeat() {

    this.router.navigate(['/castingCreation']);

  }

  redirectionUpdateHeat() {

      this.clear('HeatAlert');
      
    if (this.selectedCasting != null) {
      this.router.navigate(['/castingCreation', { idCasting: this.selectedCasting.idCasting, idSite: this.selectedSite.idSite, idMeltingGroup: this.selectedMeltingGroup.idMeltingGroup }]);
    }
    else {
        this.error("Select valid Heat", 'HeatAlert');
    }

  }

    onDeleteHeat() {
        this.clear('HeatAlert');

        if (this.selectedCasting != null) {
            var body = JSON.stringify(this.selectedCasting);
            this.castingService.DeleteCasting(body)
                .pipe(first())
                .subscribe(
                    result => {
                        if (result == true) {
                            this.alertDeteteHeat('The casting has been deleted', 'HeatAlert');
                        }
                    },
                    error => {
                        console.log(error);
                        this.errorInfo = error;
                        this.alertDeteteHeat(error, 'HeatAlert');
                    });
        }
        else {
            this.errorInfo = 'Erro';
            this.alertDeteteHeat('Select valid Heat', 'HeatAlert');
        }
            
    }

    alertDeteteHeat(message: string, id: string) {

        if (this.errorInfo == '') {
            this.success(message, id);
            this.heats.splice(this.selectedRowCasting, 1);            
            this.selectedRowCasting = 0;
            this.selectedCasting = null;
            this.RowSelectedCasting(this.selectedCasting, this.selectedRowCasting);
        }
        else {
            this.error(message, id);
        }
    }


   redirectionMoldFill() {

      this.router.navigate(['/moldFillCreation']);

   }


  redirectionUpdateMoldFill() {
      this.clear('FillAlert');
    if (this.selectedMoldLadle != null) {
      this.router.navigate(['/moldFillCreation', {
        idMold: this.selectedMoldLadle.idMold, idSite: this.selectedSite.idSite }]);
    }
    else {
        this.error("Select valid Mold Fill", 'FillAlert');
    }

  }

  
    onDeleteMoldFill() {
        this.clear('FillAlert');

        if (this.selectedMoldLadle != null) {
            var body = JSON.stringify(this.selectedMoldLadle);
            this.castingService.DeleteMoldFill(body)
                .pipe(first())
                .subscribe(
                    result => {
                        if (result == true) {
                            this.alertDeleteFill('The mold fill has been deleted', 'FillAlert');
                        }
                    },
                    error => {
                        console.log(error);
                        this.errorInfo = error;
                        this.alertDeleteFill(error, 'FillAlert');
                    });
        }
        else {
            this.errorInfo = 'Erro';
            this.alertDeleteFill('Select valid Mold Fill', 'FillAlert');
        }
            
    }

    alertDeleteFill(message: string, id: string) {

        if (this.errorInfo == '') {
            this.success(message, id);
            this.molds.splice(this.selectedRowMold, 1);
            this.selectedRowMold = 0;
            this.selectedMoldLadle = null;
            this.RowSelectedMold(this.selectedMoldLadle, this.selectedRowMold);
        }
        else {
            this.error(message, id);
        }
    }


  redirectionUpdateLinkChemistry(info: any) {

        this.router.navigate(['/chemistryCreation', { idChemistry: info.idChemistry }]);
    }

    redirectionUpdateLinkMold(info: any) {

      this.router.navigate(['/moldCreation', { idMold: info.idMold, idSite: this.selectedSite.idSite }]);

    }

    redirectionUpdateLinkMoldType(info: any) {

      console.log(info);

      this.router.navigate(['/moldComponent', { idMoldType: info.idMoldType }]);

      
    }

    redirectionUpdateLinkArea(info: any) {

        this.router.navigate(['/area', { idArea: info.idArea }]);

    }

    redirectionUpdateLinkPieceType(info: any) {
        this.router.navigate(['/pieceTypeCreation', {
            idPieceType: info.idPieceType, idSite: this.selectedSite.idSite
        }]);

    }


    sortTableNum(n: number, tableName: string) {
        var table, rows, switching, i, x, y, shouldSwitch;
        table = document.getElementById(tableName);
        switching = true;
        /*Make a loop that will continue until
        no switching has been done:*/
        while (switching) {
            //start by saying: no switching is done:
            switching = false;
            rows = table.rows;
            /*Loop through all table rows (except the
            first, which contains table headers):*/
            for (i = 1; i < (rows.length - 1); i++) {
                //start by saying there should be no switching:
                shouldSwitch = false;
                /*Get the two elements you want to compare,
                one from current row and one from the next:*/
                x = (rows[i].getElementsByTagName("TD")[n]);
                y = (rows[i + 1].getElementsByTagName("TD")[n]);
                //check if the two rows should switch place:
                if (Number(x.innerHTML) > Number(y.innerHTML)) {
                    //if so, mark as a switch and break the loop:
                    shouldSwitch = true;
                    break;
                }
            }
            if (shouldSwitch) {
                /*If a switch has been marked, make the switch
                and mark that a switch has been done:*/
                rows[i].parentNode.insertBefore(rows[i + 1], rows[i]);
                switching = true;
            }
        }
    }

    myFunction(tableName: string) {
        var inputId: string = 'myInput';
        if (tableName == "TableMaterial")
            inputId = "myInputMaterial";
        else if (tableName == "TableLandle")
            inputId = "myInputLandle";
        else if (tableName == "TableMold")
            inputId = "myInputMold";
        else if (tableName == "TablePart")
            inputId = "myInputPart";

        var input, filter, table, tr, td, i, txtValue;
        input = document.getElementById(inputId);
        filter = input.value.toUpperCase();
        table = document.getElementById(tableName);
        tr = table.getElementsByTagName("tr");
        for (i = 0; i < tr.length; i++) {
            td = tr[i].getElementsByTagName("td")[0];
            if (td) {
                txtValue = td.textContent || td.innerText;
                if (txtValue.toUpperCase().indexOf(filter) > -1) {
                    tr[i].style.display = "";
                } else {
                    tr[i].style.display = "none";
                }
            }
        }
    }

    sortTable(n: number, tableName: string) {
        var table, rows, switching, i, x, y, shouldSwitch, dir, switchcount = 0;
        table = document.getElementById(tableName);
        switching = true;
        // Set the sorting direction to ascending:
        dir = "asc";
        /* Make a loop that will continue until
        no switching has been done: */
        while (switching) {
            // Start by saying: no switching is done:
            switching = false;
            rows = table.rows;
            /* Loop through all table rows (except the
            first, which contains table headers): */
            for (i = 1; i < (rows.length - 1); i++) {
                // Start by saying there should be no switching:
                shouldSwitch = false;
                /* Get the two elements you want to compare,
                one from current row and one from the next: */
                x = rows[i].getElementsByTagName("TD")[n];
                y = rows[i + 1].getElementsByTagName("TD")[n];
                /* Check if the two rows should switch place,
                based on the direction, asc or desc: */
                if (dir == "asc") {
                    if (x.innerHTML.toLowerCase() > y.innerHTML.toLowerCase()) {
                        // If so, mark as a switch and break the loop:
                        shouldSwitch = true;
                        break;
                    }
                } else if (dir == "desc") {
                    if (x.innerHTML.toLowerCase() < y.innerHTML.toLowerCase()) {
                        // If so, mark as a switch and break the loop:
                        shouldSwitch = true;
                        break;
                    }
                }
            }
            if (shouldSwitch) {
                /* If a switch has been marked, make the switch
                and mark that a switch has been done: */
                rows[i].parentNode.insertBefore(rows[i + 1], rows[i]);
                switching = true;
                // Each time a switch is done, increase this count by 1:
                switchcount++;
            } else {
                /* If no switching has been done AND the direction is "asc",
                set the direction to "desc" and run the while loop again. */
                if (switchcount == 0 && dir == "asc") {
                    dir = "desc";
                    switching = true;
                }
            }
        }
    }


  success(message: string, id:string) {
      this.alertService.success(message, id);
      this.mytimer.stop();
      this.mytimer.start();

  }

  error(message: string, id: string) {
      this.alertService.error(message, id);
      this.mytimer.stop();
      this.mytimer.start();
  }

  info(message: string) {
      this.alertService.info(message);
      this.mytimer.stop();
      this.mytimer.start();
  }

  warn(message: string) {
      this.alertService.warn(message);
      this.mytimer.stop();
      this.mytimer.start();
  }

  clear(id: string) {
      this.alertService.clear(id);
      this.errorInfo = '';
  }

}

