export class Utils
{

  public static remove_empty = function (target) {
  Object.keys(target).map(function (key) {
    if (target[key] instanceof Object) {
      if (!Object.keys(target[key]).length && typeof target[key].getMonth !== 'function') {
        delete target[key];
      }
      else {
        Utils.remove_empty(target[key]);
      }
    }
    else if (target[key] === null) {
      delete target[key];
    }
  });
  return target;
  };





}


