
export class testvariable {
    idCookieTest: number;
    idCookieTestVariable: number;
    idTestTypeVariable: number;
    testTypeVariableName: string;
    testTypeVariableUnit: string;    
    variableValue: string;
    description: string;
    dtCookieTest: Date;
    tested: boolean;
}  
