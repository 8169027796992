import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { EnvService } from './env.service';

import { User } from '@/_models';
import { Token } from '@/_models';
import { Message } from '@angular/compiler/src/i18n/i18n_ast';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
    private currentUserSubject: BehaviorSubject<User>;
    public currentUser: Observable<User>;

    private currentTokenSubject: BehaviorSubject<Token>;
    public currentToken: Observable<Token>;
    

  constructor(private http: HttpClient, private env: EnvService) {
        this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));
    this.currentUser = this.currentUserSubject.asObservable();
    this.currentTokenSubject = new BehaviorSubject<Token>(JSON.parse(localStorage.getItem('token')));
    this.currentToken = this.currentTokenSubject.asObservable();
    }

    public get currentUserValue(): User {
        return this.currentUserSubject.value;
  }

  public get currentTokenValue(): Token {
    return this.currentTokenSubject.value;
  }

    login(email: string, password: string) {   

      return this.http.post<any>(this.env.apiUrl+`/api/Login`, {email, password })
            .pipe(map(user => {
                // login successful if there's a jwt token in the response
              if (user) {
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                localStorage.setItem('currentUser', JSON.stringify(user));
                this.currentUserSubject.next(user);
              }
              else {                
                return false;
              }
              return user;
        }));
      
  }

  token(email: string, password: string) {
    return this.http.post<any>(this.env.apiUrl + `/api/Token`, { email, password })
      .pipe(map(token => {
        // login successful if there's a jwt token in the response
        if (token) {             
          // store user details and jwt token in local storage to keep user logged in between page refreshes
          localStorage.setItem('token', JSON.stringify(token));
          this.currentTokenSubject.next(token);
        }
        else {
          return false;
        }
        return token;
      }));

  }

    logout() {
        // remove user from local storage to log user out
      localStorage.removeItem('currentUser');
      localStorage.removeItem('token');
      localStorage.removeItem('site');
      localStorage.removeItem('menuItem');
        this.currentUserSubject.next(null);
    }
}
