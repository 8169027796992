import { moldcomponent } from './moldcomponent';
import { moldladle } from './moldladle';
import { piece } from './piece';
import { imagegallery } from './imagegallery';
import { environmental } from './environmental';

export class mold {   
    idMold: number;
    reference: string;
    dtMold: Date;
    realVolume: number;
    idArea: number;
    area: string;
    idMoldType: number;
    moldType: string;
    theoricalVolume: number;
    imageGallery: imagegallery;
    moldComponents: moldcomponent[];
    moldLadles: moldladle[];
    pieces: piece[];
    environmental: environmental;
 }
