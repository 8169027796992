import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { EnvService } from './env.service';

import { Message } from '@angular/compiler/src/i18n/i18n_ast';
import { shipment } from '../_models/shipment';
import { forEach } from '@angular/router/src/utils/collection';

@Injectable({ providedIn: 'root' })

export class ShipmentService {
  private currentShipmentSubject: BehaviorSubject<shipment[]>;
  public currentShipment: Observable<shipment[]>;

  constructor(private http: HttpClient, private env: EnvService) {
    this.currentShipmentSubject = new BehaviorSubject<shipment[]>(JSON.parse(localStorage.getItem('shipment')));
    this.currentShipment = this.currentShipmentSubject.asObservable();
  }

 returnShipmentByLab(lab: Number) {
   return this.http.get<any>(this.env.apiUrl + `/api/Package/lab/` + lab.toString())
     .pipe(map(shipment => {
        // store user details and jwt token in local storage to keep user logged in between page refreshes      

       this.currentShipmentSubject.next(shipment);
       return shipment;
      }));
    }


  returnShipmentBySite(site: Number) {
      return this.http.get<any>(this.env.apiUrl + `/api/Package/site/` + site.toString())
          .pipe(map(shipment => {
              // store user details and jwt token in local storage to keep user logged in between page refreshes      

              this.currentShipmentSubject.next(shipment);
              return shipment;
          }));
    }

    returnShipmentById(id: Number) {
        return this.http.get<any>(this.env.apiUrl + `/api/Package/` + id.toString())
            .pipe(map(shipment => {
                // store user details and jwt token in local storage to keep user logged in between page refreshes      

                this.currentShipmentSubject.next(shipment);
                return shipment;
            }));
    }
        

  SaveReception(body) {
      let headers = new HttpHeaders({
          'Content-Type': 'application/json'
      });
      console.log(body);
      console.log(headers);
      console.log(this.env.apiUrl + `/api/Reception`);
      return this.http.post(this.env.apiUrl + `/api/Reception`, body, { headers })
          .pipe(map(group => {
              return true;
          }));
  }

  SaveShipment(body) {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    console.log(body);
    console.log(headers);
      console.log(this.env.apiUrl + `/api/Package`);
      return this.http.post(this.env.apiUrl + `/api/Package`, body, { headers })
      .pipe(map(group => {
        return true;
      }));
  }


  DeleteShipment(body) {
      console.log("Body " + body);
      let options = {
          headers: new HttpHeaders({
              'Content-Type': 'application/json',
          }),
          body: body,
      };
      return this.http.delete(this.env.apiUrl + `/api/Package/`, options)
          .pipe(map(cookie => {
              return true;
          }));
  }
}


