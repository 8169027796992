import { Time } from '@angular/common';
import { site } from './site';

export class User {
    idUserApplication: number;
    name: string;    
    email: string;
    //token?: string;
    //tokenValidateTo?: Time;
  identid: string;
  idlanguage: number;    
  language: string;
  company: string;
  companyType: number;    
  idCompany: number;
  profile: string;
  companyIconRoute: string;
  idSiteDefault: number;
  sites: site[];  
}
