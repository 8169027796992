

export class ladlecasting {
  idLadleCasting: number;
  reference: string;
  idCasting: number;
  castingReference: string;
  idLadle: number;
  ladleReference: string;
  qtCasting: number;
  dtCreation: Date;
  idChemistry: number;
  chemistry: string;
}
