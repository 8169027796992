import { Component, ViewChild } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, FormArray, Validators } from '@angular/forms';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, first } from 'rxjs/operators';
import { EnvService } from '../../_services/env.service';
import { UserService, MoldTypeService } from '@/_services';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { AlertService } from '@/shared/alerts';
import { User, chemicalsystem } from '../../_models/index';
import { forEach } from '@angular/router/src/utils/collection';
import { Utils } from '../../shared/utils/utils'
import { utils } from 'protractor';
import { ActivatedRoute, Router} from "@angular/router";

@Component({ templateUrl: 'chemicalSystem.component.html' })

export class ChemicalSystem {
    formNameAddUpdate: string = "New Chemical System";
    idChemicalSystem: number = 0;
    description: string = "";
    reference: string = "";  
  errorInfo = '';
  error2 = '';
  chemicalSystemResultado: chemicalsystem;
  currentUser: User;
  
  chemicalSystemForm = this.fb.group({
    reference: [''],
    description: ['']
  });


  constructor(private fb: FormBuilder, private http: HttpClient, private env: EnvService,
    private moldTypeService: MoldTypeService, private alertService: AlertService, private user: UserService,
    private router: Router, private route: ActivatedRoute) {
    this.chemicalSystemResultado = new chemicalsystem();
    this.currentUser = this.user.currentUserInfoValue;
  }

  @ViewChild('warningModal', { read: false }) public warningModal: ModalDirective;

  iniciarControles() {
    this.chemicalSystemForm.patchValue({      
      reference: this.chemicalSystemResultado.reference,
        description: this.chemicalSystemResultado.description
    });
  }


  ngOnInit() {
    this.getParameter();
    this.iniciarControles();
    
    }

    getParameter() {

        if (this.route.snapshot.paramMap.get('idChemicalSystem') != null) {
            this.idChemicalSystem = (parseInt)(this.route.snapshot.paramMap.get('idChemicalSystem'));
            console.log("ID Site " + this.idChemicalSystem /*+ "  " + this.router.snapshot.params.idTemplateLabTest*/);
            this.formNameAddUpdate = "Update Chemical System";
            this.getChemicalSystemById();
            /*this.myBtn = true;
            this.myTab = true;
            //this.state = "disabled";
            this.pieceHeatTreatmentForm.get('areas').disable();
            this.pieceHeatTreatmentForm.get('sites').disable();
            this.pieceHeatTreatmentForm.get('pieces').disable();*/
        }
    }

    getChemicalSystemById()
    {
        this.moldTypeService.getChemicalSystemById(this.idChemicalSystem)
            .pipe(first())
            .subscribe(
                chemical => {
                    console.log(chemical)
                    this.chemicalSystemResultado = chemical;
                },
                error => {
                    console.log(error);
                    this.errorInfo = error;

                });
    }

  onSubmit() {
    //Se limpia el mensaje de enviado correctamente si se estaba mostrando
    this.clear('SaveCorrect');
    //Si el formulario es válido se envía la información
    if (this.chemicalSystemForm.valid) {

      this.chemicalSystemResultado.idChemicalSystem = this.idChemicalSystem;
      this.chemicalSystemResultado.reference = this.chemicalSystemForm.value.reference;
      this.chemicalSystemResultado.description = this.chemicalSystemForm.value.description;

      Utils.remove_empty(this.chemicalSystemResultado);

      var body = JSON.stringify(this.chemicalSystemResultado);


      this.moldTypeService.SaveChemicalSystem(body)
        .pipe(first())
        .subscribe(
          result => {
            if (result == true) {
              this.cambiosGuardadosCorrectamente();
            }
          },
          error => {
            this.errorInfo = error;
            this.error(error, 'SaveCorrect');
          });

    }


  }

  
  //Limpia todos los campos del formulario y las variables que se muestran en las tablas
  resetFormulario() {
    this.chemicalSystemForm.reset();
    this.formNameAddUpdate = "New Chemical System";
  }

  //Cuando se envía correctamente la información, se muestra el mensaje y se resetea el formulario
    cambiosGuardadosCorrectamente() {
        
    this.success('data saved correctly', 'SaveCorrect');
    //Se resetean todas la variables por si se desean insertar más datos      
      if (this.idChemicalSystem == 0) this.resetFormulario();
  }

  success(message: string, id: string) {
    this.alertService.success(message, id);
  }

  error(message: string, id: string) {
    this.alertService.error(message, id);
  }

  info(message: string) {
    this.alertService.info(message);
  }

  warn(message: string) {
    this.alertService.warn(message);
  }

  clear(id: string) {
    this.alertService.clear(id);
  }

}
