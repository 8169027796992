import { testtype } from './testtype';

export class template {    
    idTemplate: number;
    reference: string;
    numCookies: number;
    idFinalStateType: number;
    idLaboratory: number;
    finalStateTypeReference: string;
    testtypes: testtype[];
}
