import { Component, ViewChild } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, FormArray, Validators } from '@angular/forms';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, first } from 'rxjs/operators';
import { EnvService } from '../../_services/env.service';
import { UserService, PieceService } from '@/_services';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { AlertService } from '@/shared/alerts';
import { User, heattreatment } from '../../_models/index';
import { forEach } from '@angular/router/src/utils/collection';
import { Utils } from '../../shared/utils/utils'
import { utils } from 'protractor';
import { ActivatedRoute, Router} from "@angular/router";

@Component({ templateUrl: 'heatTreatment.component.html' })

export class HeatTreatment {
  formNameAddUpdate: string = "New Heat Treatment";
  idHeatTreatment: number = 0;
  description: string = "";
  reference: string = "";
  errorInfo = '';
  error2 = '';
  heattreatmentResultado: heattreatment;
  currentUser: User;

  heatTreatmentForm = this.fb.group({
    reference: ['', Validators.required],
    description: ['', Validators.required]
  });


  constructor(private fb: FormBuilder, private http: HttpClient, private env: EnvService,
    private pieceService: PieceService, private alertService: AlertService, private user: UserService,
    private router: Router, private route: ActivatedRoute) {
    this.heattreatmentResultado = new heattreatment();
    this.currentUser = this.user.currentUserInfoValue;
  }

  @ViewChild('warningModal', { read: false }) public warningModal: ModalDirective;

  iniciarControles() {
    this.heatTreatmentForm.patchValue({      
        reference: this.heattreatmentResultado.reference,
        description: this.heattreatmentResultado.description
    });
  }


  ngOnInit() {

    this.getParameter();
    this.iniciarControles();
    
  }

  onSubmit() {
    //Se limpia el mensaje de enviado correctamente si se estaba mostrando
    this.clear('SaveCorrect');

    //Si el formulario es válido se envía la información
    if (this.heatTreatmentForm.valid) {

      this.heattreatmentResultado.idHeatTreatment = this.idHeatTreatment;
      this.heattreatmentResultado.reference = this.heatTreatmentForm.value.reference;
      this.heattreatmentResultado.description = this.heatTreatmentForm.value.description;

      Utils.remove_empty(this.heattreatmentResultado);

      var body = JSON.stringify(this.heattreatmentResultado);


      this.pieceService.SaveHeatTreatment(body)
        .pipe(first())
        .subscribe(
          result => {
            if (result == true) {
              this.cambiosGuardadosCorrectamente();
            }
          },
          error => {
            this.errorInfo = error;
            this.error(error, 'SaveCorrect');
          });

    }


    }

    getParameter() {

        if (this.route.snapshot.paramMap.get('idHeatTreatment') != null) {
            this.idHeatTreatment = (parseInt)(this.route.snapshot.paramMap.get('idHeatTreatment'));
            console.log("ID Site " + this.idHeatTreatment /*+ "  " + this.router.snapshot.params.idTemplateLabTest*/);
            this.formNameAddUpdate = "Update Heat Treatment";
            this.getHeatTreatmentById();
            /*this.myBtn = true;
            this.myTab = true;
            //this.state = "disabled";
            this.pieceHeatTreatmentForm.get('areas').disable();
            this.pieceHeatTreatmentForm.get('sites').disable();
            this.pieceHeatTreatmentForm.get('pieces').disable();*/
        }
    }
    getHeatTreatmentById() {

        this.pieceService.getHeatTreatmentById(this.idHeatTreatment)
            .pipe(first())
            .subscribe(
                data => {
                    if (data == false) {
                        this.error2 = "Consulta errónea.";
                    }
                    this.heattreatmentResultado = data;
                    console.log(data);
                },
                error => {
                    this.error = error;
                    this.error(error, 'Error');

                });
    }

  
  //Limpia todos los campos del formulario y las variables que se muestran en las tablas
  resetFormulario() {
    this.heatTreatmentForm.reset();

  }

  //Cuando se envía correctamente la información, se muestra el mensaje y se resetea el formulario
    cambiosGuardadosCorrectamente() {
        this.idHeatTreatment = 0;
        this.description = "";
        this.reference = "";
        this.success('data saved correctly', 'SaveCorrect');
        //Se resetean todas la variables por si se desean insertar más datos
        this.resetFormulario();
  }

  success(message: string, id: string) {
    this.alertService.success(message, id);
  }

  error(message: string, id: string) {
    this.alertService.error(message, id);
  }

  info(message: string) {
    this.alertService.info(message);
  }

  warn(message: string) {
    this.alertService.warn(message);
  }

  clear(id: string) {
    this.alertService.clear(id);
  }

}
