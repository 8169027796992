import { materialcharge } from './materialcharge';
import { ladlecasting } from './ladlecasting';

export class casting {
  idCasting: number;
  reference: string;
  meltTemperature: number;
  qtCastingTotal: number;
  dtCasting: Date;
  holdTime: number;
  idFurnace: number;
  furnace: string;
  idChemistry: number;
  chemistry: string;
  materialCharges: materialcharge[];
  ladleCastings: ladlecasting[];
}

