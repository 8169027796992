import { cookie } from './cookie';

export class group {
    idBatch: number;
    reference: string;
    idBatchType: number;
    batchTypeReference: string;
    description: string;
    dtBatchCreation: Date;
    IdLab: number;
    IdSite: number;
    cookies: cookie[];
}
