import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { EnvService } from './env.service';

import { Message } from '@angular/compiler/src/i18n/i18n_ast';
import { template } from '../_models/template';
import { forEach } from '@angular/router/src/utils/collection';

@Injectable({ providedIn: 'root' })

export class TemplateService {
    private currentTemplateSubject: BehaviorSubject<template[]>;
    public currentTemplate: Observable<template[]>;

  constructor(private http: HttpClient, private env: EnvService) {
      this.currentTemplateSubject = new BehaviorSubject<template[]>(JSON.parse(localStorage.getItem('template')));
      this.currentTemplate = this.currentTemplateSubject.asObservable();
  }

  getTemplate(lab: Number) {
   return this.http.get<any>(this.env.apiUrl + `/api/Template/all/` + lab.toString())
     .pipe(map(template => {
        // store user details and jwt token in local storage to keep user logged in between page refreshes      

         this.currentTemplateSubject.next(template);
         return template;
      }));
    }

    getTemplateById(id: Number) {
        return this.http.get<any>(this.env.apiUrl + `/api/Template/` + id.toString())
            .pipe(map(template => {
                // store user details and jwt token in local storage to keep user logged in between page refreshes      

                this.currentTemplateSubject.next(template);
                return template;
            }));
    }

    getTestType(idtemplate: Number) {
      return this.http.get<any>(this.env.apiUrl + `/api/Template/testtype/` + idtemplate.toString())
      .pipe(map(testtype => {
        // store user details and jwt token in local storage to keep user logged in between page refreshes      

          //this.currentTemplateSubject.next(testtype);
          return testtype;
      }));
    }

    getTestTypeById(test: Number) {
        return this.http.get<any>(this.env.apiUrl + `/api/testtype/` + test.toString())
            .pipe(map(temp => {
                // store user details and jwt token in local storage to keep user logged in between page refreshes      

                //this.currentTemplateLabSubject.next(temp);
                return temp;
            }));
    }

  getTestTypeVariable(idtesttype: Number) {
        return this.http.get<any>(this.env.apiUrl + `/api/Template/testtypevariable/` + idtesttype.toString())
            .pipe(map(testtype => {
                // store user details and jwt token in local storage to keep user logged in between page refreshes      

                //this.currentTemplateSubject.next(testtype);
                return testtype;
            }));
  }

  SaveTemplate(body) {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    console.log(body);
    console.log(headers);
    console.log(this.env.apiUrl + `/api/Template`);
    return this.http.post(this.env.apiUrl + `/api/Template`, body, { headers })
      .pipe(map(group => {
        return true;
      }));
  }


    DeleteTemplate(body) {
        console.log("Body " + body);
        let options = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
            }),
            body: body,
        };
        return this.http.delete(this.env.apiUrl + `/api/Template`, options)
            .pipe(map(area => {
                return true;
            }));
    }
}


