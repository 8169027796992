import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { EnvService } from './env.service';

import { User } from '@/_models';
import { Token } from '@/_models';
import { Message } from '@angular/compiler/src/i18n/i18n_ast';
import { menuItems } from '../_models/menu';
import { forEach } from '@angular/router/src/utils/collection';

@Injectable({ providedIn: 'root' })
export class MenuService {
  private currentMenuSubject: BehaviorSubject<menuItems[]>;
  public currentMenu: Observable<menuItems[]>;
  
  constructor(private http: HttpClient, private env: EnvService) {
    this.currentMenuSubject = new BehaviorSubject<menuItems[]>(JSON.parse(localStorage.getItem('menuItem')));
    this.currentMenu = this.currentMenuSubject.asObservable();
  }

  menu(idUser: Number) {

    var remove_empty = function (target) {
      Object.keys(target).map(function (key) {
        if (target[key] instanceof Object) {
          if (!Object.keys(target[key]).length && typeof target[key].getMonth !== 'function') {
            delete target[key];
          }
          else {
            remove_empty(target[key]);
          }
        }
        else if (target[key] === null) {
          delete target[key];
        }
      });
      return target;
    };
            
    return this.http.get<any>(this.env.apiUrl + `/api/Menu/` + idUser.toString())
      .pipe(map(menuItem => {             
          // store user details and jwt token in local storage to keep user logged in between page refreshes
        menuItem.menu.forEach(element => {          
          //element.url = "/moldComponent";   
          //element.icon = "icon-speedometer";
          //element.children = [{ "name": "nivel2", "url": "/moldComponent", "icon": "icon-speedometer"}];         
        });
        remove_empty(menuItem);
        localStorage.setItem('menuItem', JSON.stringify(menuItem));        
        this.currentMenuSubject.next(menuItem);
        return menuItem;
      }));          
  }  

}
