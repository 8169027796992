import { Component, ViewChild } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, FormArray, Validators } from '@angular/forms';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, first } from 'rxjs/operators';
import { EnvService } from '../../_services/env.service';
import { SiteService, UserService, CastingService, MoldService, PieceTypeService } from '@/_services';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { AlertService } from '@/shared/alerts';
import { User, site, mold, area, piece, pieceType, moldcomponent, moldladle, ladlecasting } from '../../_models/index';
import { forEach } from '@angular/router/src/utils/collection';
import { Utils } from '../../shared/utils/utils'
import { utils } from 'protractor';
import { state } from '@angular/animations';
import { ActivatedRoute } from '@angular/router';

@Component({ templateUrl: 'moldFill.component.html' })

export class MoldFill {
  formNameAddUpdate: string = "New Mold Filling";
  idMoldLadle: number = 0;
  idMold: number;
  idSite: number;
  errorInfo = '';
  error2 = '';
  sites = [];  
  molds = [];
  realVolume: number = null;
  theoreticVolume: number = null;
  moldDate: Date = null;
  ladleAvailableQuantity: number = null;
  ladles = [];
  areas = [];
  infoTableMoldLadle: moldladle[] = [];
  infoTablePieceTypes: pieceType[] = [];
  selectedPieceTypeReference: string = "";
  cant: number = 0;
  infoTablePiece: piece[] = [];
  infoTableMoldComponent: moldcomponent[] = [];
  moldCompSelect: moldcomponent;
  selectedSite: site;
  selectedMold: mold;
  selectedLadle: ladlecasting;
  selectedPieceType: pieceType;
  selectedRowPieceType: number;
  selectedMoldComp: moldcomponent;
  selectedRowMoldComp: number;
  selectedArea: area;
  selectedRowMoldLadle: number;
  selectedMoldLadle: moldladle;
  selectedPiece: piece;
  selectedRowPiece: number;
  moldResultado: mold;  
  currentUser: User;
  state: boolean[] = [];


  fillForm = this.fb.group({     
    sites: [null, Validators.required],  
    molds: [null, Validators.required],
    fill: this.fb.group({
      ladles: [null],
      quantity: [''],
      pourTemp: [''],
      pourDuration:[''],
      fillDate: ['']
    }),
    piece: this.fb.group({
      areas:[null],
      referencePiece: [''],
      timeShakeout: [''],
      pieceDate: [''],
      descriptionPiece: ['']
    })
  });

   

  constructor(private fb: FormBuilder, private http: HttpClient, private env: EnvService,
    private siteService: SiteService, private castingService: CastingService, private moldService: MoldService,
    private pieceTypeService: PieceTypeService, private user: UserService, private alertService: AlertService, private route: ActivatedRoute) {
    this.moldResultado = new mold();
    this.currentUser = this.user.currentUserInfoValue;
  }

  //Controlar la ventana popup de editar pieceType
  @ViewChild('warningModal', { read: false }) public warningModal: ModalDirective;
  @ViewChild('warningModal2', { read: false }) public warningModal2: ModalDirective;

  
  iniciarControlesMold() {
    this.fillForm.patchValue({
      sites: null,
      molds:null
    });
  }
  

  iniciarControlesFill() {
    this.fillForm.patchValue({
      fill: {
        ladles: null,
        quantity: '',
        pourTemp: '',
        pourDuration: '',
        fillDate: null
      }
    });

    this.ladleAvailableQuantity = null;
  }

  iniciarControlesPiece() {
    this.fillForm.patchValue({
      piece: {
        areas: null,
        referencePiece: '',
        timeShakeout: '',
        pieceDate: null,
        descriptionPiece:''
      }
    });
    
  }  
  

  

  RowSelectedSite() {
    if (this.selectedSite != null)
    {
      this.getArea();
      this.getMold();      
      this.getLadle();

      this.selectedPieceTypeReference = "Part Number";
      this.infoTablePieceTypes = null;
      this.theoreticVolume = null;
      this.moldDate = null;
      this.ladleAvailableQuantity = null;
    }
    else
    {
      this.molds = null;
      this.ladles = null;
      this.areas = null;

      this.selectedMold = null;
      this.selectedLadle = null;
      this.selectedArea = null;

      this.selectedPieceTypeReference = "Part Number";
      this.infoTableMoldLadle = null;
      this.infoTablePieceTypes = null;
      this.infoTablePiece = null;
    }
  }
  

  getArea() {
    this.siteService.getArea(this.selectedSite.idSite, this.currentUser.idUserApplication)
      .pipe(first())
      .subscribe(
        data => {
          if (data == false) {
            this.error2 = "Consulta errónea.";
          }
          this.areas = data;
        },
        error => {
          this.error = error;
        });

  }


  getLadle() {
    this.castingService.getLadleCastingBySite(this.selectedSite.idSite)
      .pipe(first())
      .subscribe(
        data => {
          if (data == false) {
            this.error2 = "Consulta errónea.";
          }
          this.ladles = data;
          this.ladleAvailableQuantity;
        },
        error => {
          this.error = error;
        });
  }

  RowSelectedLadleCasting() {
    if (this.selectedLadle != null) {
      this.ladleAvailableQuantity = this.selectedLadle.qtCasting;
    }
    else {
      this.ladleAvailableQuantity = null;
    }
  }

  RowSelectedMold() {
    if (this.selectedMold != null) {
      this.theoreticVolume = this.fillForm.value.molds.theoricalVolume;
      this.moldDate = this.fillForm.value.molds.dtMold;
      this.getPieceType();


      this.selectedPieceTypeReference = "Part Number";
      this.infoTablePieceTypes = null;
    }
    else {
      this.theoreticVolume = null;
      this.moldDate = null;
      this.selectedPieceTypeReference = "Part Number";
      this.infoTablePieceTypes = null;
    }
  }
    

  getPieceType() {
    this.pieceTypeService.getPieceTypeByMold(this.selectedMold.idMold, this.selectedSite.idSite)
      .pipe(first())
      .subscribe(
        data => {
          if (data == false) {
            this.error2 = "Consulta errónea.";
          }
          this.infoTablePieceTypes = data;
        },
        error => {
          this.error = error;
        });
  }


  RowSelectedPieceType(u: any, i: number) {

    this.selectedPieceType = u;   // declare variable in component.
    this.selectedRowPieceType = i;
    
    
    if (this.selectedPieceType != null) {      
      this.selectedPieceTypeReference = this.selectedPieceType.reference;
    }
    else
      this.selectedPieceTypeReference = "Part Number";
    
  }


  getUserSelect(ev, index) {
  //  console.log(ev.target.checked);
  //  console.log(this.infoTablePiece);
    if (ev.target.checked == true && this.infoTablePiece[this.selectedRowPiece].moldComps.length == 0)
      this.infoTablePiece[this.selectedRowPiece].moldComps.push(this.infoTableMoldComponent[index]);

    else if (ev.target.checked == true && this.infoTablePiece[this.selectedRowPiece].moldComps.length > 0 && this.infoTablePiece[this.selectedRowPiece].moldComps.find(item => item.idMoldComponent == this.infoTableMoldComponent[index].idMoldComponent) == null)
      this.infoTablePiece[this.selectedRowPiece].moldComps.push(this.infoTableMoldComponent[index]);

    else if (ev.target.checked == false && this.infoTablePiece[this.selectedRowPiece].moldComps.length > 0 && !(this.infoTablePiece[this.selectedRowPiece].moldComps.find(item => item.idMoldComponent == this.infoTableMoldComponent[index].idMoldComponent) == null))
      this.infoTablePiece[this.selectedRowPiece].moldComps.splice(this.infoTablePiece[this.selectedRowPiece].moldComps.findIndex(item => item.idMoldComponent == this.infoTableMoldComponent[index].idMoldComponent), 1);

    //console.log(this.infoTablePiece);
  }



 getinfoTableMoldComponent() {

    if (this.selectedMold != null && this.selectedPiece != null)
    {
        this.moldService.getComponentByMoldPieceType(this.selectedMold.idMold, this.selectedPiece.idPieceType)
            .pipe(first())
            .subscribe(
                data => {
                    if (data == false) {
                        this.error2 = "Consulta errónea.";
                    }
                    this.infoTableMoldComponent = data;
                    this.infoTableMoldComponent.forEach(item => this.state.push(false));

                    if (this.infoTablePiece[this.selectedRowPiece].moldComps != null) {
                        var i = 0;
                        this.infoTableMoldComponent.forEach(item => {
                            if (!(this.infoTablePiece[this.selectedRowPiece].moldComps.find(p => p.idMoldComponent == item.idMoldComponent) == null))
                                this.state[i] = true;
                            else
                                this.state[i] = false;

                            i++;
                        });
                    }

                },
                error => {
                    this.error = error;
                });

    }
   
  }

  RowSelectedPiece(u: any, i: number) {
    this.selectedPiece = u;   // declare variable in component.
    this.selectedRowPiece = i;
      
      console.log(this.selectedPiece);

      if (this.selectedPiece == null)
          this.infoTableMoldComponent = [];
      else
        this.getinfoTableMoldComponent();

    //if (this.infoTablePiece[this.selectedRowPiece].moldComps != null) {
    //  var i = 0;
    //  this.infoTableMoldComponent.forEach(item => {
    //    if (!(this.infoTablePiece[this.selectedRowPiece].moldComps.find(p => p.idMoldComponent == item.idMoldComponent) == null))
    //      this.state[i] = true;
    //    else
    //      this.state[i] = false;

    //    i++;
    //  });
    //}
  }

  addMoldLadle() {
    this.clear('alertFill');


    if (this.fillForm.controls["fill"].value.quantity > this.ladleAvailableQuantity) {
      this.error('You can not pour more than the available quantity', 'alertFill');
      return;
    }

    //Si se supera la cantidad teórica del molde, da error
    if (this.fillForm.controls["fill"].value.quantity > this.selectedMold.theoricalVolume) {
      this.error('This fill quantity is higher than the mold theoretical volume', 'alertFill');
      return;
    }

    if (this.realVolume + this.fillForm.controls["fill"].value.quantity > this.selectedMold.theoricalVolume) {
      this.error('With this quantity, you will exceed the mold theoretical volume', 'alertFill');
      return;
    }

    if (this.fillForm.controls["fill"].value.fillDate < this.selectedMold.dtMold) {
      this.error('The Pouring can not be done before the Mold Creation Date', 'alertFill');
      return;
    }

     //this.selectedLadle != null && 
    if (this.fillForm.controls["fill"].value.quantity != null
      && this.fillForm.controls["fill"].value.pourTemp != null && this.fillForm.controls["fill"].value.pourDuration != null
      && this.fillForm.controls["fill"].value.fillDate != null) {


      var aux = new moldladle();
      aux.idMoldLadle = 0;
      aux.idLadleCasting = this.fillForm.controls["fill"].value.ladles.idLadleCasting;
      aux.ladleCastingReference = this.fillForm.controls["fill"].value.ladles.reference;
      aux.quantity = this.fillForm.controls["fill"].value.quantity;
      aux.pourTemperature = this.fillForm.controls["fill"].value.pourTemp;
      aux.pourTime = this.fillForm.controls["fill"].value.pourDuration;
      aux.dtFillTime = this.fillForm.controls["fill"].value.fillDate;

      this.infoTableMoldLadle.push(aux);
      this.iniciarControlesFill();
      this.realVolume += aux.quantity;
    }
    else {
      this.error('Any of the values entered is not correct', 'alertFill');
    }
  }


  RowSelectedMoldLadle(u: any, i: number) {
    this.selectedMoldLadle = u;
    this.selectedRowMoldLadle = i;

  }


  RowDeleteMoldLadle(u: any, i: number) {
    this.infoTableMoldLadle.splice(i, 1);
    this.realVolume -= u.quantity;
  }

  CargarMoldLadle(u: any) {

    this.fillForm.patchValue({
      fill: {
        quantity: u.quantity,
        pourTemp: u.pourTemperature,
        pourDuration: u.pourTime,
        fillDate: u.dtFillTime
      }
    });

    this.ladles.forEach(ladle => {
      if (ladle.reference == this.selectedMoldLadle.ladleCastingReference) {
        this.selectedLadle = ladle;

        this.ladleAvailableQuantity = this.selectedLadle.qtCasting;
      }
    });
  }

  updateMoldLadle() {

    this.clear('alertEditFill');

    if (this.infoTablePiece.find((x => x.dtArticleStock < this.fillForm.controls["fill"].value.fillDate))) {
      this.error('The Pour Date can not be after the Part Creation Form', 'alertEditFill');
      return;
    }


    if (this.fillForm.controls["fill"].value.quantity > 0 && this.fillForm.controls["fill"].value.pourTemp != null && this.fillForm.controls["fill"].value.pourDuration != null
      && this.fillForm.controls["fill"].value.fillDate != null) {

      this.realVolume -= this.selectedMoldLadle.quantity;

      if (this.fillForm.controls["fill"].value.quantity > this.selectedMold.theoricalVolume) {
        this.realVolume += this.selectedMoldLadle.quantity;
        this.error('This fill quantity is higher than the mold theoretical volume', 'alertEditFill');
        return;
      }

      if (this.realVolume + this.fillForm.controls["fill"].value.quantity > this.selectedMold.theoricalVolume) {
        this.realVolume += this.selectedMoldLadle.quantity;
        this.error('With this quantity, you will exceed the mold theoretical volume', 'alertEditFill');
        return;
      }

      if (this.fillForm.controls["fill"].value.fillDate < this.selectedMold.dtMold) {
        this.realVolume += this.selectedMoldLadle.quantity;
        this.error('The Pouring can not be done before the Mold Creation Date', 'alertEditFill');        
        return;
      }

      this.infoTableMoldLadle.forEach(item => {
        if (item.idMoldLadle == this.selectedMoldLadle.idMoldLadle) {
          
          item.quantity = this.fillForm.controls["fill"].value.quantity;
          item.pourTemperature = this.fillForm.controls["fill"].value.pourTemp;
          item.pourTime = this.fillForm.controls["fill"].value.pourDuration;
          item.dtFillTime = this.fillForm.controls["fill"].value.fillDate;
          
          this.realVolume += this.fillForm.controls["fill"].value.quantity;

        }
      });
      
      this.warningModal.hide();
      this.iniciarControlesFill();
    }
    else {
      this.error('Any of the values entered is not correct', 'alertEditFill');
    }
  }



  addPiece() {
    this.clear('alertPiece');

    this.cant = 0;
    this.infoTablePiece.forEach(x => {
      if (this.selectedPieceType.reference == x.pieceTypeReference)
        this.cant += 1;
    });


    if (this.selectedPieceType.numArticle <= this.cant) {
      this.error('The number of Parts assigned to the mold must be the number of Part numbers assigned to Mold Type. You can not add more parts of the selected part number', 'alertPiece');
      return;
    }

    if (this.fillForm.controls["piece"].value.pieceDate < this.selectedMold.dtMold) {
      this.error('The Part can not be created before the Mold Creation Date', 'alertPiece');
      return;
    }
        

    if (this.infoTableMoldLadle.find((x => x.dtFillTime > this.fillForm.controls["piece"].value.pieceDate))) {
      this.error('The Part can not be created before the Mold Fill(Pour Date) ', 'alertPiece');
      return;
    }


    if (this.selectedPieceType != null && this.selectedArea != null && this.fillForm.controls["piece"].value.referencePiece != null
      && this.fillForm.controls["piece"].value.timeShakeout != null && this.fillForm.controls["piece"].value.pieceDate != null ) {

      //Si ya se ha añadido una pieza con esa referencia, se muestra el aviso de que ya existe y no se añade de nuevo
      if (this.infoTablePiece.find((x => x.reference == this.fillForm.controls["piece"].value.referencePiece))) {
        this.error('This piece has already been added', 'alertPiece');
        return;
      }

      var aux = new piece();
      aux.idPiece = 0;
      
      aux.reference = this.fillForm.controls["piece"].value.referencePiece;
      aux.timeUntilShakeout = this.fillForm.controls["piece"].value.timeShakeout;
      aux.dtArticleStock = this.fillForm.controls["piece"].value.pieceDate;
      aux.description = this.fillForm.controls["piece"].value.descriptionPiece;
      aux.idPieceType = this.selectedPieceType.idArticle;
      aux.pieceTypeReference = this.selectedPieceType.reference;

      
      if (this.fillForm.value.sites != null) {
        aux.idSite = this.fillForm.value.sites.idSite;
      }

      if (this.fillForm.controls["piece"].value.areas != null) {
        aux.idArea = this.fillForm.controls["piece"].value.areas.idArea;
        aux.area = this.fillForm.controls["piece"].value.areas.reference;
      }


      aux.moldComps = [];

      this.infoTablePiece.push(aux);
      this.iniciarControlesPiece();
    }
    else {
      this.error('Any of the values entered is not correct', 'alertPiece');
    }
  }

  RowDeletePiece(u: any, i: number) {
      this.infoTablePiece.splice(i, 1);
      this.RowSelectedPiece(null, 0);
  }


  CargarPiece(u: any) {

    console.log(u);
    this.fillForm.patchValue({
      piece: {
        timeShakeout: u.timeUntilShakeout,
        pieceDate: u.dtArticleStock,
        descriptionPiece: u.description,
        
      }
    });
    console.log(this.fillForm.patchValue({ piece }));
  }

  updatePiece() {

    this.clear('alertEditPiece');

    if (this.fillForm.controls["piece"].value.pieceDate < this.selectedMold.dtMold) {
      this.error('The Part can not be created before the Mold Creation Date', 'alertPiece');
      return;
    }


    if (this.infoTableMoldLadle.find((x => x.dtFillTime > this.fillForm.controls["piece"].value.pieceDate))) {
      this.error('The Part can not be created before the Mold Fill(Pour Date) ', 'alertPiece');
      return;
    }


    if (this.fillForm.controls["piece"].value.timeShakeout != null && this.fillForm.controls["piece"].value.pieceDate != null) {

      this.infoTablePiece.forEach(item => {
        if (item.reference == this.selectedPiece.reference) {

          item.timeUntilShakeout = this.fillForm.controls["piece"].value.timeShakeout;
          item.dtArticleStock = this.fillForm.controls["piece"].value.pieceDate;
          item.description = this.fillForm.controls["piece"].value.descriptionPiece;

          console.log(this.fillForm.controls["piece"].value);
          console.log(item);
        }
      });

      this.warningModal2.hide();
      this.iniciarControlesPiece();
    }
    else {
      this.error('Any of the values entered is not correct', 'alertEditPiece');
    }
  }



 
  onSubmit() {    
    //Se limpia el mensaje de enviado correctamente si se estaba mostrando
    this.clear('SaveCorrect');        

    //Si el formulario es válido se envía la información
    if (this.fillForm.valid) {      

      this.moldResultado.idMold = this.fillForm.value.molds.idMold;
      this.moldResultado.realVolume = this.realVolume;   
      this.moldResultado.moldLadles = [];
      this.infoTableMoldLadle.forEach(item => {
        this.moldResultado.moldLadles.push(item);
      });

      this.moldResultado.pieces = [];
      this.infoTablePiece.forEach(item => {
        this.moldResultado.pieces.push(item);
      });

      Utils.remove_empty(this.moldResultado);

      var body = JSON.stringify(this.moldResultado);

      console.log(this.moldResultado);
      console.log(body);

      this.castingService.SaveFill(body)
        .pipe(first())
        .subscribe(
          result => {
            if (result == true) {
              this.cambiosGuardadosCorrectamente();
            }
          },
          error => {
            this.errorInfo = error;
            this.error(error, 'SaveCorrect'); 
          });

    }

  } 

  ngOnInit()
  {    
    //Inicializar valores controles del formulario
    this.iniciarControlesMold();
    this.iniciarControlesFill();
    this.iniciarControlesPiece();

    this.getParameter();
  }


  getParameter() {

    if (this.route.snapshot.paramMap.get('idMold') != null) {


      this.idMold = (parseInt)(this.route.snapshot.paramMap.get('idMold'));
      this.idSite = (parseInt)(this.route.snapshot.paramMap.get('idSite'));
      

      this.formNameAddUpdate = "Update Mold Filling";

      this.getSite();

      this.selectedLadle = null;
      this.selectedArea = null;
      this.selectedPieceTypeReference = "Part Number";
    }
    else {

      this.getSite();

      this.selectedSite = null;
      this.selectedMold = null;
      this.selectedLadle = null;
      this.selectedArea = null;

      this.realVolume = 0;
      this.theoreticVolume = null;
      this.selectedPieceTypeReference = "Part Number";
      this.moldDate = null;
      this.ladleAvailableQuantity = null;

      this.infoTableMoldLadle = [];
      this.infoTablePieceTypes = [];
      this.infoTablePiece = [];

    }
  }

  getSite() {
    this.siteService.site(this.currentUser.idUserApplication)
      .pipe(first())
      .subscribe(
        data => {
          if (data == false) {
            this.errorInfo = "Consulta errónea.";
          }
          this.sites = data;
          if (this.idSite > 0) {
            this.selectedSite = this.sites.find(s => s.idSite == this.idSite);
            this.getArea();
            this.getMold();
            this.getLadle();
          }
          else {
            //Se selecciona por defecto el site del usuario
            if (this.currentUser.idSiteDefault > 0) {
              this.selectedSite = this.sites.find(x => x.idSite == this.currentUser.idSiteDefault);
              this.getArea();
              this.getMold();
              this.getLadle();
            }
            
          }
            console.log(this.idSite);
        },
        error => {
          this.errorInfo = error;

        });
  }

  getMold() {
    this.moldService.getBySite(this.selectedSite.idSite)
      .pipe(first())
      .subscribe(
        data => {
          if (data == false) {
            this.error2 = "Consulta errónea.";
          }
          this.molds = data;
          
          if (this.idMold > 0) {
            this.getMoldById();
            this.selectedMold = this.molds.find(s => s.idMold == this.idMold);
          }
          
        },
        error => {
          this.error = error;
        });
  }

  getMoldById() {

    this.castingService.getMoldById(this.idMold)
      .pipe(first())
      .subscribe(
        data => {
          if (data == false) {
            this.error2 = "Consulta errónea.";
          }
          this.moldResultado = data;

          if (this.moldResultado != null) {

            this.realVolume = this.moldResultado.realVolume;
            this.theoreticVolume = this.moldResultado.theoricalVolume;
            this.moldDate = this.moldResultado.dtMold;
            //this.fillForm.value.molds.theoricalVolume = this.moldResultado.theoricalVolume;

            this.getMoldLadle();
            this.getPieces();
            this.getPieceType();
          }
        },
        error => {
          this.error = error;
        });

  }


  getMoldLadle() {
    this.castingService.getMoldLadleByMold(this.idMold)
      .pipe(first())
      .subscribe(
        data => {
          if (data == false) {
            this.error2 = "Consulta errónea.";
          }
          this.infoTableMoldLadle = data;
        },
        error => {
          this.error = error;
        });
  }



  getPieces() {
    this.castingService.getPieceByMold(this.idMold)
      .pipe(first())
      .subscribe(
        data => {
          if (data == false) {
            this.error2 = "Consulta errónea.";
          }
          this.infoTablePiece = data;
          console.log(data);
        },
        error => {
          this.error = error;
        });
  }


  //Limpia todos los campos del formulario y las variables que se muestran en las tablas
  resetFormulario() {
    this.fillForm.reset();
    this.formNameAddUpdate = "New Mold Filling";
    this.selectedSite = null;
    this.selectedMold = null;
    this.selectedLadle = null;
    this.selectedArea = null;

    this.realVolume = 0;
    this.theoreticVolume = null;
    this.selectedPieceTypeReference = "Part Number";
    this.moldDate = null;
    this.ladleAvailableQuantity = null;

    this.infoTableMoldLadle = [];
    this.infoTablePieceTypes = [];
    this.infoTablePiece = [];
    this.infoTableMoldComponent = [];
    this.state = [];
  }

  //Cuando se envía correctamente la información, se muestra el mensaje y se resetea el formulario
  cambiosGuardadosCorrectamente() {
    this.success('data saved correctly', 'SaveCorrect');
    //Se resetean todas la variables por si se desean insertar más datos
    if (this.idMold == 0) this.resetFormulario();
  }


  success(message: string, id:string) {
    this.alertService.success(message, id);
  }

  error(message: string, id: string) {
    this.alertService.error(message, id);
  }

  info(message: string) {
    this.alertService.info(message);
  }

  warn(message: string) {
    this.alertService.warn(message);
  }

  clear(id: string) {
    this.alertService.clear(id);
  }

}

