import { Component, ViewChild } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, FormArray, Validators } from '@angular/forms';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, first } from 'rxjs/operators';
import { EnvService } from '../../_services/env.service';
import { TestTypeService, UserService, PieceService } from '@/_services';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { AlertService } from '@/shared/alerts';
import { User, heattreatment } from '../../_models/index';
import { forEach } from '@angular/router/src/utils/collection';
import { Utils } from '../../shared/utils/utils'
import { utils } from 'protractor';
import { Router } from "@angular/router";


@Component({ templateUrl: 'heatTreatmentList.component.html' })

export class HeatTreatmentList {
  errorInfo = '';
  error2 = '';
  heatTreatments = [];
  currentUser: User;
  rowSelected: heattreatment;
  selectedIndex: number;

  heatTreatmentListForm = this.fb.group({     
     heatTreatments: this.fb.group({
     reference: [''],
     description: ['']
     })
  });

  constructor(private fb: FormBuilder, private http: HttpClient, private env: EnvService,
    private heatTreatmentService: PieceService, private user: UserService, private alertService: AlertService, private router: Router) {
      this.currentUser = this.user.currentUserInfoValue;
  }

  iniciarControlesHeatTreatment() {
    this.heatTreatmentListForm.patchValue({
      heatTreatments: {
        reference: null,
        description: null
       }
    });
  }

    RowSelected(u: any, i: number) {
        console.log(u);
        console.log(i);
        this.rowSelected = u;
        this.selectedIndex = i;
    }

  

  ngOnInit()
  {
    //Inicializar valores controles del formulario    
    this.iniciarControlesHeatTreatment();

    this.heatTreatmentService.getHeatTreatment()
      .pipe(first())
      .subscribe(
        data => {
          if (data == false) {
            this.error2 = "Consulta errónea.";
          }
          this.heatTreatments = data;
        },
        error => {
          this.error = error;
        });    
  }

  redirection() {

    this.router.navigate(['/heatTreatmentCreation']);

    }

    redirectionUpdate() {
        if (this.rowSelected != null) {
            console.log("Site ID " + this.rowSelected.idHeatTreatment);
            this.router.navigate(['/heatTreatmentCreation', {
                idHeatTreatment: this.rowSelected.idHeatTreatment
            }]);
        }
        else {
            this.error("Select valid Heat Treatment", 'Update');
        }
    }

    onDelete() {
        this.clear('Defect');

        if (this.rowSelected != null) {
            console.log("Entra eliminar");
            var body = JSON.stringify(this.rowSelected);
            this.heatTreatmentService.DeleteHeatTreatmentType(body)
                .pipe(first())
                .subscribe(
                    result => {
                        if (result == true) {
                            this.alertDetete('The heat treatment has been deleted', 'Delete');
                        }
                    },
                    error => {
                        console.log(error);
                        this.errorInfo = error;
                        this.alertDetete(error, 'Defect');
                    });
        }
        else
            this.alertDetete('An error has occurred, select valid heat treatment', 'Delete');
    }

    alertDetete(message: string, id: string) {
        console.log(message, id);
        if (this.errorInfo == '' && this.selectedIndex > 0) {
            this.success(message, id);
            this.heatTreatments.splice(this.selectedIndex, 1);
            this.selectedIndex = 0;
            this.rowSelected = null;
        }
        else {
            this.error(message, id);
        }

    }

    sortTableNum(n: number, tableName: string) {
        var table, rows, switching, i, x, y, shouldSwitch;
        table = document.getElementById(tableName);
        switching = true;
        /*Make a loop that will continue until
        no switching has been done:*/
        while (switching) {
            //start by saying: no switching is done:
            switching = false;
            rows = table.rows;
            /*Loop through all table rows (except the
            first, which contains table headers):*/
            for (i = 1; i < (rows.length - 1); i++) {
                //start by saying there should be no switching:
                shouldSwitch = false;
                /*Get the two elements you want to compare,
                one from current row and one from the next:*/
                x = (rows[i].getElementsByTagName("TD")[n]);
                y = (rows[i + 1].getElementsByTagName("TD")[n]);
                //check if the two rows should switch place:
                if (Number(x.innerHTML) > Number(y.innerHTML)) {
                    //if so, mark as a switch and break the loop:
                    shouldSwitch = true;
                    break;
                }
            }
            if (shouldSwitch) {
                /*If a switch has been marked, make the switch
                and mark that a switch has been done:*/
                rows[i].parentNode.insertBefore(rows[i + 1], rows[i]);
                switching = true;
            }
        }
    }

    myFunction(tableName: string) {
        var inputId: string = 'myInput';
        //if (tableName == "TableHeat")
        //    inputId = "myInputHeat";
        //else if (tableName == "TableDefect")
        //    inputId = "myInputDefect";

        console.log("table " + tableName);
        var input, filter, table, tr, td, i, txtValue;
        console.log(document.getElementById(inputId));
        console.log(document.getElementById(tableName));
        input = document.getElementById(inputId);
        filter = input.value.toUpperCase();
        table = document.getElementById(tableName);
        tr = table.getElementsByTagName("tr");
        for (i = 0; i < tr.length; i++) {
            td = tr[i].getElementsByTagName("td")[0];
            if (td) {
                txtValue = td.textContent || td.innerText;
                if (txtValue.toUpperCase().indexOf(filter) > -1) {
                    tr[i].style.display = "";
                } else {
                    tr[i].style.display = "none";
                }
            }
        }
    }

    sortTable(n: number, tableName: string) {
        console.log("Order table " + tableName);
        var table, rows, switching, i, x, y, shouldSwitch, dir, switchcount = 0;
        table = document.getElementById(tableName);
        switching = true;
        // Set the sorting direction to ascending:
        dir = "asc";
        /* Make a loop that will continue until
        no switching has been done: */
        while (switching) {
            // Start by saying: no switching is done:
            switching = false;
            rows = table.rows;
            /* Loop through all table rows (except the
            first, which contains table headers): */
            for (i = 1; i < (rows.length - 1); i++) {
                // Start by saying there should be no switching:
                shouldSwitch = false;
                /* Get the two elements you want to compare,
                one from current row and one from the next: */
                x = rows[i].getElementsByTagName("TD")[n];
                y = rows[i + 1].getElementsByTagName("TD")[n];
                /* Check if the two rows should switch place,
                based on the direction, asc or desc: */
                if (dir == "asc") {
                    if (x.innerHTML.toLowerCase() > y.innerHTML.toLowerCase()) {
                        // If so, mark as a switch and break the loop:
                        shouldSwitch = true;
                        break;
                    }
                } else if (dir == "desc") {
                    if (x.innerHTML.toLowerCase() < y.innerHTML.toLowerCase()) {
                        // If so, mark as a switch and break the loop:
                        shouldSwitch = true;
                        break;
                    }
                }
            }
            if (shouldSwitch) {
                /* If a switch has been marked, make the switch
                and mark that a switch has been done: */
                rows[i].parentNode.insertBefore(rows[i + 1], rows[i]);
                switching = true;
                // Each time a switch is done, increase this count by 1:
                switchcount++;
            } else {
                /* If no switching has been done AND the direction is "asc",
                set the direction to "desc" and run the while loop again. */
                if (switchcount == 0 && dir == "asc") {
                    dir = "desc";
                    switching = true;
                }
            }
        }
    }


  success(message: string, id:string) {
    this.alertService.success(message, id);
  }

  error(message: string, id: string) {
    this.alertService.error(message, id);
  }

  info(message: string) {
    this.alertService.info(message);
  }

  warn(message: string) {
    this.alertService.warn(message);
  }

  clear(id: string) {
      this.alertService.clear(id);
      this.errorInfo = '';
  }

}

