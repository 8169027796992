import { imagegallery } from '.';

export class cookie {
  idCookie: number;
  reference: string;
  creationDate: Date;  
  descriptionCreated: string;
  idMoldComponent: number;
  idMold: number;
  idBatch: number;
  batchReference: string;
  sent: boolean;
  received: boolean;
  tested: boolean;
  moldComponentReference: string;
  moldReference: string;
  receptionType: string;
  descriptionReception: string;
  imageGallery: imagegallery;
  eventDate: Date;
  idCookieEventType: number;
  cookieEventTypeReference: string;
  idSite: number;
  siteReference: string;
  cookieTypeReference: string;
  location: string;
  weight: number;
}
