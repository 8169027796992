import { Component, ViewChild } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, FormArray, Validators } from '@angular/forms';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, first } from 'rxjs/operators';
import { EnvService } from '../../_services/env.service';
import { UserService, SiteService, CastingService } from '@/_services';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { AlertService } from '@/shared/alerts';
import { site, User, meltinggroup, furnace, ladle } from '../../_models/index';
import { forEach } from '@angular/router/src/utils/collection';
import { Utils } from '../../shared/utils/utils'
import { utils } from 'protractor';
import { Router } from "@angular/router";


@Component({ templateUrl: 'meltingGroupList.component.html' })

export class MeltingGroupList { 
  error2 = '';
  errorInfo = '';
  sites: any[] = [];
  meltinggroups = [];
  furnaces = [];
  ladles = [];
  selectedRowSite: number;
  selectedSite: site;
  currentUser: User;
  selectedRowMeltingGroup: number;
  selectedMeltingGroup: meltinggroup;
  selectedRowFurnace: number;
  selectedFurnace: furnace;
  selectedRowLadle: number;
  selectedLadle: ladle;

  meltingGroupListForm = this.fb.group({
    sites: [null],
    meltinggroups: this.fb.group({
      reference: [''],
      area: [''],
      description: [''],
      furnaces: this.fb.group({
        referenceFurnace: [''],
        description: ['']
      })
    }),
    ladles: this.fb.group({
      referenceLadle: [''],
      descriptionLadle: ['']
    })
  });



  constructor(private fb: FormBuilder, private http: HttpClient, private env: EnvService,
    private siteService: SiteService, private castingService: CastingService,
    private user: UserService, private alertService: AlertService, private router: Router) {
    this.currentUser = this.user.currentUserInfoValue;
  }

  iniciarControlesMeltingGroup() {
    this.meltingGroupListForm.patchValue({
      meltinggroups: {
        reference: null,
        area: null,
        description: null
      }
    });
  }

  iniciarControlesFurnace() {
    this.meltingGroupListForm.patchValue({
      furnaces: {
        referenceFurnace: null,
        description: null
      }
    });
  }

  iniciarControlesLadle() {
    this.meltingGroupListForm.patchValue({
      ladles: {
        referenceLadle: null,
        descriptionLadle: null
      }
    });
  }


  RowSelectedSite() {

    this.meltinggroups = null;
    this.furnaces = null;
    this.ladles = null;

    if (this.selectedSite != null) {
      this.getMeltingGroup();
      this.getLadle();
     
    }
  }

  getMeltingGroup() {
    this.castingService.getMeltingGroup(this.selectedSite.idSite)
      .pipe(first())
      .subscribe(
        data => {
          if (data == false) {
            this.error2 = "Consulta errónea.";
          }
          this.meltinggroups = data;
        },
        error => {
          this.error = error;
        });

  }



  RowSelectedMeltingGroup(u: any, i: number) {
    this.selectedMeltingGroup = u;   // declare variable in component.
    this.selectedRowMeltingGroup = i;
    
    this.furnaces = null;
    
    this.getFurnace();
    }

    RowSelectedFurnace(u: any, i: number) {
        this.selectedFurnace = u;   // declare variable in component.
        this.selectedRowFurnace = i;
        //this.getFurnace();
    }


    RowSelectedLadle(u: any, i: number) {
        this.selectedLadle = u;   // declare variable in component.
        this.selectedRowLadle = i;        
    }

  getFurnace() {
    if (this.selectedMeltingGroup.idMeltingGroup > 0) {
      this.castingService.getFurnace(this.selectedMeltingGroup.idMeltingGroup)
        .pipe(first())
        .subscribe(
          data => {
            if (data == false) {
              this.error2 = "Consulta errónea.";
            }
            this.furnaces = data;
          },
          error => {
            this.error = error;
          });
    }
  }


  getLadle() {
    this.castingService.getLadle(this.selectedSite.idSite)
      .pipe(first())
      .subscribe(
        data => {
          if (data == false) {
            this.error2 = "Consulta errónea.";
          }
          this.ladles = data;
          console.log(data);
        },
        error => {
          this.error = error;
        });

  }

  ngOnInit() {//Inicializar valores controles del formulario    
    
    this.siteService.site(this.currentUser.idUserApplication)
      .pipe(first())
      .subscribe(
        data => {
          if (data == false) {
            this.error2 = "Consulta errónea.";
          }
          this.sites = data;

          //Se selecciona por defecto el site del usuario
          if (this.currentUser.idSiteDefault > 0) {
            this.selectedSite = this.sites.find(x => x.idSite == this.currentUser.idSiteDefault);
            this.RowSelectedSite();
          }
          else
            this.selectedSite = null;
        },
        error => {
          this.error = error;
          this.error(error, 'Error');

        });

    
    this.iniciarControlesMeltingGroup();
    this.iniciarControlesFurnace();
    this.iniciarControlesLadle();
  }


  redirectionMeltingGroup() {

    this.router.navigate(['/meltingGroupCreation']);
  }



  redirectionFurnace() {

    this.router.navigate(['/furnaceCreation']);
    }

    redirectionMeltingGroupUpdate() {
        if (this.selectedMeltingGroup != null) {
            console.log("Site ID " + this.selectedSite.idSite);
            this.router.navigate(['/meltingGroupCreation', { idMeltingGroup: this.selectedMeltingGroup.idMeltingGroup, idSite: this.selectedSite.idSite, idArea: this.selectedMeltingGroup.idArea, reference: this.selectedMeltingGroup.reference, description: this.selectedMeltingGroup.description }]);
        }
        else {
            this.error("Select valid Melting Group", 'UpdateMeltingGroup');
        }
    }

    redirectionFurnaceUpdate() {
        if (this.selectedFurnace != null) {
            console.log("Site ID " + this.selectedSite.idSite);
            this.router.navigate(['/furnaceCreation', { idMeltingGroup: this.selectedFurnace.idMeltingGroup, idFurnace: this.selectedFurnace.idFurnace, idSite: this.selectedSite.idSite, reference: this.selectedFurnace.reference, description: this.selectedFurnace.description }]);
        }
        else {
            this.error("Select valid Furnace", 'UpdateFurnace');
        }
    }

    redirectionLadleUpdate() {
        if (this.selectedLadle != null) {
            console.log("Site ID " + this.selectedSite.idSite);
            this.router.navigate(['/ladleCreation', { idLadle: this.selectedLadle.idLadle}]);
        }
        else {
            this.error("Select valid Ladle", 'UpdateLadle');
        }
    }


    sortTableNum(n: number, tableName: string) {
        var table, rows, switching, i, x, y, shouldSwitch;
        table = document.getElementById(tableName);
        switching = true;
        /*Make a loop that will continue until
        no switching has been done:*/
        while (switching) {
            //start by saying: no switching is done:
            switching = false;
            rows = table.rows;
            /*Loop through all table rows (except the
            first, which contains table headers):*/
            for (i = 1; i < (rows.length - 1); i++) {
                //start by saying there should be no switching:
                shouldSwitch = false;
                /*Get the two elements you want to compare,
                one from current row and one from the next:*/
                x = (rows[i].getElementsByTagName("TD")[n]);
                y = (rows[i + 1].getElementsByTagName("TD")[n]);
                //check if the two rows should switch place:
                if (Number(x.innerHTML) > Number(y.innerHTML)) {
                    //if so, mark as a switch and break the loop:
                    shouldSwitch = true;
                    break;
                }
            }
            if (shouldSwitch) {
                /*If a switch has been marked, make the switch
                and mark that a switch has been done:*/
                rows[i].parentNode.insertBefore(rows[i + 1], rows[i]);
                switching = true;
            }
        }
    }

    myFunction(tableName: string) {
        var inputId: string = 'myInputMeltingGroup';
        if (tableName == "TableFurnaces")
            inputId = "myInputFurnaces";
        else if (tableName == "TableLadle")
            inputId = "myInputLadle";

        console.log("table " + tableName);
        var input, filter, table, tr, td, i, txtValue;
        console.log(document.getElementById(inputId));
        console.log(document.getElementById(tableName));
        input = document.getElementById(inputId);
        filter = input.value.toUpperCase();
        table = document.getElementById(tableName);
        tr = table.getElementsByTagName("tr");
        for (i = 0; i < tr.length; i++) {
            td = tr[i].getElementsByTagName("td")[0];
            if (td) {
                txtValue = td.textContent || td.innerText;
                if (txtValue.toUpperCase().indexOf(filter) > -1) {
                    tr[i].style.display = "";
                } else {
                    tr[i].style.display = "none";
                }
            }
        }
    }

    sortTable(n: number, tableName: string) {
        console.log("Order table " + tableName);
        var table, rows, switching, i, x, y, shouldSwitch, dir, switchcount = 0;
        table = document.getElementById(tableName);
        switching = true;
        // Set the sorting direction to ascending:
        dir = "asc";
        /* Make a loop that will continue until
        no switching has been done: */
        while (switching) {
            // Start by saying: no switching is done:
            switching = false;
            rows = table.rows;
            /* Loop through all table rows (except the
            first, which contains table headers): */
            for (i = 1; i < (rows.length - 1); i++) {
                // Start by saying there should be no switching:
                shouldSwitch = false;
                /* Get the two elements you want to compare,
                one from current row and one from the next: */
                x = rows[i].getElementsByTagName("TD")[n];
                y = rows[i + 1].getElementsByTagName("TD")[n];
                /* Check if the two rows should switch place,
                based on the direction, asc or desc: */
                if (dir == "asc") {
                    if (x.innerHTML.toLowerCase() > y.innerHTML.toLowerCase()) {
                        // If so, mark as a switch and break the loop:
                        shouldSwitch = true;
                        break;
                    }
                } else if (dir == "desc") {
                    if (x.innerHTML.toLowerCase() < y.innerHTML.toLowerCase()) {
                        // If so, mark as a switch and break the loop:
                        shouldSwitch = true;
                        break;
                    }
                }
            }
            if (shouldSwitch) {
                /* If a switch has been marked, make the switch
                and mark that a switch has been done: */
                rows[i].parentNode.insertBefore(rows[i + 1], rows[i]);
                switching = true;
                // Each time a switch is done, increase this count by 1:
                switchcount++;
            } else {
                /* If no switching has been done AND the direction is "asc",
                set the direction to "desc" and run the while loop again. */
                if (switchcount == 0 && dir == "asc") {
                    dir = "desc";
                    switching = true;
                }
            }
        }
    }

    MeltingGroupDelete() {        

        this.clear("MeltingGroupDelete");

        if (this.meltinggroups != null) {            
            var body = JSON.stringify(this.selectedMeltingGroup);
            this.castingService.DeleteMeltingGroup(body)
                .pipe(first())
                .subscribe(
                    result => {
                        if (result == true) {
                            this.MeltingGroupAlertDetete('The Melting Group has been deleted');
                        }
                    },
                    error => {
                        console.log(error);
                        this.errorInfo = error;
                        this.MeltingGroupAlertDetete(error);
                    });
        }
        else
            this.MeltingGroupAlertDetete('An error has occurred, select valid Melting Group');
    }

    MeltingGroupAlertDetete(message: string) {
        console.log(this.selectedRowMeltingGroup);
        if (this.errorInfo == '') {
            this.success(message, 'MeltingGroupDelete');
            this.meltinggroups.splice(this.selectedRowMeltingGroup, 1);
            this.selectedRowMeltingGroup = 0;
            this.selectedMeltingGroup = null;
        }
        else {
            this.error(message, 'MeltingGroupDelete');
        }

    }

    FurnaceDelete() {

        this.clear("FurnaceDelete");

        if (this.furnaces != null) {
            var body = JSON.stringify(this.selectedFurnace);
            this.castingService.DeleteFurnace(body)
                .pipe(first())
                .subscribe(
                    result => {
                        if (result == true) {
                            this.FurnaceAlertDetete('The Furnace has been deleted');
                        }
                    },
                    error => {
                        console.log(error);
                        this.errorInfo = error;
                        this.FurnaceAlertDetete(error);
                    });
        }
        else
            this.FurnaceAlertDetete('An error has occurred, select valid Furnace');
    }

    FurnaceAlertDetete(message: string) {
        console.log(this.selectedRowFurnace, this.errorInfo);

        if (this.errorInfo == '') {
            console.log("entrei");
            this.success(message, "FurnaceDelete");
            this.furnaces.splice(this.selectedRowFurnace, 1);
            this.selectedRowFurnace = 0;
            this.selectedFurnace = null;
        }
        else {
            console.log("entrei error");
            this.error(message, "FurnaceDelete");
        }

    }

    LadleDelete() {

        this.clear("LadleDelete");

        if (this.ladles != null) {
            var body = JSON.stringify(this.selectedLadle);
            this.castingService.DeleteLadle(body)
                .pipe(first())
                .subscribe(
                    result => {
                        if (result == true) {
                            this.LadleAlertDetete('The Ladle has been deleted');
                        }
                    },
                    error => {
                        console.log(error);
                        this.errorInfo = error;
                        this.LadleAlertDetete(error);
                    });
        }
        else
            this.LadleAlertDetete('An error has occurred, select valid Ladle');
    }

    LadleAlertDetete(message: string) {
        if (this.errorInfo == '') {
            this.success(message, "LadleDelete");
            this.ladles.splice(this.selectedRowLadle, 1);
            this.selectedRowLadle = 0;
            this.selectedLadle = null;
        }
        else {
            this.error(message, "LadleDelete");
        }

    }

  redirectionLadle() {

    this.router.navigate(['/ladleCreation']);
    }

    redirectionUpdateLinkArea(info: any) {

        this.router.navigate(['/area', { idArea: info.idArea }]);

    }


  success(message: string, id: string) {
    this.alertService.success(message, id);
  }

  error(message: string, id: string) {
    this.alertService.error(message, id);
  }

  info(message: string) {
    this.alertService.info(message);
  }

  warn(message: string) {
    this.alertService.warn(message);
  }

  clear(id: string) {
    this.errorInfo = '';
    this.alertService.clear(id);
  }

}

