import { Component, ViewChild, HostListener } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, FormArray, Validators } from '@angular/forms';
import { HttpClient, HttpHeaders, HttpEventType } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, first } from 'rxjs/operators';
import { EnvService } from '../../_services/env.service';
import { CookieService, SiteService, UserService, MoldService, GroupService, ImageGalleryService } from '@/_services';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { AlertService } from '@/shared/alerts';
import { cookie, User, site, moldcomponent, group, imagegallery, image } from '../../_models/index';
import { forEach } from '@angular/router/src/utils/collection';
import { Utils } from '../../shared/utils/utils'
import { utils } from 'protractor';
import { ActivatedRoute, ParamMap, Router } from "@angular/router";
import { DatePipe } from '@angular/common';

// MDB Angular Free
//import { WavesModule, TableModule } from 'angular-bootstrap-md';


@Component({
    selector: 'cookie',
    templateUrl: 'cookie.component.html',
    styleUrls: ['cookie.component.scss']
})

//@Component({ templateUrl: 'cookieList.component.html' })

export class Cookie {
    formNameAddUpdate: string = "New Cookie";
    errorInfo = '';
    eliminarCorrectamente = 'Delete ok';
    error2 = '';
    sites = [];
    molds = [];
    groups = [];
    idCookie: number = 0;
    idSite: number = 0;
    creationDate: string = "";
    selectedRow: number;
    cookieResultado: cookie;
    site: number;
    currentUser: User;
    filter: number;
    selectedSite: site;
    selectedMold: moldcomponent;
    selectedGroup: group;
    fileCount: string = "";
    selectedFile: File;
    FileList: File[] = [];
    filestring: string;
    listStringFile: string[] = [];
    fileUploadProgress: string = null;
    imageGallery: imagegallery;

    cards: any[] = [];
    slides: any = [[]];
    CAROUSEL_BREAKPOINT = 768;
    carouselDisplayMode = 'multiple';


    cookieForm = this.fb.group({
        sites: [null, Validators.required],
        molds: [null, Validators.required],
        groups: [null],
        reference: ['', Validators.required],
        creationdate: ['', Validators.required],
        description: ['']
    });

    constructor(private fb: FormBuilder, private http: HttpClient, private env: EnvService,
        private siteService: SiteService, private cookieService: CookieService, private user: UserService, private alertService: AlertService, private route: ActivatedRoute,
        private moldService: MoldService, private groupService: GroupService, private imageGalleryService: ImageGalleryService) {
        this.cookieResultado = new cookie();
        this.currentUser = this.user.currentUserInfoValue;
    }

 

    RowSelectedSite() {
        console.log(this.selectedSite);
        if (this.selectedSite != null) {
            this.getMold();
            this.getGroup();
        }
        else {
            this.molds = null;
            this.groups = null;
            this.selectedMold = null;
            this.selectedGroup = null;
        }

    }

    getMold() {
        console.log(this.selectedSite);
        if (this.selectedSite.idSite > 0) {
            this.moldService.getComponentBySite(this.selectedSite.idSite)
                .pipe(first())
                .subscribe(
                    data => {
                        console.log(data);

                        if (data == false) {
                            this.error2 = "Consulta errónea.";
                        }
                        this.molds = data;
                        if ((this.idCookie) > 0) {
                            this.selectedMold = this.molds.find(x => x.idMoldComponent == this.cookieResultado.idMoldComponent);
                        }
                    },
                    error => {
                        this.error = error;
                        this.error(error, 'Error');
                    });
        }
    }



    getGroup() {
        this.groupService.getOriginGroupBySite(this.selectedSite.idSite)
            .pipe(first())
            .subscribe(
                data => {
                    if (data == false) {
                        this.error2 = "Consulta errónea.";
                    }
                    this.groups = data;
                    if ((this.idCookie) > 0) {
                        this.selectedGroup = this.groups.find(x => x.idBatch == this.cookieResultado.idBatch);
                    }
                },
                error => {
                    this.error = error;
                    this.error(error, 'Error');
                });
    }


    //Limpia todos los campos del formulario y las variables que se muestran en las tablas
    resetFormulario() {
        this.formNameAddUpdate = "New Cookie";
        this.cookieForm.reset();
        this.molds = null;
        this.groups = null;
        this.selectedMold = null;
        this.selectedSite = null;
        this.selectedGroup = null;
        this.cards = [];
        this.slides = [];
        this.imageGallery = null;
    }

    //Cuando se envía correctamente la información, se muestra el mensaje y se resetea el formulario
    cambiosGuardadosCorrectamente() {
        this.success('data saved correctly', 'SaveCorrect');
        //Se resetean todas la variables por si se desean insertar más datos
      if (this.idCookie == 0) this.resetFormulario();

    }


    onSubmit() {
        //Se limpia el mensaje de enviado correctamente si se estaba mostrando
        this.clear('SaveCorrect');
        //console.log("ID Cookie" + parseInt(this.idCookie));
        console.log(this.cookieForm.value);
        console.log(this.imageGallery);
        //Si el formulario es válido se envía la información
        if (this.cookieForm.valid) {

            this.cookieResultado.idCookie = this.idCookie;

            this.cookieResultado.reference = this.cookieForm.value.reference;
            this.cookieResultado.descriptionCreated = this.cookieForm.value.description;
            this.cookieResultado.creationDate = this.cookieForm.value.creationdate;
          if (this.cookieForm.value.groups != null)
            this.cookieResultado.idBatch = this.cookieForm.value.groups.idBatch;
          else
            this.cookieResultado.idBatch = 0;

            this.cookieResultado.idMoldComponent = this.cookieForm.value.molds.idMoldComponent;
            this.cookieResultado.imageGallery = this.imageGallery;
            Utils.remove_empty(this.cookieResultado);

            var body = JSON.stringify(this.cookieResultado);

            console.log(this.cookieResultado);

            this.cookieService.saveCookie(body)
                .pipe(first())
                .subscribe(
                    result => {
                        if (result == true) {
                            this.cambiosGuardadosCorrectamente();
                        }
                    },
                    error => {
                        console.log(error);
                        this.errorInfo = error;
                        //this.error('An error has occurred, the information has not been saved', 'SaveCorrect');
                        this.error(error, 'SaveCorrect');
                    });

        }
    }

    getSite() {
        this.siteService.site(this.currentUser.idUserApplication)
            .pipe(first())
            .subscribe(
                data => {
                    if (data == false) {
                        this.errorInfo = "Consulta errónea.";
                    }
                    this.sites = data;
                    console.log(this.idSite);
                if (this.idSite > 0) {
                  this.selectedSite = this.sites.find(x => x.idSite == this.idSite);
                  console.log(this.selectedSite);
                  console.log(this.cookieResultado.idMoldComponent);
                  console.log(this.cookieResultado.idBatch);
                  this.getMold();
                  this.getGroup();
                }
                else {
                  //Se selecciona por defecto el site del usuario
                  if (this.currentUser.idSiteDefault > 0) {
                    this.selectedSite = this.sites.find(x => x.idSite == this.currentUser.idSiteDefault);
                    this.getMold();
                    this.getGroup();
                  }
                  
                  }
                },
                error => {
                    this.errorInfo = error;

                });
    }

    ngOnInit() {
        this.clear('Error');
        this.selectedSite = null;
        this.selectedGroup = null;
        this.selectedMold = null;

        this.getParameter();

        console.log(this.idCookie);

    }


    getParameter() {

        if ((this.route.snapshot.paramMap.get('idCookie')) != null) {
            this.idCookie = (parseInt)(this.route.snapshot.paramMap.get('idCookie'));
            //this.idSite = (parseInt)(this.route.snapshot.paramMap.get('idSite'));
            this.formNameAddUpdate = "Update Cookie";

            console.log(this.idCookie);
            this.clear('Error');

            //this.selectedSite = this.sites.find(x => x.idSite == (parseInt)(this.route.snapshot.paramMap.get('site')));

            if ((this.idCookie) > 0) {
                this.cookieService.getCookieById((this.idCookie))
                    .pipe(first())
                    .subscribe(
                        data => {
                            if (data == false) {
                                this.error2 = "Consulta errónea.";
                            }
                            this.cookieResultado = data;
                            this.formNameAddUpdate = "Update Cookie" //+ this.cookieResultado.reference;
                            if (data.idSite != null) this.idSite = data.idSite;
                            this.getSite();
                            this.iniComponentCookie();
                            let datePipe: DatePipe = new DatePipe("en-EN");
                            this.creationDate = datePipe.transform(this.cookieResultado.creationDate, 'yyyy-MM-ddTHH:mm');
                            if (this.cookieResultado.imageGallery != null) { 
                                console.log(this.cookieResultado.imageGallery);
                                this.getImageGalery();
                                
                            }
                        },
                        error => {
                            this.error = error;
                            this.error(error, 'Error');

                        });

            }

        }
        else {
            this.getSite();
        }
       
    }

    getImageGalery() {

        if (this.cookieResultado.imageGallery != null && this.cookieResultado.imageGallery.idImageGallery > 0) {

            this.imageGalleryService.returnImageGalleryById(this.cookieResultado.imageGallery.idImageGallery)
                .pipe(first())
                .subscribe(
                    data => {
                        if (data == false) {
                            this.error2 = "Consulta errónea.";
                        }
                        this.imageGallery = data;
                        this.cards.splice(0, this.cards.length);
                        if (this.imageGallery != null)
                        {
                            this.imageGallery.idImageGallery = this.cookieResultado.imageGallery.idImageGallery;
                            this.imageGallery.reference = this.cookieResultado.imageGallery.reference;
                            this.showImage();
                        }
                            

                    },
                    error => {
                        this.error = error;
                    });
        }
    }

   

    getFileDetails(e) {
        this.FileList = [];
        this.listStringFile = [];
        this.fileCount = e.target.files.length + " files";
        console.log(e.target.files);
        for (var i = 0; i < e.target.files.length; i++) {
            this.selectedFile = <File>e.target.files[i];
            this.FileList.push(this.selectedFile);
            var reader = new FileReader();
            reader.onload = this._handleReaderLoaded.bind(this);
            reader.readAsBinaryString(this.selectedFile);
        }

    }

    _handleReaderLoaded(readerEvt) {
        var binaryString = readerEvt.target.result;
        this.filestring = btoa(binaryString);  // Converting binary string data.
        this.listStringFile.push(this.filestring);
        console.log(this.filestring);
    }


    uploadFiles() {
        this.cards = [];
        this.slides = [];
        this.clear('image');
        if (this.idCookie > 0 && this.cookieResultado.imageGallery != null) {
            this.imageGallery.idImageGallery = this.cookieResultado.imageGallery.idImageGallery;
            this.imageGallery.reference = this.cookieResultado.imageGallery.reference;
            var i: number = 0;
            if (this.listStringFile.length > 0) {
                this.listStringFile.forEach(item => {
                    //var byteArray = new Uint8Array(item);
                    //console.log(item);
                    var tempimage = new image();
                    tempimage.idImage = 0;
                    tempimage.reference = this.FileList[i].name.substr(0, this.FileList[i].name.indexOf("."));
                    tempimage.description = this.FileList[i].lastModified.toString();
                    tempimage.imageFile = item;
                    if (this.imageGallery.images.find(x => x.imageFile == tempimage.imageFile) == null) {
                        this.imageGallery.images.push(tempimage);
                        i++;
                        this.fileUploadProgress = Math.round(i / this.listStringFile.length * 100) + '%';
                    }
                });

                this.showImage();
                this.fileCount = "";
            }
            else
                this.error("Select one image at least", "image");
                this.fileUploadProgress = "";
        }
        else {

            if (this.cookieForm.value != null && this.cookieForm.value.reference != "") {
                this.fileUploadProgress = '0%';
                this.imageGallery = new imagegallery();
                this.imageGallery.idImageGallery = 0;
                this.imageGallery.reference = this.cookieForm.value.reference + "_Image_Gallery";
                //this.imageGallery.description = "Prueba";
                this.imageGallery.images = [];
                var tempImageList: image[] = [];
                var i: number = 0;
                if (this.listStringFile.length > 0) {
                    this.listStringFile.forEach(item => {
                        //var byteArray = new Uint8Array(item);
                        //console.log(item);
                        var tempimage = new image();
                        tempimage.idImage = 0;
                        tempimage.reference = this.FileList[i].name.substr(0, this.FileList[i].name.indexOf("."));
                        tempimage.description = this.FileList[i].lastModified.toString();
                        tempimage.imageFile = item;
                        tempImageList.push(tempimage);
                        i++;
                        this.fileUploadProgress = Math.round(i / this.listStringFile.length * 100) + '%';
                    });
                    this.imageGallery.images = tempImageList;

                    this.showImage();
                    this.fileCount = "";
                    this.fileUploadProgress = "";
                    //console.log(this.imageGallery);
                }
                else
                    this.error("Select one image at least", "image");
            }
            else
                this.error("Select a cookie reference", "image");
        }
    }

    
    showImage() {

        this.imageGallery.images.forEach(item => {
            var url = 'data:image/jpeg;base64,' + item.imageFile; // use this in <img src="..."> binding
            this.cards.push({
                title: item.reference,
                description: item.description,
                img: url
            });
        });
        this.slides = this.chunk(this.cards, 3);
        if (window.innerWidth <= this.CAROUSEL_BREAKPOINT) {
            this.carouselDisplayMode = 'single';
        } else {
            this.carouselDisplayMode = 'multiple';
        }
    }

    @HostListener('window:resize')
    onWindowResize() {
        if (window.innerWidth <= this.CAROUSEL_BREAKPOINT) {
            this.carouselDisplayMode = 'single';
        } else {
            this.carouselDisplayMode = 'multiple';
        }
    }

    chunk(arr, chunkSize) {
        let R = [];
        for (let i = 0, len = arr.length; i < len; i += chunkSize) {
            R.push(arr.slice(i, i + chunkSize));
        }
        return R;
    }

    deleteImage(index: number) {
        
        if (this.cards.length == 1) {
            this.cards = [];
            this.imageGallery.images = [];
            this.imageGallery.idImageGallery = 0;
            this.slides = [];
            console.log(this.imageGallery);
        }
            
        else {
            this.cards = [];
            this.slides = [];
            this.imageGallery.images.splice(index, 1);
            console.log(this.imageGallery);
            this.showImage();
        }
        console.log(this.cards);
    }


        iniComponentCookie() {

            this.cookieForm.patchValue({
                reference: this.cookieResultado.reference,
                creationdate: this.cookieResultado.creationDate,
                description: this.cookieResultado.descriptionCreated
            });
        }


        success(message: string, id: string) {
            this.alertService.success(message, id);
        }

        error(message: string, id: string) {
            this.alertService.error(message, id);
        }

        info(message: string) {
            this.alertService.info(message);
        }

        warn(message: string) {
            this.alertService.warn(message);
        }

        clear(id: string) {
            this.alertService.clear(id);
        }

    }
