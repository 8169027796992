
export class chemistryelement {    
  idChemistryElement: number;
  elementPercentage: number;
  idChemistryElementType: number;
  chemistryElementTypeReference: string;
  idChemistry: number;
  idElement: number;
  elementAbreviation: string;
}
