import { Component, ViewChild } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, FormArray, Validators } from '@angular/forms';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, first } from 'rxjs/operators';
import { EnvService } from '../../_services/env.service';
import { SiteService,  UserService, ChemistryService, TypeService, ArticleService } from '@/_services';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { AlertService } from '@/shared/alerts';
import { site, User, chemistryelement, element, chemistry, type } from '../../_models/index';
import { forEach } from '@angular/router/src/utils/collection';
import { Utils } from '../../shared/utils/utils'
import { utils } from 'protractor';
import { all } from 'q';
import { ActivatedRoute } from '@angular/router';



@Component({ templateUrl: 'chemistry.component.html' })

export class Chemistry {
    formNameAddUpdate: string = "New Chemistry";
    idChemistry: number = 0;
    errorInfo = '';
    error2 = '';
    sites:  any[] = [];
    types: any[] = [];
    elements: any[] = [];
    compositions: chemistryelement[] = [];
    selectedComposition: chemistryelement;
    selectedSite: site;
    selectedElement: element;
    selectedType:type;
    currentUser: User;
    chemistryeResultado: chemistry;   
    

    chemistryForm = this.fb.group({
        sites: [null, Validators.required],        
        reference: ['', Validators.required],
        types: [null, Validators.required],
        analysisDate: [null, Validators.required], 
        description: [''],
        compositions: this.fb.group({
            elements: [null], 
            percentage: ['']
        })
    });



    constructor(private fb: FormBuilder, private http: HttpClient, private env: EnvService,
        private siteService: SiteService, private chemistryService: ChemistryService, private typeService : TypeService,
        private alertService: AlertService, private user: UserService, private articleService: ArticleService, private route: ActivatedRoute ) {
        this.chemistryeResultado = new chemistry();
        this.currentUser = this.user.currentUserInfoValue;
    }

    //Controlar la ventana popup de editar componentes
    @ViewChild('warningModal', { read: false }) public warningModal: ModalDirective;
    @ViewChild('warningModal2', { read: false }) public warningModal2: ModalDirective;



  
    addComposition() {
      this.clear('alertComposition');
        if (this.chemistryForm.controls["compositions"].value.elements.abreviation != null
          && this.chemistryForm.controls["compositions"].value.percentage != null) {

            //Si ya se ha añadido el Test Type una vez, se muestra el aviso de que ya existe y no se añade de nuevo
            if (this.compositions.find((x => x.elementAbreviation == this.chemistryForm.controls["compositions"].value.elements.abreviation))) {
              this.error('This element has already been added', 'alertComposition');
              return;
            }

            var aux = new chemistryelement();
            aux.idChemistryElement = 0;
            aux.idElement = this.chemistryForm.controls["compositions"].value.elements.idElement;
            aux.elementAbreviation = this.chemistryForm.controls["compositions"].value.elements.abreviation;
            aux.elementPercentage = this.chemistryForm.controls["compositions"].value.percentage;

            this.compositions.push(aux);
            this.iniciarControles();
        }
        else {
          this.error('Any of the values entered is not correct', 'alertComposition');
        }

    }


    iniciarControles() {
      this.chemistryForm.patchValue({
          compositions: {
                elements: null,
                percentage: ''
          }
      });
    }

    

    //Limpia todos los campos del formulario y las variables que se muestran en las tablas
    resetFormulario() {
      this.chemistryForm.reset();
      this.formNameAddUpdate = "New Chemistry";
        this.compositions = [];
        this.selectedSite = null;
        this.selectedType = null;
        this.selectedElement = null;
        this.selectedComposition = null;
    }


    //Cuando se envía correctamente la información, se muestra el mensaje y se resetea el formulario
    cambiosGuardadosCorrectamente() {
      this.success('data saved correctly', 'SaveCorrect');
      //Se resetean todas la variables por si se desean insertar más datos    
      if (this.idChemistry == 0) this.resetFormulario();
    }


    onSubmit() {    
        this.clear('SaveCorrect');     

      //Si el formulario es válido se envía la información
      if (this.chemistryForm.valid) {

          this.chemistryeResultado.idChemistry = this.idChemistry;
          this.chemistryeResultado.reference = this.chemistryForm.value.reference;
          this.chemistryeResultado.description = this.chemistryForm.value.description;
          this.chemistryeResultado.analysisDate = this.chemistryForm.value.analysisDate;
          this.chemistryeResultado.idSite = this.chemistryForm.value.sites.idSite;
          this.chemistryeResultado.idArticleFamily = this.chemistryForm.value.types.idArticleFamily;
          this.chemistryeResultado.chemistryElements = [];
          this.compositions.forEach(item => {
            this.chemistryeResultado.chemistryElements.push(item);
          });

        Utils.remove_empty(this.chemistryeResultado);

        var body = JSON.stringify(this.chemistryeResultado);


        this.chemistryService.SaveChemistry(body)
          .pipe(first())
          .subscribe(
            result => {
              if (result == true) {
                this.cambiosGuardadosCorrectamente();
              }
            },
            error => {
              this.errorInfo = error;
              this.error(error, 'SaveCorrect');
            });

      }
    } 

    ngOnInit()
    {
        this.getParameter();
        this.getSites();
        this.getFamily();
        this.getElement();

        this.iniciarControles();
        this.selectedSite = null;
        this.selectedType = null;
        this.selectedElement = null;
        this.selectedComposition = null;
    }

    getSites() {
        this.siteService.site(this.currentUser.idUserApplication)
            .pipe(first())
            .subscribe(
                data => {
                    if (data == false) {
                        this.errorInfo = "Consulta errónea.";
                    }
                    this.sites = data;
                if (this.chemistryeResultado.idSite > 0)
                  this.selectedSite = this.sites.find(s => s.idSite == this.chemistryeResultado.idSite);
                else {
                  //Se selecciona por defecto el site del usuario
                  if (this.currentUser.idSiteDefault > 0)
                    this.selectedSite = this.sites.find(x => x.idSite == this.currentUser.idSiteDefault);                 
                }
                },
                error => {
                    this.errorInfo = error;
                });
    }

    getFamily() {

        this.articleService.getFamily()
            .pipe(first())
            .subscribe(
                data => {
                    if (data == false) {
                        this.errorInfo = "Consulta errónea.";
                    }
                    this.types = data;
                    console.log(this.types);
                    if (this.chemistryeResultado != null) 
                        this.selectedType = this.types.find(s => s.idArticleFamily == this.chemistryeResultado.idArticleFamily);
                },
                error => {
                    this.error = error;
                });
    }

    getElement() {
        this.chemistryService.getElement()
            .pipe(first())
            .subscribe(
                data => {
                    if (data == false) {
                        this.errorInfo = "Consulta errónea.";
                    }
                    this.elements = data;
                },
                error => {
                    this.errorInfo = error;
                });
    }

    getComposition() {
        this.chemistryService.getCompositonByChemistry(this.idChemistry)
            .pipe(first())
            .subscribe(
                data => {
                    if (data == false) {
                        this.error2 = "Consulta errónea.";
                    }
                    this.compositions = data;
                },
                error => {
                    this.error = error;
                });
    };

    getParameter() {

        if (this.route.snapshot.paramMap.get('idChemistry') != null) {
            this.idChemistry = (parseInt)(this.route.snapshot.paramMap.get('idChemistry'));
            //this.idArticleFamily = (parseInt)(this.route.snapshot.paramMap.get('idArticleFamily'));
            //this.idArea = (parseInt)(this.route.snapshot.paramMap.get('idArea'));
            console.log("ID Site " + this.idChemistry /*+ "  " + this.router.snapshot.params.idTemplateLabTest*/);
            this.formNameAddUpdate = "Update Chemistry";
            this.getChemistryById();
            //this.myBtn = true;
            //this.myTab = true;
            ////this.state = "disabled";
            //this.articleStockForm.get('sites').disable();
            //this.articleStockForm.get('families').disable();
            //this.pieceDefectForm.get('pieces').disable();
        }
    }

    getChemistryById() {
        this.chemistryService.getChemistryById(this.idChemistry)
            .pipe(first())
            .subscribe(
                data => {
                    if (data == false) {
                        this.error2 = "Consulta errónea.";
                    }
                    this.chemistryeResultado = data;
                    console.log(this.chemistryeResultado);
                    if (this.chemistryeResultado != null) {
                        this.chemistryForm.patchValue({
                            reference: this.chemistryeResultado.reference,
                            description: this.chemistryeResultado.description,
                            analysisDate: this.chemistryeResultado.analysisDate
                        });

                        this.getSites();
                        this.getFamily();
                        this.getComposition();
                       /* this.getFamily();
                        this.getSite();
                        this.getArticle();
                        this.getSupplier();
                        this.getUnit();
                        this.getChemistry();*/


                       /* let datePipe: DatePipe = new DatePipe("en-EN");
                        this.deliveryDate = datePipe.transform(this.stockResultado.dtArticleStock, 'yyyy-MM-ddThh:mm');

                        this.iniArticleStockVar();*/
                    }
                },
                error => {
                    this.error = error;
                });
    }


    RowDelete(u: any, i: number) {
      this.compositions.splice(i, 1);
    }


    success(message: string, id:string) {
      this.alertService.success(message, id);
    }

    error(message: string, id: string) {
      this.alertService.error(message, id);
    }

    info(message: string) {
      this.alertService.info(message);
    }

    warn(message: string) {
      this.alertService.warn(message);
    }

    clear(id: string) {
      this.alertService.clear(id);
    }

}

