import { piecepart } from './piecepart';

export class pieceType {   
  idPieceType: number;
  idArticle: number;
  pieceParts: piecepart[];
  reference: string;
  description: string;
  idChemistry: number;
  chemistryReference: string;
  idArticleFamily: number;
  articleFamilyReference: string;        
  idArticleType: number;
  articleTypeReference: string;
  idSite: number;
  numArticle: number;
  articleStock: [];
}
