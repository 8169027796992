import { area } from './area';


export class site {    
  idSite: number;
  reference: string;
  idMetallurgyType: number;
  metallurgyTypeReference: string;
  default: boolean;
  idFoundry: number;
  foundry: string;
    areas: area[];  
}


