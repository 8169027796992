import { Component, OnDestroy, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Router, RouterOutlet } from "@angular/router";


@Component({
  templateUrl: './DefaultLayout.component.html'
})

export class DefaultLayoutComponent {   
  
}
