import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { EnvService } from './env.service';

import { Message } from '@angular/compiler/src/i18n/i18n_ast';
import { type } from '../_models/type';

@Injectable({ providedIn: 'root' })
export class TypeService {
    
  constructor(private http: HttpClient, private env: EnvService) {
    
  }    

  getGroupType(company: Number) {    
    console.log(this.env.apiUrl + `/api/Batch/grouptype`);
        return this.http.get<any>(this.env.apiUrl + `/api/Batch/grouptype/` + company.toString())
      .pipe(map(type => {             
          // store user details and jwt token in local storage to keep user logged in between page refreshes      
        
        console.log(type);        
        return type;
      }));          
  }

  getFinalStateType() {
    console.log(this.env.apiUrl + `/api/TestType/finalstatetype`);
    return this.http.get<any>(this.env.apiUrl + `/api/TestType/finalstatetype`)
      .pipe(map(type => {
        // store user details and jwt token in local storage to keep user logged in between page refreshes      

        return type;
      }));
  }

  getCookieEvaluationType() {
    console.log(this.env.apiUrl + `/api/Cookie/cookieevaluationtype`);
    return this.http.get<any>(this.env.apiUrl + `/api/Cookie/cookieevaluationtype`)
      .pipe(map(type => {
        // store user details and jwt token in local storage to keep user logged in between page refreshes      

        return type;
      }));
  }

  getDefectTimeType() {
      console.log(this.env.apiUrl + `/api/Piece/defecttime`);
      return this.http.get<any>(this.env.apiUrl + `/api/Piece/defecttime`)
      .pipe(map(type => {
        // store user details and jwt token in local storage to keep user logged in between page refreshes      

        return type;
      }));
  }


  getElementType() {
    return this.http.get<any>(this.env.apiUrl + `/api/Chemistry/elementtype`)
      .pipe(map(type => {
        // store user details and jwt token in local storage to keep user logged in between page refreshes      

        return type;
      }));
  }
    

  getUnitType() {
    return this.http.get<any>(this.env.apiUrl + `/api/Article/unittype`)
      .pipe(map(type => {
        // store user details and jwt token in local storage to keep user logged in between page refreshes      

        return type;
      }));
  }


  getNormType() {
    return this.http.get<any>(this.env.apiUrl + `/api/Sand/normtype`)
      .pipe(map(type => {
        // store user details and jwt token in local storage to keep user logged in between page refreshes      

        return type;
      }));
  }
}

