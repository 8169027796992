import { Component, ViewChild, HostListener } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, FormArray, Validators } from '@angular/forms';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, first } from 'rxjs/operators';
import { EnvService } from '../../_services/env.service';
import { UserService, SiteService, TypeService, PieceService, ImageGalleryService } from '@/_services';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { AlertService } from '@/shared/alerts';
import { site, piecedefect, area, piece, piecepart, User, defecttype, type, pieceheattreatment, imagegallery, image } from '../../_models/index';
import { forEach } from '@angular/router/src/utils/collection';
import { Utils } from '../../shared/utils/utils'
import { utils } from 'protractor';
import { ActivatedRoute, Router} from "@angular/router";

@Component({ templateUrl: 'pieceDefect.component.html' })

export class PieceDefect {
  formNameAddUpdate: string = "New Piece Defect";
  idPieceDefect: number = 0;
  idSite: number = 0;
  idArea: number = 0;
  myTab: boolean = false;
  myBtn: boolean = false;
  errorInfo = '';
  error2 = '';
  sites: any[] = [];
  areas: any[] = [];
  pieces: any[] = [];
  sections: any[] = [];
  defects: any[] = [];
  origins: any[] = [];
  heattreatments: any[] = []
  infoTable: piecedefect[] = [];
  selectedSite: site;
  selectedArea: area;
  selectedPiece: piece;
  selectedSection: piecepart;
  selectedDefect: defecttype;
  selectedOrigin: type;
  selectedHeatTreatment: pieceheattreatment;
  selectedRow: number;
  selectedRecord: any;
  pieceDefectResultado: piecedefect;
    currentUser: User;

    fileCount: string = "";
    selectedFile: File;
    FileList: File[] = [];
    filestring: string;
    listStringFile: string[] = [];
    fileUploadProgress: string = null;
    imageGallery: imagegallery;

    cards: any[] = [];
    slides: any = [[]];
    CAROUSEL_BREAKPOINT = 768;
    carouselDisplayMode = 'multiple';

  pieceDefectForm = this.fb.group({
    areas: [null],
    sites: [null],
    pieces: [null],
    sections: [null],
    defects: [null],
    origins: [null],
    heattreatments: [null],
    description: ['']
  });


  constructor(private fb: FormBuilder, private http: HttpClient, private env: EnvService,
      private siteService: SiteService, private pieceService: PieceService, private typeService: TypeService,
      private alertService: AlertService, private user: UserService,
      private router: Router, private route: ActivatedRoute, private imageGalleryService: ImageGalleryService) {        
    this.pieceDefectResultado = new piecedefect();
    this.currentUser = this.user.currentUserInfoValue;
  }

  @ViewChild('warningModal', { read: false }) public warningModal: ModalDirective;

  addRecord() {
    this.clear('alertPieceDefect');

    if (this.selectedSite != null
      && this.selectedArea != null
      && this.selectedPiece != null
      && this.selectedSection != null
      && this.selectedDefect != null
      && this.selectedOrigin != null) {

        console.log(this.pieceDefectForm.value);

      var aux = new piecedefect();
      aux.idPieceDefect = this.idPieceDefect;
      aux.description = this.pieceDefectForm.value.description;
      aux.idDefectTimeType = this.pieceDefectForm.value.origins.idType;
      aux.defectTimeTypeReference = this.pieceDefectForm.value.origins.name;
      aux.idPiece = this.selectedPiece.idPiece;
      aux.piece = this.selectedPiece.reference;
      aux.idDefectType = this.pieceDefectForm.value.defects.idDefectType;
      aux.defectType = this.pieceDefectForm.value.defects.reference;
      aux.idPiecePart = this.pieceDefectForm.value.sections.idPiecePart;
      aux.piecePart = this.pieceDefectForm.value.sections.reference;
        console.log(this.imageGallery);
      aux.imageGallery = this.imageGallery;

      console.log(this.pieceDefectForm.value.heattreatments)

      if (this.pieceDefectForm.value.heattreatments != null) {
        aux.idPieceHeatTreatment = this.pieceDefectForm.value.heattreatments.idPieceHeatTreatment;
        aux.heatTreatment = this.pieceDefectForm.value.heattreatments.description;
      }
     

      console.log(this.pieceDefectForm.value.pieces);
      console.log(this.pieceDefectForm.value.sections);

      this.infoTable.push(aux);

      this.iniciarControles();
    }
    else {
      this.error('Any of the values entered is not correct', 'alertPieceDefect');
    }

  }

  iniciarControles() {
    this.pieceDefectForm.patchValue({
      areas: [null],
      sites: [null],
      pieces: [null],
      sections: [null],
      defects: [null],
      origins: [null],
      heattreatments: [null],
      description: ''
    });

    this.getSite();
    this.getDefectType();
    this.getOrigin();

    this.areas = null;
    this.pieces = null;
    this.sections = null;
    this.heattreatments = null;

    this.selectedSite = null;
    this.selectedArea = null;
    this.selectedPiece = null;
    this.selectedSection = null;
    this.selectedDefect = null;
    this.selectedOrigin = null;
    this.selectedHeatTreatment = null;
    this.cards = [];
    this.slides = [];
    this.imageGallery = null;
    
  }

  getSite() {
    this.siteService.site(this.currentUser.idUserApplication)
      .pipe(first())
      .subscribe(
        data => {
          if (data == false) {
            this.error2 = "Consulta errónea.";
          }
          this.sites = data;              
          if (this.idSite > 0) {
            this.selectedSite = this.sites.find(s => s.idSite == this.idSite);
            this.getArea();
          }
          else {
            //Se selecciona por defecto el site del usuario
            if (this.currentUser.idSiteDefault > 0) {
              this.selectedSite = this.sites.find(x => x.idSite == this.currentUser.idSiteDefault);
              this.getArea();
            }   
          }
        },
        error => {
          this.error = error;
          this.error(error, 'Error');

        });
  }


    getDefectType() {
        
    this.pieceService.getDefectType()
      .pipe(first())
      .subscribe(
        data => {
          if (data == false) {
              this.error2 = "Consulta errónea.";
          }
          this.defects = data;              
          if (this.pieceDefectResultado != null && this.pieceDefectResultado.idPieceDefect != undefined) {              
          this.selectedDefect = this.defects.find(s => s.idDefectType == this.pieceDefectResultado.idDefectType);
          }
        },
        error => {
          this.error = error;
          this.error(error, 'Error');

        });
  }

  getOrigin() {
    this.typeService.getDefectTimeType()
      .pipe(first())
      .subscribe(
        data => {
          if (data == false) {
            this.error2 = "Consulta errónea.";
          }
          this.origins = data;
          if (this.pieceDefectResultado != null && this.pieceDefectResultado.idPieceDefect != undefined)                            
          {
            this.selectedOrigin = this.origins.find(s => s.idType == this.pieceDefectResultado.idDefectTimeType);
          }                        
        },
        error => {
          this.error = error;
          this.error(error, 'Error');

        });
  }


  RowSelectedSite() {
    console.log(this.selectedSite);
    if (this.selectedSite != null)
      this.getArea();
    else
    {
      this.areas = null;
      this.pieces = null;
      this.sections = null;
      this.heattreatments = null;

      this.selectedArea = null;
      this.selectedPiece = null;
      this.selectedSection = null;
      this.selectedHeatTreatment = null;
    }
  }

  getArea() {

      if (this.selectedSite != null && this.currentUser != null) {
          this.siteService.getArea(this.selectedSite.idSite, this.currentUser.idUserApplication)
              .pipe(first())
              .subscribe(
                  data => {
                      if (data == false) {
                          this.error2 = "Consulta errónea.";
                      }
                      this.areas = data;
                      if (this.idArea > 0) {
                          this.selectedArea = this.areas.find(s => s.idArea == this.idArea);
                          this.getPiece();
                      }
                  },
                  error => {
                      this.error = error;
                  });    
      }
  }

  RowSelectedArea() {
    console.log(this.selectedArea);
    if (this.selectedArea != null)
      this.getPiece();
    else
    {
      this.pieces = null;
      this.sections = null;
      this.heattreatments = null;

      this.selectedPiece = null;
      this.selectedSection = null;
      this.selectedHeatTreatment = null;
    }
  }


  getPiece() {
      console.log(this.selectedArea.idArea);
      if (this.selectedArea != null)
      {
          this.pieceService.getPiece(this.selectedArea.idArea)
              .pipe(first())
              .subscribe(
                  data => {
                      if (data == false) {
                          this.error2 = "Consulta errónea.";
                      }
                      this.pieces = data;
                      if (this.idPieceDefect > 0) {
                          this.selectedPiece = this.pieces.find(s => s.idPiece == this.pieceDefectResultado.idPiece);
                          this.getPieceHeatTreatment();
                          this.getPieceSection();
                      }
                      console.log(data);
                  },
                  error => {
                      this.error = error;
                  });
      }
  }


  RowSelectedPiece() {
    console.log(this.selectedPiece);
    if (this.selectedPiece != null) {
      this.getPieceHeatTreatment();
      this.getPieceSection();
    }
    else
    {
      this.heattreatments = null;
      this.sections = null;
      this.selectedHeatTreatment = null;
      this.selectedSection = null;
    }
  }

  getPieceHeatTreatment() {
      console.log(this.selectedPiece);
      if (this.selectedPiece != null)
      {
          this.pieceService.getPieceHeatTreatment(this.selectedPiece.idPiece)
              .pipe(first())
              .subscribe(
                  data => {
                      if (data == false) {
                          this.error2 = "Consulta errónea.";
                      }
                      this.heattreatments = data;
                      if (this.idPieceDefect > 0)
                          this.selectedHeatTreatment = this.heattreatments.find(s => s.idPieceHeatTreatment == this.pieceDefectResultado.idPieceHeatTreatment);

                  },
                  error => {
                      this.error = error;
                  });
      }
      
  }

  getPieceSection() {
      console.log(this.selectedPiece);
      if (this.selectedPiece != null)
      {
          this.pieceService.getPiecePart(this.selectedPiece.idPiece)
              .pipe(first())
              .subscribe(
                  data => {
                      if (data == false) {
                          this.error2 = "Consulta errónea.";
                      }
                      this.sections = data;
                      if (this.idPieceDefect > 0)
                          this.selectedSection = this.sections.find(s => s.idPiecePart == this.pieceDefectResultado.idPiecePart);
                      console.log(data);
                  },
                  error => {
                      this.error = error;
                  });
      }
     
  }


  ngOnInit() {

      this.selectedSite = null;
      this.selectedArea = null;
      this.selectedPiece = null;
      this.selectedSection = null;
      this.selectedDefect = null;
      this.selectedOrigin = null;
      this.selectedHeatTreatment = null;

    this.getParameter();
   
  }

  onSubmit() {
    //Se limpia el mensaje de enviado correctamente si se estaba mostrando
    this.clear('SaveCorrect');
    console.log(this.infoTable.length);
    console.log(this.infoTable);
            

      if (this.idPieceDefect > 0)
      {
          this.infoTable = [];
          this.addRecord();
      }
          
    if (this.infoTable.length == 0) {
        this.error('Enter first with some information about parts defects', 'alertPieceDefect');
    }
    else
    {
        //Si el formulario es válido se envía la información
        if (this.pieceDefectForm.valid) {

            //this.pieceDefectResultado = [];
            //this.infoTable.forEach(item => {
            //    this.pieceDefectResultado.push(item);
            //});

            console.log(this.infoTable);

            Utils.remove_empty(this.infoTable);

            var body = JSON.stringify(this.infoTable);

            console.log(body);

            this.pieceService.SavePieceDefect(body)
                .pipe(first())
                .subscribe(
                    result => {
                        if (result == true) {
                            this.cambiosGuardadosCorrectamente();
                        }
                    },
                    error => {
                        console.log(error);
                        this.errorInfo = error;
                        this.error(error, 'SaveCorrect');
                    });
        }
    }
    }

    getParameter() {

        if (this.route.snapshot.paramMap.get('idPieceDefect') != null) {
            this.idPieceDefect = (parseInt)(this.route.snapshot.paramMap.get('idPieceDefect'));
            this.idSite = (parseInt)(this.route.snapshot.paramMap.get('idSite'));
            this.idArea = (parseInt)(this.route.snapshot.paramMap.get('idArea'));            
            console.log("ID Site " + this.idSite /*+ "  " + this.router.snapshot.params.idTemplateLabTest*/);
            this.formNameAddUpdate = "Update Piece Defect";
            this.getPiceDefectById();
            this.myBtn = true;
            this.myTab = true;
            console.log(this.pieceDefectForm);
            //this.state = "disabled";                     
            this.pieceDefectForm.controls["areas"].disable();
            this.pieceDefectForm.controls["sites"].disable();
            this.pieceDefectForm.controls["pieces"].disable();
            
            console.log(this.pieceDefectForm);
        }
        else {
            this.getSite();
            this.getDefectType();
            this.getOrigin();
        }
    }

    getPiceDefectById() {
        console.log("Entra " + this.idPieceDefect);        
        this.pieceService.getPieceDefectById(this.idPieceDefect)
            .pipe(first())
            .subscribe(
                data => {
                    if (data == false) {
                        this.errorInfo = "Consulta errónea.";
                    }
                    this.pieceDefectResultado = data;
                    console.log(this.pieceDefectResultado);
                    if (this.pieceDefectResultado != null) {
                        this.getDefectType();
                        this.getOrigin();
                        this.getSite();   
                        //this.getPiece();
                        if (this.pieceDefectResultado.imageGallery != null) {
                            console.log(this.pieceDefectResultado.imageGallery);
                            this.getImageGalery();
                        }
                        
                    }
                    this.iniciarControlesPiceDefect();
                },
                error => {
                    this.error = error;
                });

    }

   iniciarControlesPiceDefect() {
        this.pieceDefectForm.patchValue({
            description: this.pieceDefectResultado.description
        });
    }


  RowSelected(u: any, i: number) {
    console.log(u);
    this.selectedRecord = u;   // declare variable in component.
    this.selectedRow = i;
  }

  RowDelete(u: any, i: number) {
    this.infoTable.splice(i, 1);
  }

  //Limpia todos los campos del formulario y las variables que se muestran en las tablas
  resetFormulario() {
     
    this.formNameAddUpdate = "New Piece Defect";
    this.pieceDefectForm.reset();
    this.infoTable = [];
    this.selectedRecord = null;

    this.getSite();
    this.getDefectType();
    this.getOrigin();

    this.selectedSite = null;
    this.selectedArea = null;
    this.selectedPiece = null;
      
    this.idPieceDefect = 0;
    this.idSite = 0;
    this.idArea = 0;
    this.myBtn = false;
    this.myTab = false;
    this.fileUploadProgress = "";
    this.fileCount = "";
    this.pieceDefectForm.get('areas').enable();
    this.pieceDefectForm.get('sites').enable();
    this.pieceDefectForm.get('pieces').enable();
    this.cards = [];
      this.slides = [];
      this.imageGallery = null;
  }

  

  //Cuando se envía correctamente la información, se muestra el mensaje y se resetea el formulario
    cambiosGuardadosCorrectamente() {

        this.success('data saved correctly', 'SaveCorrect');                                

      if (this.idPieceDefect == 0) this.resetFormulario();
    }

    getImageGalery() {

        if (this.pieceDefectResultado.imageGallery != null && this.pieceDefectResultado.imageGallery.idImageGallery > 0) {

            this.imageGalleryService.returnImageGalleryById(this.pieceDefectResultado.imageGallery.idImageGallery)
                .pipe(first())
                .subscribe(
                    data => {
                        if (data == false) {
                            this.error2 = "Consulta errónea.";
                        }
                        this.imageGallery = data;
                        this.cards.splice(0, this.cards.length);
                        if (this.imageGallery != null) {
                            this.imageGallery.idImageGallery = this.pieceDefectResultado.imageGallery.idImageGallery;
                            this.imageGallery.reference = this.pieceDefectResultado.imageGallery.reference;
                            this.showImage();
                        }


                    },
                    error => {
                        this.error = error;
                    });
        }
    }

    getFileDetails(e) {
        this.FileList = [];
        this.listStringFile = [];
        this.fileCount = e.target.files.length + " files";
        console.log(e.target.files);
        for (var i = 0; i < e.target.files.length; i++) {
            this.selectedFile = <File>e.target.files[i];
            this.FileList.push(this.selectedFile);
            var reader = new FileReader();
            reader.onload = this._handleReaderLoaded.bind(this);
            reader.readAsBinaryString(this.selectedFile);
        }

    }

    _handleReaderLoaded(readerEvt) {
        var binaryString = readerEvt.target.result;
        this.filestring = btoa(binaryString);  // Converting binary string data.
        this.listStringFile.push(this.filestring);
        console.log(this.filestring);
    }

    uploadFiles() {
        this.cards = [];
        this.slides = [];        
        this.clear('image');
        if (this.idPieceDefect > 0 && this.pieceDefectResultado.imageGallery != null) {
            this.imageGallery.idImageGallery = this.pieceDefectResultado.imageGallery.idImageGallery;
            this.imageGallery.reference = this.pieceDefectResultado.imageGallery.reference;
            var i: number = 0;
            if (this.listStringFile.length > 0) {
                this.listStringFile.forEach(item => {
                    //var byteArray = new Uint8Array(item);
                    //console.log(item);
                    var tempimage = new image();
                    tempimage.idImage = 0;
                    tempimage.reference = this.FileList[i].name.substr(0, this.FileList[i].name.indexOf("."));
                    tempimage.description = this.FileList[i].lastModified.toString();
                    tempimage.imageFile = item;
                    if (this.imageGallery.images.find(x => x.imageFile == tempimage.imageFile) == null) {
                        this.imageGallery.images.push(tempimage);
                        i++;
                        this.fileUploadProgress = Math.round(i / this.listStringFile.length * 100) + '%';
                    }
                });

                this.showImage();
                this.fileCount = "";
            }
            else
                this.error("Select one image at least", "image");
            this.fileUploadProgress = "";
        }
        else {
            console.log(this.selectedPiece);
            if (this.selectedPiece != null && this.selectedPiece.reference != "") {
                this.fileUploadProgress = '0%';
                this.imageGallery = new imagegallery();
                this.imageGallery.idImageGallery = 0;
                this.imageGallery.reference = this.selectedPiece.reference  + "_Image_Gallery";
                //this.imageGallery.description = "Prueba";
                this.imageGallery.images = [];
                var tempImageList: image[] = [];
                var i: number = 0;
                if (this.listStringFile.length > 0) {
                    this.listStringFile.forEach(item => {
                        //var byteArray = new Uint8Array(item);
                        //console.log(item);
                        var tempimage = new image();
                        tempimage.idImage = 0;
                        tempimage.reference = this.FileList[i].name.substr(0, this.FileList[i].name.indexOf("."));
                        tempimage.description = this.FileList[i].lastModified.toString();
                        tempimage.imageFile = item;
                        tempImageList.push(tempimage);
                        i++;
                        this.fileUploadProgress = Math.round(i / this.listStringFile.length * 100) + '%';
                    });
                    this.imageGallery.images = tempImageList;

                    this.showImage();
                    this.fileCount = "";
                    this.fileUploadProgress = "";
                    //console.log(this.imageGallery);
                }
                else
                    this.error("Select one image at least", "image");
            }
            else
                this.error("Piece reference not founded", "image");
        }
    }

    showImage() {

        this.imageGallery.images.forEach(item => {
            var url = 'data:image/jpeg;base64,' + item.imageFile; // use this in <img src="..."> binding
            this.cards.push({
                title: item.reference,
                description: item.description,
                img: url
            });
        });
        this.slides = this.chunk(this.cards, 3);
        if (window.innerWidth <= this.CAROUSEL_BREAKPOINT) {
            this.carouselDisplayMode = 'single';
        } else {
            this.carouselDisplayMode = 'multiple';
        }
    }

    @HostListener('window:resize')
    onWindowResize() {
        if (window.innerWidth <= this.CAROUSEL_BREAKPOINT) {
            this.carouselDisplayMode = 'single';
        } else {
            this.carouselDisplayMode = 'multiple';
        }
    }

    chunk(arr, chunkSize) {
        let R = [];
        for (let i = 0, len = arr.length; i < len; i += chunkSize) {
            R.push(arr.slice(i, i + chunkSize));
        }
        return R;
    }

    deleteImage(index: number) {

        if (this.cards.length == 1) {
            this.cards = [];
            this.imageGallery.images = [];
            this.imageGallery.idImageGallery = 0;
            this.slides = [];
            console.log(this.imageGallery);
        }

        else {
            this.cards = [];
            this.slides = [];
            this.imageGallery.images.splice(index, 1);
            console.log(this.imageGallery);
            this.showImage();
        }
        console.log(this.cards);
    }

  success(message: string, id: string) {
    this.alertService.success(message, id);
  }

  error(message: string, id: string) {
    this.alertService.error(message, id);
  }

  info(message: string) {
    this.alertService.info(message);
  }

  warn(message: string) {
    this.alertService.warn(message);
  }

  clear(id: string) {
    this.alertService.clear(id);
  }

}
