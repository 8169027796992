import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { MDBBootstrapModule } from 'angular-bootstrap-md';
import { BrowserModule } from '@angular/platform-browser';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { EnvServiceProvider } from './_services/env.service.provider';
import { ViewChild } from '@angular/core';
import { ModalModule } from 'ngx-bootstrap';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { ShowHidePasswordModule } from 'ngx-show-hide-password';
import { TypeaheadModule } from 'ngx-bootstrap';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { DateTimePickerModule } from '@syncfusion/ej2-angular-calendars';
import { BsDatepickerModule } from 'ngx-bootstrap';
import { MatStepperModule } from '@angular/material/stepper';

import { AppComponent } from './app.component';
// Import routing module
import { AppRoutingModule } from './app.routing';

import { JwtInterceptor, ErrorInterceptor } from './_helpers';
import { HomeComponent } from './home';
import { MoldType } from './Interfaces/MoldType';
import { MoldCreation } from './Interfaces/MoldCreation';
import { CookieList } from './Interfaces/CookieList';
import { Cookie } from './Interfaces/Cookie';
import { PieceList } from './Interfaces/PieceList';
import { MoldTypeList } from './Interfaces/MoldTypeList';
import { MoldList } from './Interfaces/MoldList';
import { SiteList } from './Interfaces/SiteList';
import { Area } from './Interfaces/Area';
import { EnvironmentalList } from './Interfaces/EnviromentalList';
import { Environmental } from './Interfaces/Environmental';
import { CastingList } from './Interfaces/CastingList';
import { PieceDefect } from './Interfaces/PieceDefect';
import { PieceHeatTreatment } from './Interfaces/PieceHeatTreatment';
import { LoginComponent } from './login';
import { AlertModule } from './shared/alerts';
import { MultiAlertsComponent } from './shared/multi-alerts';
import { MeltingGroupList } from './Interfaces/MeltingGroupList';
import { HeatTreatmentList } from './Interfaces/HeatTreatmentList';
import { DefectList } from './Interfaces/DefectList';
import { CoatingProcessList } from './Interfaces/CoatingProcessList';
import { ChemicalSystemList } from './Interfaces/ChemicalSystemList';
import { Casting } from './Interfaces/Casting';
import { PieceTypeList } from './Interfaces/PieceTypeList';
import { ArticleFamilyList } from './Interfaces/ArticleFamilyList';
import { ArticleList } from './Interfaces/ArticleList';
import { ArticleFamily } from './Interfaces/ArticleFamily';
import { ArticleStockList } from './Interfaces/ArticleStockList';
import { ChemistryList } from './Interfaces/ChemistryList';
import { ElementList } from './Interfaces/ElementList';
import { MeltingGroup } from './Interfaces/MeltingGroup';
import { Furnace } from './Interfaces/Furnace';
import { CoatingProcess } from './Interfaces/CoatingProcess';
import { DefectType } from './Interfaces/Defect';
import { HeatTreatment } from './Interfaces/HeatTreatment';
import { ChemicalSystem } from './Interfaces/ChemicalSystem';
import { PieceType } from './Interfaces/PieceType';
import { Chemistry } from './Interfaces/Chemistry';
import { Element } from './Interfaces/Element';
import { Article } from './Interfaces/Article';
import { ArticleStock } from './Interfaces/ArticleStock';
import { Ladle } from './Interfaces/Ladle';
import { MoldFill } from './Interfaces/MoldFill';
import { DefaultLayoutComponent } from './Interfaces/DefaultLayout';

import { LocationStrategy, HashLocationStrategy } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { NguiDatetimePickerModule } from '@ngui/datetime-picker';

import {
  AppAsideModule,
  AppBreadcrumbModule,
  AppHeaderModule,
  AppFooterModule,
  AppSidebarModule,
} from '@coreui/angular';

@NgModule({
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        MatProgressBarModule,
        DateTimePickerModule,
    ReactiveFormsModule,
    MatStepperModule,
    MatInputModule,
    MatSelectModule,
    FormsModule,
    HttpClientModule,
    AppAsideModule,
    AppBreadcrumbModule.forRoot(),
    AppFooterModule,
    AppHeaderModule,
    AppSidebarModule,
    PerfectScrollbarModule,
    AlertModule,
    ModalModule.forRoot(),
    BsDropdownModule.forRoot(),
    ScrollingModule,
    ShowHidePasswordModule,
        AppRoutingModule,
        MDBBootstrapModule.forRoot(),
        BsDatepickerModule.forRoot(),
    TypeaheadModule.forRoot(),
    NguiDatetimePickerModule
    ],
    declarations: [
        AppComponent,
        HomeComponent,
      LoginComponent,
      MoldType,
        CookieList,
        Cookie,
      PieceList,
      MoldTypeList,
      SiteList,
      Area,
      EnvironmentalList,
      Environmental,
      CastingList,
      PieceDefect,
      PieceHeatTreatment,
      MultiAlertsComponent,
        MoldList,
      MoldCreation,
      MeltingGroupList,
      HeatTreatmentList,
      DefectList,
      CoatingProcessList,
      ChemicalSystemList,
      Casting,
      PieceTypeList,
      ArticleFamilyList,
      ArticleList,
      ArticleFamily,
      ArticleStockList,
      ChemistryList,
      ElementList,
      MeltingGroup,
      Furnace,
      CoatingProcess,
      DefectType,
      HeatTreatment,
      ChemicalSystem,
      PieceType,
      Chemistry,
      Element,
      Article,
      ArticleStock,
      Ladle,
      MoldFill,
      DefaultLayoutComponent

    ],
    providers: [
      { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
      { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
        EnvServiceProvider,        
    ],
    bootstrap: [AppComponent],
    schemas: [NO_ERRORS_SCHEMA]
})

export class AppModule { }
