

export class ladle {
  idLadle: number;
  reference: string;
  description: string;
  capacity: number;
  idArea: number;
  idSite: number;
  area: string;
}
