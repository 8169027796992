import { Routes, RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';

import { HomeComponent } from './home';
import { LoginComponent } from './login';
import { AuthGuard } from './_guards';
import { MoldType } from './Interfaces/MoldType';
import { MoldCreation } from './Interfaces/MoldCreation';
import { CookieList } from './Interfaces/CookieList';
import { Cookie } from './Interfaces/Cookie';
import { PieceList } from './Interfaces/PieceList';
import { MoldTypeList } from './Interfaces/MoldTypeList';
import { MoldList } from './Interfaces/MoldList';
import { SiteList } from './Interfaces/SiteList';
import { Area } from './Interfaces/Area';
import { EnvironmentalList } from './Interfaces/EnviromentalList';
import { Environmental } from './Interfaces/Environmental';
import { CastingList } from './Interfaces/CastingList';
import { PieceDefect } from './Interfaces/PieceDefect';
import { PieceHeatTreatment } from './Interfaces/PieceHeatTreatment';
import { MeltingGroupList } from './Interfaces/MeltingGroupList';
import { HeatTreatmentList } from './Interfaces/HeatTreatmentList';
import { DefectList } from './Interfaces/DefectList';
import { CoatingProcessList } from './Interfaces/CoatingProcessList';
import { ChemicalSystemList } from './Interfaces/ChemicalSystemList';
import { Casting } from './Interfaces/Casting';
import { PieceTypeList } from './Interfaces/PieceTypeList';
import { ArticleFamilyList } from './Interfaces/ArticleFamilyList';
import { ArticleList } from './Interfaces/ArticleList';
import { ArticleFamily } from './Interfaces/ArticleFamily';
import { ArticleStockList } from './Interfaces/ArticleStockList';
import { ChemistryList } from './Interfaces/ChemistryList';
import { ElementList } from './Interfaces/ElementList';
import { MeltingGroup } from './Interfaces/MeltingGroup';
import { Furnace } from './Interfaces/Furnace';
import { CoatingProcess } from './Interfaces/CoatingProcess';
import { DefectType } from './Interfaces/Defect';
import { HeatTreatment } from './Interfaces/HeatTreatment';
import { ChemicalSystem } from './Interfaces/ChemicalSystem';
import { PieceType } from './Interfaces/PieceType';
import { Chemistry } from './Interfaces/Chemistry';
import { Element } from './Interfaces/Element';
import { Article } from './Interfaces/Article';
import { ArticleStock } from './Interfaces/ArticleStock';
import { Ladle } from './Interfaces/Ladle';
import { MoldFill } from './Interfaces/MoldFill';
import { DefaultLayoutComponent } from './Interfaces/DefaultLayout';


const appRoutes: Routes = [
    {
        path: '',
        component: HomeComponent,
        canActivate: [AuthGuard]
    },
    {
        path: '',
        data: {
            title: 'Cookies'
        },
        children: [
            {
                path: '',
                pathMatch: 'full',
                redirectTo: 'cookie'
            },
            {
                path: 'cookie',
                component: CookieList,
                canActivate: [AuthGuard],
                data: {
                    title: 'Cookies'
                }
            }
        ]
    },
    {
        path: '',
        data: {
            title: 'Molds'
        },
        children: [
            {
                path: '',
                pathMatch: 'full',
                redirectTo: 'mold'
            },
            {
                path: 'moldType',
                component: MoldTypeList,
                canActivate: [AuthGuard],
                data: {
                    title: 'Mold Types'
                }
            },
            {
                path: 'mold',
                component: MoldList,
                canActivate: [AuthGuard],
                data: {
                    title: 'Mold'
                }
            },
            {
                path: 'chemicalSystem',
                component: ChemicalSystemList,
                canActivate: [AuthGuard],
                data: {
                  title: 'Chemical System'
                }
            },
            {
                path: 'coatingProcess',
                component: CoatingProcessList,
                canActivate: [AuthGuard],
                data: {
                  title: 'Coating Process'
                }
            }
        ]
    },

    {
        path: 'login',
        component: LoginComponent
    },
    {
        path: 'moldComponent',
        component: MoldType,
        canActivate: [AuthGuard]
    },
    {
        path: 'moldCreation',
        component: MoldCreation,
        canActivate: [AuthGuard]
    },
    {
        path: 'cookieCreation',
        component: Cookie,
        canActivate: [AuthGuard]
  },
  {
    path: '',
    children: [
      {
        path: '',
        component: DefaultLayoutComponent,
        canActivate: [AuthGuard],
        data: {
          title: 'Data'
        },
        children: [          
          {
            path: 'batch',
            loadChildren: () => import('./Interfaces/GroupList/grouplist.module').then(m => m.GroupModule)
          },
          {
            path: 'package',
            loadChildren: () => import('./Interfaces/ShipmentList/shipmentList.module').then(m => m.ShipmentModule)
          },
          {
            path: 'cookieTests',
            loadChildren: () => import('./Interfaces/CookieTestList/cookieTestList.module').then(m => m.CookieTestModule)
          }
        ]
      }]
  },
    {
        path: '',        
        children: [            
            {
            path: '',
            component: DefaultLayoutComponent,
                canActivate: [AuthGuard],
                data: {
                  title: 'Configuration'
            },
            children: [
              {
                path: 'template',
                loadChildren: () => import('./Interfaces/TemplateList/templateList.module').then(m => m.TemplateModule)
              },
              {
                path: 'testType',
                loadChildren: () => import('./Interfaces/TestTypeList/testTypeList.module').then(m => m.TestTypeModule)
              }
            ]
            }]
    },
    
    {
        path: '',
        data: {
            title: 'Site'
        },
        children: [
            {
                path: '',
                pathMatch: 'full',
                redirectTo: 'site'
            },
            {
                path: 'site',
                component: SiteList,
                canActivate: [AuthGuard],
                data: {
                    title: 'Sites & Areas'
                }
            },
            {
                path: 'environmental',
                component: EnvironmentalList,
                canActivate: [AuthGuard],
                data: {
                    title: 'Environmental Data'
                }
            }

        ]
    },
    {
        path: 'area',
        component: Area,
        canActivate: [AuthGuard]
    },
    {
        path: 'environmentalCreation',
        component: Environmental,
        canActivate: [AuthGuard]
    },
    {
        path: '',
        data: {
            title: 'Melting'
        },
        children: [
            {
                path: '',
                pathMatch: 'full',
                redirectTo: 'casting'
            },
            {
                path: 'casting',
                component: CastingList,
                canActivate: [AuthGuard],
                data: {
                    title: 'Heats'
                }
            },
            {
                path: 'meltingGroup',
                component: MeltingGroupList,
                canActivate: [AuthGuard],
                data: {
                    title: 'Melting Group'
                }
            }

        ]
    },
    {
        path: 'castingCreation',
        component: Casting,
        canActivate: [AuthGuard]
    },
    {
        path: '',
        data: {
            title: 'Part'
        },
        children: [
            {
                path: '',
                pathMatch: 'full',
                redirectTo: 'piece'
            },
            {
                path: 'pieceType',
                component: PieceTypeList,
                canActivate: [AuthGuard],
                data: {
                    title: 'Part Numbers'
                }
            },
            {
                path: 'piece',
                component: PieceList,
                canActivate: [AuthGuard],
                data: {
                    title: 'Parts'
                }
            },
            {
              path: 'heatTreatment',
              component: HeatTreatmentList,
              canActivate: [AuthGuard],
              data: {
                title: 'Heat Treatments'
              }
            },
            {
              path: 'defect',
              component: DefectList,
              canActivate: [AuthGuard],
              data: {
                title: 'Defect Types'
              }
            }

        ]
    },
    {
        path: 'pieceDefectCreation',
        component: PieceDefect,
        canActivate: [AuthGuard]
    },
    {
        path: 'pieceHeatTreatmentCreation',
        component: PieceHeatTreatment,
        canActivate: [AuthGuard]
    },
    {
        path: '',
        data: {
            title: 'Articles'
        },
        children: [
            {
                path: '',
                pathMatch: 'full',
                redirectTo: 'article'
            },
            {
                path: 'articleFamily',
                component: ArticleFamilyList,
                canActivate: [AuthGuard],
                data: {
                    title: 'Article Families'
                }
            },
            {
                path: 'article',
                component: ArticleList,
                canActivate: [AuthGuard],
                data: {
                    title: 'Article'
                }
            },
            {
                path: 'articleStock',
                component: ArticleStockList,
                canActivate: [AuthGuard],
                data: {
                    title: 'Article Stocks'
                }
            },
            {
                path: 'chemistry',
                component: ChemistryList,
                canActivate: [AuthGuard],
                data: {
                    title: 'Chemistries'
                }
          },
            {
            path: 'element',
            component: ElementList,
            canActivate: [AuthGuard],
            data: {
              title: 'Elements'
            }
          }
        ]
    },
    {
      path: 'articleFamilyCreation',
      component: ArticleFamily,
      canActivate: [AuthGuard]
    },
    {
      path: 'meltingGroupCreation',
      component: MeltingGroup,
      canActivate: [AuthGuard]
    },
    {
      path: 'furnaceCreation',
      component: Furnace,
      canActivate: [AuthGuard]
    },
    {
      path: 'coatingProcessCreation',
      component: CoatingProcess,
      canActivate: [AuthGuard]
    },
    {
      path: 'defectTypeCreation',
      component: DefectType,
      canActivate: [AuthGuard]
    },
    {
      path: 'heatTreatmentCreation',
      component: HeatTreatment,
      canActivate: [AuthGuard]
    },
    {
      path: 'chemicalSystemCreation',
      component: ChemicalSystem,
      canActivate: [AuthGuard]
    },
    {
      path: 'pieceTypeCreation',
      component: PieceType,
      canActivate: [AuthGuard]
    },
    {
      path: 'chemistryCreation',
      component: Chemistry,
      canActivate: [AuthGuard]
    },
    {
      path: 'elementCreation',
      component: Element,
      canActivate: [AuthGuard]
    },
    {
      path: 'articleCreation',
      component: Article,
      canActivate: [AuthGuard]
    },
    {
      path: 'articleStockCreation',
      component: ArticleStock,
      canActivate: [AuthGuard]
    },
    {
      path: 'ladleCreation',
      component: Ladle,
      canActivate: [AuthGuard]
    },
    {
      path: 'moldFillCreation',
      component: MoldFill,
      canActivate: [AuthGuard]
    },
  {
    path: 'moldList',
    component: MoldList,
    canActivate: [AuthGuard]
  },

    
    // otherwise redirect to home
    { path: '**', redirectTo: '' }
];

export const routing = RouterModule.forRoot(appRoutes);

@NgModule({
  imports: [RouterModule.forRoot(appRoutes)],
    exports: [RouterModule]
})
export class AppRoutingModule { }
