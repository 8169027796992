import { Component, ViewChild } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, FormArray, Validators } from '@angular/forms';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, first } from 'rxjs/operators';
import { EnvService } from '../../_services/env.service';
import { UserService, ArticleService, ChemistryService, TypeService } from '@/_services';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { AlertService } from '@/shared/alerts';
import { User, type, element } from '../../_models/index';
import { forEach } from '@angular/router/src/utils/collection';
import { Utils } from '../../shared/utils/utils'
import { utils } from 'protractor';
import { ActivatedRoute, Router} from "@angular/router";

@Component({ templateUrl: 'element.component.html' })

export class Element {
  errorInfo = '';
  error2 = '';
  types: any[] = [];
  selectedType = type;
  elementResultado: element;
  currentUser: User;

  elementForm = this.fb.group({
    reference: ['', Validators.required],
    symbol: ['', Validators.required],
    types: [null, Validators.required]
  });


  constructor(private fb: FormBuilder, private http: HttpClient, private env: EnvService,
    private chemistryService: ChemistryService, private typeService: TypeService, private alertService: AlertService, private user: UserService,
    private router: Router, private route: ActivatedRoute) {
    this.elementResultado = new element();
    this.currentUser = this.user.currentUserInfoValue;
  }

  @ViewChild('warningModal', { read: false }) public warningModal: ModalDirective;

  iniciarControles() {
    this.elementForm.patchValue({
      reference: '',
      symbol: '',
      types: null
    });
  }

  ngOnInit() {

    this.iniciarControles();

    this.typeService.getElementType()
      .pipe(first())
      .subscribe(
        data => {
          if (data == false) {
            this.errorInfo = "Consulta errónea.";
          }
          this.types = data;
        },
        error => {
          this.error = error;
        });

    this.selectedType = null;
    
  }

  onSubmit() {
    //Se limpia el mensaje de enviado correctamente si se estaba mostrando
    this.clear('SaveCorrect');
    //Si el formulario es válido se envía la información
    if (this.elementForm.valid) {

      this.elementResultado.idElement = 0;
      this.elementResultado.reference = this.elementForm.value.reference;
      this.elementResultado.abreviation = this.elementForm.value.symbol;
      this.elementResultado.idElementType = this.elementForm.value.types.idType;
      
      Utils.remove_empty(this.elementResultado);

      var body = JSON.stringify(this.elementResultado);

      this.chemistryService.SaveElement(body)
        .pipe(first())
        .subscribe(
          result => {
            if (result == true) {
              this.cambiosGuardadosCorrectamente();
            }
          },
          error => {
            this.errorInfo = error;
            this.error(error, 'SaveCorrect');
          });

    }


  }

  
  //Limpia todos los campos del formulario y las variables que se muestran en las tablas
  resetFormulario() {
    this.elementForm.reset();

    this.selectedType = null;
  }

  //Cuando se envía correctamente la información, se muestra el mensaje y se resetea el formulario
  cambiosGuardadosCorrectamente() {
    this.success('data saved correctly', 'SaveCorrect');
    //Se resetean todas la variables por si se desean insertar más datos
    this.resetFormulario();
  }

  success(message: string, id: string) {
    this.alertService.success(message, id);
  }

  error(message: string, id: string) {
    this.alertService.error(message, id);
  }

  info(message: string) {
    this.alertService.info(message);
  }

  warn(message: string) {
    this.alertService.warn(message);
  }

  clear(id: string) {
    this.alertService.clear(id);
  }

}
