import { moldTypeElement } from './MoldTypeElement';
import { pieceType } from './PieceType';

export class moldTypeEntity {   
  idMoldType: number;
  reference: string;
  description: string;
  theoricalVolume: number;
  idSite: number;
  moldTypeElements: moldTypeElement[];
  pieceTypes: pieceType[];
}

