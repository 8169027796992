import { Injectable } from '@angular/core';
import {
    HttpInterceptor,
    HttpRequest,
    HttpResponse,
    HttpHandler,
    HttpEvent,
    HttpErrorResponse
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

import { AuthenticationService } from '@/_services';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
    constructor(private authenticationService: AuthenticationService) { }

    //interceptOld(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    //    // add authorization header with jwt token if available      

    //  let currentUser = this.authenticationService.currentUserValue;
    //  let currentToken = this.authenticationService.currentTokenValue;
    //  console.log(currentUser);
    //  console.log(currentToken);
    //  if (currentUser && currentToken && currentToken.token) {
        
    //        request = request.clone({
    //            setHeaders: {
    //            Authorization: `Bearer ${currentToken.token}`
    //            }
    //        });
    //    }

    //    return next.handle(request);
    //}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const token: string = localStorage.getItem('token');

        let currentUser = this.authenticationService.currentUserValue;
        let currentToken = this.authenticationService.currentTokenValue;
        console.log(currentUser);
        console.log(currentToken);
        if (currentUser && currentToken && currentToken.token) {

            request = request.clone({
                setHeaders: {
                    Authorization: `Bearer ${currentToken.token}`
                }
            });
        }

        //if (token) {
        //    request = request.clone({ headers: request.headers.set('Authorization', 'Bearer ' + token) });
        //}

        if (!request.headers.has('Content-Type')) {
            request = request.clone({ headers: request.headers.set('Content-Type', 'application/json') });
        }

        request = request.clone({ headers: request.headers.set('Accept', 'application/json') });

        return next.handle(request).pipe(
            map((event: HttpEvent<any>) => {
                if (event instanceof HttpResponse) {
                    console.log('event--->>>', event);
                }
                return event;
            }));
    }
}
