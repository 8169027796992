import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { EnvService } from './env.service';

import { Message } from '@angular/compiler/src/i18n/i18n_ast';
import { casting } from '../_models/casting';
import { forEach } from '@angular/router/src/utils/collection';

@Injectable({ providedIn: 'root' })

export class CastingService {
  private currentCastingSubject: BehaviorSubject<casting[]>;
  public currentCasting: Observable<casting[]>;

    constructor(private http: HttpClient, private env: EnvService) {
      this.currentCastingSubject = new BehaviorSubject<casting[]>(JSON.parse(localStorage.getItem('casting')));
        this.currentCasting = this.currentCastingSubject.asObservable();
    }

    getCasting(idFurnace: Number) {
      return this.http.get<any>(this.env.apiUrl + `/api/Casting/furnace/` + idFurnace.toString())
              .pipe(map(temp => {
                  // store user details and jwt token in local storage to keep user logged in between page refreshes      

                  this.currentCastingSubject.next(temp);
                  return temp;
              }));
    }

    getCastingById(idCasting: Number) {
      return this.http.get<any>(this.env.apiUrl + `/api/Casting/` + idCasting.toString())
        .pipe(map(temp => {
          // store user details and jwt token in local storage to keep user logged in between page refreshes      

          this.currentCastingSubject.next(temp);
          return temp;
        }));
    }

    getMaterialByCasting(idCasting: Number) {
      return this.http.get<any>(this.env.apiUrl + `/api/Casting/material/` + idCasting.toString())
              .pipe(map(temp => {
                  // store user details and jwt token in local storage to keep user logged in between page refreshes      

                  //this.currentTemplateLabSubject.next(temp);
                  return temp;
              }));
    }

    getLadleCastingByCasting(idCasting: Number) {
      return this.http.get<any>(this.env.apiUrl + `/api/Casting/ladlecasting/casting/` + idCasting.toString())
        .pipe(map(temp => {
          // store user details and jwt token in local storage to keep user logged in between page refreshes      

          //this.currentTemplateLabSubject.next(temp);
          return temp;
        }));
    }

    getLadleCastingBySite(idSite: Number) {
      return this.http.get<any>(this.env.apiUrl + `/api/Casting/ladlecasting/site/` + idSite.toString())
        .pipe(map(temp => {
          // store user details and jwt token in local storage to keep user logged in between page refreshes      

          //this.currentTemplateLabSubject.next(temp);
          return temp;
        }));
    }

    getMoldByLadle(idLadle: Number) {
      return this.http.get<any>(this.env.apiUrl + `/api/Casting/mold/` + idLadle.toString())
        .pipe(map(temp => {
          // store user details and jwt token in local storage to keep user logged in between page refreshes      

          //this.currentTemplateLabSubject.next(temp);
          return temp;
        }));
    }

    getPieceByMold(idMold: Number) {
      return this.http.get<any>(this.env.apiUrl + `/api/Casting/piece/mold/` + idMold.toString())
        .pipe(map(piece => {
          // store user details and jwt token in local storage to keep user logged in between page refreshes      

          //this.currentPieceSubject.next(piece);
          return piece;
        }));
    }

    getMoldLadleByMold(idMold: Number) {
      return this.http.get<any>(this.env.apiUrl + `/api/Casting/moldladle/` + idMold.toString())
        .pipe(map(temp => {
          // store user details and jwt token in local storage to keep user logged in between page refreshes      

          //this.currentTemplateLabSubject.next(temp);
          return temp;
        }));
    }

    getMoldById(idMold: Number) {
      return this.http.get<any>(this.env.apiUrl + `/api/Casting/mold/id/` + idMold.toString())
        .pipe(map(mold => {
          // store user details and jwt token in local storage to keep user logged in between page refreshes      

          //this.currentPieceSubject.next(piece);
          return mold;
        }));
    }




    getMeltingGroup(idSite: Number) {
      return this.http.get<any>(this.env.apiUrl + `/api/Casting/meltinggroup/site/` + idSite.toString())
        .pipe(map(temp => {
          // store user details and jwt token in local storage to keep user logged in between page refreshes      

          //this.currentMeltingGroupSubject.next(temp);
          return temp;
        }));
    }

    getMeltingGroupById(idMeltingGroup: Number) {
      return this.http.get<any>(this.env.apiUrl + `/api/Casting/idMeltingGroup/` + idMeltingGroup.toString())
        .pipe(map(temp => {
          // store user details and jwt token in local storage to keep user logged in between page refreshes      

          //this.currentTemplateLabSubject.next(temp);
          return temp;
        }));
    }


    getFurnace(idMeltingGroup: Number) {
      return this.http.get<any>(this.env.apiUrl + `/api/Casting/meltinggroup/` + idMeltingGroup.toString())
        .pipe(map(temp => {
          // store user details and jwt token in local storage to keep user logged in between page refreshes      

          //this.currentFurnaceSubject.next(temp);
          return temp;
        }));
    }

    getFurnaceById(idFurnace: Number) {
      return this.http.get<any>(this.env.apiUrl + `/api/Casting/idFurnace/` + idFurnace.toString())
        .pipe(map(temp => {
          // store user details and jwt token in local storage to keep user logged in between page refreshes      

          //this.currentTemplateLabSubject.next(temp);
          return temp;
        }));
    }


    getLadle(idSite: Number) {
      return this.http.get<any>(this.env.apiUrl + `/api/Casting/ladle/site/` + idSite.toString())
        .pipe(map(temp => {
          // store user details and jwt token in local storage to keep user logged in between page refreshes      

          //this.currentMeltingGroupSubject.next(temp);
          return temp;
        }));
    }

    getLadleById(idLadle: Number) {
      return this.http.get<any>(this.env.apiUrl + `/api/Casting/idLadle/` + idLadle.toString())
        .pipe(map(temp => {
          // store user details and jwt token in local storage to keep user logged in between page refreshes      

          //this.currentTemplateLabSubject.next(temp);
          return temp;
        }));
    }



    SaveCasting(body) {
      let headers = new HttpHeaders({
        'Content-Type': 'application/json'
      });
      console.log(body);
      console.log(headers);
      console.log(this.env.apiUrl + `/api/Casting`);
      return this.http.post(this.env.apiUrl + `/api/Casting/casting`, body, { headers })
        .pipe(map(casting => {
          return true;
        }));
  }



    SaveFill(body) {
      let headers = new HttpHeaders({
        'Content-Type': 'application/json'
      });
      return this.http.post(this.env.apiUrl + `/api/Casting/moldfill`, body, { headers })
        .pipe(map(fill => {
          return true;
        }));
    }


    SaveMeltingGroup(body) {
      let headers = new HttpHeaders({
        'Content-Type': 'application/json'
      });    
      return this.http.post(this.env.apiUrl + `/api/Casting/meltinggroup`, body, { headers })
        .pipe(map(group => {
          return true;
        }));
    }

    SaveFurnace(body) {
      let headers = new HttpHeaders({
        'Content-Type': 'application/json'
      });
      return this.http.post(this.env.apiUrl + `/api/Casting/furnace`, body, { headers })
        .pipe(map(group => {
          return true;
        }));
    }

    SaveLadle(body) {
      let headers = new HttpHeaders({
        'Content-Type': 'application/json'
      });
      return this.http.post(this.env.apiUrl + `/api/Casting/ladle`, body, { headers })
        .pipe(map(group => {
          return true;
        }));
    }
    
    DeleteMeltingGroup(body) {
        console.log("Body " + body);
        let options = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
            }),
            body: body,
        };
        return this.http.delete(this.env.apiUrl + `/api/Casting/meltinggroup`, options)
            .pipe(map(area => {
                return true;
            }));
    }

    DeleteFurnace(body) {
        console.log("Body " + body);
        let options = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
            }),
            body: body,
        };
        return this.http.delete(this.env.apiUrl + `/api/Casting/furnace`, options)
            .pipe(map(area => {
                return true;
            }));
    }

    DeleteLadle(body) {
        console.log("Body " + body);
        let options = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
            }),
            body: body,
        };
        return this.http.delete(this.env.apiUrl + `/api/Casting/ladle`, options)
            .pipe(map(area => {
                return true;
            }));
    }

    DeleteCasting(body) {
        console.log("Body " + body);
        let options = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
            }),
            body: body,
        };
        return this.http.delete(this.env.apiUrl + `/api/Casting/casting`, options)
            .pipe(map(area => {
                return true;
            }));
    }

    DeleteMoldFill(body) {
        console.log("Body " + body);
        let options = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
            }),
            body: body,
        };
        return this.http.delete(this.env.apiUrl + `/api/Casting/moldfill`, options)
            .pipe(map(area => {
                return true;
            }));
    } 
}



