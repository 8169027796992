import { Component, ViewChild, HostListener } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, FormArray, Validators } from '@angular/forms';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, first } from 'rxjs/operators';
import { EnvService } from '../../_services/env.service';
import { SiteService,  PieceTypeService, MoldTypeService, MoldService, ArticleService, UserService, ImageGalleryService } from '@/_services';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { AlertService } from '@/shared/alerts';
import { moldTypeEntity, moldTypeElement, pieceTypeAssign, mold, moldcomponent, moldcomponentcompositions, site, User, area, articleStock, imagegallery, image } from '../../_models/index';
import { forEach } from '@angular/router/src/utils/collection';
import { Utils } from '../../shared/utils/utils'
import { utils } from 'protractor';
import { MoldType } from '../MoldType';
import { all } from 'q';
import { ActivatedRoute } from '@angular/router';
import { isNullOrUndefined } from 'util';


@Component({ templateUrl: 'moldCreation.component.html' })

export class MoldCreation {
    errorInfo = '';
    formNameAddUpdate: string = "New Mold";
    idMold: number = 0;
    idArea: number = 0;
    ind: number = 0;
    cant: number = 0;
    sites = [];
    articleStock = [];
    coatingProcess = [];
    areas = [];
    pieces = [];
    theoricalVolume: number = 0;
    selectedMoldTypeReference: string = "";
    selectedTypeMoldType: string = "";
    idMoldType: number;
    idSite: number;
    moldType: moldTypeEntity;
    moldtypeElements: moldTypeElement[] = [];
    moldTypes = [];
    moldComp: moldcomponent[] = [];
    moldComposition: moldcomponentcompositions[] = [];
    moldComponentType: any[] = [];
    chemicalSystem: any[] = [];
    infoTableselectedMoldComponent: any[] = [];
    selectedMoldTypeElement: moldTypeElement;
    selectedMoldType: moldTypeEntity;
    selectedMoldComponent: moldcomponent;
    selectedRowMoldType: number;
    selectedRowMoldTypeElement: number;
    selectedRowMoldComponent: number;
    selectedComposition: moldcomponentcompositions;    
    selectedRowComposition: number;
    moldResultado: mold;
    selectedSite: site;
    currentUser: User;
    selectedArea = area;
    selectedAreaMold = area;
    selectedArticleStock = articleStock;  
    selectedCoatingProcess: any;

    fileCount: string = "";
    selectedFile: File;
    FileList: File[] = [];
    filestring: string;
    listStringFile: string[] = [];
    fileUploadProgress: string = null;
    imageGallery: imagegallery;

    cards: any[] = [];
    slides: any = [[]];
    CAROUSEL_BREAKPOINT = 768;
    carouselDisplayMode = 'multiple';

    moldCreationForm = this.fb.group({
        reference: ['', Validators.required],
        sites: [null, Validators.required],
        moldTypes: [null, Validators.required],
        creationDate: ['', Validators.required],
        description: [''],
        areas: [null, Validators.required],        
        moldComponent: this.fb.group({
            areas: [null],
            reference: [''],
            creationDateComponent: [''],
            vibrationTime: [0],
            description: ['']
        }),
        componentComposition: this.fb.group({
            articleStock: [null],
            sandRatio: [0],
            bos: [0],
            quantity: [0],
            temperature: [0],
            coatingProcess: [null],
            thickness: [0],
            density: [0],
            observation: ['']
        })       
    });



    constructor(private fb: FormBuilder, private http: HttpClient, private env: EnvService,
        private siteService: SiteService, 
        private moldComponentTypeService: MoldTypeService, private modlService: MoldService,
        private alertService: AlertService, private articleService: ArticleService, private user: UserService, private route: ActivatedRoute, private imageGalleryService: ImageGalleryService) {
        this.moldResultado = new mold();
        this.currentUser = this.user.currentUserInfoValue;
    }

    //Controlar la ventana popup de editar componentes
    @ViewChild('warningModal', { read: false }) public warningModal: ModalDirective;
    @ViewChild('warningModal2', { read: false }) public warningModal2: ModalDirective;
    @ViewChild('warningModal3', { read: false }) public warningModal3: ModalDirective;

    addComponentComposition() {

        console.log(this.moldCreationForm.controls["componentComposition"].value);
        console.log(this.moldCreationForm.controls["componentComposition"].value.articleStock.idArticleStock);

        this.clear('alertcomponentComposition');
        
        if (this.moldCreationForm.controls["componentComposition"].value.articleStock.idArticleStock > 0) {

            //Si ya se ha añadido el PieceYpe una vez, se muestra el aviso de que ya existe y no se añade de nuevo
            if (this.moldComposition.find((x => x.idArticleStock == this.moldCreationForm.controls["componentComposition"].value.articleStock.idArticleStock
                                            && x.moldComponentReference == this.moldComp[this.selectedRowMoldComponent].reference))) {
                this.error('The selected article has already been added', 'alertcomponentComposition');
                return;
            }            

            var aux = new moldcomponentcompositions();
            aux.idMoldComponentComposition = 0;
            aux.idMoldComponent = this.moldComp[this.selectedRowMoldComponent].idMoldComponent;
            aux.idArticleStock = this.moldCreationForm.controls["componentComposition"].value.articleStock.idArticleStock;
            aux.articleStockReference = this.moldCreationForm.controls["componentComposition"].value.articleStock.reference;
            aux.sandRatio = this.moldCreationForm.controls["componentComposition"].value.sandRatio;
            aux.bos = this.moldCreationForm.controls["componentComposition"].value.bos;
            aux.thickness = this.moldCreationForm.controls["componentComposition"].value.thickness;
            aux.density = this.moldCreationForm.controls["componentComposition"].value.density;
            aux.observation = this.moldCreationForm.controls["componentComposition"].value.observation;
            aux.quantity = this.moldCreationForm.controls["componentComposition"].value.quantity;
            aux.temperature = this.moldCreationForm.controls["componentComposition"].value.temperature;
            aux.moldComponentReference = this.moldComp[this.selectedRowMoldComponent].reference;

            if (this.moldCreationForm.controls["componentComposition"].value.coatingProcess != null)
            {
                aux.coatingProcess = this.moldCreationForm.controls["componentComposition"].value.coatingProcess.reference;
                aux.idCoatingProcess = this.moldCreationForm.controls["componentComposition"].value.coatingProcess.idCoatingProcess;
            }

            this.moldComposition.push(aux);
            console.log("Composition" + aux);

            console.log(this.selectedMoldComponent);
            console.log(this.selectedRowMoldComponent);

            this.resetComposition();

        }
        else {
            this.error('Any of the values entered is not correct', 'alertcomponentComposition');
        }

    }


    updateComponentComposition() {

        this.clear('alertcomponentComposition');

        console.log(this.moldCreationForm.controls["componentComposition"].value);
        console.log(this.selectedComposition);
                
        this.moldComposition.forEach(itemComposition => {                                
            if (itemComposition.articleStockReference == this.selectedComposition.articleStockReference
                && itemComposition.moldComponentReference == this.moldComp[this.selectedRowMoldComponent].reference) {

                itemComposition.sandRatio = this.moldCreationForm.controls["componentComposition"].value.sandRatio;
                itemComposition.bos = this.moldCreationForm.controls["componentComposition"].value.bos;
                itemComposition.thickness = this.moldCreationForm.controls["componentComposition"].value.thickness;
                itemComposition.density = this.moldCreationForm.controls["componentComposition"].value.density;
                itemComposition.observation = this.moldCreationForm.controls["componentComposition"].value.observation;
                itemComposition.quantity = this.moldCreationForm.controls["componentComposition"].value.quantity;
                itemComposition.temperature = this.moldCreationForm.controls["componentComposition"].value.temperature;

                if (this.moldCreationForm.controls["componentComposition"].value.coatingProcess != null) {
                    itemComposition.coatingProcess = this.moldCreationForm.controls["componentComposition"].value.coatingProcess.reference;
                    itemComposition.idCoatingProcess = this.moldCreationForm.controls["componentComposition"].value.coatingProcess.idCoatingProcess;
                }
            }
        });           
            
        this.resetComposition();
                
    }

    addMoldComponent() {
        this.clear('alertmoldComponent');

        this.cant = 0;
        this.moldComp.forEach(x => {
            if (this.selectedMoldType.reference == x.moldTypeElementReference)
                this.cant += 1;
        });

        if (this.moldCreationForm.controls["moldComponent"].value.areas.idArea != null
            && this.moldCreationForm.controls["moldComponent"].value.areas.reference != null && this.moldCreationForm.controls["moldComponent"].value.reference != null
            && this.moldCreationForm.controls["moldComponent"].value.creationDateComponent != null
            && this.moldCreationForm.controls["moldComponent"].value.vibrationTime != null && (this.selectedMoldTypeElement.numArticle > this.cant)) {

            console.log("Area ID " + this.moldCreationForm.controls["moldComponent"].value.areas.idArea);

            var aux = new moldcomponent();
            aux.idMold = this.idMold;
            aux.idMoldComponent = 0 //this.moldComp.length + 1;
            aux.idArea = this.moldCreationForm.controls["moldComponent"].value.areas.idArea;
            aux.area = this.moldCreationForm.controls["moldComponent"].value.areas.reference;
            aux.idMoldTypeElement = this.selectedMoldTypeElement.idMoldTypeElement;
            aux.moldTypeElementReference = this.selectedMoldTypeElement.reference;
            aux.dtArticleStock = this.moldCreationForm.controls["moldComponent"].value.creationDateComponent,
            aux.idSite = this.idSite,
            aux.description = this.moldCreationForm.controls["moldComponent"].value.description;
            aux.reference = this.moldCreationForm.controls["moldComponent"].value.reference;
            aux.vibrationTime = this.moldCreationForm.controls["moldComponent"].value.vibrationTime;            
            aux.moldComponentCompositions = [];

            this.moldComp.push(aux);            
            this.selectedAreaMold = null;
            this.iniciarControlesMoldComponent();
            this.selectedTypeMoldType = "Drag / Core Reference";
            this.selectedMoldTypeReference = "";
        }
        else {
            this.error('Any of the values entered is not correct', 'alertmoldComponent');
        }
        //if (this.infoTable.length > 0)
        //    this.moldCreationForm.controls["moldComponent"].enable();
    }


    updateComponent() {

        this.clear('alertcomponent');

        console.log(this.moldCreationForm.controls["moldComponent"].value.areas);

        if (this.moldCreationForm.controls["moldComponent"].value.areas.idArea != null
            && this.moldCreationForm.controls["moldComponent"].value.areas.reference != null 
            && this.moldCreationForm.controls["moldComponent"].value.creationDateComponent != null
            && this.moldCreationForm.controls["moldComponent"].value.vibrationTime != null ) {

            this.moldComp.forEach(item => {
                if (item.reference == this.selectedMoldComponent.reference) {

                    console.log("entrei");
                    item.idArea = this.moldCreationForm.controls["moldComponent"].value.areas.idArea;
                    item.area = this.moldCreationForm.controls["moldComponent"].value.areas.reference;
                    item.dtArticleStock = this.moldCreationForm.controls["moldComponent"].value.creationDateComponent;
                    item.vibrationTime = this.moldCreationForm.controls["moldComponent"].value.vibrationTime;
                    item.description = this.moldCreationForm.controls["moldComponent"].value.description;    
                }
            });

            this.resetComponent();
        }
        else {
            this.error('Any of the values entered is not correct', 'alertcomponent');
        }

    }

    RowSelected(u: any, i: number) {
        
        this.selectedMoldTypeElement = u;   // declare variable in component.
        this.selectedRowMoldTypeElement = i;
        this.selectedMoldTypeReference = this.selectedMoldTypeElement.reference;

        console.log(this.selectedMoldTypeElement);

        if (this.selectedMoldTypeElement.articleTypeReference == "Core Type")
            this.selectedTypeMoldType = "Core Reference";
        if (this.selectedMoldTypeElement.articleTypeReference == "Drag Type")
            this.selectedTypeMoldType = "Drag Reference";

        console.log(this.selectedMoldType.reference);
    }
   

    RowDelete(u: any, i: number) {
        this.clear('alertmoldComponent');
        this.selectedMoldComponent = u;

        if (this.infoTableselectedMoldComponent.length > 0) {
            this.error('First delete all compositions assign', 'alertmoldComponent');
        }
        else {
            this.moldComp.splice(i, 1);
        }
    }


    RowDeleteComposition(u: any, i: number) {

        console.log(u);
        console.log(i);

        //this.infoTableselectedMoldComponent.splice(i, 1);
                

        this.moldComposition.forEach(item => {
            if ((item.articleStockReference == u.articleStockReference) &&
                (item.moldComponentReference == (this.selectedMoldComponent.reference) || item.idMoldComponent == (this.selectedMoldComponent.idMoldComponent))) {
                console.log(item);
                console.log(this.ind);
                this.ind = this.moldComposition.indexOf(item);
                console.log(this.ind);
                this.moldComposition.splice(this.ind, 1);
            }
        });

        //this.moldComposition = [];

        //Si existe algún componente drag/core que tiene asignado un tipo de pieza removido, quitalo
        //this.infoTableselectedMoldComponent.forEach(item => {
        //    this.moldComposition.push({
        //        idMoldComponentComposition: item.idMoldComponentComposition,
        //        idMoldComponent: item.idMoldComponent,
        //        articleStockReference: item.articleStockReference,
        //        moldComponentReference: item.moldComponentReference,
        //        thickness: item.thickness,
        //        density: item.density,
        //        sandRatio: item.sandRatio,
        //        bos: item.bos,
        //        quantity: item.quantity,
        //        temperature: item.temperature,
        //        observation: item.observation,
        //        coatingProcess: item.coatingProcess,
        //        idArticleStock: item.idArticleStock,
        //        idCoatingProcess: item.idCoatingProcess
        //    })            
        //});

        this.RowMoldComponent(this.selectedMoldComponent, this.selectedRowMoldComponent);
    }

    //TABLA DRAGCORE
    RowMoldComponent(u: any, i: number) {
        this.infoTableselectedMoldComponent = [];
        this.selectedMoldComponent = u;   // declare variable in component.
        this.selectedRowMoldComponent = i;
        console.log(this.selectedMoldComponent);
        console.log(this.moldComposition);

        console.log(this.moldComposition);
        console.log(this.selectedMoldComponent);

        if (this.moldComposition.length > 0) {

            this.moldComposition.forEach(item => {
                if (item.moldComponentReference == this.selectedMoldComponent.reference) {
                    console.log(this.selectedMoldComponent.reference, item.moldComponentReference);                    
                    this.infoTableselectedMoldComponent.push({
                        idMoldComponentComposition: item.idMoldComponentComposition,
                        idMoldComponent: item.idMoldComponent,
                        articleStockReference: item.articleStockReference,
                        moldComponentReference: item.moldComponentReference,
                        thickness: item.thickness,
                        density: item.density,
                        sandRatio: item.sandRatio,
                        bos: item.bos,
                        quantity: item.quantity,
                        temperature: item.temperature,
                        observation: item.observation,
                        coatingProcess: item.coatingProcess
                    })
                }
            });
        }
        
    }

  //TABLA DRAGCORE ASIGNACION PIEZAS
    RowselectedComposition(u: any, i: number) {
    this.selectedComposition = u;  
    this.selectedRowComposition = i;

    this.CargarControlesComponentComposition(this.selectedComposition);

    this.coatingProcess.forEach(coating => {
        if (coating.reference == this.selectedComposition.coatingProcess) {
            this.selectedCoatingProcess = coating;
        }
    });
        
        console.log(this.selectedComposition);
        console.log("composition interfaz");
        console.log(this.moldCreationForm.controls["componentComposition"].value);

  }


  iniciarControlesMoldComponent() {
      this.moldCreationForm.patchValue({
      moldComponent: {
              areas: [null],
              reference: '',
              creationDateComponent: null,
              vibrationTime: null,
              description: ''
      }
    });
    }

    iniciarControlesComponentComposition() {
      this.moldCreationForm.patchValue({
          componentComposition: {
              articleStock: [],
              sandRatio: 0,
              bos: 0,
              quantity: 0,
              temperature: 0,
              coatingProcess: [],
              thickness: 0,
              density: 0,
              observation: ''
      }
      });

        this.selectedArticleStock = null;
        this.selectedCoatingProcess = null;
    }

    CargarControlesComponentComposition(u: any) {

        this.moldCreationForm.patchValue({
            componentComposition: {
                articleStock: this.selectedArticleStock,
                sandRatio: u.sandRatio,
                bos: u.bos,
                quantity: u.quantity,
                temperature: u.temperature,
                coatingProcess: this.selectedCoatingProcess,
                thickness: u.thickness,
                density: u.density,
                observation: u.observation
            }
        });

        this.selectedArticleStock = null;
        this.selectedCoatingProcess = null;
    }
         
  CloseComponentComposition() {
      this.resetComposition();
    }

  CloseComponent() {
      this.resetComponent();
    }

  //Limpia todos los campos del formulario y las variables que se muestran en las tablas
    resetFormulario() {
      this.formNameAddUpdate = "New Mold";
      this.moldCreationForm.reset();
    //this.infoTable = [];
      this.moldComp = [];
      this.moldComposition = [];
      this.selectedMoldComponent = null;
      this.moldCreationForm.controls["moldComponent"].disable();
      this.moldtypeElements = [];
    //if (this.infoTable.length == 0)
    //      this.moldCreationForm.controls["moldComponent"].disable();

      this.selectedTypeMoldType = "Drag / Core Reference";
      this.selectedMoldTypeReference = "";
      this.selectedAreaMold = null;
      this.selectedArea = null;
      this.selectedArticleStock = null;
      this.selectedCoatingProcess = null;

      this.cards = [];
      this.slides = [];
      this.imageGallery = null;
  }

    //Limpia todos los campos del formulario y las variables que se muestran en las tablas
    resetComposition() {
        this.moldCreationForm.controls["componentComposition"].reset();
        this.iniciarControlesComponentComposition();
        this.warningModal.hide();
        this.warningModal2.hide();
        this.RowMoldComponent(this.selectedMoldComponent, this.selectedRowMoldComponent);
      
        console.log("passou");
        console.log(this.moldCreationForm.controls["componentComposition"]);
    }


    //Limpia todos los campos del formulario y las variables que se muestran en las tablas
    resetComponent() {
        this.moldCreationForm.controls["moldComponent"].reset();
        this.iniciarControlesMoldComponent();
        this.warningModal3.hide();
    }

    CargarControlesComponent(u: any) {

        this.areas.forEach(area => {
            if (area.reference == u.area) {
                this.selectedAreaMold = area;
            }
        });

        this.moldCreationForm.patchValue({
            moldComponent: {
                areas: this.selectedAreaMold,
                reference: u.reference,
                creationDateComponent: u.dtArticleStock,
                vibrationTime: u.vibrationTime,
                description: u.description
            }
        });

    }

  //Cuando se envía correctamente la información, se muestra el mensaje y se resetea el formulario
  cambiosGuardadosCorrectamente() {
    this.success('data saved correctly', 'SaveCorrect');
      //Se resetean todas la variables por si se desean insertar más datos
      
    if (this.idMold == 0) this.resetFormulario();
  }

   getElementsMoldType() {
      if (this.idSite > 0) {
          this.moldComponentTypeService.getMoldCompType(this.idSite)
              .pipe(first())
              .subscribe(
                  dataMold => {
                      //this.moldComponentType = dataMold;
                      this.moldTypes = dataMold;
                      if (this.idMoldType > 0) {
                          this.selectedMoldType = this.moldTypes.find(x => x.idMoldType == this.idMoldType);
                          this.CargarElementsMoldType();
                      }
                  },
                  error => {
                      console.log(error);
                      this.errorInfo = error;

                  });
      }
    }

    getAreas() {
      if (this.idSite > 0) {
          this.siteService.getArea(this.idSite, this.currentUser.idUserApplication)
              .pipe(first())
              .subscribe(
                  dataMold => {
                      //this.moldComponentType = dataMold;
                      this.areas = dataMold;
                      if (this.idArea > 0) {
                          this.selectedArea = this.areas.find(x => x.idArea == this.idArea);
                      }
                  },
                  error => {
                      console.log(error);
                      this.errorInfo = error;

                  });
      }
    }

    getCompositions() {
      if (this.idSite > 0) {
          this.articleService.getArticleComposition(this.idSite)
              .pipe(first())
              .subscribe(
                  data => {
                      this.articleStock = data;
                  },
                  error => {
                      console.log(error);
                      this.errorInfo = error;

                  });
      }
    }

  RowSelectedSite() {
    this.moldTypes = [];
    this.moldComp = [];
    this.theoricalVolume = 0;
    this.idSite = 0;
    if (this.selectedSite)
      this.idSite = this.selectedSite.idSite;
    this.getAreas();
    this.getElementsMoldType();
    this.getCompositions();
     
  }

    CargarElementsMoldType() {

        if (this.idMoldType > 0) {
            this.theoricalVolume = this.moldTypes.find(x => x.idMoldType == this.idMoldType).theoricalVolume;
            console.log("theoricalVolume " + this.theoricalVolume)
            this.moldComponentTypeService.getComponentDragCore(this.idMoldType)
                .pipe(first())
                .subscribe(
                    dataMold => {
                        //this.moldComponentType = dataMold;
                        this.moldtypeElements = dataMold;
                        console.log("MoldComponentType" + this.moldtypeElements);
                        console.log("Cantidad " + this.moldtypeElements.length)
                        if (this.moldtypeElements.length > 0) {
                            this.moldCreationForm.controls["moldComponent"].enable();
                        }
                    },
                    error => {
                        console.log(error);
                        this.errorInfo = error;

                    });
        }
        else {
            this.iniciarControlesMoldComponent;
            this.selectedTypeMoldType = "Drag / Core Reference";
            this.selectedMoldTypeReference = "";
            this.moldCreationForm.controls["moldComponent"].disable();
            this.moldtypeElements = [];
        }

    }

    selectChangeHandler(event: any) {        
        this.idMoldType = event.target.value;
        this.theoricalVolume = 0;
        console.log(this.idMoldType);
        this.CargarElementsMoldType();
    }

  onSubmit() {    
    //Se limpia el mensaje de enviado correctamente si se estaba mostrando
      this.clear('SaveCorrect');     

    //Si el formulario es válido se envía la información
      if (this.moldCreationForm.valid) {      

          this.moldResultado.idMold = this.idMold;
          this.moldResultado.idArea = this.moldCreationForm.value.areas.idArea;
          this.moldResultado.idMoldType = this.moldTypes.find(x => x.idMoldType == this.idMoldType).idMoldType;
          this.moldResultado.moldType = this.moldTypes.find(x => x.idMoldType == this.idMoldType).reference;
          this.moldResultado.area = this.moldCreationForm.value.areas.reference;
          this.moldResultado.dtMold = this.moldCreationForm.value.creationDate;
          this.moldResultado.reference = this.moldCreationForm.value.reference;
          this.moldResultado.imageGallery = this.imageGallery;
          this.moldResultado.moldComponents = [];          
                    
          this.moldComp.forEach(itemComponent => {
              itemComponent.moldComponentCompositions = [];
              this.moldComposition.forEach(itemComposition => {
                  if (itemComposition.moldComponentReference == itemComponent.reference) {
                      console.log(itemComposition);
                      itemComponent.moldComponentCompositions.push(itemComposition);
                  }
              });
          });

          this.moldComp.forEach(item => {
            this.moldResultado.moldComponents.push(item);
          });
     
          Utils.remove_empty(this.moldResultado);

          console.log(this.moldResultado);

          var body = JSON.stringify(this.moldResultado);

          this.modlService.saveMold(body)
              .pipe(first())
              .subscribe(
                  result => {
                      if (result == true) {
                          this.cambiosGuardadosCorrectamente();
                      }
                  },
                  error => {
                      console.log(error);
                      this.errorInfo = error;
                      //this.error('An error has occurred, the information has not been saved', 'SaveCorrect');
                      this.error(error, 'SaveCorrect');
                  });

      }
  } 

    getSite() {
        this.siteService.site(this.currentUser.idUserApplication)
            .pipe(first())
            .subscribe(
                data => {
                    if (data == false) {
                        this.errorInfo = "Consulta errónea.";
                    }
                this.sites = data;
                 
                if (this.idSite !== undefined && this.idSite > 0) {
                  this.selectedSite = this.sites.find(x => x.idSite == this.idSite);
                  this.getAreas();
                  this.getElementsMoldType();
                  this.getCompositions();
                }
                else {                  
                  //Se selecciona por defecto el site del usuario
                  if (this.currentUser.idSiteDefault > 0) {
                    this.selectedSite = this.sites.find(x => x.idSite == this.currentUser.idSiteDefault);
                    this.idSite = this.selectedSite.idSite;
                    this.getAreas();
                    this.getElementsMoldType();
                    this.getCompositions();                    
                  }
                  else
                    this.selectedSite = null;
                }
                },
                error => {
                    this.errorInfo = error;

                });
    }

  ngOnInit()
  {
    console.log("entrei creation");
    this.selectedTypeMoldType = "Drag / Core Reference";
    this.selectedSite = null;
    this.selectedArea = null;
    this.selectedAreaMold = null;
    this.selectedArticleStock = null;
    this.selectedCoatingProcess = null;
    this.idMold = 0;

    this.getParameter();   

      this.modlService.getAllCoating()
          .pipe(first())
          .subscribe(
              data => {
                  this.coatingProcess = data;
              },
              error => {
                  console.log(error);
                  this.errorInfo = error;

              });

    //Inicializar valores controles del formulario
      this.iniciarControlesMoldComponent();
      this.iniciarControlesComponentComposition();
    }


    getParameter() {

        if (this.route.snapshot.paramMap.get('idMold') != null) {
            this.idMold = (parseInt)(this.route.snapshot.paramMap.get('idMold'));
            this.idSite = (parseInt)(this.route.snapshot.paramMap.get('idSite'));
            console.log(this.idSite);
            this.formNameAddUpdate = "Update Mold";
            this.getMoldById();
            this.moldCreationForm.controls["sites"].disable();
            this.moldCreationForm.controls["moldTypes"].disable();
        }
        else {
            this.getSite();
            this.moldCreationForm.controls["moldComponent"].disable();
        }
    }

    getMoldById() {
        
        this.modlService.getById(this.idMold)
            .pipe(first())
            .subscribe(
                data => {
                    if (data == false) {
                        this.errorInfo = "Consulta errónea.";
                    }
                    this.moldResultado = data;
                    console.log(this.moldResultado);
                    if (this.moldResultado.imageGallery != null) {
                        console.log(this.moldResultado.imageGallery);
                        this.getImageGalery();

                    }
                    console.log(data);
                    this.iniciarControlesMold();                           
                },
                error => {
                    this.error = error;
                });
    }

    getImageGalery() {
        console.log(this.moldResultado);
        if (this.moldResultado.imageGallery != null && this.moldResultado.imageGallery.idImageGallery > 0) {

            this.imageGalleryService.returnImageGalleryById(this.moldResultado.imageGallery.idImageGallery)
                .pipe(first())
                .subscribe(
                    data => {
                        if (data == false) {
                            this.errorInfo = "Consulta errónea.";
                        }
                        this.imageGallery = data;
                        this.cards.splice(0, this.cards.length);
                        if (this.imageGallery != null) {
                            this.imageGallery.idImageGallery = this.moldResultado.imageGallery.idImageGallery;
                            this.imageGallery.reference = this.moldResultado.imageGallery.reference;
                            this.showImage();
                        }


                    },
                    error => {
                        this.error = error;
                    });
        }
    }

    getFileDetails(e) {
        this.FileList = [];
        this.listStringFile = [];
        this.fileCount = e.target.files.length + " files";
        console.log(e.target.files);
        for (var i = 0; i < e.target.files.length; i++) {
            this.selectedFile = <File>e.target.files[i];
            this.FileList.push(this.selectedFile);
            var reader = new FileReader();
            reader.onload = this._handleReaderLoaded.bind(this);
            reader.readAsBinaryString(this.selectedFile);
        }

    }

    _handleReaderLoaded(readerEvt) {
        var binaryString = readerEvt.target.result;
        this.filestring = btoa(binaryString);  // Converting binary string data.
        this.listStringFile.push(this.filestring);
        console.log(this.filestring);
    }

    //resizeImage(file: File, maxWidth: number, maxHeight: number): Promise<Blob> {
    //    return new Promise((resolve, reject) => {
    //        let image = new Image();
    //        image.src = URL.createObjectURL(file);
    //        image.onload = () => {
    //            let width = image.width;
    //            let height = image.height;
    //            maxWidth = width / 2;
    //            maxHeight = height / 2;

    //            console.log(image.width + " " + image.height);

    //            if (width <= maxWidth && height <= maxHeight) {
    //                resolve(file);
    //            }

    //            let newWidth;
    //            let newHeight;

    //            if (width > height) {
    //                newHeight = height * (maxWidth / width);
    //                newWidth = maxWidth;
    //            } else {
    //                newWidth = width * (maxHeight / height);
    //                newHeight = maxHeight;
    //            }

    //            let canvas = document.createElement('canvas');
    //            canvas.width = newWidth;
    //            canvas.height = newHeight;

    //            let context = canvas.getContext('2d');

    //            context.drawImage(image, 0, 0, newWidth, newHeight);

    //            console.log(newWidth + " " + newHeight);

    //            canvas.toBlob(resolve, file.type);
    //        };
    //        image.onerror = reject;
    //    });
    //}


    uploadFiles() {
        this.cards = [];
        this.slides = [];        
        this.clear('image');
        if (this.idMold > 0 && this.moldResultado.imageGallery != null) {
            this.imageGallery.idImageGallery = this.moldResultado.imageGallery.idImageGallery;
            this.imageGallery.reference = this.moldResultado.imageGallery.reference;
            var i: number = 0;
            if (this.listStringFile.length > 0) {
                this.listStringFile.forEach(item => {
                    //var byteArray = new Uint8Array(item);
                    //console.log(item);
                    var tempimage = new image();
                    tempimage.idImage = 0;
                    tempimage.reference = this.FileList[i].name.substr(0, this.FileList[i].name.indexOf("."));
                    tempimage.description = this.FileList[i].lastModified.toString();
                    tempimage.imageFile = item;
                    if (this.imageGallery.images.find(x => x.imageFile == tempimage.imageFile) == null) {
                        this.imageGallery.images.push(tempimage);
                        i++;
                        this.fileUploadProgress = Math.round(i / this.listStringFile.length * 100) + '%';
                    }
                });

                this.showImage();
                this.fileCount = "";
            }
            else
                this.error("Select one image at least", "image");
            this.fileUploadProgress = "";
        }
        else {

            if (this.moldCreationForm.value != null && this.moldCreationForm.value.reference != "") {
                this.fileUploadProgress = '0%';
                this.imageGallery = new imagegallery();
                this.imageGallery.idImageGallery = 0;
                this.imageGallery.reference = this.moldCreationForm.value.reference + "_Image_Gallery";
                //this.imageGallery.description = "Prueba";
                this.imageGallery.images = [];
                var tempImageList: image[] = [];
                var i: number = 0;
                if (this.listStringFile.length > 0) {
                    this.listStringFile.forEach(item => {
                        //var byteArray = new Uint8Array(item);
                        //console.log(item);
                        var tempimage = new image();
                        tempimage.idImage = 0;
                        tempimage.reference = this.FileList[i].name.substr(0, this.FileList[i].name.indexOf("."));
                        tempimage.description = this.FileList[i].lastModified.toString();
                        tempimage.imageFile = item;
                        tempImageList.push(tempimage);
                        i++;
                        this.fileUploadProgress = Math.round(i / this.listStringFile.length * 100) + '%';
                    });
                    this.imageGallery.images = tempImageList;

                    this.showImage();
                    this.fileCount = "";
                    this.fileUploadProgress = "";
                    //console.log(this.imageGallery);
                }
                else
                    this.error("Select one image at least", "image");
            }
            else
                this.error("Select a mold reference", "image");
        }
    }

    showImage() {

        this.imageGallery.images.forEach(item => {
            var url = 'data:image/jpeg;base64,' + item.imageFile; // use this in <img src="..."> binding
            this.cards.push({
                title: item.reference,
                description: item.description,
                img: url
            });
        });
        this.slides = this.chunk(this.cards, 3);
        if (window.innerWidth <= this.CAROUSEL_BREAKPOINT) {
            this.carouselDisplayMode = 'single';
        } else {
            this.carouselDisplayMode = 'multiple';
        }
    }

    @HostListener('window:resize')
    onWindowResize() {
        if (window.innerWidth <= this.CAROUSEL_BREAKPOINT) {
            this.carouselDisplayMode = 'single';
        } else {
            this.carouselDisplayMode = 'multiple';
        }
    }

    chunk(arr, chunkSize) {
        let R = [];
        for (let i = 0, len = arr.length; i < len; i += chunkSize) {
            R.push(arr.slice(i, i + chunkSize));
        }
        return R;
    }

    deleteImage(index: number) {

        if (this.cards.length == 1) {
            this.cards = [];
            this.imageGallery.images = [];
            this.imageGallery.idImageGallery = 0;
            this.slides = [];
            console.log(this.imageGallery);
        }

        else {
            this.cards = [];
            this.slides = [];
            this.imageGallery.images.splice(index, 1);
            console.log(this.imageGallery);
            this.showImage();
        }
        console.log(this.cards);
    }


    iniciarControlesMold() {
        this.moldCreationForm.patchValue({
            reference: this.moldResultado.reference,
            creationDate: this.moldResultado.dtMold,
            //description: this.moldResultado.description,
        });

        this.moldComp = this.moldResultado.moldComponents;

        this.moldResultado.moldComponents.forEach(item => {
            item.moldComponentCompositions.forEach(itemcomp => {
                console.log(itemcomp);
                //this.infoTableselectedMoldComponent.push(itemcomp);
                this.moldComposition.push(itemcomp);
            })
        });

        console.log(this.moldResultado.moldComponents);
        console.log(this.infoTableselectedMoldComponent);

        this.idArea = this.moldResultado.idArea;
        this.idMoldType = this.moldResultado.idMoldType;

        this.getSite();             
    }

  success(message: string, id:string) {
    this.alertService.success(message, id);
  }

  error(message: string, id: string) {
    this.alertService.error(message, id);
  }

  info(message: string) {
    this.alertService.info(message);
  }

  warn(message: string) {
    this.alertService.warn(message);
  }

  clear(id: string) {
    this.alertService.clear(id);
  }

}

