import { Component, ViewChild } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, FormArray, Validators } from '@angular/forms';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, first } from 'rxjs/operators';
import { EnvService } from '../../_services/env.service';
import { UserService, PieceService } from '@/_services';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { AlertService } from '@/shared/alerts';
import { User, defecttype } from '../../_models/index';
import { forEach } from '@angular/router/src/utils/collection';
import { Utils } from '../../shared/utils/utils'
import { utils } from 'protractor';
import { ActivatedRoute, Router} from "@angular/router";

@Component({ templateUrl: 'defect.component.html' })

export class DefectType {
  formNameAddUpdate: string = "New Defect";
  idDefectType: number = 0;
  description: string = "";
  reference: string = "";
  errorInfo = '';
  error2 = '';
  defectResultado: defecttype;
  currentUser: User;

  defectForm = this.fb.group({
    reference: ['', Validators.required],
    description: ['']
  });


  constructor(private fb: FormBuilder, private http: HttpClient, private env: EnvService,
    private pieceService: PieceService, private alertService: AlertService, private user: UserService,
    private router: Router, private route: ActivatedRoute) {
    this.defectResultado = new defecttype();
    this.currentUser = this.user.currentUserInfoValue;
  }

  @ViewChild('warningModal', { read: false }) public warningModal: ModalDirective;

  iniciarControles() {
    this.defectForm.patchValue({      
        reference: this.defectResultado.reference,
        description: this.defectResultado.description
    });
  }


  ngOnInit() {
    this.getParameter();
    this.iniciarControles();
    
  }

  onSubmit() {
    //Se limpia el mensaje de enviado correctamente si se estaba mostrando
    this.clear('SaveCorrect');
    //Si el formulario es válido se envía la información
    if (this.defectForm.valid) {

      this.defectResultado.idDefectType = this.idDefectType;
      this.defectResultado.reference = this.defectForm.value.reference;
      this.defectResultado.description = this.defectForm.value.description;

      Utils.remove_empty(this.defectResultado);

      var body = JSON.stringify(this.defectResultado);


      this.pieceService.SaveDefect(body)
        .pipe(first())
        .subscribe(
          result => {
            if (result == true) {
              this.cambiosGuardadosCorrectamente();
            }
          },
          error => {
            this.errorInfo = error;
            this.error(error, 'SaveCorrect');
          });

    }


    }

    getParameter() {

        if (this.route.snapshot.paramMap.get('idDefectType') != null) {
            this.idDefectType = (parseInt)(this.route.snapshot.paramMap.get('idDefectType'));
            console.log("ID Site " + this.idDefectType /*+ "  " + this.router.snapshot.params.idTemplateLabTest*/);
            this.formNameAddUpdate = "Update Defect";
            this.getDefectTypeById();
           /* this.myBtn = true;
            this.myTab = true;
            //this.state = "disabled";
            this.pieceHeatTreatmentForm.get('areas').disable();
            this.pieceHeatTreatmentForm.get('sites').disable();
            this.pieceHeatTreatmentForm.get('pieces').disable();*/
        }
    }

    getDefectTypeById() {
 
            this.pieceService.getDefectTypeById(this.idDefectType)
                .pipe(first())
                .subscribe(
                    data => {
                        if (data == false) {
                            this.error2 = "Consulta errónea.";
                        }
                        this.defectResultado = data;
                        console.log(data);
                    },
                    error => {
                        this.error = error;
                        this.error(error, 'Error');

                    });
      }

  
  //Limpia todos los campos del formulario y las variables que se muestran en las tablas
  resetFormulario() {
    this.defectForm.reset();
    this.formNameAddUpdate = "New Defect";
  }

  //Cuando se envía correctamente la información, se muestra el mensaje y se resetea el formulario
    cambiosGuardadosCorrectamente() {
        
    this.success('data saved correctly', 'SaveCorrect');
    //Se resetean todas la variables por si se desean insertar más datos
      if (this.idDefectType == 0) this.resetFormulario();    
  }

  success(message: string, id: string) {
    this.alertService.success(message, id);
  }

  error(message: string, id: string) {
    this.alertService.error(message, id);
  }

  info(message: string) {
    this.alertService.info(message);
  }

  warn(message: string) {
    this.alertService.warn(message);
  }

  clear(id: string) {
    this.alertService.clear(id);
  }

}
