
export class environmental {    
  idEnvironmental: number;
  temperature: number;
  humidity: number;
  dtEnvironmental: Date;
  idSite: number;
  site: string;
  idArea: number;
  area: string;
  environmentals: environmental[];
}

