import { piecedefect } from './piecedefect';
import { pieceheattreatment } from './pieceheattreatment';
import { moldcomponent } from './moldcomponent';

export class piece {
  idPiece: number;
  reference: string;
  description: string;
  dtArticleStock: Date;
  idPieceType: number;
  pieceTypeReference: string;  
  timeUntilShakeout: number;
  idArea: number;
  area: string;
  idSite: number;
  idChemistry: number;
  chemistry: string;
  quality: string;
  workHours: number;
  defects: piecedefect[];
  heattreatments: pieceheattreatment[];
  moldComps: moldcomponent[];
  moldCompReferences: string;
}
