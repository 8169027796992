import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { EnvService } from './env.service';

import { Message } from '@angular/compiler/src/i18n/i18n_ast';
import { templatelab } from '../_models/templatelab';
import { test } from '../_models/test';
import { forEach } from '@angular/router/src/utils/collection';

@Injectable({ providedIn: 'root' })

export class TestService {
    private currentTemplateLabSubject: BehaviorSubject<templatelab[]>;
    public currentTemplateLab: Observable<templatelab[]>;

  constructor(private http: HttpClient, private env: EnvService) {
      this.currentTemplateLabSubject = new BehaviorSubject<templatelab[]>(JSON.parse(localStorage.getItem('templatelab')));
      this.currentTemplateLab = this.currentTemplateLabSubject.asObservable();
  }

    returnTemplateLab(lab: Number) {
     return this.http.get<any>(this.env.apiUrl + `/api/CookieTest/template/` + lab.toString())
     .pipe(map(temp => {
        // store user details and jwt token in local storage to keep user logged in between page refreshes      

         this.currentTemplateLabSubject.next(temp);
         return temp;
      }));
    }

    returnTemplateLabById(idTemplateLab: Number) {
        return this.http.get<any>(this.env.apiUrl + `/api/CookieTest/` + idTemplateLab.toString())
            .pipe(map(temp => {
                // store user details and jwt token in local storage to keep user logged in between page refreshes      

                this.currentTemplateLabSubject.next(temp);
                return temp;
            }));
    }

    returnTestById(idTest: Number) {
        return this.http.get<any>(this.env.apiUrl + `/api/CookieTest/testbyid/` + idTest.toString())
            .pipe(map(temp => {
                // store user details and jwt token in local storage to keep user logged in between page refreshes      

                this.currentTemplateLabSubject.next(temp);
                return temp;
            }));
    }

    returnVariableById(idVar: Number) {
        return this.http.get<any>(this.env.apiUrl + `/api/CookieTest/variablebyid/` + idVar.toString())
            .pipe(map(temp => {
                // store user details and jwt token in local storage to keep user logged in between page refreshes      

                this.currentTemplateLabSubject.next(temp);
                return temp;
            }));
    }

    returnTest(templatelab: Number) {
        return this.http.get<any>(this.env.apiUrl + `/api/CookieTest/test/` + templatelab.toString())
            .pipe(map(temp => {
                // store user details and jwt token in local storage to keep user logged in between page refreshes      

                //this.currentTemplateLabSubject.next(temp);
                return temp;
            }));
    }

    returnVariable(test: Number) {
        return this.http.get<any>(this.env.apiUrl + `/api/CookieTest/variable/` + test.toString())
            .pipe(map(temp => {
                // store user details and jwt token in local storage to keep user logged in between page refreshes      

                //this.currentTemplateLabSubject.next(temp);
                return temp;
            }));
    }


    SaveTestResume(body) {
        let headers = new HttpHeaders({
            'Content-Type': 'application/json'
        });
        console.log(body);
        console.log(headers);
        console.log(this.env.apiUrl + `/api/CookieTest`);
        return this.http.post(this.env.apiUrl + `/api/CookieTest/template/`, body, { headers })
            .pipe(map(templatelab => {
              return templatelab;
            }));
    }

    DeleteTestResume(body) {
        console.log("Body " + body);
        let options = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
            }),
            body: body,
        };
        return this.http.delete(this.env.apiUrl + `/api/CookieTest/template/`, options)
            .pipe(map(area => {
                return true;
            }));
    }

    SaveTest(body) {
        let headers = new HttpHeaders({
            'Content-Type': 'application/json'
        });
        console.log("Body:  "+body);
        console.log(headers);
        console.log(this.env.apiUrl + `/api/CookieTest`);
        return this.http.post(this.env.apiUrl + `/api/CookieTest/test/`, body, { headers })
            .pipe(map(test => {
                return true;
            }));
    }

    DeleteTest(body) {
        console.log("Body " + body);
        let options = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
            }),
            body: body,
        };
        return this.http.delete(this.env.apiUrl + `/api/CookieTest/test/`, options)
            .pipe(map(area => {
                return true;
            }));
    }

    SaveVariable(body) {
        let headers = new HttpHeaders({
            'Content-Type': 'application/json'
        });
        console.log("Body:  " + body);
        console.log(headers);
        console.log(this.env.apiUrl + `/api/CookieTest`);
        return this.http.post(this.env.apiUrl + `/api/CookieTest/variable/`, body, { headers })
            .pipe(map(test => {
                return true;
            }));
    }

    DeleteVariable(body) {
        console.log("Body " + body);
        let options = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
            }),
            body: body,
        };
        return this.http.delete(this.env.apiUrl + `/api/CookieTest/variable/`, options)
            .pipe(map(area => {
                return true;
            }));
    }
}


