import { Component, ViewChild } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, FormArray, Validators } from '@angular/forms';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, first } from 'rxjs/operators';
import { EnvService } from '../../_services/env.service';
import { UserService, MoldService } from '@/_services';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { AlertService } from '@/shared/alerts';
import { User, coatingprocess } from '../../_models/index';
import { forEach } from '@angular/router/src/utils/collection';
import { Utils } from '../../shared/utils/utils'
import { utils } from 'protractor';
import { ActivatedRoute, Router} from "@angular/router";

@Component({ templateUrl: 'coatingProcess.component.html' })

export class CoatingProcess {
  formNameAddUpdate: string = "New Coating Process";
  idCoatingProcess: number = 0;
  description: string = "";
  reference: string = "";
  errorInfo = '';
  error2 = '';
  coatingResultado: coatingprocess;
  currentUser: User;

  coatingProcessForm = this.fb.group({
    reference: ['', Validators.required],
    description: ['']
  });


  constructor(private fb: FormBuilder, private http: HttpClient, private env: EnvService,
    private moldService: MoldService, private alertService: AlertService, private user: UserService,
    private router: Router, private route: ActivatedRoute) {
    this.coatingResultado = new coatingprocess();
    this.currentUser = this.user.currentUserInfoValue;
  }

  @ViewChild('warningModal', { read: false }) public warningModal: ModalDirective;

  iniciarControles() {
      this.coatingProcessForm.patchValue({
          reference: this.reference,
          description: this.description
    });
  }


  ngOnInit() {
    this.getParameter();
    this.iniciarControles();
    
    }

    getParameter() {

        if (this.route.snapshot.paramMap.get('idCoatingProcess') != null) {
            this.idCoatingProcess = (parseInt)(this.route.snapshot.paramMap.get('idCoatingProcess'));
            this.reference = (this.route.snapshot.paramMap.get('reference'));
            this.description = (this.route.snapshot.paramMap.get('description'));
            console.log("ID Site " + this.idCoatingProcess /*+ "  " + this.router.snapshot.params.idTemplateLabTest*/);
            this.formNameAddUpdate = "Update Coating Process";
            /*  this.getPieceHeatTreatmentById();
            this.myBtn = true;
            this.myTab = true;
            //this.state = "disabled";
            this.pieceHeatTreatmentForm.get('areas').disable();
            this.pieceHeatTreatmentForm.get('sites').disable();
            this.pieceHeatTreatmentForm.get('pieces').disable();*/
        }
    }

  onSubmit() {
    //Se limpia el mensaje de enviado correctamente si se estaba mostrando
    this.clear('SaveCorrect');
    //Si el formulario es válido se envía la información
    if (this.coatingProcessForm.valid) {

      this.coatingResultado.idCoatingProcess = this.idCoatingProcess;
      this.coatingResultado.reference = this.coatingProcessForm.value.reference;
      this.coatingResultado.description = this.coatingProcessForm.value.description;

      Utils.remove_empty(this.coatingResultado);

      var body = JSON.stringify(this.coatingResultado);


      this.moldService.saveCoating(body)
        .pipe(first())
        .subscribe(
          result => {
            if (result == true) {
              this.cambiosGuardadosCorrectamente();
            }
          },
          error => {
            this.errorInfo = error;
            this.error(error, 'SaveCorrect');
          });

    }


  }

  
  //Limpia todos los campos del formulario y las variables que se muestran en las tablas
  resetFormulario() {
    this.coatingProcessForm.reset();
    this.formNameAddUpdate = "New Coating Process";
  }

  //Cuando se envía correctamente la información, se muestra el mensaje y se resetea el formulario
    cambiosGuardadosCorrectamente() {
    this.success('data saved correctly', 'SaveCorrect');
    //Se resetean todas la variables por si se desean insertar más datos
      if (this.idCoatingProcess == 0) this.resetFormulario();
  }

  success(message: string, id: string) {
    this.alertService.success(message, id);
  }

  error(message: string, id: string) {
    this.alertService.error(message, id);
  }

  info(message: string) {
    this.alertService.info(message);
  }

  warn(message: string) {
    this.alertService.warn(message);
  }

  clear(id: string) {
    this.alertService.clear(id);
  }

}
