import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { EnvService } from './env.service';

import { Message } from '@angular/compiler/src/i18n/i18n_ast';
import { piece } from '../_models/piece';
import { forEach } from '@angular/router/src/utils/collection';

@Injectable({ providedIn: 'root' })

export class PieceService {
    private currentPieceSubject: BehaviorSubject<piece[]>;
    public currentPiece: Observable<piece[]>;

  constructor(private http: HttpClient, private env: EnvService) {
    this.currentPieceSubject = new BehaviorSubject<piece[]>(JSON.parse(localStorage.getItem('piece')));
    this.currentPiece = this.currentPieceSubject.asObservable();
  }

 getPiece(idArea: Number) {
   return this.http.get<any>(this.env.apiUrl + `/api/Piece/area/` + idArea.toString())
     .pipe(map(piece => {
        // store user details and jwt token in local storage to keep user logged in between page refreshes      

       this.currentPieceSubject.next(piece);
         return piece;
      }));
  }

  getPieceBySite(idSite: Number) {
    return this.http.get<any>(this.env.apiUrl + `/api/Piece/site/` + idSite.toString())
      .pipe(map(piece => {
        // store user details and jwt token in local storage to keep user logged in between page refreshes      

        this.currentPieceSubject.next(piece);
        return piece;
      }));
  }

  

  getPiecePart(idPiece: Number) {
    return this.http.get<any>(this.env.apiUrl + `/api/Piece/piecepart/` + idPiece.toString())
      .pipe(map(piece => {
        // store user details and jwt token in local storage to keep user logged in between page refreshes      

        this.currentPieceSubject.next(piece);
        return piece;
      }));
    }
    
  getDefectType() {
      return this.http.get<any>(this.env.apiUrl + `/api/Piece/defecttype/`)
          .pipe(map(defecttype => {
              // store user details and jwt token in local storage to keep user logged in between page refreshes      

              //this.currentDefectTypeSubject.next(defecttype);
              return defecttype;
          }));
    }

    getDefectTypeById(idDefectType: number) {
        return this.http.get<any>(this.env.apiUrl + `/api/Piece/defecttype/` + idDefectType.toString())
            .pipe(map(defecttype => {
                // store user details and jwt token in local storage to keep user logged in between page refreshes      

                //this.currentDefectTypeSubject.next(defecttype);
                return defecttype;
            }));
    }


  getHeatTreatment() {
      return this.http.get<any>(this.env.apiUrl + `/api/Piece/heattreatment/`)
          .pipe(map(heattreatment => {
              // store user details and jwt token in local storage to keep user logged in between page refreshes      

              return heattreatment;
          }));
    }

    getHeatTreatmentById(idHeatTreatment: number) {
        return this.http.get<any>(this.env.apiUrl + `/api/Piece/heattreatment/` + idHeatTreatment.toString())
            .pipe(map(heattreatment => {
                // store user details and jwt token in local storage to keep user logged in between page refreshes      

                return heattreatment;
            }));
    }

  getPieceDefect(idpiece: Number) {
      return this.http.get<any>(this.env.apiUrl + `/api/Piece/piecedefect/` + idpiece.toString())
          .pipe(map(piecedefect => {
              // store user details and jwt token in local storage to keep user logged in between page refreshes      

              //this.currentTemplateSubject.next(testtype);
              return piecedefect;
          }));
    }

    getPieceDefectById(idpieceDefect: Number) {
        console.log("Id Pice Defect " + idpieceDefect);
        return this.http.get<any>(this.env.apiUrl + `/api/Piece/piecedefect/piecedefect/` + idpieceDefect.toString())
            .pipe(map(piecedefect => {
                // store user details and jwt token in local storage to keep user logged in between page refreshes      

                //this.currentTemplateSubject.next(testtype);
                return piecedefect;
            }));
    }

    getPieceHeatTreatmentById(idHeatTreatment: Number) {
        console.log("Id Pice Defect " + idHeatTreatment);
        return this.http.get<any>(this.env.apiUrl + `/api/Piece/pieceHeatTreatment/pieceHeatTreatment/` + idHeatTreatment.toString())
            .pipe(map(piecedefect => {
                // store user details and jwt token in local storage to keep user logged in between page refreshes      

                //this.currentTemplateSubject.next(testtype);
                return piecedefect;
            }));
    }

  getPieceHeatTreatment(idpiece: Number) {
      return this.http.get<any>(this.env.apiUrl + `/api/Piece/pieceHeatTreatment/` + idpiece.toString())
          .pipe(map(pieceheattreatment => {
              // store user details and jwt token in local storage to keep user logged in between page refreshes      

              //this.currentTemplateSubject.next(testtype);
              return pieceheattreatment;
          }));
  }

  SavePieceDefect(body) {
      let headers = new HttpHeaders({
          'Content-Type': 'application/json'
      });
      return this.http.post(this.env.apiUrl + `/api/Piece/pieceDefect`, body, { headers })
          .pipe(map(piecedefect => {
              return true;
          }));
    }
    

  SavePieceHeatTreatment(body) {
      let headers = new HttpHeaders({
          'Content-Type': 'application/json'
      });
      return this.http.post(this.env.apiUrl + `/api/Piece/pieceHeatTreatment`, body, { headers })
          .pipe(map(pieceheattreatment => {
              return true;
          }));
  } 

  SaveDefect(body) {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    return this.http.post(this.env.apiUrl + `/api/Piece/defecttype`, body, { headers })
      .pipe(map(defect => {
        return true;
      }));
  }

  SaveHeatTreatment(body) {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    return this.http.post(this.env.apiUrl + `/api/Piece/heattreatment`, body, { headers })
      .pipe(map(heattreatment => {
        return true;
      }));
  }

  DeletePieceDefect(body) {
      console.log("Body " + body);
      let options = {
          headers: new HttpHeaders({
              'Content-Type': 'application/json',
          }),
          body: body,
      };
      return this.http.delete(this.env.apiUrl + `/api/Piece/piecedefect`, options)
          .pipe(map(area => {
              return true;
          }));
    }

    DeletePieceHeatTreatment(body) {
        console.log("Body " + body);
        let options = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
            }),
            body: body,
        };
        return this.http.delete(this.env.apiUrl + `/api/Piece/pieceheattreatment`, options)
            .pipe(map(area => {
                return true;
            }));
    }

    DeleteDefectType(body) {
        console.log("Body " + body);
        let options = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
            }),
            body: body,
        };
        return this.http.delete(this.env.apiUrl + `/api/Piece/defecttype`, options)
            .pipe(map(area => {
                return true;
            }));
    } 

    DeleteHeatTreatmentType(body) {
        console.log("Body " + body);
        let options = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
            }),
            body: body,
        };
        return this.http.delete(this.env.apiUrl + `/api/Piece/heattreatmenttype`, options)
            .pipe(map(area => {
                return true;
            }));
    } 
}


