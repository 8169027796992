import { Component, ViewChild } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, FormArray, Validators } from '@angular/forms';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, first } from 'rxjs/operators';
import { EnvService } from '../../_services/env.service';
import { UserService, SiteService, PieceService } from '@/_services';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { AlertService } from '@/shared/alerts';
import { site, area, piece, heattreatment, pieceheattreatment, User } from '../../_models/index';
import { forEach } from '@angular/router/src/utils/collection';
import { Utils } from '../../shared/utils/utils'
import { utils } from 'protractor';
import { ActivatedRoute, Router} from "@angular/router";
import { DatePipe } from '@angular/common';

@Component({ templateUrl: 'pieceHeatTreatment.component.html' })

export class PieceHeatTreatment {
  formNameAddUpdate: string = "New Piece Heat Treatment";
  idPieceHeatTreatment: number = 0;
  idSite: number = 0;
  idArea: number = 0;
  myTab: boolean = false;
  myBtn: boolean = false;
  creationDate: string = "";
  errorInfo = '';
  error2 = '';
  sites: any[] = [];
  areas: any[] = [];
  pieces: any[] = [];
  heattreatments: any[] = [];
  infoTable: pieceheattreatment[] = [];
  selectedSite: site;
  selectedArea: area;
  selectedPiece: piece;
  selectedHeatTreatment: heattreatment;
  selectedRow: number;
  selectedRecord: any;
  pieceheattreatmentResultado: pieceheattreatment;
  currentUser: User;

  pieceHeatTreatmentForm = this.fb.group({
    areas: [null],
    sites: [null],
    pieces: [null],
    heattreatments: [null],
    temperature: [''],
    humidity: [''],
    numcycle: [''],
    dtHeatTreatment: [''],
    description: ['']
  });


  constructor(private fb: FormBuilder, private http: HttpClient, private env: EnvService,
    private siteService: SiteService, private pieceService: PieceService, 
    private alertService: AlertService, private user: UserService, private router: Router, private route: ActivatedRoute) {        
      this.pieceheattreatmentResultado = new pieceheattreatment();
    this.currentUser = this.user.currentUserInfoValue;
  }

  @ViewChild('warningModal', { read: false }) public warningModal: ModalDirective;

  addRecord() {
    this.clear('alertPieceHeatTreatment');

    if (this.selectedPiece != null
      && this.selectedHeatTreatment != null
      && this.pieceHeatTreatmentForm.value.dtHeatTreatment != null
      && this.pieceHeatTreatmentForm.value.numcycle != null) {

      var aux = new pieceheattreatment();
      aux.idPieceHeatTreatment = this.idPieceHeatTreatment;
      aux.idPiece = this.selectedPiece.idPiece;
      aux.piece = this.selectedPiece.reference;
      aux.idHeatTreatment = this.pieceHeatTreatmentForm.value.heattreatments.idHeatTreatment;
      aux.heatTreatment = this.pieceHeatTreatmentForm.value.heattreatments.reference;
      aux.numHeatCycle = this.pieceHeatTreatmentForm.value.numcycle;
      aux.dtHeatTreatment = this.pieceHeatTreatmentForm.value.dtHeatTreatment;
      aux.description = this.pieceHeatTreatmentForm.value.description;

      console.log(this.pieceHeatTreatmentForm.value.sites);
      console.log(this.pieceHeatTreatmentForm.value.areas);

      this.infoTable.push(aux);

      this.iniciarControles();
    }
    else {
      this.error('Any of the values entered is not correct', 'alertPieceHeatTreatment');
    }

  }

  iniciarControles() {
    this.pieceHeatTreatmentForm.patchValue({
      areas: [null],
      sites: [null],
      pieces: [null],
      heattreatments: [null],
      numcycle: [''],
      dtHeatTreatment: [''],
      description: ['']
    });

    this.getSite();
    this.getHeatTreatment();

    this.areas = null;
    this.pieces = null;

    this.selectedSite = null;
    this.selectedArea = null;
    this.selectedPiece = null;
    this.selectedHeatTreatment = null;
  }



  getSite() {
    this.siteService.site(this.currentUser.idUserApplication)
      .pipe(first())
      .subscribe(
        data => {
          if (data == false) {
            this.error2 = "Consulta errónea.";
          }
          this.sites = data;
              
          if (this.idSite > 0) {
            this.selectedSite = this.sites.find(s => s.idSite == this.idSite);
            this.getArea();
          }
          else {
            if (this.currentUser.idSiteDefault > 0) {
              this.selectedSite = this.sites.find(x => x.idSite == this.currentUser.idSiteDefault);
              this.getArea();
            }   
          }
        },
        error => {
          this.error = error;
          this.error(error, 'Error');

        });
  }

  getHeatTreatment() {
      this.pieceService.getHeatTreatment()
      .pipe(first())
      .subscribe(
        data => {
          if (data == false) {
            this.error2 = "Consulta errónea.";
          }
              this.heattreatments = data;
   
        },
        error => {
          this.error = error;
          this.error(error, 'Error');

        });
  }

  RowSelectedSite() {
    console.log(this.selectedSite);
    if (this.selectedSite != null)
      this.getArea();
    else
    {
      this.areas = null;
      this.pieces = null;
      this.selectedArea = null;
      this.selectedPiece = null;
    }
  }

  getArea() {
    console.log(this.selectedSite);
    console.log(this.currentUser.idUserApplication);
    console.log(this.currentUser);
    this.siteService.getArea(this.selectedSite.idSite, this.currentUser.idUserApplication)
      .pipe(first())
      .subscribe(
        data => {
          if (data == false) {
            this.error2 = "Consulta errónea.";
          }
          this.areas = data;
              if (this.idArea > 0) {
                  this.selectedArea = this.areas.find(s => s.idArea == this.idArea);
                  this.getPiece();
              }
          console.log(data);
        },
        error => {
          this.error = error;
        });
  }

  RowSelectedArea() {
    console.log(this.selectedArea);
    if (this.selectedArea != null)
      this.getPiece();
    else
    {
      this.pieces = null;
      this.selectedPiece = null;
    }
  }


  getPiece() {
    console.log(this.selectedArea.idArea);
    console.log(this.selectedArea);
    this.pieceService.getPiece(this.selectedArea.idArea)
      .pipe(first())
      .subscribe(
        data => {
          if (data == false) {
            this.error2 = "Consulta errónea.";
          }
              this.pieces = data;
              if (this.idPieceHeatTreatment > 0) {
                  this.selectedPiece = this.pieces.find(s => s.idPiece == this.pieceheattreatmentResultado.idPiece);
              }
          console.log(data);
        },
        error => {
          this.error = error;
        });
  }

  ngOnInit() {
    
    this.getSite();
    this.getHeatTreatment();
    this.getParameter();
    

    this.selectedSite = null;
    this.selectedArea = null;
    this.selectedPiece = null;
    this.selectedHeatTreatment = null;
  }

  onSubmit() {
    //Se limpia el mensaje de enviado correctamente si se estaba mostrando
    this.clear('SaveCorrect');
    console.log(this.pieceHeatTreatmentForm);
       
    //Si el formulario es válido se envía la información
    if (this.pieceHeatTreatmentForm.valid) {

        if (this.idPieceHeatTreatment > 0)
            this.addRecord();

        console.log(this.infoTable);

        Utils.remove_empty(this.infoTable);           

        var body = JSON.stringify(this.infoTable);

      console.log(body);

        this.pieceService.SavePieceHeatTreatment(body)
        .pipe(first())
        .subscribe(
          result => {
            if (result == true) {
              this.cambiosGuardadosCorrectamente();
            }
          },
          error => {
            console.log(error);
            this.errorInfo = error;
            this.error(error, 'SaveCorrect');
          });
    }

    }

    getParameter() {

        if (this.route.snapshot.paramMap.get('idPieceHeatTreatment') != null) {
            this.idPieceHeatTreatment = (parseInt)(this.route.snapshot.paramMap.get('idPieceHeatTreatment'));
            this.idSite = (parseInt)(this.route.snapshot.paramMap.get('idSite'));
            this.idArea = (parseInt)(this.route.snapshot.paramMap.get('idArea'));
            console.log("ID Site " + this.idSite /*+ "  " + this.router.snapshot.params.idTemplateLabTest*/);
            this.formNameAddUpdate = "Update Piece Heat Treatment";
            this.getPieceHeatTreatmentById();
            this.myBtn = true;
            this.myTab = true;
            //this.state = "disabled";
            this.pieceHeatTreatmentForm.get('areas').disable();
            this.pieceHeatTreatmentForm.get('sites').disable();
            this.pieceHeatTreatmentForm.get('pieces').disable();
        }
    }

    getPieceHeatTreatmentById() {
        console.log("Entra " + this.idPieceHeatTreatment);
        this.pieceService.getPieceHeatTreatmentById(this.idPieceHeatTreatment)
            .pipe(first())
            .subscribe(
                data => {
                    if (data == false) {
                        this.errorInfo = "Consulta errónea.";
                    }
                    this.pieceheattreatmentResultado = data;
                    console.log("Pice Defect Result " + this.pieceheattreatmentResultado);
                    if (this.pieceheattreatmentResultado != null) {
                        this.selectedHeatTreatment = this.heattreatments.find(s => s.idHeatTreatment == this.pieceheattreatmentResultado.idHeatTreatment);
                        let datePipe: DatePipe = new DatePipe("en-EN");
                        this.creationDate = datePipe.transform(this.pieceheattreatmentResultado.dtHeatTreatment, 'yyyy-MM-ddThh:mm');
                    }
                    console.log(data);
                    console.log(this.pieceheattreatmentResultado.idHeatTreatment);
                    this.iniciarControlesPieceHeatTreatment();
                },
                error => {
                    this.error = error;
                });

    }

    iniciarControlesPieceHeatTreatment() {
        this.pieceHeatTreatmentForm.patchValue({
            numcycle: this.pieceheattreatmentResultado.numHeatCycle,
            dtHeatTreatment: this.pieceheattreatmentResultado.dtHeatTreatment,
            description: this.pieceheattreatmentResultado.description
        });
    }


  RowSelected(u: any, i: number) {
    console.log(u);
    this.selectedRecord = u;   // declare variable in component.
    this.selectedRow = i;
  }

  RowDelete(u: any, i: number) {
    this.infoTable.splice(i, 1);
  }

  //Limpia todos los campos del formulario y las variables que se muestran en las tablas
  resetFormulario() {
    this.pieceHeatTreatmentForm.reset();
    this.infoTable = [];
    this.selectedRecord = null;
        
    this.getSite();
    this.getHeatTreatment();

    this.areas = null;
    this.pieces = null;
  }

  

  //Cuando se envía correctamente la información, se muestra el mensaje y se resetea el formulario
  cambiosGuardadosCorrectamente() {
      if (this.idPieceHeatTreatment > 0) {
          this.success('data updated correctly', 'SaveCorrect');
          this.formNameAddUpdate = "New Piece Heat Treatment";
          this.idPieceHeatTreatment = 0;
          this.idSite = 0;
          this.idArea = 0;
          this.myBtn = false;
          this.myTab = false;
          this.pieceHeatTreatmentForm.get('areas').enable();
          this.pieceHeatTreatmentForm.get('sites').enable();
          this.pieceHeatTreatmentForm.get('pieces').enable();
      }
      else {
          this.success('data saved correctly', 'SaveCorrect');
      }
      //Se resetean todas la variables por si se desean insertar más datos
      this.resetFormulario();
  }

  success(message: string, id: string) {
    this.alertService.success(message, id);
  }

  error(message: string, id: string) {
    this.alertService.error(message, id);
  }

  info(message: string) {
    this.alertService.info(message);
  }

  warn(message: string) {
    this.alertService.warn(message);
  }

  clear(id: string) {
    this.alertService.clear(id);
  }

}
