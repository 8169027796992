import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { EnvService } from './env.service';

import { Message } from '@angular/compiler/src/i18n/i18n_ast';
import { mold } from '../_models/mold';
import { forEach } from '@angular/router/src/utils/collection';

@Injectable({ providedIn: 'root' })

export class MoldService {
    private currentMoldSubject: BehaviorSubject<mold[]>;
    public currentMold: Observable<mold[]>;

      constructor(private http: HttpClient, private env: EnvService) {
          this.currentMoldSubject = new BehaviorSubject<mold[]>(JSON.parse(localStorage.getItem('mold')));
          this.currentMold = this.currentMoldSubject.asObservable();
      }

      getBySite(idSite: Number) {
          return this.http.get<any>(this.env.apiUrl + `/api/Mold/mold/` + idSite.toString())
              .pipe(map(comp => {
                  // store user details and jwt token in local storage to keep user logged in between page refreshes      

                  this.currentMoldSubject.next(comp);
                  return comp;
              }));
    }

    getById(id: Number) {
        return this.http.get<any>(this.env.apiUrl + `/api/Mold/` + id.toString())
            .pipe(map(comp => {
                // store user details and jwt token in local storage to keep user logged in between page refreshes      

                this.currentMoldSubject.next(comp);
                return comp;
            }));
    }

    getComponentByMold(idMold: Number) {
      return this.http.get<any>(this.env.apiUrl + `/api/Mold/component/` + idMold.toString())
        .pipe(map(comp => {
          // store user details and jwt token in local storage to keep user logged in between page refreshes      

          return comp;
        }));
    }

    getComponentByMoldPieceType(idMold: Number, idPieceType: Number) {
      return this.http.get<any>(this.env.apiUrl + `/api/Mold/component/moldpiecetype/` + idMold.toString() + `/` + idPieceType.toString())
        .pipe(map(comp => {
          // store user details and jwt token in local storage to keep user logged in between page refreshes      

          return comp;
        }));
    }


    getAllCoating() {
        return this.http.get<any>(this.env.apiUrl + `/api/Mold/coating/all/`)
            .pipe(map(comp => {
                // store user details and jwt token in local storage to keep user logged in between page refreshes      

                this.currentMoldSubject.next(comp);
                return comp;
            }));
    }

    getComponentBySite(idsite: Number) {
        console.log(idsite);
        return this.http.get<any>(this.env.apiUrl + `/api/Mold/componentbysite/` + idsite.toString())
                .pipe(map(comp => {
                    // store user details and jwt token in local storage to keep user logged in between page refreshes      

                    return comp;
                }));
    }

    getCompositionByCookie(idCookie: Number) {
         return this.http.get<any>(this.env.apiUrl + `/api/Mold/comp/` + idCookie.toString())
         .pipe(map(comp => {
            // store user details and jwt token in local storage to keep user logged in between page refreshes      

             //this.currentMoldSubject.next(comp);
             return comp;
          }));
    }

    getCompositionMoldComponent(idMoldComponent: Number) {
        return this.http.get<any>(this.env.apiUrl + `/api/Mold/composition/` + idMoldComponent.toString())
            .pipe(map(moldcomp => {
                // store user details and jwt token in local storage to keep user logged in between page refreshes      

                //this.currentMoldSubject.next(comp);
                return moldcomp;
            }));
  }

  getMaterialMixerMoldComponent(idMoldComponent: Number) {
    return this.http.get<any>(this.env.apiUrl + `/api/Mold/materialmixer/` + idMoldComponent.toString())
      .pipe(map(moldcomp => {
        // store user details and jwt token in local storage to keep user logged in between page refreshes      

        //this.currentMoldSubject.next(comp);
        return moldcomp;
      }));
  }


    getComponent(idMold: Number) {
        return this.http.get<any>(this.env.apiUrl + `/api/Mold/comp/` + idMold.toString())
            .pipe(map(moldComp => {
                // store user details and jwt token in local storage to keep user logged in between page refreshes      

                console.log(moldComp);
                return moldComp;
            }));
    }

    saveMold(body) {
        let headers = new HttpHeaders({
            'Content-Type': 'application/json'
        });
        console.log(body);
        console.log(headers);
        console.log(this.env.apiUrl + `/api/Mold`);
        return this.http.post(this.env.apiUrl + `/api/Mold/mold`, body, { headers })
            .pipe(map(mold => {
                return true;
            }));
    }

    saveCoating(body) {
      let headers = new HttpHeaders({
        'Content-Type': 'application/json'
      });
      console.log(body);
      console.log(headers);
      console.log(this.env.apiUrl + `/api/Mold`);
      return this.http.post(this.env.apiUrl + `/api/Mold/coating`, body, { headers })
        .pipe(map(mold => {
          return true;
        }));
    }

    DeleteMold(body) {
        console.log("Body " + body);
        let options = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
            }),
            body: body,
        };
        return this.http.delete(this.env.apiUrl + `/api/Mold/`, options)
            .pipe(map(area => {
                return true;
            }));
    }

    DeleteCoatingProcess(body) {
        console.log("Body " + body);
        let options = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
            }),
            body: body,
        };
        return this.http.delete(this.env.apiUrl + `/api/Mold/coatingprocess/`, options)
            .pipe(map(area => {
                return true;
            }));
    }
}


