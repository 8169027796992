import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { EnvService } from './env.service';

import { Message } from '@angular/compiler/src/i18n/i18n_ast';
import { cookie } from '../_models/cookie';
import { forEach } from '@angular/router/src/utils/collection';

@Injectable({ providedIn: 'root' })
export class CookieService {
  private currentCookieSubject: BehaviorSubject<cookie[]>;
  public currentCookie: Observable<cookie[]>;

  constructor(private http: HttpClient, private env: EnvService) {
    this.currentCookieSubject = new BehaviorSubject<cookie[]>(JSON.parse(localStorage.getItem('cookie')));
    this.currentCookie = this.currentCookieSubject.asObservable();
  }

 getCookieBySite(site: Number) {
    return this.http.get<any>(this.env.apiUrl + `/api/Cookie/site/` + site.toString())
      .pipe(map(cookie => {
        // store user details and jwt token in local storage to keep user logged in between page refreshes      

        this.currentCookieSubject.next(cookie);
        return cookie;
      }));
  }
  
  getCookieByLab(lab: Number) {
    return this.http.get<any>(this.env.apiUrl + `/api/Cookie/lab/` + lab.toString())
      .pipe(map(cookie => {
        // store user details and jwt token in local storage to keep user logged in between page refreshes      

        this.currentCookieSubject.next(cookie);
        return cookie;
      }));
    }

    getCookieById(id: Number) {
        return this.http.get<any>(this.env.apiUrl + `/api/Cookie/` + id.toString())
            .pipe(map(cookie => {
                // store user details and jwt token in local storage to keep user logged in between page refreshes      

                this.currentCookieSubject.next(cookie);
                return cookie;
            }));
    }
  
  getCookieByGroup(group: Number) {
    return this.http.get<any>(this.env.apiUrl + `/api/Cookie/group/` + group.toString())
      .pipe(map(cookie => {
        // store user details and jwt token in local storage to keep user logged in between page refreshes      

        this.currentCookieSubject.next(cookie);
        return cookie;
      }));
  }
  
  getCookieByPackage(pac: Number) {
    return this.http.get<any>(this.env.apiUrl + `/api/Cookie/package/` + pac.toString())
      .pipe(map(cookie => {
        // store user details and jwt token in local storage to keep user logged in between page refreshes      

        this.currentCookieSubject.next(cookie);
        return cookie;
      }));
    }

    saveCookie(body) {
        let headers = new HttpHeaders({
            'Content-Type': 'application/json'
        });
        console.log(body);
        console.log(headers);        
        return this.http.post(this.env.apiUrl + `/api/Cookie`, body, { headers })
            .pipe(map(cookie => {
                return true;
            }));
    }

   /* UploadFile(body: FormData) {
        console.log(body);
        return this.http.post(this.env.apiUrl + `/api/Cookie/UploadFiles`, body
        ).pipe(map(result => {
            return true
        }));
    }*/


    deleteCookie(body) {
        console.log("Body " + body);
        let options = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
            }),
            body: body,
        };
        return this.http.delete(this.env.apiUrl + `/api/Cookie/`, options)
            .pipe(map(cookie => {
                return true;
            }));
        } 
}


