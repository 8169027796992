import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { EnvService } from './env.service';

import { Message } from '@angular/compiler/src/i18n/i18n_ast';
import { testtype } from '../_models/testtype';
import { forEach } from '@angular/router/src/utils/collection';

@Injectable({ providedIn: 'root' })

export class TestTypeService {
    private currentTestTypeSubject: BehaviorSubject<testtype[]>;
    public currentTestType: Observable<testtype[]>;

  constructor(private http: HttpClient, private env: EnvService) {
      this.currentTestTypeSubject = new BehaviorSubject<testtype[]>(JSON.parse(localStorage.getItem('testtype')));
      this.currentTestType = this.currentTestTypeSubject.asObservable();
  }

  getTestType() {
        return this.http.get<any>(this.env.apiUrl + `/api/TestType/all/`)
            .pipe(map(temp => {
                // store user details and jwt token in local storage to keep user logged in between page refreshes      

                this.currentTestTypeSubject.next(temp);
                return temp;
            }));
  }

  getTestTypeVariable(test: Number) {
    return this.http.get<any>(this.env.apiUrl + `/api/TestType/testtypevariable/` + test.toString())
            .pipe(map(temp => {
                // store user details and jwt token in local storage to keep user logged in between page refreshes      

                //this.currentTemplateLabSubject.next(temp);
                return temp;
            }));
    }

  
  SaveTestType(body) {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    console.log(body);
    console.log(headers);
    console.log(this.env.apiUrl + `/api/TestType`);
    return this.http.post(this.env.apiUrl + `/api/TestType`, body, { headers })
      .pipe(map(group => {
        return true;
      }));
    }


    DeleteTestType(body) {
        console.log("Body " + body);
        let options = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
            }),
            body: body,
        };
        return this.http.delete(this.env.apiUrl + `/api/TestType`, options)
            .pipe(map(area => {
                return true;
            }));
    }
}



