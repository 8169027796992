import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { EnvService } from './env.service';

import { Message } from '@angular/compiler/src/i18n/i18n_ast';
import { site } from '../_models/site';
import { pieceType } from '../_models';

@Injectable({ providedIn: 'root' })
export class PieceTypeService {
    
  constructor(private http: HttpClient, private env: EnvService) {
    
  }

    getPieceType(idSite: Number) {    
      console.log(this.env.apiUrl + `/api/PieceType/` + idSite.toString());
      return this.http.get<any>(this.env.apiUrl + `/api/PieceType/` + idSite.toString())
        .pipe(map(pieceType => {             
            // store user details and jwt token in local storage to keep user logged in between page refreshes      
        
          console.log(pieceType);        
          return pieceType;
        }));          
    }

    getPieceTypeById(id: Number, idSite: Number) {
        console.log(this.env.apiUrl + `/api/PieceType/` + id.toString()+"/"+idSite.toString());
        return this.http.get<any>(this.env.apiUrl + `/api/PieceType/` + id.toString() + "/"+idSite.toString())
            .pipe(map(pieceType => {
                // store user details and jwt token in local storage to keep user logged in between page refreshes      

                console.log(pieceType);
                return pieceType;
            }));
    }

    getPieceTypeByComponent(idComponent: Number) {
        console.log(this.env.apiUrl + `/api/PieceType/` + idComponent.toString());
        return this.http.get<any>(this.env.apiUrl + `/api/PieceType/` + idComponent.toString())
            .pipe(map(pieceType => {
                // store user details and jwt token in local storage to keep user logged in between page refreshes      

                console.log(pieceType);
                return pieceType;
            }));
    }


    getPieceTypeByMold(idMold: Number, idSite: Number) {
      return this.http.get<any>(this.env.apiUrl + `/api/PieceType/mold/` + idMold.toString() + `/` + idSite.toString())
        .pipe(map(pieceType => {
          // store user details and jwt token in local storage to keep user logged in between page refreshes      

          console.log(pieceType);
          return pieceType;
        }));
    }


    getPiecePart(idPieceType: Number) {    
      console.log(this.env.apiUrl + `/api/PieceType/pieceType/` + idPieceType.toString());
      return this.http.get<any>(this.env.apiUrl + `/api/PieceType/pieceType/` + idPieceType.toString())
        .pipe(map(pieceType => {             
            // store user details and jwt token in local storage to keep user logged in between page refreshes      
        
          console.log(pieceType);        
          return pieceType;
        }));          
    }

    SavePieceType(body) {
      let headers = new HttpHeaders({
        'Content-Type': 'application/json'
      });
      return this.http.post(this.env.apiUrl + `/api/PieceType/piecetype`, body, { headers })
        .pipe(map(defect => {
          return true;
        }));
    }


    DeletePieceType(body) {
        console.log("Body " + body);
        let options = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
            }),
            body: body,
        };
        return this.http.delete(this.env.apiUrl + `/api/PieceType`, options)
            .pipe(map(area => {
                return true;
            }));
    } 

}
