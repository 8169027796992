import { Component, ViewChild, HostListener } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, FormArray, Validators } from '@angular/forms';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, first } from 'rxjs/operators';
import { EnvService } from '../../_services/env.service';
import { MoldService, UserService, SiteService, ImageGalleryService } from '@/_services';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { AlertService } from '@/shared/alerts';
import { mold, moldcomponent, User, site, imagegallery, coating } from '../../_models/index';
import { forEach } from '@angular/router/src/utils/collection';
import { Utils } from '../../shared/utils/utils'
import { utils } from 'protractor';
import { Router, ActivatedRoute } from '@angular/router';
//import { Container } from '@angular/compiler/src/i18n/i18n_ast';
import { DatePipe } from '@angular/common';

@Component({ templateUrl: 'moldList.component.html' })

export class MoldList {
    errorInfo = '';
    error2 = '';
    molds = [];
    components = [];
    composition = [];
    materialMixer = [];
    mixBegin: string;
    mixFinish: string;
    temperature : number;
    coating = coating;
    stockReference: string;
    coatingProcess: string;
    densityBA: number;
    densitySI: number;
    quantityArticleStock: number;
    thickness: number;
    sites = [];
    selectedRowMold: number;
    selectedRowComp: number;
    selectedRowSite: number;
    selectedMold: mold;
    selectedComp: moldcomponent;
    selectedSite: site;
    currentUser: User;
    idMold: number;
    idComp: number;
    idSite: number;

    cards: any[] = [];
    slides: any = [[]];
    @ViewChild('warningModal') warningModal: any;
    CAROUSEL_BREAKPOINT = 768;
    carouselDisplayMode = 'multiple';
    imageGallery: imagegallery;
    prog: boolean = false;
    progressVal: number;

    moldListForm = this.fb.group({
        sites: [null, Validators.required],
        molds: this.fb.group({
            IdMolds: [''],
            reference: [''],
            creationDate: [''],
            volume: [''],
            IdMoldType: [''],
            moldTypes: ['']
        }),
      components: this.fb.group({
        reference: [''],
        area: [''],
        vibrationTime: [''],
        articleReference: [''],
        coating: this.fb.group({
          stockReference: [''],
          coatingProcess: [''],
          densityBA: [''],
          densitySI: [''],
          quantityArticleStock: [''],
          thickness: ['']
        }),
        mixer: this.fb.group({
          mixBegin: [''],
          mixFinish: [''],
          temperature: ['']    
        }),
        composition: this.fb.group({
            articleStockReference: [''],
          sandRatio: [''],
            temperature: [''],
          quantity: [''],
          bos: [''],
          coatingProcess: ['']

        })
      })
    });

    constructor(private fb: FormBuilder, private http: HttpClient, private env: EnvService,
      private moldService: MoldService, private user: UserService, private siteService: SiteService, private alertService: AlertService, private router: Router, private activatedrouter: ActivatedRoute, private imageGalleryService: ImageGalleryService) {
        //this.cookieResultado = new cookie();
        this.currentUser = this.user.currentUserInfoValue;
    }

    iniciarControlesMolds() {
        this.moldListForm.patchValue({
            molds: {
                IdMolds: [''],
                reference: null,
                creationDate: null,
                volume: 0,
                moldTypes: null
            }
        });
    }

    iniciarControlesComponents() {
        this.moldListForm.patchValue({
            components: {
              reference: null,
              area: 0,
              vibrationTime: null,
              articleReference: null,
            }
        });
    }

    iniciarControlesComposition() {
        this.moldListForm.patchValue({
            composition: {
                articleStock: null,
                sandRatio: 0,
                temp: 0,
                quantity: 0,
                bos: 0,
                coatingProcess: null
            }
        });
  }

  iniciarControlesMixer() {
    this.moldListForm.patchValue({
      mixer: {
        mixBegin: null,
        mixFinish: null,
        temperature: 0
      }
    });


    this.mixBegin = null;
    this.mixFinish = null;
    this.temperature = 0;

  }

  iniciarControlesCoating() {
    this.moldListForm.patchValue({
      coating: {
        stockReference: null,
        coatingProcess: null,
        densityBA: 0,
        densitySI: 0,
        quantityArticleStock: 0,
        thickness: 0
      }
    });

    this.stockReference = null;
      this.coatingProcess = null;
      this.densityBA = 0;
      this.densitySI = 0;
      this.quantityArticleStock = 0;
      this.thickness = 0;

  }

    RowSelectedSite() {
      console.log(this.selectedSite);
      this.molds = null;
      this.components = null;
      this.composition = null;
      this.materialMixer = null;
      this.iniciarControlesCoating();
      this.iniciarControlesMixer();

      if (this.selectedSite != null)
        this.getMold();
      
    }

  RowSelectedMold(u: any, i: number) {
    console.log(u, i);
        this.selectedMold = u;   // declare variable in component.
        this.selectedRowMold = i;        
        this.components = null;
        this.composition = null;
        this.materialMixer = null;
      this.iniciarControlesCoating();
      this.iniciarControlesMixer();
        this.getComponent();
    }

    RowSelectedComp(u: any, i: number) {
        console.log(u);
        console.log(i);
        this.selectedComp = u;   // declare variable in component.
        this.selectedRowComp = i;
        this.composition = null;
        this.materialMixer = null;
      this.iniciarControlesCoating();
      this.iniciarControlesMixer();
        this.getComposition();
        this.getMaterialMixer();
    
        if (u != null && u.coating != null) {
          this.stockReference = u.coating.stockReference;
          this.coatingProcess = u.coating.coatingProcess;
          this.densityBA = u.coating.densityBA;
          this.densitySI = u.coating.densitySI;
          this.quantityArticleStock = u.coating.quantityArticleStock;
          this.thickness = u.coating.thickness;
        }
    }

  
    getMold() {      
        console.log(this.selectedSite);
      if (this.selectedSite.idSite > 0) {
        this.moldService.getBySite(this.selectedSite.idSite)
                .pipe(first())
                .subscribe(
                    data => {
                        if (data == false) {
                            this.error2 = "Consulta errónea.";
                        }
                        this.molds = data;

                    //Se el site fue informado
                    
                    if (this.idMold > 0) {
                      this.selectedMold = this.molds.find(x => x.idMold == this.idMold);
                      var i: number;
                      i = this.molds.findIndex(x => x.idMold == this.idMold);
                      console.log(i);
                      this.RowSelectedMold(this.selectedMold, i);
                    }

                    },
                    error => {
                        this.error = error;
                    });
        }
    }
   
    getComponent() {
      console.log(this.selectedRowComp);
      
      if (this.selectedMold.idMold > 0) {
        this.moldService.getComponentByMold(this.selectedMold.idMold)
          .pipe(first())
          .subscribe(
            data => {
              if (data == false) {
                this.error2 = "Consulta errónea.";
              }
              this.components = data;
              this.coating = data.coating;

              console.log(this.components);
              //Se el site fue informado
              if (this.idComp > 0) {
                this.selectedComp = this.components.find(x => x.idMoldComponent == this.idComp);
                var i: number;
                i = this.components.findIndex(x => x.idMoldComponent == this.idComp);
                this.RowSelectedComp(this.selectedComp, i);
              }


            },
            error => {
              this.error = error;
            });
      }
    }


  getMaterialMixer() {
    console.log(this.selectedComp);

    if (this.selectedComp != null && this.selectedComp.idMoldComponent > 0) {
      this.moldService.getMaterialMixerMoldComponent(this.selectedComp.idMoldComponent)
        .pipe(first())
        .subscribe(
          data => {
            if (data == false) {
              this.error2 = "Consulta errónea.";
            }
     
            let datePipe: DatePipe = new DatePipe("en-EN");

            if (data != null) {
              this.mixBegin = datePipe.transform(data.mixBegin, 'yyyy-MM-ddThh:mm');
              this.mixFinish = datePipe.transform(data.mixFinish, 'yyyy-MM-ddThh:mm');

              this.temperature = data.temperature;
              this.materialMixer = data.sandMixerMaterials;
              console.log(this.materialMixer);
              
            }            
          },
          error => {
            this.error = error;
          });
    }
  }


  getComposition() {

    console.log(this.selectedComp);

    if (this.selectedComp != null && this.selectedComp.idMoldComponent > 0) {
        this.moldService.getCompositionMoldComponent(this.selectedComp.idMoldComponent)
        .pipe(first())
        .subscribe(
            data => {
                if (data == false) {
                    this.error2 = "Consulta errónea.";
                }
                this.composition = data;
                console.log(data);
            },
            error => {
                this.error = error;
            });
      }
  }

  getSite() {
    this.siteService.site(this.currentUser.idUserApplication)
      .pipe(first())
      .subscribe(
        data => {
          if (data == false) {
            this.error2 = "Consulta errónea.";
          }
          this.sites = data;

            //Se el site fue informado
          if (this.idSite > 0) {
            this.selectedSite = this.sites.find(x => x.idSite == this.idSite);
            this.RowSelectedSite();
          }
          else {
            //Se selecciona por defecto el site del usuario
            if (this.currentUser.idSiteDefault > 0) {
              this.selectedSite = this.sites.find(x => x.idSite == this.currentUser.idSiteDefault);
              this.RowSelectedSite();
            }
          }
        },
        error => {
          this.error = error;

        });
  }

    ngOnInit() {
    
        //Inicializar valores controles del formulario    
        this.iniciarControlesMolds();
        this.iniciarControlesComponents();
        this.iniciarControlesComposition();
        this.iniciarControlesCoating();
        this.selectedSite = null;

      this.getParameter();

      

    }

  getParameter() {
       

    if (this.activatedrouter.snapshot.paramMap.get('idMold') != null) {

      this.idMold = (parseInt)(this.activatedrouter.snapshot.paramMap.get('idMold'));
      this.idComp = (parseInt)(this.activatedrouter.snapshot.paramMap.get('idMoldcomponent'));
      this.idSite = (parseInt)(this.activatedrouter.snapshot.paramMap.get('idSite'));

    }

    this.getSite();

  }



    redirection() {

        this.router.navigate(['/moldCreation']);

    }

    redirectionUpdate() {
        console.log(this.selectedMold.idMoldType);
        if (this.selectedRowMold != null) {
            this.router.navigate(['/moldCreation', {
                idMold: this.selectedMold.idMold, idSite: this.selectedSite.idSite
            }]);
        }
        else {
            this.error("Select valid mold", 'Update');
        }
    }

      redirectionUpdateLinkArea(info: any) {

        this.router.navigate(['/area', { idArea: info.idArea}]);

    }

    redirectionUpdateLinkArticle(info: any) {
        this.router.navigate(['/articleCreation', { idArticle: info.idArticle }]);
    }

    redirectionUpdateLinkArticleStock(info: any) {
        this.router.navigate(['/articleStockCreation', { idArticleStock: info.idArticleStock }]);
    }

    redirectionUpdateLinkMoldType(info: any) {

        this.router.navigate(['/moldComponent', { idMoldType: info.idMoldType }]);

    }

    
    onDelete() {
        this.clear('Delete');

        if (this.selectedMold != null) {
            console.log("Entra eliminar");
            var body = JSON.stringify(this.selectedMold);
            this.moldService.DeleteMold(body)
                .pipe(first())
                .subscribe(
                    result => {
                        if (result == true) {
                            this.alertDetete('The mold has been deleted', 'Delete');
                        }
                    },
                    error => {
                        console.log(error);
                        this.errorInfo = error;
                        this.alertDetete(error, 'Delete');
                    });
        }
        else
            this.alertDetete('An error has occurred, select valid Mold', 'Delete');
    }

    alertDetete(message: string, id: string) {

        if (this.errorInfo == '' && this.selectedRowMold > 0) {
            this.success(message, id);
            this.molds.splice(this.selectedRowMold, 1);
            this.selectedRowMold = 0;
            this.selectedMold = null;
        }
        else {
            this.error(message, id);
        }
    }


    sortTableNum(n: number, tableName: string) {
        var table, rows, switching, i, x, y, shouldSwitch;
        table = document.getElementById(tableName);
        switching = true;
        /*Make a loop that will continue until
        no switching has been done:*/
        while (switching) {
            //start by saying: no switching is done:
            switching = false;
            rows = table.rows;
            /*Loop through all table rows (except the
            first, which contains table headers):*/
            for (i = 1; i < (rows.length - 1); i++) {
                //start by saying there should be no switching:
                shouldSwitch = false;
                /*Get the two elements you want to compare,
                one from current row and one from the next:*/
                x = (rows[i].getElementsByTagName("TD")[n]);
                y = (rows[i + 1].getElementsByTagName("TD")[n]);
                //check if the two rows should switch place:
                if (Number(x.innerHTML) > Number(y.innerHTML)) {
                    //if so, mark as a switch and break the loop:
                    shouldSwitch = true;
                    break;
                }
            }
            if (shouldSwitch) {
                /*If a switch has been marked, make the switch
                and mark that a switch has been done:*/
                rows[i].parentNode.insertBefore(rows[i + 1], rows[i]);
                switching = true;
            }
        }
    }

    
    myFunction(tableName: string) {
        var inputId: string = 'myInput';
        if (tableName == "TableDragCore")
            inputId = "myInputDragCore";
        else if (tableName == "TableDragCoreComposition")
            inputId = "myInputComposition";

        console.log("table " + tableName);
        var input, filter, table, tr, td, i, txtValue;
        console.log(document.getElementById(inputId));
        console.log(document.getElementById(tableName));
        input = document.getElementById(inputId);
        filter = input.value.toUpperCase();
        table = document.getElementById(tableName);
        tr = table.getElementsByTagName("tr");
        for (i = 0; i < tr.length; i++) {
            td = tr[i].getElementsByTagName("td")[0];
            if (td) {
                txtValue = td.textContent || td.innerText;
                if (txtValue.toUpperCase().indexOf(filter) > -1) {
                    tr[i].style.display = "";
                } else {
                    tr[i].style.display = "none";
                }
            }
        }
    }

    sortTable(n: number, tableName: string) {
        console.log("Order table " + tableName);
        var table, rows, switching, i, x, y, shouldSwitch, dir, switchcount = 0;
        table = document.getElementById(tableName);
        switching = true;
        // Set the sorting direction to ascending:
        dir = "asc";
        /* Make a loop that will continue until
        no switching has been done: */
        while (switching) {
            // Start by saying: no switching is done:
            switching = false;
            rows = table.rows;
            /* Loop through all table rows (except the
            first, which contains table headers): */
            for (i = 1; i < (rows.length - 1); i++) {
                // Start by saying there should be no switching:
                shouldSwitch = false;
                /* Get the two elements you want to compare,
                one from current row and one from the next: */
                x = rows[i].getElementsByTagName("TD")[n];
                y = rows[i + 1].getElementsByTagName("TD")[n];
                /* Check if the two rows should switch place,
                based on the direction, asc or desc: */
                if (dir == "asc") {
                    if (x.innerHTML.toLowerCase() > y.innerHTML.toLowerCase()) {
                        // If so, mark as a switch and break the loop:
                        shouldSwitch = true;
                        break;
                    }
                } else if (dir == "desc") {
                    if (x.innerHTML.toLowerCase() < y.innerHTML.toLowerCase()) {
                        // If so, mark as a switch and break the loop:
                        shouldSwitch = true;
                        break;
                    }
                }
            }
            if (shouldSwitch) {
                /* If a switch has been marked, make the switch
                and mark that a switch has been done: */
                rows[i].parentNode.insertBefore(rows[i + 1], rows[i]);
                switching = true;
                // Each time a switch is done, increase this count by 1:
                switchcount++;
            } else {
                /* If no switching has been done AND the direction is "asc",
                set the direction to "desc" and run the while loop again. */
                if (switchcount == 0 && dir == "asc") {
                    dir = "desc";
                    switching = true;
                }
            }
        }
    }

    getImageGalery(info: any) {

        if (info.imageGallery != null && info.imageGallery.idImageGallery > 0) {

            this.imageGalleryService.returnImageGalleryById(info.imageGallery.idImageGallery)
                .pipe(first())
                .subscribe(
                    data => {
                        if (data == false) {
                            this.error2 = "Consulta errónea.";
                        }
                        this.imageGallery = data;
                        this.cards.splice(0, this.cards.length);
                        //var i = 0;
                        this.imageGallery.images.forEach(item => {
                            var url = 'data:image/jpeg;base64,' + item.imageFile; // use this in <img src="..."> binding
                            this.cards.push({
                                title: item.reference,
                                description: item.description,
                                img: url
                            });

                            //i++;
                            //this.progressVal = (i / (this.imageGallery.images.length)) * 100;
                            //console.log(this.progressVal);
                            
                        });
                        this.slides = this.chunk(this.cards, 3);
                        if (window.innerWidth <= this.CAROUSEL_BREAKPOINT) {
                            this.carouselDisplayMode = 'single';
                        } else {
                            this.carouselDisplayMode = 'multiple';
                        }

                        this.warningModal.show();
                        
                    },
                    error => {
                        this.error = error;
                    });
        }
    }

    OpenModal(info: any, i: number) {
        this.prog = true;
        console.log(info);
        this.getImageGalery(info);
    }

    CloseModal() {
        this.prog = false;
        this.progressVal = 0;
        this.warningModal.hide();
    }

    @HostListener('window:resize')
    onWindowResize() {
        if (window.innerWidth <= this.CAROUSEL_BREAKPOINT) {
            this.carouselDisplayMode = 'single';
        } else {
            this.carouselDisplayMode = 'multiple';
        }
    }

    chunk(arr, chunkSize) {
        let R = [];
        for (let i = 0, len = arr.length; i < len; i += chunkSize) {
            R.push(arr.slice(i, i + chunkSize));
        }
        console.log(R)
        return R;
    }


    success(message: string, id: string) {
        this.alertService.success(message, id);
    }

    error(message: string, id: string) {
        this.alertService.error(message, id);
    }

    info(message: string) {
        this.alertService.info(message);
    }

    warn(message: string) {
        this.alertService.warn(message);
    }

    clear(id: string) {
        this.alertService.clear(id);
        this.errorInfo = '';
    }

}

