import { Component, ViewChild, HostListener } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, FormArray, Validators } from '@angular/forms';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, first } from 'rxjs/operators';
import { EnvService } from '../../_services/env.service';
import { SiteService, PieceService, UserService, ImageGalleryService } from '@/_services';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { AlertService } from '@/shared/alerts';
import { piece, User, site, area, piecedefect, pieceheattreatment, imagegallery } from '../../_models/index';
import { forEach } from '@angular/router/src/utils/collection';
import { Utils } from '../../shared/utils/utils'
import { utils } from 'protractor';
import { Router } from "@angular/router";


@Component({ templateUrl: 'pieceList.component.html' })

export class PieceList {
    error2 = '';
    errorInfo = '';
  sites = [];
  areas = [];
  pieces = [];
  defects = [];
  heattreatments = [];
  selectedRowPiece: number;
  selectedPiece: piece;
  selectedRowDefect: number;
  selectedDefect: piecedefect;
  selectedRowHeat: number;
  selectedHeat: pieceheattreatment;
  selectedSite: site;
  selectedArea: area;
  currentUser: User;

    cards: any[] = [];
    slides: any = [[]];
    @ViewChild('warningModal') warningModal: any;
    CAROUSEL_BREAKPOINT = 768;
    carouselDisplayMode = 'multiple';
    imageGallery: imagegallery;


  pieceListForm = this.fb.group({
    sites: [null],
    areas: [null],
    pieces: this.fb.group({
      referencePiece: [''],
      referencePieceType: [''],
      timeUntilShakeout: [''],
      area: [''],
      created: [''],
      description: ['']
    }),
    defects: this.fb.group({
      defectType: [''],
      origin: [''],
      piecePart: [''],
      descriptionDefect: ['']
    }),
    heattreatments: this.fb.group({
      heatTreatment: [''],
      numberCycle: [''],
      descriptionHeatTreatment: ['']
    })
    
  });


  constructor(private fb: FormBuilder, private http: HttpClient, private env: EnvService,
    private siteService: SiteService, private pieceService: PieceService, 
      private user: UserService, private alertService: AlertService, private router: Router, private imageGalleryService: ImageGalleryService ) {
    this.currentUser = this.user.currentUserInfoValue;
  }

  iniciarControlesPiece() {
    this.pieceListForm.patchValue({
      pieces: {
        referencePiece: null,
        referencePieceType: null,
        timeUntilShakeout: 0,
        area: null,
        created: null,
        description: null
      }
    });
  }

  iniciarControlesDefect() {
    this.pieceListForm.patchValue({
      defects: {
        referenceDefect: null,
        defectType: null,
        origin: null,
        piecePart: null,
        descriptionDefect: null
      }
    });
  }

  iniciarControlesHeatTreatment() {
    this.pieceListForm.patchValue({
      heattreatments: {
        heatTreatment: null,
        numberCycle: 0,
        descriptionHeatTreatment: null
      }
    });
  }


  RowSelectedSite() {

    this.areas = null;
    this.pieces = null;
    this.defects = null;
    this.heattreatments = null;
    this.selectedArea = null;

    console.log(this.selectedSite);
    if (this.selectedSite != null) {
      this.getArea();
      this.getPiece();
    }   
    
  }

  getArea() {
    console.log(this.selectedSite);
    console.log(this.currentUser.idUserApplication);
    console.log(this.currentUser);

    this.siteService.getArea(this.selectedSite.idSite, this.currentUser.idUserApplication)
      .pipe(first())
      .subscribe(
        data => {
          if (data == false) {
            this.error2 = "Consulta errónea.";
          }
          this.areas = data;
          console.log(data);
        },
        error => {
          this.error = error;
        });
    
  }


  RowSelectedArea() {
    console.log(this.selectedArea);
    if (this.selectedArea != null)
      this.getPiece();
    else
    {
      this.pieces = null;
      this.defects = null;
      this.heattreatments = null;
    }
  }

  RowSelectedPiece(u: any, i: number) {
    console.log(u);
    console.log(i);
    this.selectedPiece = u;
    this.selectedRowPiece = i;
    this.getDefect();
    this.getHeatTreatment();
    }

    RowSelectedDefect(u: any, i: number) {
        console.log(u);
        console.log(i);
        this.selectedDefect = u;
        this.selectedRowDefect = i;
    }

    RowSelectedHeat(u: any, i: number) {
        console.log(u);
        console.log(i);
        this.selectedHeat = u;
        this.selectedRowHeat = i;
    }

  getPiece()
  {
    if (this.selectedArea != null) {
      this.pieceService.getPiece(this.selectedArea.idArea)
        .pipe(first())
        .subscribe(
          data => {
            if (data == false) {
              this.error2 = "Consulta errónea.";
            }
            this.pieces = data;
            console.log(data);
          },
          error => {
            this.error = error;
          });
    }
    else if (this.selectedSite != null) {
      this.pieceService.getPieceBySite(this.selectedSite.idSite)
        .pipe(first())
        .subscribe(
          data => {
            if (data == false) {
              this.error2 = "Consulta errónea.";
            }
            this.pieces = data;
            console.log(data);
          },
          error => {
            this.error = error;
          });
    }
   
  }

  getDefect() {
    console.log(this.selectedPiece.idPiece);
    if (this.selectedPiece.idPiece > 0) {
        this.pieceService.getPieceDefect(this.selectedPiece.idPiece)
        .pipe(first())
        .subscribe(
          data => {
            if (data == false) {
              this.error2 = "Consulta errónea.";
            }
            this.defects = data;
            console.log(data);
          },
          error => {
            this.error = error;
          });
    }
  }

  getHeatTreatment() {
    console.log(this.selectedPiece.idPiece);
    if (this.selectedPiece.idPiece > 0) {
        this.pieceService.getPieceHeatTreatment(this.selectedPiece.idPiece)
        .pipe(first())
        .subscribe(
          data => {
            if (data == false) {
              this.error2 = "Consulta errónea.";
            }
            this.heattreatments = data;
            console.log(data);
          },
          error => {
            this.error = error;
          });
    }
  }

  ngOnInit() {

    //Inicializar valores controles del formulario    
    this.iniciarControlesPiece();
    this.iniciarControlesDefect();
    this.iniciarControlesHeatTreatment();

    this.siteService.site(this.currentUser.idUserApplication)
      .pipe(first())
      .subscribe(
        data => {
          if (data == false) {
            this.error2 = "Consulta errónea.";
          }
          this.sites = data;
          //Se selecciona por defecto el site del usuario
          if (this.currentUser.idSiteDefault > 0) {
            this.selectedSite = this.sites.find(x => x.idSite == this.currentUser.idSiteDefault);
            this.RowSelectedSite();
          }
          else
            this.selectedSite = null;
        },
        error => {
          this.error = error;

        });


   
    this.selectedArea = null;
       
  }



  redirectionPart() {

    this.router.navigate(['/pieceCreation']);

  }

  redirectionDefect() {

    this.router.navigate(['/pieceDefectCreation']);

    }

    redirectionDefectUpdate() {
        if (this.selectedDefect != null) {
            console.log("Site ID " + this.selectedSite.idSite);
            this.router.navigate(['/pieceDefectCreation', {
                idPieceDefect: this.selectedDefect.idPieceDefect, idArea: this.selectedArea.idArea, idSite: this.selectedSite.idSite
            }]);
        }
        else {
            this.error("Select valid Piece Defect", 'UpdateDefect');
        }
    }

  redirectionHeat() {

    this.router.navigate(['/pieceHeatTreatmentCreation']);

    }

    redirectionUpdateLinkPieceType(info: any) {
        console.log(info);
        this.router.navigate(['/pieceTypeCreation', {
            idPieceType: info.idPieceType, idSite: this.selectedSite.idSite
        }]);

    }

    redirectionHeatUpdate() {
        if (this.selectedHeat != null) {
            console.log("Site ID " + this.selectedSite.idSite);
            this.router.navigate(['/pieceHeatTreatmentCreation', {
                idPieceHeatTreatment: this.selectedHeat.idPieceHeatTreatment, idArea: this.selectedArea.idArea, idSite: this.selectedSite.idSite
            }]);
        }
        else {
            this.error("Select valid Heat Treatment", 'UpdateHeat');
        }
    }


    onDefectDelete() {
        this.clear('DefectDelete');

        if (this.selectedDefect != null) {
            console.log("Entra eliminar");
            var body = JSON.stringify(this.selectedDefect);
            this.pieceService.DeletePieceDefect(body)
                .pipe(first())
                .subscribe(
                    result => {
                        if (result == true) {
                            this.alertDefectDetete('The piece defect has been deleted', 'DefectDelete');
                        }
                    },
                    error => {
                        console.log(error);
                        this.errorInfo = error;
                        this.alertDefectDetete(error, 'DefectDelete');
                    });
        }
        else
            this.alertDefectDetete('An error has occurred, select valid piece defect', 'DefectDelete');
    }

    alertDefectDetete(message: string, id: string) {
        console.log("entrei", message, id);
        if (this.errorInfo == '') {
            this.success(message, id);
            this.defects.splice(this.selectedRowDefect, 1);
            this.selectedRowDefect  = 0;
            this.selectedDefect = null;
        }
        else {
            this.error(message, id);
        }

    }

    onHeatDelete() {
        this.clear('HeatDelete');

        if (this.selectedHeat != null) {
            console.log("Entra eliminar");
            var body = JSON.stringify(this.selectedHeat);
            this.pieceService.DeletePieceHeatTreatment(body)
                .pipe(first())
                .subscribe(
                    result => {
                        if (result == true) {
                            this.alertHeatDetete('The piece heat has been deleted', 'HeatDelete');
                        }
                    },
                    error => {
                        console.log(error);
                        this.errorInfo = error;
                        this.alertHeatDetete(error, 'HeatDelete');
                    });
        }
        else
            this.alertHeatDetete('An error has occurred, select valid piece heat', 'HeatDelete');
    }

    alertHeatDetete(message: string, id: string) {
        console.log("entrei", message, id);
        if (this.errorInfo == '') {
            this.success(message, id);
            console.log("entrei2", message, id);
            this.heattreatments.splice(this.selectedRowHeat, 1);
            this.selectedRowHeat = 0;
            this.selectedHeat = null;
        }
        else {
            this.error(message, id);
        }

    }

    redirectionUpdateLinkDefectType(info: any) {

        this.router.navigate(['/defectTypeCreation', {
            idDefectType: info.idDefectType
        }]);

    }

    redirectionUpdateLinkHeatTreatment(info: any) {
        this.router.navigate(['/heatTreatmentCreation', {
            idHeatTreatment: info.idHeatTreatment
        }]);

    }

    sortTableNum(n: number, tableName: string) {
        var table, rows, switching, i, x, y, shouldSwitch;
        table = document.getElementById(tableName);
        switching = true;
        /*Make a loop that will continue until
        no switching has been done:*/
        while (switching) {
            //start by saying: no switching is done:
            switching = false;
            rows = table.rows;
            /*Loop through all table rows (except the
            first, which contains table headers):*/
            for (i = 1; i < (rows.length - 1); i++) {
                //start by saying there should be no switching:
                shouldSwitch = false;
                /*Get the two elements you want to compare,
                one from current row and one from the next:*/
                x = (rows[i].getElementsByTagName("TD")[n]);
                y = (rows[i + 1].getElementsByTagName("TD")[n]);
                //check if the two rows should switch place:
                if (Number(x.innerHTML) > Number(y.innerHTML)) {
                    //if so, mark as a switch and break the loop:
                    shouldSwitch = true;
                    break;
                }
            }
            if (shouldSwitch) {
                /*If a switch has been marked, make the switch
                and mark that a switch has been done:*/
                rows[i].parentNode.insertBefore(rows[i + 1], rows[i]);
                switching = true;
            }
        }
    }

    myFunction(tableName: string) {
        var inputId: string = 'myInputPart';
        if (tableName == "TableHeat")
            inputId = "myInputHeat";
        else if (tableName == "TableDefect")
            inputId = "myInputDefect";

        console.log("table " + tableName);
        var input, filter, table, tr, td, i, txtValue;
        console.log(document.getElementById(inputId));
        console.log(document.getElementById(tableName));
        input = document.getElementById(inputId);
        filter = input.value.toUpperCase();
        table = document.getElementById(tableName);
        tr = table.getElementsByTagName("tr");
        for (i = 0; i < tr.length; i++) {
            td = tr[i].getElementsByTagName("td")[0];
            if (td) {
                txtValue = td.textContent || td.innerText;
                if (txtValue.toUpperCase().indexOf(filter) > -1) {
                    tr[i].style.display = "";
                } else {
                    tr[i].style.display = "none";
                }
            }
        }
    }

    sortTable(n: number, tableName: string) {
        console.log("Order table " + tableName);
        var table, rows, switching, i, x, y, shouldSwitch, dir, switchcount = 0;
        table = document.getElementById(tableName);
        switching = true;
        // Set the sorting direction to ascending:
        dir = "asc";
        /* Make a loop that will continue until
        no switching has been done: */
        while (switching) {
            // Start by saying: no switching is done:
            switching = false;
            rows = table.rows;
            /* Loop through all table rows (except the
            first, which contains table headers): */
            for (i = 1; i < (rows.length - 1); i++) {
                // Start by saying there should be no switching:
                shouldSwitch = false;
                /* Get the two elements you want to compare,
                one from current row and one from the next: */
                x = rows[i].getElementsByTagName("TD")[n];
                y = rows[i + 1].getElementsByTagName("TD")[n];
                /* Check if the two rows should switch place,
                based on the direction, asc or desc: */
                if (dir == "asc") {
                    if (x.innerHTML.toLowerCase() > y.innerHTML.toLowerCase()) {
                        // If so, mark as a switch and break the loop:
                        shouldSwitch = true;
                        break;
                    }
                } else if (dir == "desc") {
                    if (x.innerHTML.toLowerCase() < y.innerHTML.toLowerCase()) {
                        // If so, mark as a switch and break the loop:
                        shouldSwitch = true;
                        break;
                    }
                }
            }
            if (shouldSwitch) {
                /* If a switch has been marked, make the switch
                and mark that a switch has been done: */
                rows[i].parentNode.insertBefore(rows[i + 1], rows[i]);
                switching = true;
                // Each time a switch is done, increase this count by 1:
                switchcount++;
            } else {
                /* If no switching has been done AND the direction is "asc",
                set the direction to "desc" and run the while loop again. */
                if (switchcount == 0 && dir == "asc") {
                    dir = "desc";
                    switching = true;
                }
            }
        }
    }

    getImageGalery(info: any) {

        if (info.imageGallery != null && info.imageGallery.idImageGallery > 0) {

            this.imageGalleryService.returnImageGalleryById(info.imageGallery.idImageGallery)
                .pipe(first())
                .subscribe(
                    data => {
                        if (data == false) {
                            this.error2 = "Consulta errónea.";
                        }
                        this.imageGallery = data;
                        this.cards.splice(0, this.cards.length);
                        this.imageGallery.images.forEach(item => {
                            var url = 'data:image/jpeg;base64,' + item.imageFile; // use this in <img src="..."> binding
                            this.cards.push({
                                title: item.reference,
                                description: item.description,
                                img: url
                            });
                        });
                        this.slides = this.chunk(this.cards, 3);
                        if (window.innerWidth <= this.CAROUSEL_BREAKPOINT) {
                            this.carouselDisplayMode = 'single';
                        } else {
                            this.carouselDisplayMode = 'multiple';
                        }

                        this.warningModal.show();

                    },
                    error => {
                        this.error = error;
                    });
        }
    }

    OpenModal(info: any, i: number) {
        console.log(info);
        this.getImageGalery(info);
    }

    @HostListener('window:resize')
    onWindowResize() {
        if (window.innerWidth <= this.CAROUSEL_BREAKPOINT) {
            this.carouselDisplayMode = 'single';
        } else {
            this.carouselDisplayMode = 'multiple';
        }
    }

    chunk(arr, chunkSize) {
        let R = [];
        for (let i = 0, len = arr.length; i < len; i += chunkSize) {
            R.push(arr.slice(i, i + chunkSize));
        }
        console.log(R)
        return R;
    }


  success(message: string, id: string) {
    this.alertService.success(message, id);
  }

  error(message: string, id: string) {
    this.alertService.error(message, id);
  }

  info(message: string) {
    this.alertService.info(message);
  }

  warn(message: string) {
    this.alertService.warn(message);
  }

  clear(id: string) {
    this.alertService.clear(id);
    this.errorInfo = '';
  }

}

