import { Component, ViewChild } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, FormArray, Validators } from '@angular/forms';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, first } from 'rxjs/operators';
import { EnvService } from '../../_services/env.service';
import { UserService, SiteService, ArticleService, SandService, ChemistryService } from '@/_services';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { AlertService } from '@/shared/alerts';
import { User, site, article, articleFamily, articleStock, sand } from '../../_models/index';
import { forEach } from '@angular/router/src/utils/collection';
import { Utils } from '../../shared/utils/utils'
import { utils } from 'protractor';
import { Router } from "@angular/router";
import { DatePipe } from '@angular/common';

@Component({ templateUrl: 'articleStockList.component.html' })

export class ArticleStockList { 
  error2 = '';
  errorInfo = '';
  sites = [];
  families = [];
  articles = [];
  stocks = [];
  selectedStock: articleStock;
  selectedRowStock: number;
  //sands = [];
  mostrar: boolean;
  afs: number;
  screen: number;
  surface: number;
  analisysDate: string;
  granulometries = [];
  chemistries = [];
  selectedSite: site;
  selectedFamily: articleFamily;
  selectedArticle: article;
  currentUser: User;
  
  articleStockListForm = this.fb.group({
    sites: [null],
    families: [null],
    articles: [null],
    stocks: this.fb.group({
      reference: [''],
      articleStockQuantity:[''],
      unitTypeReference: [''],
      dtArticleStock: [''],
      unitCost: [''],
      supplierName: [''],
      chemistryReference: [''],
      description: ['']
    }),
    sands: this.fb.group({      
      afs: [''],
      screen: [''],
      surface: [''],
      analisysDate: [''],
      granulometries: this.fb.group({
        meshSize: [''],
        pcRetained: ['']
      })
    }),
    chemistries: this.fb.group({
      element: [''],
      percentage: ['']
    })
  });


  constructor(private fb: FormBuilder, private http: HttpClient, private env: EnvService,
    private siteService: SiteService, private articleService: ArticleService, private sandService: SandService, private chemistryService: ChemistryService,
    private user: UserService, private alertService: AlertService, private router: Router) {
      this.currentUser = this.user.currentUserInfoValue;
  }

  iniciarControlesStock() {
    this.articleStockListForm.patchValue({
      articles: {
        reference: null,
        articleStockQuantity: null,
        unitTypeReference: null,
        dtArticleStock: null,
        unitCost: null,
        supplierName: null,
        chemistryReference: null,
        description: null
       }
    });
  }

  iniciarControlesSand() {
    this.afs = null;
    this.screen = null;
    this.surface = null;
    this.analisysDate = null;

    this.articleStockListForm.patchValue({
      sands: {
        afs: null,        
        screen: null,        
        surface: null,        
        analisysDate: null        
      }
    });
  }

  iniciarControlesGranulometry() {
    this.articleStockListForm.patchValue({
      granulometries: {
        meshSize: null,
        pcRetained: null
      }
    });
  }

  iniciarControlesChemistry() {
    this.articleStockListForm.patchValue({
      chemistries: {
        element: null,
        percentage: null
      }
    });
  }

  RowSelectedSite() {
    
    this.articles = null;
    this.selectedArticle = null;
    this.stocks = null;
    this.chemistries = null;
    this.granulometries = null;
    this.iniciarControlesSand();

    if (this.selectedSite == null) {
      this.selectedFamily = null;
      this.mostrar = false;
    }
    else {
      this.getArticleStock();
    }
  }


  RowSelectedFamily() {
    if (this.selectedSite != null && this.selectedFamily != null)
    {
      this.getArticle();

      if (this.selectedFamily.reference == "Sand") 
        this.mostrar = true;
      else
        this.mostrar = false;

      this.stocks = null;
      this.selectedArticle = null;
      this.afs = null;
      this.iniciarControlesSand();
      this.granulometries = null;
      this.chemistries = null;

      this.getArticleStock();
    }      
    else {
      this.stocks = null;
      this.articles = null;

      this.mostrar = false;

      this.selectedArticle = null;
      this.afs = null;
      this.iniciarControlesSand();
      this.granulometries = null;
      this.chemistries = null;
    }
  }


 
  getArticle() {
    this.articleService.getArticleBySiteFamily(this.selectedSite.idSite, this.selectedFamily.idArticleFamily)
      .pipe(first())
      .subscribe(
        data => {
          if (data == false) {
            this.error2 = "Consulta errónea.";
          }
          this.articles = data;
        },
        error => {
          this.error = error;
        });
  }


  RowSelectedArticle() {
    if (this.selectedArticle != null) {
      this.getArticleStock();

      this.chemistries = null;
      this.granulometries = null;
      this.afs = null;
      this.iniciarControlesSand();
    }
    else {
      this.stocks = null;
      this.afs = null;
      this.iniciarControlesSand();
      this.granulometries = null;
      this.chemistries = null;
    }
  }
  h

  getArticleStock() {

    if (this.selectedArticle == null) {
      if (this.selectedSite != null && this.selectedFamily != null) {
        this.articleService.getStockBySiteFamily(this.selectedSite.idSite, this.selectedFamily.idArticleFamily)
          .pipe(first())
          .subscribe(
            data => {
              if (data == false) {
                this.error2 = "Consulta errónea.";
              }
              this.stocks = data;
            },
            error => {
              this.error = error;
            });
      }
      else {
        this.stocks = null;
        this.afs = null;
        this.iniciarControlesSand();
        this.granulometries = null;
        this.chemistries = null;
      }
    }
    else {
      this.articleService.getStockByArticle(this.selectedArticle.idArticle)
        .pipe(first())
        .subscribe(
          data => {
            if (data == false) {
              this.error2 = "Consulta errónea.";
            }
            this.stocks = data;
          },
          error => {
            this.error = error;
          });
    }
   
  }

  RowSelectedStock(u: any, i: number) {    
    if (this.selectedFamily.reference == "Sand") {
      this.selectedStock = u;
      this.selectedRowStock = i;

      this.chemistries = null;
      this.granulometries = null;

      this.getSand();
      this.getGranulometry();
      this.getChemistry();
    }
    else {
      this.selectedStock = u; 
      this.selectedRowStock = i;

      this.chemistries = null;
      this.granulometries = null;

      this.getChemistry();
      this.afs = null;
      this.iniciarControlesSand();
      this.granulometries = null;
    }
  }

  getSand() {

    if (this.selectedStock.idArticleStock > 0) {
      this.sandService.getSand(this.selectedStock.idArticleStock)
        .pipe(first())
        .subscribe(
          data => {
            if (data == false) {
              this.error2 = "Consulta errónea.";
            }
            this.articleStockListForm.value.sands = data;
            this.iniSandVar();
          },
          error => {
            this.error = error;
            this.afs = null;
            this.iniciarControlesSand();
          });
    }

  }
  

  iniSandVar() {

    this.afs = this.articleStockListForm.value.sands.afs;
    this.screen = this.articleStockListForm.value.sands.screenDistributionNumber;
    this.surface = this.articleStockListForm.value.sands.surfaceArea;
    
    let datePipe: DatePipe = new DatePipe("en-EN");
    this.analisysDate = datePipe.transform(this.articleStockListForm.value.sands.sandAnalysis, 'yyyy-MM-ddThh:mm');

  }

  getGranulometry() {
    if (this.selectedStock.idArticleStock > 0) {
      this.sandService.getGranulometry(this.selectedStock.idArticleStock)
        .pipe(first())
        .subscribe(
          data => {
            if (data == false) {
              this.error2 = "Consulta errónea.";
            }
            this.granulometries = data;
          },
          error => {
            this.error = error;
          });
    }    
    
  }


  getChemistry() {
    if (this.selectedStock.idChemistry > 0) {
      this.chemistryService.getChemistryByStock(this.selectedStock.idArticleStock)
        .pipe(first())
        .subscribe(
          data => {
            if (data == false) {
              this.error2 = "Consulta errónea.";
            }
            this.chemistries = data;
          },
          error => {
            this.error = error;
          });
    }   
    
  }


  ngOnInit()
  {
    //Inicializar valores controles del formulario    
    this.iniciarControlesStock();
    this.iniciarControlesSand();
    this.iniciarControlesGranulometry();
    this.iniciarControlesChemistry();

    this.siteService.site(this.currentUser.idUserApplication)
      .pipe(first())
      .subscribe(
        data => {
          if (data == false) {
            this.error2 = "Consulta errónea.";
          }
          this.sites = data;

          //Se selecciona por defecto el site del usuario
          if (this.currentUser.idSiteDefault > 0)
            this.selectedSite = this.sites.find(x => x.idSite == this.currentUser.idSiteDefault);
          else
            this.selectedSite = null;
        },
        error => {
          this.error = error;
        });

    this.articleService.getFamily()
      .pipe(first())
      .subscribe(
        data => {
          if (data == false) {
            this.error2 = "Consulta errónea.";
          }
          this.families = data;
        },
        error => {
          this.error = error;
        });

    this.mostrar = false;

    this.selectedSite = null;
    this.selectedFamily = null;
    this.selectedArticle = null;
  }

  redirection() {

    this.router.navigate(['/articleStockCreation']);

    }

    redirectionUpdate() {
        if (this.selectedStock != null) {
            console.log("Site ID " + this.selectedStock.idArticle);
            this.router.navigate(['/articleStockCreation', {
                idArticleStock: this.selectedStock.idArticleStock/*, idArticleFamily: this.selectedFamily.idArticleFamily*/
            }]);
        }
        else {
            this.error("Select valid Article Stock", 'Update');
        }
    }

  redirectionUpdateLinkArticleStock(info: any) {
    this.router.navigate(['/articleStockCreation', { idArticleStock: info.idArticleStock }]);
  }

    redirectionUpdateLinkArticle(info: any) {
        this.router.navigate(['/articleCreation', { idArticle: info.idArticle }]);
    }

    redirectionUpdateLinkChemistry(info: any) {
        console.log(info);
        this.router.navigate(['/chemistryCreation', { idChemistry: info.idChemistry}]);
    }

    sortTableNum(n: number, tableName: string) {
        var table, rows, switching, i, x, y, shouldSwitch;
        table = document.getElementById(tableName);
        switching = true;
        /*Make a loop that will continue until
        no switching has been done:*/
        while (switching) {
            //start by saying: no switching is done:
            switching = false;
            rows = table.rows;
            /*Loop through all table rows (except the
            first, which contains table headers):*/
            for (i = 1; i < (rows.length - 1); i++) {
                //start by saying there should be no switching:
                shouldSwitch = false;
                /*Get the two elements you want to compare,
                one from current row and one from the next:*/
                x = (rows[i].getElementsByTagName("TD")[n]);
                y = (rows[i + 1].getElementsByTagName("TD")[n]);
                //check if the two rows should switch place:
                if (Number(x.innerHTML) > Number(y.innerHTML)) {
                    //if so, mark as a switch and break the loop:
                    shouldSwitch = true;
                    break;
                }
            }
            if (shouldSwitch) {
                /*If a switch has been marked, make the switch
                and mark that a switch has been done:*/
                rows[i].parentNode.insertBefore(rows[i + 1], rows[i]);
                switching = true;
            }
        }
    }

    myFunction(tableName: string) {
        var inputId: string = 'myInput';
        if (tableName == "TableComposition")
            inputId = "myInputComposition";
        /*else if (tableName == "TableDefect")
            inputId = "myInputDefect";*/

        console.log("table " + tableName);
        var input, filter, table, tr, td, i, txtValue;
        console.log(document.getElementById(inputId));
        console.log(document.getElementById(tableName));
        input = document.getElementById(inputId);
        filter = input.value.toUpperCase();
        table = document.getElementById(tableName);
        tr = table.getElementsByTagName("tr");
        for (i = 0; i < tr.length; i++) {
            td = tr[i].getElementsByTagName("td")[0];
            if (td) {
                txtValue = td.textContent || td.innerText;
                if (txtValue.toUpperCase().indexOf(filter) > -1) {
                    tr[i].style.display = "";
                } else {
                    tr[i].style.display = "none";
                }
            }
        }
    }

    sortTable(n: number, tableName: string) {
        console.log("Order table " + tableName);
        var table, rows, switching, i, x, y, shouldSwitch, dir, switchcount = 0;
        table = document.getElementById(tableName);
        switching = true;
        // Set the sorting direction to ascending:
        dir = "asc";
        /* Make a loop that will continue until
        no switching has been done: */
        while (switching) {
            // Start by saying: no switching is done:
            switching = false;
            rows = table.rows;
            /* Loop through all table rows (except the
            first, which contains table headers): */
            for (i = 1; i < (rows.length - 1); i++) {
                // Start by saying there should be no switching:
                shouldSwitch = false;
                /* Get the two elements you want to compare,
                one from current row and one from the next: */
                x = rows[i].getElementsByTagName("TD")[n];
                y = rows[i + 1].getElementsByTagName("TD")[n];
                /* Check if the two rows should switch place,
                based on the direction, asc or desc: */
                if (dir == "asc") {
                    if (x.innerHTML.toLowerCase() > y.innerHTML.toLowerCase()) {
                        // If so, mark as a switch and break the loop:
                        shouldSwitch = true;
                        break;
                    }
                } else if (dir == "desc") {
                    if (x.innerHTML.toLowerCase() < y.innerHTML.toLowerCase()) {
                        // If so, mark as a switch and break the loop:
                        shouldSwitch = true;
                        break;
                    }
                }
            }
            if (shouldSwitch) {
                /* If a switch has been marked, make the switch
                and mark that a switch has been done: */
                rows[i].parentNode.insertBefore(rows[i + 1], rows[i]);
                switching = true;
                // Each time a switch is done, increase this count by 1:
                switchcount++;
            } else {
                /* If no switching has been done AND the direction is "asc",
                set the direction to "desc" and run the while loop again. */
                if (switchcount == 0 && dir == "asc") {
                    dir = "desc";
                    switching = true;
                }
            }
        }
    }

    onDelete() {
        this.clear('Delete');

        if (this.selectedStock != null) {
            console.log("Entra eliminar");
            var body = JSON.stringify(this.selectedStock);
            this.articleService.deleteArticleStock(body)
                .pipe(first())
                .subscribe(
                    result => {
                        if (result == true) {
                            this.alertDetete('The stock has been deleted', 'Delete');
                        }
                    },
                    error => {
                        console.log(error);
                        this.errorInfo = error;
                        this.alertDetete(error, 'Delete');
                    });
        }
        else
            this.error('An error has occurred, select valid stock', 'Delete');
    }

    alertDetete(message: string, id: string) {
        if (this.errorInfo == '') {
            this.success(message, id);
            this.stocks.splice(this.selectedRowStock, 1);
            this.granulometries = [];
            this.chemistries = [];
          this.afs = 0;
          this.iniciarControlesSand();
            this.selectedRowStock = 0;
            this.selectedStock = null;
        }
        else {
            this.error(message, id);
        }

        //Se resetean todas la variables por si se desean insertar más datos
        console.log("Selected row " + this.selectedRowStock);

    }


  success(message: string, id:string) {
    this.alertService.success(message, id);
  }

  error(message: string, id: string) {
    this.alertService.error(message, id);
  }

  info(message: string) {
    this.alertService.info(message);
  }

  warn(message: string) {
    this.alertService.warn(message);
  }

  clear(id: string) {
    this.alertService.clear(id);
  }

}

