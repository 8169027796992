import { Component, ViewChild } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, FormArray, Validators } from '@angular/forms';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, first } from 'rxjs/operators';
import { EnvService } from '../../_services/env.service';
import { UserService, SiteService, CastingService } from '@/_services';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { AlertService } from '@/shared/alerts';
import { site, area, User, meltinggroup } from '../../_models/index';
import { forEach } from '@angular/router/src/utils/collection';
import { Utils } from '../../shared/utils/utils'
import { utils } from 'protractor';
import { ActivatedRoute, Router} from "@angular/router";

@Component({ templateUrl: 'meltingGroup.component.html' })

export class MeltingGroup {
    formNameAddUpdate: string = "New Melting Group";
    idMeltingGroup: number = 0;
    idArea: number = 0;
    idSite: number = 0;
    reference: string = "";
    description: string = "";  
  errorInfo = '';
  error2 = '';
  sites: any[] = [];
  areas: any[] = [];
  selectedSite: site;
  selectedArea: area;
  selectedRow: number;
  meltingGroupResultado: meltinggroup;
  currentUser: User;

  meltingGroupForm = this.fb.group({
    areas: [null, Validators.required],
    sites: [null, Validators.required],
    reference: ['', Validators.required],
    description: ['', Validators.required]
  });


  constructor(private fb: FormBuilder, private http: HttpClient, private env: EnvService,
    private siteService: SiteService, private alertService: AlertService, private user: UserService, private castingService: CastingService,
    private router: Router, private route: ActivatedRoute) {
    this.meltingGroupResultado = new meltinggroup();
    this.currentUser = this.user.currentUserInfoValue;
  }

  @ViewChild('warningModal', { read: false }) public warningModal: ModalDirective;
   

  iniciarControles() {
    this.meltingGroupForm.patchValue({
      sites: [null],
      areas: [null],
      reference: this.reference,
      description: this.description
    });
  }

  RowSelectedSite() {
    if (this.selectedSite != null)
      this.getArea();
    else
    {
      this.areas = null;
      this.selectedArea = null;
    }
  }

  getArea() {
    this.siteService.getArea(this.selectedSite.idSite, this.currentUser.idUserApplication)
      .pipe(first())
      .subscribe(
        data => {
          if (data == false) {
            this.error2 = "Consulta errónea.";
          }
              this.areas = data;
              if (this.idArea > 0)
                  this.selectedArea = this.areas.find(x => x.idArea == this.idArea);
        },
        error => {
          this.error = error;
        });
    
  }

  ngOnInit() {

    this.getParameter();
    this.iniciarControles();

    this.siteService.site(this.currentUser.idUserApplication)
      .pipe(first())
      .subscribe(
        data => {
          if (data == false) {
            this.error2 = "Consulta errónea.";
          }
              this.sites = data;
          if (this.idSite > 0) {
            this.selectedSite = this.sites.find(x => x.idSite == this.idSite);
            this.getArea();
          }
          else {
            //Se selecciona por defecto el site del usuario
            if (this.currentUser.idSiteDefault > 0) {
              this.selectedSite = this.sites.find(x => x.idSite == this.currentUser.idSiteDefault);
              this.getArea();
            }
          }
          },
        error => {
          this.error = error;
          this.error(error, 'Error');

        });

    
    this.selectedArea = null;
    }

    getParameter() {

        if (this.route.snapshot.paramMap.get('idMeltingGroup') != null) {
            this.idMeltingGroup = (parseInt)(this.route.snapshot.paramMap.get('idMeltingGroup'));
            this.idArea = (parseInt)(this.route.snapshot.paramMap.get('idArea'));
            this.idSite = (parseInt)(this.route.snapshot.paramMap.get('idSite'));
            this.reference = (this.route.snapshot.paramMap.get('reference'));
            this.description = (this.route.snapshot.paramMap.get('description'));
            console.log("ID Site " + this.idSite /*+ "  " + this.router.snapshot.params.idTemplateLabTest*/);
            this.formNameAddUpdate = "Update Melting Group"
        }
    }

  onSubmit() {
    //Se limpia el mensaje de enviado correctamente si se estaba mostrando
    this.clear('SaveCorrect');
       
    //Si el formulario es válido se envía la información
    if (this.meltingGroupForm.valid) {

      this.meltingGroupResultado.idMeltingGroup = this.idMeltingGroup;
      this.meltingGroupResultado.idArea = this.meltingGroupForm.value.areas.idArea;
      this.meltingGroupResultado.reference = this.meltingGroupForm.value.reference;
      this.meltingGroupResultado.description = this.meltingGroupForm.value.description;

      Utils.remove_empty(this.meltingGroupResultado);           

      var body = JSON.stringify(this.meltingGroupResultado);


      this.castingService.SaveMeltingGroup(body)
        .pipe(first())
        .subscribe(
          result => {
            if (result == true) {
              this.cambiosGuardadosCorrectamente();
            }
          },
          error => {
            this.errorInfo = error;
            this.error(error, 'SaveCorrect');
          });
    }

  }


  //Limpia todos los campos del formulario y las variables que se muestran en las tablas
  resetFormulario() {
    this.meltingGroupForm.reset();
    this.formNameAddUpdate = "New Melting Group";
    this.selectedSite = null;
    this.areas = null;
  }

  

  //Cuando se envía correctamente la información, se muestra el mensaje y se resetea el formulario
    cambiosGuardadosCorrectamente() {
        
    this.success('data saved correctly', 'SaveCorrect');
    //Se resetean todas la variables por si se desean insertar más datos
      if (this.idMeltingGroup == 0) this.resetFormulario();
  }

  success(message: string, id: string) {
    this.alertService.success(message, id);
  }

  error(message: string, id: string) {
    this.alertService.error(message, id);
  }

  info(message: string) {
    this.alertService.info(message);
  }

  warn(message: string) {
    this.alertService.warn(message);
  }

  clear(id: string) {
    this.alertService.clear(id);
  }

}
