import { Component, ViewChild } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, FormArray, Validators } from '@angular/forms';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, first } from 'rxjs/operators';
import { EnvService } from '../../_services/env.service';
import { UserService, SiteService, ChemistryService, TypeService, ArticleService } from '@/_services';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { AlertService } from '@/shared/alerts';
import { User, site, chemistry } from '../../_models/index';
import { forEach } from '@angular/router/src/utils/collection';
import { Utils } from '../../shared/utils/utils'
import { utils } from 'protractor';
import { Router } from "@angular/router";


@Component({ templateUrl: 'ElementList.component.html' })

export class ElementList { 
    error2 = '';
    errorInfo = '';
  sites = [];
  types = [];
  chemistries = [];
  compositions = [];
  elements = [];
  selectedSite: site;
  selectedType: any;
  selectedRowChemistry: number;
  selectedChemistry: chemistry;
  currentUser: User;

  chemistryListForm = this.fb.group({
    sites: [null],
    types: [null],
    chemistries: this.fb.group({
      reference: [''],
      chemistryTypeReference: [''],
      description: [''],
      compositions: this.fb.group({
        elementAbreviation: [''],
        elementPercentage: ['']
      })
    }),
    elements: this.fb.group({
      reference: [''],
      abreviation: [''],
      elementTypeReference: ['']
    })
  });


  constructor(private fb: FormBuilder, private http: HttpClient, private env: EnvService,
    private siteService: SiteService, private chemistryService: ChemistryService, private typeService: TypeService,
      private user:UserService, private alertService: AlertService, private articleService: ArticleService, private router: Router) {
      this.currentUser = this.user.currentUserInfoValue;
  }

  iniciarControlesChemistry() {
    this.chemistryListForm.patchValue({
      chemistries: {
        reference: null,
        chemistryTypeReference: null,
        description: null
       }
    });
  }

  iniciarControlesComposition() {
    this.chemistryListForm.patchValue({
      compositions: {
        elementAbreviation: null,
        elementPercentage: null
      }
    });
  }

  iniciarControlesElement() {
    this.chemistryListForm.patchValue({
      elements: {
        reference: null,
        abreviation: null,
        elementTypeReference: null
      }
    });
  }


  RowSelectedType() {

    this.chemistries = null;
    this.compositions = null;

    if (this.selectedSite != null && this.selectedType != null)
      this.getChemistry();      
  }

  RowSelectedSite() {

    this.selectedType = null;
    this.RowSelectedType();
   
  }
  

  getChemistry() {
    console.log(this.selectedSite.idSite, this.selectedType.idType);

    this.chemistryService.getChemistryBySiteType(this.selectedSite.idSite, this.selectedType.idArticleFamily)
      .pipe(first())
      .subscribe(
        data => {
          if (data == false) {
            this.error2 = "Consulta errónea.";
          }
          this.chemistries = data;
        },
        error => {
          this.error = error;
        });
  }


  RowSelectedChemistry(u: any, i: number) {

    this.selectedChemistry = u;
    this.selectedRowChemistry = i;
    
    this.compositions = null;
    this.getComposition();    
  }


  getComposition() {
    this.chemistryService.getCompositonByChemistry(this.selectedChemistry.idChemistry)
      .pipe(first())
      .subscribe(
        data => {
          if (data == false) {
            this.error2 = "Consulta errónea.";
          }
          this.compositions = data;
        },
        error => {
          this.error = error;
        });
  };



  ngOnInit()
  {
    //Inicializar valores controles del formulario    
    this.iniciarControlesElement();
    
    this.chemistryService.getElement()
      .pipe(first())
      .subscribe(
        data => {
          if (data == false) {
            this.error2 = "Consulta errónea.";
          }
          this.elements = data;
        },
        error => {
          this.error = error;
        });

    
    this.selectedSite = null;
    this.selectedType = null;
  }

  redirection() {

    this.router.navigate(['/chemistryCreation']);

  }
  redirectionElement() {

    this.router.navigate(['/elementCreation']);

    }

    redirectionUpdate() {
        if (this.selectedChemistry != null) {
            console.log("Site ID " + this.selectedChemistry.idChemistry);
            this.router.navigate(['/chemistryCreation', {
                idChemistry: this.selectedChemistry.idChemistry
            }]);
        }
        else {
            this.error("Select valid Chemistry", 'Update');
        }
    }

    sortTableNum(n: number, tableName: string) {
        var table, rows, switching, i, x, y, shouldSwitch;
        table = document.getElementById(tableName);
        switching = true;
        /*Make a loop that will continue until
        no switching has been done:*/
        while (switching) {
            //start by saying: no switching is done:
            switching = false;
            rows = table.rows;
            /*Loop through all table rows (except the
            first, which contains table headers):*/
            for (i = 1; i < (rows.length - 1); i++) {
                //start by saying there should be no switching:
                shouldSwitch = false;
                /*Get the two elements you want to compare,
                one from current row and one from the next:*/
                x = (rows[i].getElementsByTagName("TD")[n]);
                y = (rows[i + 1].getElementsByTagName("TD")[n]);
                //check if the two rows should switch place:
                if (Number(x.innerHTML) > Number(y.innerHTML)) {
                    //if so, mark as a switch and break the loop:
                    shouldSwitch = true;
                    break;
                }
            }
            if (shouldSwitch) {
                /*If a switch has been marked, make the switch
                and mark that a switch has been done:*/
                rows[i].parentNode.insertBefore(rows[i + 1], rows[i]);
                switching = true;
            }
        }
    }

    myFunction(tableName: string) {
        var inputId: string = 'myInputChemistries';
        if (tableName == "TableComposition")
            inputId = "myInputComposition";
        else if (tableName == "TableElements")
            inputId = "myInputElements";

        console.log("table " + tableName);
        var input, filter, table, tr, td, i, txtValue;
        console.log(document.getElementById(inputId));
        console.log(document.getElementById(tableName));
        input = document.getElementById(inputId);
        filter = input.value.toUpperCase();
        table = document.getElementById(tableName);
        tr = table.getElementsByTagName("tr");
        for (i = 0; i < tr.length; i++) {
            td = tr[i].getElementsByTagName("td")[0];
            if (td) {
                txtValue = td.textContent || td.innerText;
                if (txtValue.toUpperCase().indexOf(filter) > -1) {
                    tr[i].style.display = "";
                } else {
                    tr[i].style.display = "none";
                }
            }
        }
    }

    sortTable(n: number, tableName: string) {
        console.log("Order table " + tableName);
        var table, rows, switching, i, x, y, shouldSwitch, dir, switchcount = 0;
        table = document.getElementById(tableName);
        switching = true;
        // Set the sorting direction to ascending:
        dir = "asc";
        /* Make a loop that will continue until
        no switching has been done: */
        while (switching) {
            // Start by saying: no switching is done:
            switching = false;
            rows = table.rows;
            /* Loop through all table rows (except the
            first, which contains table headers): */
            for (i = 1; i < (rows.length - 1); i++) {
                // Start by saying there should be no switching:
                shouldSwitch = false;
                /* Get the two elements you want to compare,
                one from current row and one from the next: */
                x = rows[i].getElementsByTagName("TD")[n];
                y = rows[i + 1].getElementsByTagName("TD")[n];
                /* Check if the two rows should switch place,
                based on the direction, asc or desc: */
                if (dir == "asc") {
                    if (x.innerHTML.toLowerCase() > y.innerHTML.toLowerCase()) {
                        // If so, mark as a switch and break the loop:
                        shouldSwitch = true;
                        break;
                    }
                } else if (dir == "desc") {
                    if (x.innerHTML.toLowerCase() < y.innerHTML.toLowerCase()) {
                        // If so, mark as a switch and break the loop:
                        shouldSwitch = true;
                        break;
                    }
                }
            }
            if (shouldSwitch) {
                /* If a switch has been marked, make the switch
                and mark that a switch has been done: */
                rows[i].parentNode.insertBefore(rows[i + 1], rows[i]);
                switching = true;
                // Each time a switch is done, increase this count by 1:
                switchcount++;
            } else {
                /* If no switching has been done AND the direction is "asc",
                set the direction to "desc" and run the while loop again. */
                if (switchcount == 0 && dir == "asc") {
                    dir = "desc";
                    switching = true;
                }
            }
        }
    }

    onDelete() {
        this.clear('Delete');

        if (this.selectedChemistry != null) {
            console.log("Entra eliminar");
            var body = JSON.stringify(this.selectedChemistry);
            this.chemistryService.DeleteChemistry(body)
                .pipe(first())
                .subscribe(
                    result => {
                        if (result == true) {
                            this.alertDetete('The chemistry has been deleted', 'Delete');
                        }
                    },
                    error => {
                        console.log(error);
                        this.errorInfo = error;
                        this.alertDetete(error, 'Delete');
                    });
        }
        else
            this.error('An error has occurred, select valid chemistry', 'Delete');
    }

    alertDetete(message: string, id: string) {
        if (this.errorInfo == '') {
            this.success(message, id);
            this.chemistries.splice(this.selectedRowChemistry, 1);
            this.compositions = [];
            this.selectedRowChemistry = 0;
            this.selectedChemistry = null;
        }
        else {
            this.error(message, id);
        }

        //Se resetean todas la variables por si se desean insertar más datos
        console.log("Selected row " + this.selectedRowChemistry);

    }




  success(message: string, id:string) {
    this.alertService.success(message, id);
  }

  error(message: string, id: string) {
    this.alertService.error(message, id);
  }

  info(message: string) {
    this.alertService.info(message);
  }

  warn(message: string) {
    this.alertService.warn(message);
  }

  clear(id: string) {
    this.alertService.clear(id);
  }

}

