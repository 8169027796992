import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { EnvService } from './env.service';

import { User } from '@/_models';
import { Token } from '@/_models';
import { Message } from '@angular/compiler/src/i18n/i18n_ast';
import { group } from '../_models/group';
import { forEach } from '@angular/router/src/utils/collection';

@Injectable({ providedIn: 'root' })
export class GroupService {
  private currentGroupSubject: BehaviorSubject<group[]>;
  public currentGroup: Observable<group[]>;
  
  constructor(private http: HttpClient, private env: EnvService) {
    this.currentGroupSubject = new BehaviorSubject<group[]>(JSON.parse(localStorage.getItem('group')));
    this.currentGroup = this.currentGroupSubject.asObservable();
  }

    
  getOriginGroupBySite(site: Number) {
      return this.http.get<any>(this.env.apiUrl + `/api/Batch/origin/` + site.toString())
          .pipe(map(group => {
              // store user details and jwt token in local storage to keep user logged in between page refreshes      

              this.currentGroupSubject.next(group);
              return group;
          }));
  }

   getContainerBySite(site: Number) {
        return this.http.get<any>(this.env.apiUrl + `/api/Batch/container/site/` + site.toString())
            .pipe(map(group => {
                // store user details and jwt token in local storage to keep user logged in between page refreshes      
                console.log("passei: " + this.env.apiUrl + `/api/Batch/container/` + site.toString());
                this.currentGroupSubject.next(group);
                return group;
            }));
    }


    getContainerByPackage(idPackage: Number) {
       return this.http.get<any>(this.env.apiUrl + `/api/Batch/container/` + idPackage.toString())
            .pipe(map(container => {
                // store user details and jwt token in local storage to keep user logged in between page refreshes      

                //this.currentShipmentSubject.next(shipment);
                return container;
            }));
    }

  getGroupBySite(site: Number) {
    return this.http.get<any>(this.env.apiUrl + `/api/Batch/site/` + site.toString())
      .pipe(map(group => {
        // store user details and jwt token in local storage to keep user logged in between page refreshes      

        this.currentGroupSubject.next(group);
        return group;
      }));
  }

  getGroupByLab(lab: Number) {    
    return this.http.get<any>(this.env.apiUrl + `/api/Batch/lab/` + lab.toString())
      .pipe(map(group => {             
          // store user details and jwt token in local storage to keep user logged in between page refreshes      
      
        this.currentGroupSubject.next(group);
        return group;
      }));          
    }

    ReturnById(id: Number) {
        return this.http.get<any>(this.env.apiUrl + `/api/Batch/` + id.toString())
            .pipe(map(group => {
                // store user details and jwt token in local storage to keep user logged in between page refreshes      

                this.currentGroupSubject.next(group);
                return group;
            }));
    }
  
  SaveGroup(body) {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    console.log(body);
    console.log(headers);
    console.log(this.env.apiUrl + `/api/Batch`);
    return this.http.post(this.env.apiUrl + `/api/Batch`, body, { headers })
      .pipe(map(group => {
        return true;
      }));
    }



    DeleteGroup(body) {
        console.log("Body " + body);
        let options = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
            }),
            body: body,
        };
        return this.http.delete(this.env.apiUrl + `/api/Batch/`, options)
            .pipe(map(cookie => {
                return true;
            }));
    }


}
