import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { EnvService } from './env.service';
import { User } from '@/_models';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class UserService {
    private currentUserInfoSubject: BehaviorSubject<User>;
    public currentUserInfo: Observable<User>;
    
    constructor(private http: HttpClient, private env: EnvService) {
        this.currentUserInfoSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUserInfo')));
        this.currentUserInfo = this.currentUserInfoSubject.asObservable();
    }

    public get currentUserInfoValue(): User {
        return this.currentUserInfoSubject.value;
    }

    getAll() {
        return this.http.get<User[]>(`${config.apiUrl}/users`);
    }

    getUserInfo(user: Number) {
        console.log(this.env.apiUrl + `/api/User`);        
        return this.http.get<any>(this.env.apiUrl + `/api/User/` + user.toString())
            .pipe(map(userinfo => {
                // store user details and jwt token in local storage to keep user logged in between page refreshes      
                if (userinfo) {                    
                    localStorage.setItem('currentUserInfo', JSON.stringify(userinfo));
                    this.currentUserInfoSubject.next(userinfo);
                }
                else {
                    return false;
                }
                return userinfo;
            }));
    }
}
