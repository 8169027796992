import { chemistryelement } from './chemistryelement';

export class chemistry {    
  idChemistry: number;
  reference: string;
  description: string;
  idArticleFamily: number;
  ArticleFamilyReference: string;
  idSite: number;
  analysisDate: Date;
  chemistryElements: chemistryelement[];
}

