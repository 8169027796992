

export class moldladle {
  idMoldLadle: number;
  pourTemperature: number;
  pourTime: number;
  quantity: number;
  dtFillTime: Date;
  
  idMold: number;
  moldReference: string;
  realVolume: number;
  idLadleCasting: number;
  ladleCastingReference: string;
  dtLadleCasting: Date;
}
