import { imagegallery } from '.';

export class piecedefect {
  idPieceDefect: number;
  description: string;
  idDefectTimeType: number;
  defectTimeTypeReference: string;
  idDefectType: number;
  defectType: string;
  idPiece: number;
  piece: string;
  idPiecePart: number;
  piecePart: string;
  idPieceHeatTreatment: number;
    heatTreatment: string;
    imageGallery: imagegallery;
}
