import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class Utils2
{
  public stop: any;
  public start: any;
  public reset: any;

 

  public timer(fn, t) {
      var timerObj = setInterval(fn, t);

      this.stop = function () {
          if (timerObj) {
              clearInterval(timerObj);
              timerObj = null;
          }
          return this;
      }

      // start timer using current settings (if it's not already running)
      this.start = function () {
          if (!timerObj) {
              this.stop();
              timerObj = setInterval(fn, t);
          }
          return this;
      }

      // start with new interval, stop current interval
      this.reset = function (newT) {
          t = newT;
          return this.stop().start();
      }
  }



}


