import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { EnvService } from './env.service';

import { Message } from '@angular/compiler/src/i18n/i18n_ast';
import { sand } from '../_models/sand';
import { forEach } from '@angular/router/src/utils/collection';
import { granulometry } from '../_models';

@Injectable({ providedIn: 'root' })
export class SandService {
  private currentSandSubject: BehaviorSubject<sand[]>;
  public currentSand: Observable<sand[]>;

  constructor(private http: HttpClient, private env: EnvService) {
    this.currentSandSubject = new BehaviorSubject<sand[]>(JSON.parse(localStorage.getItem('sand')));
    this.currentSand = this.currentSandSubject.asObservable();
  }

    getSand(idStock: Number) {
      return this.http.get<any>(this.env.apiUrl + `/api/Sand/stock/` + idStock.toString())
        .pipe(map(sand => {
          // store user details and jwt token in local storage to keep user logged in between page refreshes      
          
          this.currentSandSubject.next(sand);
          return sand;
        }));
    }
  


    getSandById(idSand: Number) {
        return this.http.get<any>(this.env.apiUrl + `/api/Sand/` + idSand.toString())
            .pipe(map(sand => {
                // store user details and jwt token in local storage to keep user logged in between page refreshes      

                this.currentSandSubject.next(sand);
                return sand;
            }));
    }

    getGranulometry(idSand: Number) {
      return this.http.get<any>(this.env.apiUrl + `/api/Sand/granulometry/` + idSand.toString())
        .pipe(map(granulometry => {
          // store user details and jwt token in local storage to keep user logged in between page refreshes      

          this.currentSandSubject.next(granulometry);
          return granulometry;
        }));
    }



    getGranulometryNorm(idType: Number) {
      return this.http.get<any>(this.env.apiUrl + `/api/Sand/granulometrynorm/` + idType.toString())
        .pipe(map(granulometry => {
          // store user details and jwt token in local storage to keep user logged in between page refreshes      

          this.currentSandSubject.next(granulometry);
          return granulometry;
        }));
    }

    saveSand(body) {
        let headers = new HttpHeaders({
            'Content-Type': 'application/json'
        });
        console.log(body);
        console.log(headers);        
        return this.http.post(this.env.apiUrl + `/api/Sand`, body, { headers })
            .pipe(map(sand => {
                return true;
            }));
    }

  
    deleteSand(idSand: Number) {
        let headers = new HttpHeaders({
            'Content-Type': 'application/json'
        });
        return this.http.post(this.env.apiUrl + `/api/Sand` + idSand.toString(), { headers })
    } 
}


