export class pieceTypeAssign {   
  numPiece: number;
  idPieceType: number;
  pieceTypeReference: string;
  idMoldTypeElement: number;
  pieceTypeDescription: string;
  moldTypeElementReference: string;
}


