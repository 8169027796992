import { sandmixermaterial } from './sandmixermaterial';

export class sandmixerprocess {    
  idSandMixerProcess: number;
  temperature: number;
  mixBegin: Date;
  mixFinish: Date;
  sandMixerMaterial: sandmixermaterial;
}

