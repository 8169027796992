
export class coating {    
  idStock: number;
  stockReference: string;
  coatingProcess: string;
  densityBA: number;
  densitySI: number;  
  quantityArticleStock: number;
  coatingDate: Date;
  thickness: number;
  observation: string;

}


