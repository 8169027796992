import { test, } from './test';
import { imagegallery } from './imagegallery';

export class templatelab {    
    idTemplateLabTest: number; //TemplateLabTest
    idTemplateLab: number;//TemplateLab
    idTemplate: number;//Template
    TemplateReference: string;//Template
    testDescription: string;//TemplateLabTest
    resultDescription: string;//TemplateLabTest
    beginDate: Date;//TemplateLabTest
    endDate: Date;//TemplateLabTest
    resultFileRoute: string;//TemplateLabTest
    imageGallery: imagegallery;
    tests: test[];//Template
}
