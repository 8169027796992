import { Injectable } from '@angular/core';
import { Router, NavigationStart } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { filter } from 'rxjs/operators';
import { forEach } from '@angular/router/src/utils/collection';

import { Alert, AlertType } from './alert.model';

@Injectable({ providedIn: 'root' })
export class AlertService {
    private subject = new Subject<Alert>();
    private keepAfterRouteChange = false;
    stop: any;
    start: any;
    reset: any;

    constructor(private router: Router) {
        // clear alert messages on route change unless 'keepAfterRouteChange' flag is true
        this.router.events.subscribe(event => {
            if (event instanceof NavigationStart) {
                if (this.keepAfterRouteChange) {
                    // only keep for a single route change
                    this.keepAfterRouteChange = false;
                } else {
                    // clear alert messages
                    this.clear();
                }                
            }
        });
       
    }

    // enable subscribing to alerts observable
    onAlert(alertId?: string): Observable<Alert> {
        return this.subject.asObservable().pipe(filter(x => x && x.alertId === alertId));
    }

    // convenience methods
    success(message: string, alertId?: string) {        
        this.alert(new Alert({ message, type: AlertType.Success, alertId }));
    }

    error(message: string, alertId?: string) {        
        this.alert(new Alert({ message, type: AlertType.Error, alertId }));
    }

    info(message: string, alertId?: string) {        
        this.alert(new Alert({ message, type: AlertType.Info, alertId }));
    }

    warn(message: string, alertId?: string) {        
        this.alert(new Alert({ message, type: AlertType.Warning, alertId }));
    }

    // main alert method    
    alert(alert: Alert) {
        this.keepAfterRouteChange = alert.keepAfterRouteChange;
        // clear alert messages
        this.clear(alert.alertId);
        this.subject.next(alert);
    }

    // clear alerts
    clear(alertId?: string) {
        this.subject.next(new Alert({ alertId }));
    }

    Timer(fn, t) {        
      var timerObj = setInterval(fn, t);

      this.stop = function () {
          if (timerObj) {
              clearInterval(timerObj);
              timerObj = null;
          }          
          return this;
      }

      // start timer using current settings (if it's not already running)
      this.start = function () {
          if (!timerObj) {
              this.stop();
              timerObj = setInterval(fn, t);
          }          
          return this;
      }

      // start with new interval, stop current interval
      this.reset = function (newT) {
          t = newT;
          return this.stop().start();
    }
}

}
