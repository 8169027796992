import { Component, ViewChild } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, FormArray, Validators } from '@angular/forms';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, first } from 'rxjs/operators';
import { EnvService } from '../../_services/env.service';
import { UserService, SiteService } from '@/_services';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { AlertService } from '@/shared/alerts';
import { site, area, User, environmental } from '../../_models/index';
import { forEach } from '@angular/router/src/utils/collection';
import { Utils } from '../../shared/utils/utils'
import { utils } from 'protractor';
import { Router } from "@angular/router";


@Component({ templateUrl: 'environmentalList.component.html' })

export class EnvironmentalList { 
    error2 = '';
    errorInfo = '';
  sites : any[] = [];
  areas : any[] = [];
  environmentals = [];
  selectedRowSite: number;
  selectedSite: site;
    selectedArea: area;
    selectedEnv: environmental;
    selectedRowEnv: number;
  currentUser: User;


  environmentalListForm = this.fb.group({
    sites: [null],
    areas:[null],
     environmentals: this.fb.group({
       temperature: [''],
       humidity: [''],
       dtEnvironmental: ['']
     })
  });




  constructor(private fb: FormBuilder, private http: HttpClient, private env: EnvService,
    private siteService: SiteService, private user: UserService, private alertService: AlertService, private router: Router) {
    this.currentUser = this.user.currentUserInfoValue;
  }

  iniciarControlesEnvironmental() {
    this.environmentalListForm.patchValue({
      environmentals: {
        temperature: null,
        humidity: null,
        dtEnvironmental: null
       }
    });
  }


  RowSelectedSite() {
    if (this.selectedSite != null) {
      this.getArea();
      this.selectedArea = null;
      this.environmentals = null;
    }      
    else
    {
      this.areas = null;
      this.environmentals = null;
      this.selectedArea = null;      
    }
  }

    RowSelectedEnv(u: any, i: number) {
        this.selectedEnv = u;
        this.selectedRowEnv = i;
    }

  getArea() {
    this.siteService.getArea(this.selectedSite.idSite, this.currentUser.idUserApplication)
        .pipe(first())
        .subscribe(
          data => {
            if (data == false) {
              this.error2 = "Consulta errónea.";
            }
            this.areas = data;
          },
          error => {
            this.error = error;
          });
    
  }

  RowSelectedArea() {
    if (this.selectedArea)
      this.getEnvironmental();
    else
      this.environmentals = null;
  }

  getEnvironmental() {
    if (this.selectedArea != null) {
      this.siteService.getEnvironmental(this.selectedArea.idArea)
        .pipe(first())
        .subscribe(
          data => {
            if (data == false) {
              this.error2 = "Consulta errónea.";
            }
            this.environmentals = data;
          },
          error => {
            this.error = error;
          });
    }
  }


  ngOnInit()
  {//Inicializar valores controles del formulario    
    this.iniciarControlesEnvironmental();

    this.siteService.site(this.currentUser.idUserApplication)
      .pipe(first())
      .subscribe(
        data => {
          if (data == false) {
            this.error2 = "Consulta errónea.";
          }
          this.sites = data;

          //Se selecciona por defecto el site del usuario
          if (this.currentUser.idSiteDefault > 0) {
            this.selectedSite = this.sites.find(x => x.idSite == this.currentUser.idSiteDefault);
            this.RowSelectedSite();
          }
          else
            this.selectedSite = null;
        },
        error => {
          this.error = error;
          this.error(error, 'Error');

        });
    
    this.selectedArea = null;
  }

  redirection() {

    this.router.navigate(['/environmentalCreation']);

    }

    redirectionUpdate() {
        if (this.selectedEnv != null) {
            console.log("Site ID " + this.selectedSite.idSite);
            this.router.navigate(['/environmentalCreation', {
                idEnvironmental: this.selectedEnv.idEnvironmental, idArea: this.selectedArea.idArea, idSite: this.selectedSite.idSite     
            }]);
        }
        else {
            this.error("Select valid environmental", 'Update');
        }
    }

    onDelete() {
        this.clear('Delete');

        if (this.selectedEnv != null) {
            console.log("Entra eliminar");
            var body = JSON.stringify(this.selectedEnv);
            this.siteService.DeleteEnvironmental(body)
                .pipe(first())
                .subscribe(
                    result => {
                        if (result == true) {
                            this.alertDetete('The environmental has been deleted', 'Delete');
                        }
                    },
                    error => {
                        console.log(error);
                        this.errorInfo = error;
                        this.alertDetete(error, 'Delete');
                    });
        }
        else
            this.alertDetete('An error has occurred, select valid environmental data', 'Delete');
    }

    alertDetete(message: string, id: string) {
        if (this.errorInfo == '' && this.selectedRowEnv > 0) {
            this.success(message, id);
            this.environmentals.splice(this.selectedRowEnv, 1);
            this.selectedRowEnv = 0;
            this.selectedEnv = null;
        }
        else {
            this.error(message, id);
        }

     }

    sortTableNum(n: number, tableName: string) {
        var table, rows, switching, i, x, y, shouldSwitch;
        table = document.getElementById(tableName);
        switching = true;
        /*Make a loop that will continue until
        no switching has been done:*/
        while (switching) {
            //start by saying: no switching is done:
            switching = false;
            rows = table.rows;
            /*Loop through all table rows (except the
            first, which contains table headers):*/
            for (i = 1; i < (rows.length - 1); i++) {
                //start by saying there should be no switching:
                shouldSwitch = false;
                /*Get the two elements you want to compare,
                one from current row and one from the next:*/
                x = (rows[i].getElementsByTagName("TD")[n]);
                y = (rows[i + 1].getElementsByTagName("TD")[n]);
                //check if the two rows should switch place:
                if (Number(x.innerHTML) > Number(y.innerHTML)) {
                    //if so, mark as a switch and break the loop:
                    shouldSwitch = true;
                    break;
                }
            }
            if (shouldSwitch) {
                /*If a switch has been marked, make the switch
                and mark that a switch has been done:*/
                rows[i].parentNode.insertBefore(rows[i + 1], rows[i]);
                switching = true;
            }
        }
    }

    myFunction(tableName: string) {
        var inputId: string = 'myInput';
        console.log("table " + tableName);
        var input, filter, table, tr, td, i, txtValue;
        console.log(document.getElementById(inputId));
        console.log(document.getElementById(tableName));
        input = document.getElementById(inputId);
        filter = input.value.toUpperCase();
        table = document.getElementById(tableName);
        tr = table.getElementsByTagName("tr");
        for (i = 0; i < tr.length; i++) {
            td = tr[i].getElementsByTagName("td")[2];
            if (td) {
                txtValue = td.textContent || td.innerText;
                if (txtValue.toUpperCase().indexOf(filter) > -1) {
                    tr[i].style.display = "";
                } else {
                    tr[i].style.display = "none";
                }
            }
        }
    }

    sortTable(n: number, tableName: string) {
        console.log("Order table " + tableName);
        var table, rows, switching, i, x, y, shouldSwitch, dir, switchcount = 0;
        table = document.getElementById(tableName);
        switching = true;
        // Set the sorting direction to ascending:
        dir = "asc";
        /* Make a loop that will continue until
        no switching has been done: */
        while (switching) {
            // Start by saying: no switching is done:
            switching = false;
            rows = table.rows;
            /* Loop through all table rows (except the
            first, which contains table headers): */
            for (i = 1; i < (rows.length - 1); i++) {
                // Start by saying there should be no switching:
                shouldSwitch = false;
                /* Get the two elements you want to compare,
                one from current row and one from the next: */
                x = rows[i].getElementsByTagName("TD")[n];
                y = rows[i + 1].getElementsByTagName("TD")[n];
                /* Check if the two rows should switch place,
                based on the direction, asc or desc: */
                if (dir == "asc") {
                    if (x.innerHTML.toLowerCase() > y.innerHTML.toLowerCase()) {
                        // If so, mark as a switch and break the loop:
                        shouldSwitch = true;
                        break;
                    }
                } else if (dir == "desc") {
                    if (x.innerHTML.toLowerCase() < y.innerHTML.toLowerCase()) {
                        // If so, mark as a switch and break the loop:
                        shouldSwitch = true;
                        break;
                    }
                }
            }
            if (shouldSwitch) {
                /* If a switch has been marked, make the switch
                and mark that a switch has been done: */
                rows[i].parentNode.insertBefore(rows[i + 1], rows[i]);
                switching = true;
                // Each time a switch is done, increase this count by 1:
                switchcount++;
            } else {
                /* If no switching has been done AND the direction is "asc",
                set the direction to "desc" and run the while loop again. */
                if (switchcount == 0 && dir == "asc") {
                    dir = "desc";
                    switching = true;
                }
            }
        }
    }


  success(message: string, id:string) {
    this.alertService.success(message, id);
  }

  error(message: string, id: string) {
    this.alertService.error(message, id);
  }

  info(message: string) {
    this.alertService.info(message);
  }

  warn(message: string) {
    this.alertService.warn(message);
  }

  clear(id: string) {
      this.alertService.clear(id);
      this.errorInfo = '';
  }

}

