
export class article {    
  idArticle: number;
  reference: string;
  description: string;
  idChemistry: number;
  chemistryReference: string;
  idArticleFamily: number;
  articleFamilyReference: string;
  idArticleType: number;
  articleTypeReference: string;
  idSite: number;
  siteReference: string;
}
