import { Component, ViewChild, HostListener } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, FormArray, Validators } from '@angular/forms';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, first } from 'rxjs/operators';
import { EnvService } from '../../_services/env.service';
import { CookieService, SiteService, UserService, MoldService, ImageGalleryService } from '@/_services';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { AlertService } from '@/shared/alerts';
import { cookie, User, site, imagegallery } from '../../_models/index';
import { forEach } from '@angular/router/src/utils/collection';
import { Utils } from '../../shared/utils/utils'
import { utils } from 'protractor';
import { Router } from "@angular/router";
// MDB Angular Free
//import { WavesModule, TableModule } from 'angular-bootstrap-md';

@Component({
    selector: 'cookieList',
    templateUrl: 'cookieList.component.html',
    styleUrls: ['cookieList.component.scss']
})

//@Component({ templateUrl: 'cookieList.component.html' })

export class CookieList {
    errorInfo = '';
    siteReference = 'Select Site';
  error2 = '';
  //sites = [];
  cookies = [];
  compositions = [];
  infoTable: cookie[] = [];  
  selectedRow: number;
  cookieResultado: cookie;
  site: number;
  currentUser: User;
  filter: number;
  headCookies = ['ID', 'Cookie', 'Drag / Core', 'Sent', 'Received', 'Tested'];    
  cookieSelected: cookie;
    selectedSite: site;
    cards: any[] = [];
    slides: any = [[]];
    @ViewChild('warningModal') warningModal: any;
    CAROUSEL_BREAKPOINT = 768;
    carouselDisplayMode = 'multiple';
  imageGallery: imagegallery;
  sites = [];
        
  cookieListForm = this.fb.group({
    sites: [null, Validators.required],
    cookies: this.fb.group({
      nameCookie: [''],
      moldCompReference: [''],
      sent: [''],
      received: [''],
      tested: ['']
    }),
     compositions: this.fb.group({
         ID: [''],
         reference: [''],
         tickness: [''],
         density: [''],
         sandRatio: [''],
         bos: [''],
         quantity: [''],
         temperature: [''],
         coatingProcess: [''],
         observation: [''],
     })
  });
       
  constructor(private fb: FormBuilder, private http: HttpClient, private env: EnvService,
      private siteService: SiteService, private cookieService: CookieService, private user: UserService, private alertService: AlertService, private router: Router,
      private moldService: MoldService, private imageGalleryService: ImageGalleryService ) {
    this.cookieResultado = new cookie();
    this.currentUser = this.user.currentUserInfoValue;
  }

    iniciarControlesCookie() {      
    this.cookieListForm.patchValue({
        cookies: {
        nameCookie: null,
        moldCompReference: '',
        sent: false,
        received: false,
        tested: false
      }
    });
    }

    iniciarControlesComposition() {
        this.cookieListForm.patchValue({
            compositions: {
                ID: null,
                reference: '',
                tickness: 0,
                density: 0,
                sandRatio: 0,
                bos: 0,
                quantity: 0,
                temperature: 0,
                coatingProcess: '',
                observation: '',
            }
        });
    }

    //TABLA Groups
    RowSelected(u: any, i: number) {
        console.log(u);
        console.log(i);
        this.cookieSelected = u;   // declare variable in component.
        this.selectedRow = i;
        this.getComposition();
    }


    RowSelectedSite() {        
        this.getCookie();
    }

    getComposition()
    {
        this.clear('alertcookie');

        this.moldService.getCompositionByCookie(this.cookieSelected.idCookie)

            .pipe(first())
            .subscribe(
                data => {
                    if (data == false) {
                        this.error2 = "Consulta errónea.";
                    }
                    this.compositions = data;
                    console.log(data);
                },
                error => {
                    this.error = error;
                    this.error(error, 'alertcookie');
                });

    }

  getCookie() {    
   
    this.clear('alertcookie');
    
    if (this.selectedSite != null) {
      this.cookieService.getCookieBySite(this.selectedSite.idSite)
        .pipe(first())
        .subscribe(
          data => {
            if (data == false) {
              this.error2 = "Consulta errónea.";
            }
            this.cookies = data;
            console.log(data);
          },
          error => {
            this.error = error;
            this.error(error, 'alertcookie');
          });
    }
    else
    {
      if (this.currentUser.companyType == 3) //laboratory
      {
        this.cookieService.getCookieByLab(this.currentUser.idCompany)
          .pipe(first())
          .subscribe(
            data => {
              if (data == false) {
                this.error2 = "Consulta errónea.";
              }
              this.cookies = data;
              console.log(data);
            },
            error => {
              this.error = error;
              this.error(error, 'alertcookie');
            });
      }
      else {
        if (this.currentUser.idSiteDefault != null) {
          this.cookieService.getCookieBySite(this.currentUser.idSiteDefault)
            .pipe(first())
            .subscribe(
              data => {
                if (data == false) {
                  this.error2 = "Consulta errónea.";
                }
                this.cookies = data;
                console.log(data);
              },
              error => {
                this.error = error;
                this.error(error, 'alertcookie');
              });
        }
        else {
          this.cookies = null;
          this.compositions = null;
        }
      }
    }    
   
  }


  ngOnInit()
  {
      
    this.siteService.site(this.currentUser.idUserApplication)
      .pipe(first())
      .subscribe(
        data => {
          if (data == false) {
            this.error2 = "Consulta errónea.";
          }
          this.sites = data;
          //Se selecciona por defecto el site del usuario
          if (this.currentUser.idSiteDefault > 0)
            this.selectedSite = this.sites.find(x => x.idSite == this.currentUser.idSiteDefault);
          else
            this.selectedSite = null;
        },
        error => {
          this.error = error;
          this.error(error, 'alertcookie');
        });
      
    this.getCookie();

    //Inicializar valores controles del formulario    
    this.iniciarControlesCookie();
    this.iniciarControlesComposition();         
    //this.selectedSite = this.user.currentUserInfoValue;
    }

    getImageGalery(info: any) {

        if (info.imageGallery != null && info.imageGallery.idImageGallery > 0) {

            this.imageGalleryService.returnImageGalleryById(info.imageGallery.idImageGallery)
                .pipe(first())
                .subscribe(
                    data => {
                        if (data == false) {
                            this.error2 = "Consulta errónea.";
                        }
                        this.imageGallery = data;
                        this.cards.splice(0, this.cards.length);
                        this.imageGallery.images.forEach(item => {
                            var url = 'data:image/jpeg;base64,' + item.imageFile; // use this in <img src="..."> binding
                            this.cards.push({
                                title: item.reference,
                                description: item.description,
                                img: url
                            });
                        });
                        this.slides = this.chunk(this.cards, 3);
                        if (window.innerWidth <= this.CAROUSEL_BREAKPOINT) {
                            this.carouselDisplayMode = 'single';
                        } else {
                            this.carouselDisplayMode = 'multiple';
                        }

                        this.warningModal.show();

                    },
                    error => {
                        this.error = error;
                    });
        }
    }

    OpenModal(info: any, i: number) {

        this.getImageGalery(info);
    }

    @HostListener('window:resize')
    onWindowResize() {
        if (window.innerWidth <= this.CAROUSEL_BREAKPOINT) {
            this.carouselDisplayMode = 'single';
        } else {
            this.carouselDisplayMode = 'multiple';
        }
    }

    chunk(arr, chunkSize) {
        let R = [];
        for (let i = 0, len = arr.length; i < len; i += chunkSize) {
            R.push(arr.slice(i, i + chunkSize));
        }
        return R;
    }
    
    redirection() {

        this.router.navigate(['/cookieCreation']);

    }

    redirectionUpdate() {        
        var idSite = 0;
        if (this.currentUser.idSiteDefault != null)
            idSite = this.currentUser.idSiteDefault;
                
        if (this.cookieSelected != null) {
            this.router.navigate(['/cookieCreation', { idCookie: this.cookieSelected.idCookie, idSite: idSite}]);
        }
        else {
            this.error("Select valid Cookie", 'Update');
        }
    }

    redirectionUpdateLink(info: number) {
        this.router.navigate(['/articleStockCreation', { idArticleStock: info}]);
    }


  redirectionUpdateLinkMold(info: any) {
    console.log(info);
    var comp = 0;
    if (info.idMoldComponent != null) comp = info.idMoldComponent;
    console.log(info.idMold, comp, info.idSite)
    var bool = this.router.navigate(['/mold', { idMold: info.idMold, idMoldcomponent: comp, idSite: info.idSite }]);

    //var bool2 = this.router.navigate(['/moldCreation', { idMold: info.idMold, idSite: info.idSite }]);

    //console.log(bool2);
  }



    onDelete() {
        this.clear('Delete');

        if (this.cookieSelected!=null) {
            console.log("Entra eliminar");
            var body = JSON.stringify(this.cookieSelected);
            this.cookieService.deleteCookie(body)
                .pipe(first())
                .subscribe(
                    result => {
                        if (result == true) {
                            this.alertDetete('The cookie has been deleted', 'Delete');
                        }
                    },
                    error => {
                        console.log(error);
                        this.errorInfo = error;
                        this.alertDetete(error, 'Delete');
                    });
        }
        else
            this.alertDetete('An error has occurred, select valid cookie', 'Delete');                
    }

    alertDetete(message: string, id: string) {
        if (this.selectedRow > 0 && this.errorInfo == '') {
            this.success(message, id);
            this.cookies.splice(this.selectedRow, 1);
            this.infoTable.splice(this.selectedRow, 1);
            this.selectedRow = 0;
            this.cookieSelected = null;
        }
        else
        {
            this.error(message, id);
        }
     
        //Se resetean todas la variables por si se desean insertar más datos
       console.log("Selected row " + this.selectedRow);
        
    }

    sortTableNum(n: number, tableName: string) {
        var table, rows, switching, i, x, y, shouldSwitch;
        table = document.getElementById(tableName);
        switching = true;
        /*Make a loop that will continue until
        no switching has been done:*/
        while (switching) {
            //start by saying: no switching is done:
            switching = false;
            rows = table.rows;
            /*Loop through all table rows (except the
            first, which contains table headers):*/
            for (i = 1; i < (rows.length - 1); i++) {
                //start by saying there should be no switching:
                shouldSwitch = false;
                /*Get the two elements you want to compare,
                one from current row and one from the next:*/
                x = (rows[i].getElementsByTagName("TD")[n]);
                y = (rows[i + 1].getElementsByTagName("TD")[n]);
                //check if the two rows should switch place:
                if (Number(x.innerHTML) > Number(y.innerHTML)) {
                    //if so, mark as a switch and break the loop:
                    shouldSwitch = true;
                    break;
                }
            }
            if (shouldSwitch) {
                /*If a switch has been marked, make the switch
                and mark that a switch has been done:*/
                rows[i].parentNode.insertBefore(rows[i + 1], rows[i]);
                switching = true;
            }
        }
    }

    myFunction(tableName: string, index: number) {
        var inputId: string = 'myInput';
        if (tableName == "TableCookieComposition")
            inputId = "myInputComposition";
        
        console.log("table " + tableName);
        var input, filter, table, tr, td, i, txtValue;
        input = document.getElementById(inputId);
        filter = input.value.toUpperCase();
        table = document.getElementById(tableName);
        tr = table.getElementsByTagName("tr");
        for (i = 0; i < tr.length; i++) {
            td = tr[i].getElementsByTagName("td")[index];
            if (td) {
                txtValue = td.textContent || td.innerText;
                if (txtValue.toUpperCase().indexOf(filter) > -1) {
                    tr[i].style.display = "";
                } else {
                    tr[i].style.display = "none";
                }
            }
        }
    }

    sortTable(n: number, tableName: string) {
        console.log("Order table " + tableName);
    var table, rows, switching, i, x, y, shouldSwitch, dir, switchcount = 0;
        table = document.getElementById(tableName);
    switching = true;
    // Set the sorting direction to ascending:
    dir = "asc";
    /* Make a loop that will continue until
    no switching has been done: */
    while (switching) {
        // Start by saying: no switching is done:
        switching = false;
        rows = table.rows;
        /* Loop through all table rows (except the
        first, which contains table headers): */
        for (i = 1; i < (rows.length - 1); i++) {
            // Start by saying there should be no switching:
            shouldSwitch = false;
            /* Get the two elements you want to compare,
            one from current row and one from the next: */
            x = rows[i].getElementsByTagName("TD")[n];
            y = rows[i + 1].getElementsByTagName("TD")[n];
            /* Check if the two rows should switch place,
            based on the direction, asc or desc: */
            if (dir == "asc") {
                if (x.innerHTML.toLowerCase() > y.innerHTML.toLowerCase()) {
                    // If so, mark as a switch and break the loop:
                    shouldSwitch = true;
                    break;
                }
            } else if (dir == "desc") {
                if (x.innerHTML.toLowerCase() < y.innerHTML.toLowerCase()) {
                    // If so, mark as a switch and break the loop:
                    shouldSwitch = true;
                    break;
                }
            }
        }
        if (shouldSwitch) {
            /* If a switch has been marked, make the switch
            and mark that a switch has been done: */
            rows[i].parentNode.insertBefore(rows[i + 1], rows[i]);
            switching = true;
            // Each time a switch is done, increase this count by 1:
            switchcount++;
        } else {
            /* If no switching has been done AND the direction is "asc",
            set the direction to "desc" and run the while loop again. */
            if (switchcount == 0 && dir == "asc") {
                dir = "desc";
                switching = true;
            }
        }
    }
}
    
  success(message: string, id:string) {
    this.alertService.success(message, id);
  }

  error(message: string, id: string) {
    this.alertService.error(message, id);
  }

  info(message: string) {
    this.alertService.info(message);
  }

  warn(message: string) {
    this.alertService.warn(message);
  }

  clear(id: string) {
    this.alertService.clear(id);
  }

}

