import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder} from '@angular/forms';
import { AuthenticationService, MenuService, UserService, SiteService } from './_services';
import { User, site } from './_models';
import { map, first } from 'rxjs/operators';

import { OnDestroy, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { navItems, NavData } from './_nav';
import { menuItems } from './_models/menu';

@Component({ selector: 'app', templateUrl: 'app.component.html' })
export class AppComponent {
  currentUser: User;
  currenMenu: menuItems;
  public navItems = navItems;
  public navItems2 : menuItems[];
  public sidebarMinimized = true;
  private changes: MutationObserver;
  public element: HTMLElement;
  public currentUserInfo: User;
  public sites: any[] = [];
  public selectedSite: site;
  public idSite: number = 0;
  public error2 = '';

  siteForm = this.fb.group({
    sites: [null]
  });

    constructor(
        private router: Router,
      private authenticationService: AuthenticationService,
      private menuService: MenuService,
      private user: UserService,
      private siteService: SiteService,
      private fb: FormBuilder,
      @Inject(DOCUMENT) _document?: any
    ) {
      this.authenticationService.currentUser.subscribe(x => this.currentUser = x);
      this.changes = new MutationObserver((mutations) => {
        this.sidebarMinimized = _document.body.classList.contains('sidebar-minimized');
      });
      this.element = _document.body;
      this.changes.observe(<Element>this.element, {
        attributes: true,
        attributeFilter: ['class']
      });

      
    }

    logout() {
        this.authenticationService.logout();
        this.router.navigate(['/login']);
  }

  ngOnInit() {    
    
    this.authenticationService.currentToken.subscribe(value => {
      if (value) {
        this.menuService.menu(this.authenticationService.currentUserValue.idUserApplication).subscribe(res => {
          this.navItems2 = res.menu;
          this.currentUserInfo = this.user.currentUserInfoValue;
          this.getSite();
          //console.log(this.navItems2);
          //console.log(navItems);
        });
        return true;
      }
      else {
      }
    });
    
    
  }
  getSite() {
    this.siteService.site(this.currentUser.idUserApplication)
      .pipe(first())
      .subscribe(
        data => {
          if (data == false) {
            this.error2 = "Consulta errónea.";
          }
          this.sites = data;
          if (this.currentUserInfo.idSiteDefault > 0) {
            this.selectedSite = this.sites.find(x => x.idSite == this.currentUserInfo.idSiteDefault);
          }

        },
        error => {

        });
  }
  RowSelectedSite() {
    if (this.selectedSite != null) {
      this.currentUserInfo.idSiteDefault = this.selectedSite.idSite;
    }
    else {
      this.currentUserInfo.idSiteDefault = 0;
    }
  }
}
