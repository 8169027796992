import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { EnvService } from './env.service';

import { Message } from '@angular/compiler/src/i18n/i18n_ast';
import { article } from '../_models/article';
import { forEach } from '@angular/router/src/utils/collection';

@Injectable({ providedIn: 'root' })
export class ArticleService {
  private currentArticleSubject: BehaviorSubject<article[]>;
  public currentArticle: Observable<article[]>;

  constructor(private http: HttpClient, private env: EnvService) {
    this.currentArticleSubject = new BehaviorSubject<article[]>(JSON.parse(localStorage.getItem('article')));
    this.currentArticle = this.currentArticleSubject.asObservable();
  }

    getFamily() {
      return this.http.get<any>(this.env.apiUrl + `/api/Article/family/`)
        .pipe(map(articleFamily => {
          // store user details and jwt token in local storage to keep user logged in between page refreshes      

          //this.currentFamilySubject.next(articleFamily);
          return articleFamily;
        }));
    }

    getFamilyById(idFamily: Number) {
      return this.http.get<any>(this.env.apiUrl + `/api/Article/family/` + idFamily.toString())
        .pipe(map(articleFamily => {
          // store user details and jwt token in local storage to keep user logged in between page refreshes      

          //this.currentFamilySubject.next(articleFamily);
          return articleFamily;
        }));
    }


    getArticleType(family: string) {
        return this.http.get<any>(this.env.apiUrl + `/api/Article/articletype/` + family.toString())
            .pipe(map(type => {
                // store user details and jwt token in local storage to keep user logged in between page refreshes      

                return type;
            }));
    }

    getArticleBySiteFamily(idSite: Number, idFamily: Number) {
        console.log("Id Familia " + idFamily)
      return this.http.get<any>(this.env.apiUrl + `/api/Article/` + idSite.toString() + `/` + idFamily.toString())
        .pipe(map(articleFamily => {
          // store user details and jwt token in local storage to keep user logged in between page refreshes      
          
          this.currentArticleSubject.next(articleFamily);
          return articleFamily;
        }));
    }
  


    getArticleById(idArticle: Number) {
        return this.http.get<any>(this.env.apiUrl + `/api/Article/` + idArticle.toString())
            .pipe(map(article => {
                // store user details and jwt token in local storage to keep user logged in between page refreshes      

                this.currentArticleSubject.next(article);
                return article;
            }));
    }



    getArticleComposition(site: Number) {
      return this.http.get<any>(this.env.apiUrl + `/api/Article/Composition/` + site.toString())
        .pipe(map(articleStock => {
          // store user details and jwt token in local storage to keep user logged in between page refreshes      

          //this.currentArticleStockSubject.next(articleStock);
          return articleStock;
        }));
    }



    getStockByArticle(idArticle: Number) {
      return this.http.get<any>(this.env.apiUrl + `/api/Article/article/` + idArticle.toString())
        .pipe(map(articleStock => {
          // store user details and jwt token in local storage to keep user logged in between page refreshes      

          //this.currentArticleStockSubject.next(articleStock);
          return articleStock;
        }));
    }

    getStockBySiteFamily(idSite:Number, idFamily: Number) {
      return this.http.get<any>(this.env.apiUrl + `/api/Article/stock/family/` + idSite.toString() + `/` + idFamily.toString())
        .pipe(map(articleStock => {
          // store user details and jwt token in local storage to keep user logged in between page refreshes      

          //this.currentArticleStockSubject.next(articleStock);
          return articleStock;
        }));
    }

    getStockBySite(idSite: Number) {
      return this.http.get<any>(this.env.apiUrl + `/api/Article/stock/site/` + idSite.toString())
        .pipe(map(articleStock => {
          // store user details and jwt token in local storage to keep user logged in between page refreshes      

          //this.currentArticleStockSubject.next(articleStock);
          return articleStock;
        }));
    }

    getStockById(idStock: Number) {
      return this.http.get<any>(this.env.apiUrl + `/api/Article/stock/` + idStock.toString())
        .pipe(map(articleStock => {
          // store user details and jwt token in local storage to keep user logged in between page refreshes      

          //this.currentArticleStockSubject.next(articleStock);
          return articleStock;
        }));
    }

  
    saveFamily(body) {
      let headers = new HttpHeaders({
        'Content-Type': 'application/json'
      });
      console.log(body);
      console.log(headers);
      return this.http.post(this.env.apiUrl + `/api/Article/family`, body, { headers })
        .pipe(map(articleFamily => {
          return true;
        }));
    }


    saveArticle(body) {
        let headers = new HttpHeaders({
            'Content-Type': 'application/json'
        });
        console.log(body);
        console.log(headers);        
        return this.http.post(this.env.apiUrl + `/api/Article/article`, body, { headers })
            .pipe(map(article => {
                return true;
            }));
    }


    saveStock(body) {
      let headers = new HttpHeaders({
        'Content-Type': 'application/json'
      });
      console.log(body);
      console.log(headers);
      return this.http.post(this.env.apiUrl + `/api/Article/stock`, body, { headers })
        .pipe(map(articleStock => {
          return true;
        }));
  }


    deleteArticleStock(body) {
        console.log("Body " + body);
        let options = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
            }),
            body: body,
        };
        return this.http.delete(this.env.apiUrl + `/api/Article/stock`, options)
            .pipe(map(articleStock => {
                return true;
            }));
    }   

    deleteArticle(body) {
        console.log("Body " + body);
        let options = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
            }),
            body: body,
        };
        return this.http.delete(this.env.apiUrl + `/api/Article/article`, options)
            .pipe(map(article => {
                return true;
            }));
    }   
}


