import { Component, ViewChild } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, FormArray, Validators } from '@angular/forms';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, first } from 'rxjs/operators';
import { EnvService } from '../../_services/env.service';
import { UserService, ArticleService, TypeService, SiteService, ChemistryService, CompanyService, SandService } from '@/_services';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { AlertService } from '@/shared/alerts';
import { User, articleFamily, type, chemistry, site, article, company, articleStock, granulometry, sand} from '../../_models/index';
import { forEach } from '@angular/router/src/utils/collection';
import { Utils } from '../../shared/utils/utils'
import { utils } from 'protractor';
import { ActivatedRoute, Router} from "@angular/router";
import { DatePipe } from '@angular/common';

@Component({ templateUrl: 'articleStock.component.html' })

export class ArticleStock {
  formNameAddUpdate: string = "New Article Stock";
    idArticleStock: number = 0;
    idArticleFamily: number = 0;
    deliveryDate: string = "";
  errorInfo = '';
  error2 = '';
  sites: any[] = [];
  families: any[] = [];
  articles: any[] = [];  
  suppliers: any[] = [];
  units: any[] = [];
  chemistries: any[] = [];
  types: any[] = [];
  meshes: any[] = [];
  selectedSite: site;
  selectedFamily: articleFamily;
  selectedArticle: article;  
  selectedSupplier: company;
  selectedUnit: type;
  selectedChemistry: chemistry;
    selectedType: type;
    selectedMesh: type;
    selectedTypeModal: type;
    selectedMeshModal: type;
  selectedGranulometry: granulometry;
  selectedRowGranulometry: number;
  granulometries = [];
  stockResultado: any;
  currentUser: User;
    mostrar: boolean = false;
    afs: number = 0;
    sand: sand;

  articleStockForm = this.fb.group({
    sites: [null, Validators.required],
    families: [null, Validators.required],
    articles: [null, Validators.required],    
    reference: ['', Validators.required],
    dtReception: ['', Validators.required],
    cost: [0],
    suppliers: [null],
    quantity: ['', Validators.required],
    units: [null, Validators.required],
    chemistries: [null],
    description: [''],
    sands: this.fb.group({
      afs: [''],
      screen: [''],
      surface: [''],
      analisysDate: [''],
      types: [null],
      meshes: [null],
      percentage: ['']
    }),

    EditGranulometry: this.fb.group({
        typesModal: [''],
        meshesModal: [''],
        percentageModal:''
    })
  });


  constructor(private fb: FormBuilder, private http: HttpClient, private env: EnvService,
    private articleService: ArticleService, private typeService: TypeService, private siteService: SiteService, private sandService: SandService,
    private chemistryServvice: ChemistryService, private companyService: CompanyService,
    private alertService: AlertService, private user: UserService,
    private router: Router, private route: ActivatedRoute) {
    this.stockResultado = new articleStock();
    this.currentUser = this.user.currentUserInfoValue;
  }

  @ViewChild('warningModal', { read: false }) public warningModal: ModalDirective;

  iniciarControles() {
    this.articleStockForm.patchValue({
      sites: null,
      families: null,
      articles: null,
      reference: '',
      dtReception: '',
      cost: 0,
      suppliers: null,
      quantity: '',
      units: null,
      chemistries: null,
      description: ''
    });
  }

  iniciarControlesSand() {
    this.articleStockForm.patchValue({
      sands: {
        //afs: 0,
        //screen: 0,
        //surface: 0,
        //analisysDate: '',
        types: null,
        meshes: null,
        percentage: ''
      }
    });    
  }


  RowSelectedSite() {
      if (this.selectedSite != null && this.selectedFamily != null) {
          this.getChemistry();
    }      
    else {
      this.mostrar = false;

      this.chemistries = null;
      this.articles = null;

      this.selectedFamily = null;
      this.selectedChemistry = null;
      this.selectedArticle = null;
    }
  }

    getChemistry() {
        var idSite: number = 0;
        var idArticleFamily: number = 0;
        if (this.idArticleStock > 0) {
            idSite = this.stockResultado.idSite;
            idArticleFamily = this.idArticleFamily;
        }
        else if (this.selectedSite != null && this.selectedFamily != null) {
            idSite = this.selectedSite.idSite;
            idArticleFamily = this.selectedFamily.idArticleFamily;
        }

      this.chemistryServvice.getChemistryBySiteType(idSite, idArticleFamily)
      .pipe(first())
      .subscribe(
        data => {
          if (data == false) {
            this.error2 = "Consulta errónea.";
          }
              this.chemistries = data;              
              if (this.stockResultado.idChemistry > 0)
                  this.selectedChemistry = this.chemistries.find(s => s.idChemistry == this.stockResultado.idChemistry);
              else
                  this.selectedChemistry = null;
              
        },
        error => {
          this.error = error;
        });
    
  }

  RowSelectedFamily() {
    if (this.selectedSite != null && this.selectedFamily != null)
    {
        this.getArticle();
        this.getChemistry();
        
        if (this.selectedFamily.reference == "Sand") {
          this.iniciarControlesSand();
          this.mostrar = true;

          this.getNormType();

          this.selectedType = null;
          this.selectedMesh = null;
      }        
      else
        this.mostrar = false;
    }
    else {
      this.articles = null;
      this.selectedArticle = null;
      this.mostrar = false;
    }
    }

    getNormType() {
        this.typeService.getNormType()
            .pipe(first())
            .subscribe(
                data => {
                    if (data == false) {
                        this.errorInfo = "Consulta errónea.";
                    }
                    this.types = data;
                    console.log(this.types);
            
                },
                error => {
                    this.error = error;
                });
    }

    getArticle() {
        var idSite: number = 0;
        var idArticleFamily: number = 0;
       /* if (this.idArticleStock > 0) {
            idSite = this.stockResultado.idSite;
            idArticleFamily = this.idArticleFamily;
        }
        else */if (this.selectedSite != null && this.selectedFamily != null) {
            idSite = this.selectedSite.idSite;
            idArticleFamily = this.selectedFamily.idArticleFamily;
        }
        this.articleService.getArticleBySiteFamily(idSite, idArticleFamily)
      .pipe(first())
      .subscribe(
        data => {
          if (data == false) {
            this.error2 = "Consulta errónea.";
          }
              this.articles = data;
              if (this.stockResultado.idArticle > 0)
                  this.selectedArticle = this.articles.find(s => s.idArticle == this.stockResultado.idArticle);
              else
                  this.selectedArticle = null;
        },
        error => {
          this.error = error;
        });

  }


  RowSelectedType() {
    if (this.selectedType != null) {
      this.getGranulometryNorm();
    }
    else {
      this.meshes = null;
      this.selectedMesh = null;
    }
  }

    RowSelectedGranulometry(u: any, i: number) {
        this.selectedGranulometry = u;
        this.selectedRowGranulometry = i;
        console.log(this.types);
        console.log(this.selectedGranulometry);
        if (this.selectedGranulometry != null) {
            this.selectedTypeModal = this.types.find(x => x.idType == this.selectedGranulometry.idNormType);
            this.getGranulometryNorm();
        }
    }

  
    getGranulometryNorm() {

        var idType: number = 0;
        if (this.selectedGranulometry != null)
            idType = this.selectedTypeModal.idType;
        else
            idType = this.selectedType.idType;

        console.log(idType);
     this.sandService.getGranulometryNorm(idType)
      .pipe(first())
      .subscribe(
        data => {
          if (data == false) {
            this.error2 = "Consulta errónea.";
          }
              this.meshes = data;
              if (this.selectedGranulometry != null)
                  this.selectedMeshModal = this.meshes.find(x => x.meshSize == this.selectedGranulometry.meshSize); 
        },
        error => {
          this.error = error;
        });

    this.selectedMesh = null;
  }



  addGranulometry()
  {
    this.clear('alertGranulometry');
    if (this.articleStockForm.controls["sands"].value.meshes.meshSize != null
      && this.articleStockForm.controls["sands"].value.percentage != null) {

      //Si ya se ha añadido el Test Type una vez, se muestra el aviso de que ya existe y no se añade de nuevo
      if (this.granulometries.find((x => x.meshSize == this.articleStockForm.controls["sands"].value.meshes.meshSize))) {
        this.error('This mesh size has already been added', 'alertGranulometry');
        return;
      }

      var aux = new granulometry();
      aux.idGranulometry = 0;
      aux.idGranulometryNorm = this.articleStockForm.controls["sands"].value.meshes.idGranulometryNorm;
      aux.meshSize = this.articleStockForm.controls["sands"].value.meshes.meshSize;
      aux.retainedPercentage = this.articleStockForm.controls["sands"].value.percentage;
      aux.idNormType = this.articleStockForm.controls["sands"].value.types.idType;
            

      this.granulometries.push(aux);
      this.iniciarControlesSand();
      this.selectedMesh = null;
    }
    else {
      this.error('Any of the values entered is not correct', 'alertGranulometry');
    }

  }


    updateGranulometry(selectedindexType: number, selectedindexMesh: number, value: number) {
        if (selectedindexType != 0)
            selectedindexType -= 1;
        if (selectedindexMesh != 0)
            selectedindexMesh -= 1;

        console.log("IndexType " + this.types[selectedindexType].idType);
        console.log("Mesh " + this.meshes[selectedindexMesh].meshSize);
        console.log("Value " + value);
        this.selectedGranulometry.idNormType = this.types[selectedindexType].idType;
        this.selectedGranulometry.meshSize = this.meshes[selectedindexMesh].meshSize;
        this.selectedGranulometry.retainedPercentage = value;
        
        this.warningModal.hide();
    }


    ngOnInit() {

    this.selectedSite = null;
    this.selectedFamily = null;
    this.selectedArticle = null;
    this.selectedSupplier = null;
    this.selectedUnit = null;
    this.selectedChemistry = null;

    this.getParameter();
    this.iniciarControles();       
         

 
    }

    getSite() {
        this.siteService.site(this.currentUser.idUserApplication)
            .pipe(first())
            .subscribe(
                data => {
                    if (data == false) {
                        this.errorInfo = "Consulta errónea.";
                    }
                    this.sites = data;
                if (this.stockResultado.idSite > 0) {
                  this.selectedSite = this.sites.find(s => s.idSite == this.stockResultado.idSite);
                  console.log(this.selectedSite);
                  this.getArticleById();
                }
                else {
                  //Se selecciona por defecto el site del usuario
                  if (this.currentUser.idSiteDefault > 0)
                    this.selectedSite = this.sites.find(x => x.idSite == this.currentUser.idSiteDefault);
                  else
                    this.selectedSite = null;
                }
                     
                        
                },
                error => {
                    this.errorInfo = error;
                });
    }

    getFamily() {
        this.articleService.getFamily()
            .pipe(first())
            .subscribe(
                data => {
                    if (data == false) {
                        this.error2 = "Consulta errónea.";
                    }
                    this.families = data;
                    if (this.idArticleFamily > 0) {
                        console.log(this.idArticleFamily);
                        this.selectedFamily = this.families.find(s => s.idArticleFamily == this.idArticleFamily);
                        this.getArticle();
                        this.getChemistry();
                        if (this.selectedFamily.reference == "Sand")
                        {
                            this.mostrar = true;
                            this.getNormType();
                            this.getSand();
                            this.getGranulometry();
                        }
                    }
                },
                error => {
                    this.error = error;
                });
    }

    getArticleById() {
        this.articleService.getArticleById(this.stockResultado.idArticle)
            .pipe(first())
            .subscribe(
                data => {
                    if (data == false) {
                        this.error2 = "Consulta errónea.";
                    }
                    this.selectedArticle = data;
                    
                    if (this.selectedArticle != null) {
                        console.log(this.selectedArticle);
                        this.idArticleFamily = this.selectedArticle.idArticleFamily;
                        this.getFamily();
                    }
                    },
                error => {
                    this.error = error;
                });
    }

    getSupplier() {
        this.companyService.getSupplierByFoundry(this.currentUser.idCompany)
            .pipe(first())
            .subscribe(
                data => {
                    if (data == false) {
                        this.errorInfo = "Consulta errónea.";
                    }
                    this.suppliers = data;
                    if (this.stockResultado.idSupplier > 0)
                        this.selectedSupplier = this.suppliers.find(s => s.idCompany == this.stockResultado.idSupplier);
                },
                error => {
                    this.error = error;
                });
    }

    getUnit() {
        this.typeService.getUnitType()
            .pipe(first())
            .subscribe(
                data => {
                    if (data == false) {
                        this.errorInfo = "Consulta errónea.";
                    }
                    this.units = data;
                    if (this.stockResultado.idUnitType > 0)
                        this.selectedUnit = this.units.find(s => s.idType == this.stockResultado.idUnitType);
                },
                error => {
                    this.error = error;
                });
    }

    getSand() {

        if (this.idArticleStock > 0) {
            this.sandService.getSand(this.idArticleStock)
                .pipe(first())
                .subscribe(
                    data => {
                        if (data == false) {
                            this.error2 = "Consulta errónea.";
                        }
                        this.sand = data;
                        this.afs = this.sand.afs;
                        console.log("AFS " + this.afs);
                        this.iniSandVar();
                    },
                    error => {
                        this.error = error;
                    });
        }

    }

    getGranulometry() {
        if (this.idArticleStock > 0) {
            this.sandService.getGranulometry(this.idArticleStock)
                .pipe(first())
                .subscribe(
                    data => {
                        if (data == false) {
                            this.error2 = "Consulta errónea.";
                        }
                        this.granulometries = data;
                    },
                    error => {
                        this.error = error;
                    });
        }

    }

    getParameter() {

        if (this.route.snapshot.paramMap.get('idArticleStock') != null) {
            this.idArticleStock = (parseInt)(this.route.snapshot.paramMap.get('idArticleStock'));
            //this.idArticleFamily = (parseInt)(this.route.snapshot.paramMap.get('idArticleFamily'));
            //this.idArea = (parseInt)(this.route.snapshot.paramMap.get('idArea'));
            console.log("ID Site " + this.idArticleStock /*+ "  " + this.router.snapshot.params.idTemplateLabTest*/);
            this.formNameAddUpdate = "Update Article Stock";
            this.getArticleStockById();
            //this.myBtn = true;
            //this.myTab = true;
            ////this.state = "disabled";
            this.articleStockForm.get('sites').disable();
            this.articleStockForm.get('families').disable();
            this.articleStockForm.get('articles').disable();
        }
        else {
            this.getSite();
            this.getSupplier();
            this.getFamily();
            this.getUnit();
        }
    }

    getArticleStockById() {
        this.articleService.getStockById(this.idArticleStock)
            .pipe(first())
            .subscribe(
                data => {
                    if (data == false) {
                        this.error2 = "Consulta errónea.";
                    }
                    this.stockResultado = data;
                    console.log(this.stockResultado);
                    if (this.stockResultado != null) {                      
                        this.iniArticleStockVar();

                        this.getSite();                                                                    
                        this.getSupplier();
                        this.getUnit();
                        this.getGranulometry();

                        let datePipe: DatePipe = new DatePipe("en-EN");
                        this.deliveryDate = datePipe.transform(this.stockResultado.dtArticleStock, 'yyyy-MM-dd HH:mm');
                        
                    }
                },
                error => {
                    this.error = error;
                });
    }

    iniArticleStockVar() {
        this.articleStockForm.patchValue({
            reference: this.stockResultado.reference,
            dtReception: this.stockResultado.dtArticleStock,
            cost: this.stockResultado.unitCost,
            quantity: this.stockResultado.articleStockQuantity,
            description: this.stockResultado.description,
            //afs: this.afs,
            //sands: {
            //    afs: this.afs
            //}
        });

    }

  iniSandVar() {
    
        this.articleStockForm.patchValue({
            sands: {
              afs: this.sand.afs,
              screen: this.sand.screenDistributionNumber,
              surface: this.sand.surfaceArea,
              analisysDate: this.sand.sandAnalysis
            }
        });

    }

  onSubmit() {
    //Se limpia el mensaje de enviado correctamente si se estaba mostrando
    this.clear('SaveCorrect');
    //Si el formulario es válido se envía la información
      if (this.articleStockForm.valid) {
          if (this.selectedFamily.reference == "Sand" && this.granulometries.length == 0) {
              this.error("At least one granulometry must be informed ", 'SaveCorrect');
          }
          else
          {
            console.log(this.articleStockForm.value.cost);

              if (this.selectedFamily.reference == "Sand") 
                this.stockResultado = new sand();
              else
                this.stockResultado = new articleStock();

              if (this.idArticleStock > 0) {
                  this.stockResultado.idArticleStock = this.idArticleStock;
                  this.stockResultado.idArticle = this.selectedArticle.idArticle;
                  this.stockResultado.idSite = this.selectedSite.idSite;
              }
              else
              {
                  this.stockResultado.idArticleStock = 0;
                  this.stockResultado.idArticle = this.articleStockForm.value.articles.idArticle;
                  this.stockResultado.idSite = this.articleStockForm.value.sites.idSite;
              }
              
              this.stockResultado.reference = this.articleStockForm.value.reference;
              this.stockResultado.description = this.articleStockForm.value.description;
              this.stockResultado.articleStockQuantity = this.articleStockForm.value.quantity;
              this.stockResultado.unitCost = this.articleStockForm.value.cost;
              this.stockResultado.idUnitType = this.articleStockForm.value.units.idType;
              this.stockResultado.dtArticleStock = this.articleStockForm.value.dtReception;              
              if (this.articleStockForm.value.suppliers != null) this.stockResultado.idSupplier = this.articleStockForm.value.suppliers.idCompany;              
              if (this.articleStockForm.value.chemistries != null)
              this.stockResultado.idChemistry = this.articleStockForm.value.chemistries.idChemistry;

              if (this.mostrar == true && this.selectedFamily.reference == "Sand" ) {
                  
                this.stockResultado.afs = this.articleStockForm.controls["sands"].value.afs;
                this.stockResultado.screenDistributionNumber = this.articleStockForm.controls["sands"].value.screen;
                this.stockResultado.surfaceArea = this.articleStockForm.controls["sands"].value.surface;
                this.stockResultado.sandAnalysis = this.articleStockForm.controls["sands"].value.analisysDate;

                this.stockResultado.granulometries = [];
                this.granulometries.forEach(item => {
                    this.stockResultado.granulometries.push(item);
                });
              }

              console.log(this.stockResultado);

              Utils.remove_empty(this.stockResultado);

              var body = JSON.stringify(this.stockResultado);

            if (this.selectedFamily.reference == "Sand") {
              this.sandService.saveSand(body)
                .pipe(first())
                .subscribe(
                  result => {
                    if (result == true) {
                      this.cambiosGuardadosCorrectamente();
                    }
                  },
                  error => {
                    this.errorInfo = error;
                    this.error(error, 'SaveCorrect');
                  });
            }
            else {
              this.articleService.saveStock(body)
                .pipe(first())
                .subscribe(
                  result => {
                    if (result == true) {
                      this.cambiosGuardadosCorrectamente();
                    }
                  },
                  error => {
                    this.errorInfo = error;
                    this.error(error, 'SaveCorrect');
                  });
            }             
       }
    }
  }


  RowDelete(u: any, i: number) {
    this.granulometries.splice(i, 1);
  }

  
  //Limpia todos los campos del formulario y las variables que se muestran en las tablas
  resetFormulario() {
    this.articleStockForm.reset();

    this.formNameAddUpdate = "New Article Stock";
    this.chemistries = null;
    this.articles = null;
    this.meshes = null;
    this.granulometries = null;

    this.selectedSite = null;
    this.selectedFamily = null;
    this.selectedArticle = null;
    this.selectedType = null;
    this.selectedSupplier = null;
    this.selectedUnit = null;
    this.selectedChemistry = null;
  }

  //Cuando se envía correctamente la información, se muestra el mensaje y se resetea el formulario
  cambiosGuardadosCorrectamente() {
      
      if (this.idArticleStock > 0) {
        this.success('data update correctly', 'SaveCorrect');      
      }
      else {
        this.success('data saved correctly', 'SaveCorrect');
        this.resetFormulario();
      }
      
    //Se resetean todas la variables por si se desean insertar más datos
      

  }

  success(message: string, id: string) {
    this.alertService.success(message, id);
  }

  error(message: string, id: string) {
    this.alertService.error(message, id);
  }

  info(message: string) {
    this.alertService.info(message);
  }

  warn(message: string) {
    this.alertService.warn(message);
  }

  clear(id: string) {
    this.alertService.clear(id);
  }

}
