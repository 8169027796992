import { Component, ViewChild } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, FormArray, Validators } from '@angular/forms';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, first } from 'rxjs/operators';
import { EnvService } from '../../_services/env.service';
import { UserService, SiteService, CastingService } from '@/_services';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { AlertService } from '@/shared/alerts';
import { site, User, meltinggroup, furnace } from '../../_models/index';
import { forEach } from '@angular/router/src/utils/collection';
import { Utils } from '../../shared/utils/utils'
import { utils } from 'protractor';
import { ActivatedRoute, Router} from "@angular/router";

@Component({ templateUrl: 'furnace.component.html' })

export class Furnace {
    formNameAddUpdate: string = "New Furnace";
    idMeltingGroup: number = 0;
    idFurnace: number = 0;
    idSite: number = 0;
    reference: string = "";
    description: string = "";  
  errorInfo = '';
  error2 = '';
  sites: any[] = [];
  meltinggroups: any[] = [];
  selectedSite: site;
  selectedMeltingGroup: meltinggroup;
  selectedRow: number;
  furnaceResultado: furnace;
  currentUser: User;

  furnaceForm = this.fb.group({
    sites: [null, Validators.required],
    meltinggroups: [null, Validators.required],
    reference: ['', Validators.required],
    description: ['', Validators.required]
  });


  constructor(private fb: FormBuilder, private http: HttpClient, private env: EnvService,
    private siteService: SiteService, private alertService: AlertService, private user: UserService, private castingService: CastingService,
    private router: Router, private route: ActivatedRoute) {
    this.furnaceResultado = new furnace();
    this.currentUser = this.user.currentUserInfoValue;
  }

  @ViewChild('warningModal', { read: false }) public warningModal: ModalDirective;
   

  iniciarControles() {
    this.furnaceForm.patchValue({
      sites: [null],
      areas: [null],
      reference: this.reference,
      description: this.description
    });
  }

  RowSelectedSite() {
    if (this.selectedSite != null)
      this.getMeltingGroup();
    else
    {
      this.meltinggroups = null;
      this.selectedMeltingGroup = null;
    }
  }

  getMeltingGroup() {
    this.castingService.getMeltingGroup(this.selectedSite.idSite)
      .pipe(first())
      .subscribe(
        data => {
          if (data == false) {
            this.error2 = "Consulta errónea.";
          }
              this.meltinggroups = data;
              if (this.idMeltingGroup > 0)
                  this.selectedMeltingGroup = this.meltinggroups.find(x => x.idMeltingGroup == this.idMeltingGroup);
        },
        error => {
          this.error = error;
        });
    
  }

    ngOnInit() {

        this.getParameter();

    this.iniciarControles();

    this.siteService.site(this.currentUser.idUserApplication)
      .pipe(first())
      .subscribe(
        data => {
          if (data == false) {
            this.error2 = "Consulta errónea.";
          }
              this.sites = data;
          if (this.idSite > 0) {
            this.selectedSite = this.sites.find(x => x.idSite == this.idSite);
            this.getMeltingGroup();
          }
          else {
            //Se selecciona por defecto el site del usuario
            if (this.currentUser.idSiteDefault > 0) {
              this.selectedSite = this.sites.find(x => x.idSite == this.currentUser.idSiteDefault);
              this.getMeltingGroup();
            }
            else
              this.selectedSite = null;
          }
        },
        error => {
          this.error = error;
          this.error(error, 'Error');

        });
         
    this.selectedMeltingGroup = null;
    }

    getParameter() {

        if (this.route.snapshot.paramMap.get('idFurnace') != null) {
            this.idMeltingGroup = (parseInt)(this.route.snapshot.paramMap.get('idMeltingGroup'));
            this.idFurnace = (parseInt)(this.route.snapshot.paramMap.get('idFurnace'));
            this.idSite = (parseInt)(this.route.snapshot.paramMap.get('idSite'));
            this.reference = (this.route.snapshot.paramMap.get('reference'));
            this.description = (this.route.snapshot.paramMap.get('description'));
            console.log("ID Site " + this.idSite /*+ "  " + this.router.snapshot.params.idTemplateLabTest*/);
            this.formNameAddUpdate = "Update Furnace"
        }
    }

  onSubmit() {
    //Se limpia el mensaje de enviado correctamente si se estaba mostrando
    this.clear('SaveCorrect');
       
    //Si el formulario es válido se envía la información
    if (this.furnaceForm.valid) {

      this.furnaceResultado.idFurnace = this.idFurnace;
      this.furnaceResultado.idMeltingGroup = this.furnaceForm.value.meltinggroups.idMeltingGroup;
      this.furnaceResultado.reference = this.furnaceForm.value.reference;
      this.furnaceResultado.description = this.furnaceForm.value.description;

      Utils.remove_empty(this.furnaceResultado);           

      var body = JSON.stringify(this.furnaceResultado);


      this.castingService.SaveFurnace(body)
        .pipe(first())
        .subscribe(
          result => {
            if (result == true) {
              this.cambiosGuardadosCorrectamente();
            }
          },
          error => {
            this.errorInfo = error;
            this.error(error, 'SaveCorrect');
          });
    }

  }


  //Limpia todos los campos del formulario y las variables que se muestran en las tablas
  resetFormulario() {
    this.furnaceForm.reset();
    this.formNameAddUpdate = "New Furnace";
    this.selectedSite = null;
    this.meltinggroups = null;
    this.selectedMeltingGroup = null;
  }

  

  //Cuando se envía correctamente la información, se muestra el mensaje y se resetea el formulario
    cambiosGuardadosCorrectamente() {        
    this.success('data saved correctly', 'SaveCorrect');
    //Se resetean todas la variables por si se desean insertar más datos
    if (this.idFurnace == 0) this.resetFormulario();    
  }

  success(message: string, id: string) {
    this.alertService.success(message, id);
  }

  error(message: string, id: string) {
    this.alertService.error(message, id);
  }

  info(message: string) {
    this.alertService.info(message);
  }

  warn(message: string) {
    this.alertService.warn(message);
  }

  clear(id: string) {
    this.alertService.clear(id);
  }

}
