import { Component, ViewChild } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, FormArray, Validators } from '@angular/forms';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, first } from 'rxjs/operators';
import { EnvService } from '../../_services/env.service';
import { UserService, ArticleService } from '@/_services';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { AlertService } from '@/shared/alerts';
import { User, articleFamily } from '../../_models/index';
import { forEach } from '@angular/router/src/utils/collection';
import { Utils } from '../../shared/utils/utils'
import { utils } from 'protractor';
import { ActivatedRoute, Router} from "@angular/router";

@Component({ templateUrl: 'articleFamily.component.html' })

export class ArticleFamily {
  errorInfo = '';
  error2 = '';
  familyResultado: articleFamily;
  currentUser: User;

  articleFamilyForm = this.fb.group({
    reference: ['', Validators.required],
    description: ['', Validators.required]
  });


  constructor(private fb: FormBuilder, private http: HttpClient, private env: EnvService,
    private articleService: ArticleService, private alertService: AlertService, private user: UserService,
    private router: Router, private route: ActivatedRoute) {
    this.familyResultado = new articleFamily();
    this.currentUser = this.user.currentUserInfoValue;
  }

  @ViewChild('warningModal', { read: false }) public warningModal: ModalDirective;

  iniciarControles() {
    this.articleFamilyForm.patchValue({
      reference: [''],
      description: ['']
    });
  }

  ngOnInit() {

    this.iniciarControles();
    
  }

  onSubmit() {
    //Se limpia el mensaje de enviado correctamente si se estaba mostrando
    this.clear('SaveCorrect');
    //Si el formulario es válido se envía la información
    if (this.articleFamilyForm.valid) {

      this.familyResultado.idArticleFamily = 0;
      this.familyResultado.reference = this.articleFamilyForm.value.reference;
      this.familyResultado.description = this.articleFamilyForm.value.description;
      
      Utils.remove_empty(this.familyResultado);

      var body = JSON.stringify(this.familyResultado);


      this.articleService.saveFamily(body)
        .pipe(first())
        .subscribe(
          result => {
            if (result == true) {
              this.cambiosGuardadosCorrectamente();
            }
          },
          error => {
            this.errorInfo = error;
            this.error(error, 'SaveCorrect');
          });

    }


  }

  
  //Limpia todos los campos del formulario y las variables que se muestran en las tablas
  resetFormulario() {
    this.articleFamilyForm.reset();
    
  }

  //Cuando se envía correctamente la información, se muestra el mensaje y se resetea el formulario
  cambiosGuardadosCorrectamente() {
    this.success('data saved correctly', 'SaveCorrect');
    //Se resetean todas la variables por si se desean insertar más datos
    this.resetFormulario();
  }

  success(message: string, id: string) {
    this.alertService.success(message, id);
  }

  error(message: string, id: string) {
    this.alertService.error(message, id);
  }

  info(message: string) {
    this.alertService.info(message);
  }

  warn(message: string) {
    this.alertService.warn(message);
  }

  clear(id: string) {
    this.alertService.clear(id);
  }

}
