
export class moldcomponentcompositions {
    idMoldComponent: number;
    moldComponentReference: string;
    idMoldComponentComposition: number;
    idArticleStock: number;
    articleStockReference: string;
    thickness: number;
    density: number;
    sandRatio: number;
    bos: number;
    quantity: number;
    temperature: number;
    observation: string;    
    coatingProcess: string;
    idCoatingProcess: number;
}
