import { moldcomponentcompositions } from './moldcomponentcompositions';
import { sandmixerprocess } from './sandmixerprocess';
import { coating } from './coating';

export class moldcomponent {
    idMoldComponent: number;
    idMoldTypeElement: number;
    moldTypeElementReference: string;
    vibrationTime: number;
    dtArticleStock: Date;
    description: string;
    reference: string;
    idSite: number;
    idMold: number;
    idArea: number;
    area: string;
    moldComponentCompositions: moldcomponentcompositions[];
    sandmixerprocess: sandmixerprocess;
    coating: coating;
}
