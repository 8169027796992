import { granulometry } from './granulometry';

export class articleStock {
    idArticleStock: number;
    reference: string;
    description: string;
    articleStockQuantity: number;
    unitCost: number;
    idUnitType: number;
    unitTypeReference: string;
    dtArticleStock: Date;
    idArticle: number;
    articleReference: string;
    idChemistry: number;
    chemistryReference: string;
    idSupplier: number;
    supplierName: string;
    idSite: number;
    site: string;

    //afs: number;
    //granulometries: granulometry[];
}
