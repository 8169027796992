import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';

import { AuthenticationService, MenuService, UserService } from '@/_services';
import { Message } from '@angular/compiler/src/i18n/i18n_ast';

@Component({ templateUrl: 'login.component.html' })
export class LoginComponent implements OnInit {
    loginForm: FormGroup;
    loading = false;
    submitted = false;
    returnUrl: string;
    error = '';

    constructor(
        private formBuilder: FormBuilder,
        private route: ActivatedRoute,
        private router: Router,
      private authenticationService: AuthenticationService,
        private menuService: MenuService,
        private userService: UserService
    ) { }

    ngOnInit() {
        this.loginForm = this.formBuilder.group({
            email: ['', Validators.required],
            password: ['', Validators.required]
        });

        // reset login status
        this.authenticationService.logout();

        // get return url from route parameters or default to '/'
        this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
    }

    // convenience getter for easy access to form fields
    get f() { return this.loginForm.controls; }

    onSubmit() {
        this.submitted = true;

        // stop here if form is invalid
        if (this.loginForm.invalid) {
            return;
        }

        this.loading = true;
        
      this.authenticationService.login(this.f.email.value, this.f.password.value)
            .pipe(first())
            .subscribe(
              data => {
                if (data == false) {
                  this.error = "El usuario y contraseña indicados no son válidos. Inténtelo de nuevo.";
                  this.loading = false;                   
               }                
                // Si el usuario existe y se ha logeado se registra el token
                this.authenticationService.token(this.f.email.value, this.f.password.value)
                  .pipe(first())
                  .subscribe(
                    data => {
                      //Si hay token se carga el menu y user information
                     if (this.authenticationService.token) {
                        this.menuService.menu(this.authenticationService.currentUserValue.idUserApplication)
                          .pipe(first())
                          .subscribe(
                             data => {
                                  console.log("antes");
                                  console.log(this.authenticationService.currentUserValue);
                            },
                            error => {
                              this.error = error;
                                console.log("error menu" + error);
                              this.loading = false;
                              });
                         
                         this.userService.getUserInfo(this.authenticationService.currentUserValue.idUserApplication)
                             .pipe(first())
                             .subscribe(
                                 data => {
                                     console.log("depois");
                                     console.log(this.userService.currentUserInfoValue);
                                 },
                                 error => {
                                     this.error = error;
                                     console.log("error user info" + error);
                                     this.loading = false;
                                 });                        
                      }
                    },
                    error => {
                      this.error = error;

                      this.loading = false;
                    });                
                
                this.router.navigate([this.returnUrl]);
                },
                error => {
                  this.error = error;
                  
                  this.loading = false;                  
              });

      
      
      
    }
}
