import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { EnvService } from './env.service';

import { Message } from '@angular/compiler/src/i18n/i18n_ast';
import { company } from '../_models/company';
import { forEach } from '@angular/router/src/utils/collection';

@Injectable({ providedIn: 'root' })
export class CompanyService {
    private currentCompanySubject: BehaviorSubject<company[]>;
    public currentCompany: Observable<company[]>;

  constructor(private http: HttpClient, private env: EnvService) {
      this.currentCompanySubject = new BehaviorSubject<company[]>(JSON.parse(localStorage.getItem('company')));
      this.currentCompany = this.currentCompanySubject.asObservable();
  }

 getLaboratory() {
     return this.http.get<any>(this.env.apiUrl + `/api/Company/lab`)
      .pipe(map(company => {
        // store user details and jwt token in local storage to keep user logged in between page refreshes      

          this.currentCompanySubject.next(company);
          return company;
      }));
  }


  getSupplierByFoundry(idFoundry: Number) {
    return this.http.get<any>(this.env.apiUrl + `/api/Company/supplier/` + idFoundry.toString())
      .pipe(map(company => {
        // store user details and jwt token in local storage to keep user logged in between page refreshes      

        this.currentCompanySubject.next(company);
        return company;
      }));
  }


}


