import { Component, ViewChild } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, FormArray, Validators } from '@angular/forms';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, first } from 'rxjs/operators';
import { EnvService } from '../../_services/env.service';
import { UserService, SiteService } from '@/_services';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { AlertService } from '@/shared/alerts';
import { site, area, environmental, User } from '../../_models/index';
import { forEach } from '@angular/router/src/utils/collection';
import { Utils } from '../../shared/utils/utils'
import { utils } from 'protractor';
import { ActivatedRoute, Router} from "@angular/router";
import { DatePipe } from '@angular/common';

@Component({ templateUrl: 'environmental.component.html' })

export class Environmental {
    formNameAddUpdate: string = "New Environmental";
    idSite: number = 0;
    idArea: number = 0;
    idEnvironmental: number = 0;
    envDate: string = "";
    myTab: boolean = false;
    myBtn: boolean = false;
  errorInfo = '';
  error2 = '';
  sites: any[] = [];
  areas: any[] = [];
  infoTable: environmental[] = [];
  selectedSite: site;
  selectedArea: area;
  selectedRow: number;
  selectedRecord: any;
  environmentalResultado: environmental;
  currentUser: User;
  recorddate: string;

  environmentalForm = this.fb.group({
    areas: [null],
    sites: [null],
    temperature: [0],
    humidity: [0],
    dtEnvironmental: ['']
  });


  constructor(private fb: FormBuilder, private http: HttpClient, private env: EnvService,
    private siteService: SiteService, private alertService: AlertService, private user: UserService,
    private router: Router, private route: ActivatedRoute) {        
    this.environmentalResultado = new environmental;
    this.currentUser = this.user.currentUserInfoValue;
  }

  @ViewChild('warningModal', { read: false }) public warningModal: ModalDirective;

  addRecord() {
    this.clear('alertTestType');
    
      console.log(this.environmentalForm.value);

      if (((this.environmentalForm.value.sites != null && this.environmentalForm.value.sites.idSite != null) || (this.idSite > 0))
         && ((this.environmentalForm.value.areas != null && this.environmentalForm.value.areas.idArea != null) || (this.idArea > 0))      
      && this.environmentalForm.value.dtEnvironmental != null) {

      var aux = new environmental();
      aux.idEnvironmental = this.idEnvironmental;    
      
      aux.dtEnvironmental = this.environmentalForm.value.dtEnvironmental;           

      if (this.environmentalForm.value.humidity == null)
          aux.humidity = 0;
      else
          aux.humidity = this.environmentalForm.value.humidity;

      if (this.environmentalForm.value.temperature == null)
          aux.temperature = 0;
      else
          aux.temperature = this.environmentalForm.value.temperature;

        if (this.idEnvironmental != 0) {
            //aux.site = this.environmentalForm.value.sites.reference;
            aux.idSite = this.idSite;
            aux.idArea = this.idArea;
            //aux.area = this.environmentalForm.value.areas.reference;
        }
        else
        {
            aux.site = this.environmentalForm.value.sites.reference;
            aux.idSite = this.environmentalForm.value.sites.idSite;
            aux.idArea = this.environmentalForm.value.areas.idArea;
            aux.area = this.environmentalForm.value.areas.reference;
        }            
        console.log(this.environmentalForm.value.dtEnvironmental);
      this.infoTable.push(aux);

      this.iniciarControles();
    }
    else {
      this.error('Any of the values entered is not correct', 'alertTestType');
    }

  }

  iniciarControles() {
    //this.environmentalForm.patchValue({
    //  sites: [null],
    //  areas: [null],
    //  temperature: [0],
    //  humidity: [0],
    //  dtEnvironmental: ['']
    //});

      this.environmentalForm.reset();
      this.selectedRecord = null;
      this.areas = null;
      this.selectedSite = null;
      this.selectedArea = null;
  }

  RowSelectedSite() {
    if (this.selectedSite != null) {
      this.getArea();
      this.selectedArea = null;
    }
    else
    {
      this.areas = null;
      this.selectedArea = null;
    }
  }

  getArea() {
    this.siteService.getArea(this.selectedSite.idSite, this.currentUser.idUserApplication)
      .pipe(first())
      .subscribe(
        data => {
          if (data == false) {
            this.error2 = "Consulta errónea.";
          }
              this.areas = data;
              if (this.idArea > 0)
                  this.selectedArea = this.areas.find(x => x.idArea == this.idArea);
              
        },
        error => {
          this.error = error;
        });
   }


    getSite() {
        this.siteService.site(this.currentUser.idUserApplication)
            .pipe(first())
            .subscribe(
                data => {
                    if (data == false) {
                        this.error2 = "Consulta errónea.";
                    }
                    this.sites = data;
                if (this.idSite > 0) {
                  this.selectedSite = this.sites.find(x => x.idSite == this.idSite);
                  this.getArea();
                }
                else {
                  //Se selecciona por defecto el site del usuario
                  if (this.currentUser.idSiteDefault > 0) {
                    this.selectedSite = this.sites.find(x => x.idSite == this.currentUser.idSiteDefault);
                    this.getArea();
                  }
                }


                },
                error => {
                    this.error = error;
                    this.error(error, 'Error');

                });
    }


    ngOnInit() {

      this.getParameter();

 
    }

    getParameter() {

        if (this.route.snapshot.paramMap.get('idEnvironmental') != null) {
            this.idArea = (parseInt)(this.route.snapshot.paramMap.get('idArea'));
            this.idSite = (parseInt)(this.route.snapshot.paramMap.get('idSite'));
            this.idEnvironmental = (parseInt)(this.route.snapshot.paramMap.get('idEnvironmental'));
            console.log("ID Site " + this.idSite /*+ "  " + this.router.snapshot.params.idTemplateLabTest*/);
            this.formNameAddUpdate = "Update Environmental Record";
            this.getEnvironmentalById();
            this.myBtn = true;
            this.myTab = true;
            this.getSite();
            this.environmentalForm.get('sites').disable();
            this.environmentalForm.get('areas').disable();
        }
        else
        {
            this.getSite();
            this.selectedSite = null;
            this.selectedArea = null;
        }
    }

    iniciarControlesEnv() {
        this.environmentalForm.patchValue({
            temperature: this.environmentalResultado.temperature,
            humidity: this.environmentalResultado.humidity,
            dtEnvironmental: this.environmentalResultado.dtEnvironmental
        });
    }

    getEnvironmentalById() {
            this.siteService.getEnvironmentalById(this.idEnvironmental)
                .pipe(first())
                .subscribe(
                    data => {
                        if (data == false) {
                            this.error2 = "Consulta errónea.";
                        }
                        this.environmentalResultado = data;
                        if (this.environmentalResultado != null) {
                            let datePipe: DatePipe = new DatePipe("en-EN");
                            this.envDate = datePipe.transform(this.environmentalResultado.dtEnvironmental, 'yyyy-MM-ddTHH:mm');
                            this.iniciarControlesEnv();                            
                        }
                    },
                    error => {
                        this.error = error;
                    });
     }

  onSubmit() {
    //Se limpia el mensaje de enviado correctamente si se estaba mostrando
    this.clear('SaveCorrect');
       
    //Si el formulario es válido se envía la información
    if (this.environmentalForm.valid) {      

      if (this.idEnvironmental > 0) {
            this.addRecord();

      }
         

        if (this.infoTable.length == 0) {
            this.error("At least one environmental record must be informed ", 'SaveCorrect');
        }

      Utils.remove_empty(this.infoTable);           

      var body = JSON.stringify(this.infoTable);

        console.log(this.infoTable);

      this.siteService.SaveEnvironmental(body)
        .pipe(first())
        .subscribe(
          result => {
            if (result == true) {
              this.cambiosGuardadosCorrectamente();
            }
          },
          error => {
            this.errorInfo = error;
            this.error(error, 'SaveCorrect');

            this.areas = null;
            this.selectedSite = null;
            this.selectedArea = null;
          });
    }

  }


  RowSelected(u: any, i: number) {
    this.selectedRecord = u;   // declare variable in component.
    this.selectedRow = i;
  }

  RowDelete(u: any, i: number) {
    this.infoTable.splice(i, 1);
  }

  //Limpia todos los campos del formulario y las variables que se muestran en las tablas
  resetFormulario() {
    this.environmentalForm.reset();
    this.infoTable = [];
    this.selectedRecord = null;
    this.areas = null;
    this.selectedSite = null;
    this.selectedArea = null;
    this.environmentalForm.get('sites').enable();
    this.environmentalForm.get('areas').enable();
    this.formNameAddUpdate = "New Environmental";
  }

  

  //Cuando se envía correctamente la información, se muestra el mensaje y se resetea el formulario
  cambiosGuardadosCorrectamente() {
    this.success('data saved correctly', 'SaveCorrect');
    //Se resetean todas la variables por si se desean insertar más datos
    if (this.idEnvironmental == 0) this.resetFormulario();    
  }

  success(message: string, id: string) {
    this.alertService.success(message, id);
  }

  error(message: string, id: string) {
    this.alertService.error(message, id);
  }

  info(message: string) {
    this.alertService.info(message);
  }

  warn(message: string) {
    this.alertService.warn(message);
  }

  clear(id: string) {
    this.alertService.clear(id);
  }

   
}
