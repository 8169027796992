import { Component, ViewChild } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, FormArray, Validators } from '@angular/forms';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, first } from 'rxjs/operators';
import { EnvService } from '../../_services/env.service';
import { SiteService, PieceTypeService, MoldTypeService, UserService } from '@/_services';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { AlertService } from '@/shared/alerts';
import { moldTypeEntity, moldTypeElement, pieceTypeAssign, User, site, pieceType } from '../../_models/index';
import { forEach } from '@angular/router/src/utils/collection';
import { Utils } from '../../shared/utils/utils'
import { utils } from 'protractor';
import { all } from 'q';
import { ActivatedRoute } from '@angular/router';


@Component({ templateUrl: 'moldType.component.html' })

export class MoldType {
  formNameAddUpdate: string = "New Mold Type";
    idMoldType: number = 0;
    idSite: number = 0;
  errorInfo = '';
  sites = [];
  pieces = [];
  moldComponentType: any[] = [];
  chemicalSystem: any[] = [];
  infoTable: pieceType[] = [];
  infoTableDragCore: moldTypeElement[] = [];
  selectedSite: site;
  selectedPieceType: any;
  selectedMoldComponentType: any;
  selectedChemicalSystem: any;
  selectedDragCore: any;
  selectedDragCorePiece: any;
  selectedRow: number;
  selectedRowDragCore: number;
  moldTypeResultado: moldTypeEntity;  
  currentUser: User;
  
  moldTypeForm = this.fb.group({     
    reference: ['', Validators.required],
    sites: [null, Validators.required],    
    description: [''],
    theoreticalVolume: ['', Validators.required],
    piecesType: this.fb.group({
      piecesTypeControl: [''],
      numberArticles: ['']
    }),
    dragCore: this.fb.group({
      referenceDragCore: [''],
      moldComponentType: [''],
      numberDragCore: [''], 
      chemicalSystem: [''],
      descriptionDragCore: ['']
    }),
    EditPiecesType: this.fb.group({
      piecesTypeControlModal: [''],
      numberArticlesModal: ['']
    })
  });

   

  constructor(private fb: FormBuilder, private http: HttpClient, private env: EnvService,
    private siteService: SiteService, private pieceTypeService: PieceTypeService, private moldTypeService: MoldTypeService, 
      private user: UserService, private alertService: AlertService, private route: ActivatedRoute) {
    this.moldTypeResultado = new moldTypeEntity();
    this.currentUser = this.user.currentUserInfoValue;
  }

  //Controlar la ventana popup de editar pieceType
  @ViewChild('warningModal', { read: false }) public warningModal: ModalDirective;
  @ViewChild('warningModal2', { read: false }) public warningModal2: ModalDirective;
  @ViewChild('warningModal3', { read: false }) public warningModal3: ModalDirective;


  RowSelectedSite() {
    console.log(this.selectedSite);
    if (this.selectedSite != null)
      this.getPieceType();
    else {
      this.pieces = null;
    }
  }

  getPieceType() {
    this.pieceTypeService.getPieceType(this.selectedSite.idSite)
      .pipe(first())
      .subscribe(
        data => {
          if (data == false) {
            this.errorInfo = "Consulta errónea.";
          }
          this.pieces = data;
          console.log(data);
        },
        error => {
          this.error = error;
        });
  }


  //get piecesType() {
  //  return this.moldTypeForm.get('piecesTypePrueba') as FormArray;
  //}

  addPiecesType() {
    console.log(this.moldTypeForm.controls["piecesType"].value.piecesTypeControl);
    this.clear('alertPieceType');    
    if (this.moldTypeForm.controls["piecesType"].value.piecesTypeControl != null && this.moldTypeForm.controls["piecesType"].value.numberArticles > 0) {

      //Si ya se ha añadido el PieceYpe una vez, se muestra el aviso de que ya existe y no se añade de nuevo
      if (this.infoTable.find((x => x.idArticle == this.moldTypeForm.controls["piecesType"].value.piecesTypeControl.idArticle))) {
        this.error('The selected piece type has already been added', 'alertPieceType');
        return;
        }
        
     this.infoTable.push({
       idPieceType: this.moldTypeForm.controls["piecesType"].value.piecesTypeControl.idArticle,
       idArticle: this.moldTypeForm.controls["piecesType"].value.piecesTypeControl.idArticle,
       numArticle: this.moldTypeForm.controls["piecesType"].value.numberArticles,
       idArticleType: this.moldTypeForm.controls["piecesType"].value.piecesTypeControl.idArticleType,
       reference: this.moldTypeForm.controls["piecesType"].value.piecesTypeControl.reference,
       idChemistry: this.moldTypeForm.controls["piecesType"].value.piecesTypeControl.idChemistry,
       chemistryReference: '',
       articleTypeReference: '',       
       description: this.moldTypeForm.controls["piecesType"].value.piecesTypeControl.description,
       pieceParts: [],       
      idArticleFamily: 0,
      articleFamilyReference: '',
      idSite: 0,
      articleStock: []
     })
        
      //Si ya hay componentes drag/core creados, hay que asignarle esta pieza para que después puedan editar el número de asignación
      this.infoTableDragCore.forEach(item => {
        item.piecesTypeAssign.push({
          numPiece: this.moldTypeForm.controls["piecesType"].value.numberArticles,
          idPieceType: this.pieces.find(x => x.idArticle == this.moldTypeForm.controls["piecesType"].value.piecesTypeControl.idArticle).idPieceType,
          pieceTypeReference: this.moldTypeForm.controls["piecesType"].value.piecesTypeControl.reference,
          idMoldTypeElement: 0,
          moldTypeElementReference: '',
          pieceTypeDescription: this.moldTypeForm.controls["piecesType"].value.piecesTypeControl.description
        })
      });

        console.log(this.infoTable);

      this.iniciarControlesPieceType();
    }
    else {
      this.error('Any of the values entered is not correct', 'alertPieceType');
    }

    if (this.infoTable.length > 0)
      this.moldTypeForm.controls["dragCore"].enable();
    
  }

  updatePiecesType(selectedindex: number, numberArticles: number) {    
    this.selectedPieceType.numArticle = numberArticles;
    this.selectedPieceType.idMoldTypeElement = this.pieces[selectedindex].idArticle;
    this.selectedPieceType.idMoldCompType = this.pieces[selectedindex].idArticleType;
    this.selectedPieceType.moldCompTypeReference = this.pieces[selectedindex].reference;
    this.selectedPieceType.idChemicalSystem = this.pieces[selectedindex].idChemistry;
    this.selectedPieceType.description = this.pieces[selectedindex].description;
    this.selectedPieceType.reference = this.pieces[selectedindex].reference;
      

    //Si existe algún componente drag/core que tiene asignado un número de piezas mayor que el que se editó, se igual al nuevo valor de número piezas    
    this.infoTableDragCore.forEach(item => {
      item.piecesTypeAssign.forEach(itemPiece => {
        if (itemPiece.idPieceType == this.selectedPieceType.idMoldTypeElement && itemPiece.numPiece > numberArticles) {
          console.log(itemPiece);
          itemPiece.numPiece = numberArticles;
        }
      });
    });

     this.warningModal.hide();    
  }

  addDragCore() {
      this.clear('alertDragCore');      

    if (this.moldTypeForm.controls["dragCore"].value.referenceDragCore != null
      && this.moldTypeForm.controls["dragCore"].value.moldComponentType != null
      && this.moldTypeForm.controls["dragCore"].value.numberDragCore != null
      && this.moldTypeForm.controls["dragCore"].value.chemicalSystem != null
      && this.moldTypeForm.controls["dragCore"].value.descriptionDragCore != null) {
      
        console.log(this.moldTypeForm.controls["dragCore"].value.moldComponentType);

      var aux = new moldTypeElement();
     aux.idMoldTypeElement = 0;
      aux.numArticle = this.moldTypeForm.controls["dragCore"].value.numberDragCore;
      aux.idMoldCompType = this.moldTypeForm.controls["dragCore"].value.moldComponentType.idArticleType;
      aux.articleTypeReference = this.moldTypeForm.controls["dragCore"].value.moldComponentType.reference;
      aux.reference = this.moldTypeForm.controls["dragCore"].value.referenceDragCore;
      aux.idChemicalSystem = this.moldTypeForm.controls["dragCore"].value.chemicalSystem.idChemicalSystem;
      aux.chemicalSystemReference = this.moldTypeForm.controls["dragCore"].value.chemicalSystem.reference;
      aux.description = this.moldTypeForm.controls["dragCore"].value.descriptionDragCore;
      aux.piecesTypeAssign = [];
      this.infoTable.forEach(item => {
        aux.piecesTypeAssign.push({
          numPiece: item.numArticle,
          idPieceType: this.pieces.find(x => x.idArticle == item.idPieceType).idPieceType,
          pieceTypeReference: item.reference,
          idMoldTypeElement: 0,
          moldTypeElementReference: '',
          pieceTypeDescription: item.description
        })
      });
        this.infoTableDragCore.push(aux);


      this.iniciarControlesDragCore();
    }
    else {
      this.error('Any of the values entered is not correct','alertDragCore');
    }

  }

  //TABLA PIECES TYPE
  RowSelected(u: any, i: number) {
    console.log(u);
  this.selectedPieceType = u;   // declare variable in component.
  this.selectedRow = i;
  }

  RowDelete(u: any, i: number) {    
    this.infoTable.splice(i, 1);

    if (this.infoTable.length > 0)
    {
        this.moldTypeForm.controls["dragCore"].enable();
        //Si existe algún componente drag/core que tiene asignado un tipo de pieza removido, quitalo
        this.infoTableDragCore.forEach(item => {
            item.piecesTypeAssign.splice(i, 1);
        });
    }      
    else
    {
      this.moldTypeForm.controls["dragCore"].disable();
      this.infoTableDragCore = [];
    }
                  

  }


  RowDeleteDragCore(u: any, i: number) {
      this.infoTableDragCore.splice(i, 1);

      console.log(this.selectedDragCore);


      this.selectedDragCore = null;
      this.selectedDragCore.piecesTypeAssign = [];
  }
  

  //TABLA DRAGCORE
  RowDragCore(u: any, i: number) {
    this.selectedDragCore = u;   // declare variable in component.
    this.selectedRowDragCore = i;    
    console.log(this.selectedDragCore);
  }

  //TABLA DRAGCORE ASIGNACION PIEZAS
  RowDragCorePiece(u: any) {
    this.selectedDragCorePiece = u;   // declare variable in component.
    console.log(this.selectedDragCorePiece);
      this.selectedPieceType = this.infoTable.find(x => x.idPieceType == this.selectedDragCorePiece.idPieceType);
  }

  //ACTUALIZA EL NÚMERO DE PIEZAS A LAS QUE ESTÁ ASIGNADA EL COMPONENTE
  updatePiecesTypeAssign(numberArticles: number) {    
    this.selectedDragCore.piecesTypeAssign.find(x => x.idPieceType == this.selectedDragCorePiece.idPieceType).numPiece = numberArticles;    
    this.warningModal2.hide();
  }

  iniciarControlesDragCore() {
    this.moldTypeForm.patchValue({
      dragCore: {
        referenceDragCore: '',
        moldComponentType: null,
        numberDragCore: '',
        chemicalSystem: null,
        descriptionDragCore: ''
      }
    });

      this.selectedMoldComponentType = null;
      this.selectedChemicalSystem = null;
    }

  iniciarControlesPieceType() {
    this.moldTypeForm.patchValue({
      piecesType: {
        piecesTypeControl: null,
        numberArticles: ''
      }
    });
  }

    CargarControlesComponent(u: any) {

        this.moldComponentType.forEach(comp => {
            if (comp.reference == u.articleTypeReference) {
                this.selectedMoldComponentType = comp;
            }
        });

        this.chemicalSystem.forEach(chemical => {
            if (chemical.reference == u.chemicalSystemReference) {
                this.selectedChemicalSystem = chemical;
            }
        });

        this.moldTypeForm.patchValue({
            dragCore: {
                referenceDragCore: u.reference,
                moldComponentType: this.selectedMoldComponentType,
                numberDragCore: u.numArticle,
                chemicalSystem: this.selectedChemicalSystem,
                descriptionDragCore: u.description
            }
        });

        console.log(this.moldTypeForm.controls["dragCore"].value);
    }

    updateComponent() {

        this.clear('alertEditComponent');
        console.log(this.moldTypeForm.controls["dragCore"].value);
        console.log(this.selectedDragCore);

        if (this.moldTypeForm.controls["dragCore"].value.referenceDragCore != ""
            && this.moldTypeForm.controls["dragCore"].value.numberDragCore != 0
            && this.moldTypeForm.controls["dragCore"].value.moldComponentType.idArticleType != null
            && this.moldTypeForm.controls["dragCore"].value.chemicalSystem.idChemicalSystem != null)
        {
            this.infoTableDragCore.forEach(item => {
                if (item.reference == this.selectedDragCore.reference) {
                    item.reference = this.moldTypeForm.controls["dragCore"].value.referenceDragCore;
                    item.numArticle = this.moldTypeForm.controls["dragCore"].value.numberDragCore;
                    item.idMoldCompType = this.moldTypeForm.controls["dragCore"].value.moldComponentType.idArticleType;
                    item.articleTypeReference = this.moldTypeForm.controls["dragCore"].value.moldComponentType.reference;
                    item.idChemicalSystem = this.moldTypeForm.controls["dragCore"].value.chemicalSystem.idChemicalSystem;
                    item.chemicalSystemReference = this.moldTypeForm.controls["dragCore"].value.chemicalSystem.reference;
                    item.description = this.moldTypeForm.controls["dragCore"].value.descriptionDragCore;

                    console.log(item);
                }
            });
        }
        else {
            this.error('Any of the values entered is not correct', 'alertEditComponent');
        }
        
        this.resetComponent();
    }

    closeUpdateComponent() {
      this.resetComponent();
  }

  //Limpia todos los campos del formulario y las variables que se muestran en las tablas
    resetComponent() {
      this.moldTypeForm.controls["dragCore"].reset();
      this.iniciarControlesDragCore();
      this.selectedDragCore = null;
      this.warningModal3.hide();      
  }

  //Limpia todos los campos del formulario y las variables que se muestran en las tablas
  resetFormulario() {
    this.moldTypeForm.reset();
    this.formNameAddUpdate = "New Mold Type";
    this.infoTable = [];
    this.infoTableDragCore = [];
    this.selectedDragCore = null;
    if (this.infoTable.length == 0)
      this.moldTypeForm.controls["dragCore"].disable();
  }

  //Cuando se envía correctamente la información, se muestra el mensaje y se resetea el formulario
  cambiosGuardadosCorrectamente() {
    this.success('data saved correctly', 'SaveCorrect');
    //Se resetean todas la variables por si se desean insertar más datos    
    if (this.idMoldType == 0) this.resetFormulario();
  }


  onSubmit() {    
    //Se limpia el mensaje de enviado correctamente si se estaba mostrando
      this.clear('SaveCorrect');
      console.log(this.moldTypeForm);
      console.log(this.infoTable);
      console.log(this.infoTableDragCore);
      console.log(this.moldTypeForm.valid);

    //Si el formulario es válido se envía la información
    if (this.moldTypeForm.valid) {      

      this.moldTypeResultado.idMoldType = this.idMoldType;
      this.moldTypeResultado.reference = this.moldTypeForm.value.reference;
      this.moldTypeResultado.description = this.moldTypeForm.value.description;
      this.moldTypeResultado.idSite = this.moldTypeForm.value.sites.idSite;
      this.moldTypeResultado.theoricalVolume = this.moldTypeForm.value.theoreticalVolume;
      this.moldTypeResultado.pieceTypes = [];
      this.moldTypeResultado.moldTypeElements = [];

      this.infoTable.forEach(item => {
        this.moldTypeResultado.pieceTypes.push(item);
      });
      this.infoTableDragCore.forEach(item => {
        this.moldTypeResultado.moldTypeElements.push(item);
      });
      Utils.remove_empty(this.moldTypeResultado);

     console.log(this.moldTypeResultado);

      var body = JSON.stringify(this.moldTypeResultado);     


      this.moldTypeService.SaveMoldType(body)
        .pipe(first())
        .subscribe(
          result => {
            if (result == true) {
              this.cambiosGuardadosCorrectamente();
            }
          },
          error => {
            console.log(error);
            this.errorInfo = error;
            //this.error('An error has occurred, the information has not been saved', 'SaveCorrect');
              this.error(error, 'SaveCorrect'); 
          });

       }


  } 

    getSite()
    {
        this.siteService.site(this.currentUser.idUserApplication)
            .pipe(first())
            .subscribe(
                data => {
                    if (data == false) {
                        this.errorInfo = "Consulta errónea.";
                    }
                    this.sites = data;
                if (this.idSite > 0) {
                  this.selectedSite = this.sites.find(x => x.idSite == this.idSite);
                  this.getPieceType();
                }
                else {
                  //Se selecciona por defecto el site del usuario
                  if (this.currentUser.idSiteDefault > 0) {
                    this.selectedSite = this.sites.find(x => x.idSite == this.currentUser.idSiteDefault);
                    this.getPieceType();
                  }
                  
                }
                },
                error => {
                    this.errorInfo = error;

                });
    }

    getCompType()
    {
        this.moldTypeService.getCompType()
            .pipe(first())
            .subscribe(
                dataMold => {
                    this.moldComponentType = dataMold;
                },
                error => {
                    console.log(error);
                    this.errorInfo = error;

                });
    }

    getChemical()
    {
        this.moldTypeService.getChemicalSystem()
            .pipe(first())
            .subscribe(
                chemical => {
                    console.log(chemical)
                    this.chemicalSystem = chemical;
                },
                error => {
                    console.log(error);
                    this.errorInfo = error;

                });
    }   

  ngOnInit()
  {
    this.selectedSite = null;
    this.idMoldType = 0;
    this.getParameter();    
    this.getCompType();
    this.getChemical();

    //Inicializar valores controles del formulario
    this.iniciarControlesDragCore();
    this.iniciarControlesPieceType();
    
  }

  getParameter() {

    if (this.route.snapshot.paramMap.get('idMoldType') != null) {
          console.log(this.route.snapshot.paramMap.get('idMoldType'));
          this.idMoldType = (parseInt)(this.route.snapshot.paramMap.get('idMoldType'));
          this.idSite = (parseInt)(this.route.snapshot.paramMap.get('idSite'));
          //this.idArticle = (parseInt)(this.route.snapshot.paramMap.get('idArticle'));
          console.log("ID Site " + this.idSite /*+ "  " + this.router.snapshot.params.idTemplateLabTest*/);
          this.formNameAddUpdate = "Update Mold Type";
          this.getMoldTypeById();
          //this.myBtn = true;
          //this.myTab = true;
      }
      else
      {
          this.getSite();
          this.moldTypeForm.controls["dragCore"].disable();   
      }
  }

  getMoldTypeById() {
    console.log(this.idMoldType);
      this.moldTypeService.getById(this.idMoldType)
          .pipe(first())
          .subscribe(
              data => {
                  if (data == false) {
                      this.errorInfo = "Consulta errónea.";
                  }
                  this.moldTypeResultado = data;
                  console.log(data);
                  //this.getPieceTypeByMoldType();
                  //this.getComponentByMoldType();
                  this.getSite();
                  this.iniciarControlesMoldType();
              },
              error => {
                  this.error = error;
              });
  }


  iniciarControlesMoldType() {
      this.moldTypeForm.patchValue({
          reference: this.moldTypeResultado.reference,
          theoreticalVolume: this.moldTypeResultado.theoricalVolume,
          description: this.moldTypeResultado.description,
      });

      console.log(this.moldTypeResultado);

      this.infoTableDragCore = this.moldTypeResultado.moldTypeElements;
      this.infoTable = this.moldTypeResultado.pieceTypes;

      //console.log(this.moldTypeResultado.moldTypeElements);

      //this.infoTableDragCore.forEach(item => {          
      //    item.piecesTypeAssign = [];
      //    this.moldTypeResultado.moldTypeElements.forEach(itemComp => {
      //        console.log(item);
      //        console.log(itemComp);
      //        if (item.idMoldTypeElement == itemComp.idMoldTypeElement) {
      //            console.log("entrei", itemComp.piecesTypeAssign);
      //            itemComp.piecesTypeAssign.forEach(itempieceassign => {
      //                console.log("new piece", itempieceassign);
      //                var aux = new pieceTypeAssign();
      //                aux.numPiece = itempieceassign.numPiece,
      //                aux.idPieceType = itempieceassign.idPieceType,
      //                aux.pieceTypeReference = itempieceassign.pieceTypeReference,
      //                item.piecesTypeAssign.push(aux);
      //            })
      //        }
      //    })                 
      //}); 
           
      console.log(this.moldTypeResultado);
    }

  success(message: string, id:string) {
    this.alertService.success(message, id);
  }

  error(message: string, id: string) {
    this.alertService.error(message, id);
  }

  info(message: string) {
    this.alertService.info(message);
  }

  warn(message: string) {
    this.alertService.warn(message);
  }

  clear(id: string) {
    this.alertService.clear(id);
  }

}

