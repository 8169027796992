import { testvariable } from './testvariable';
import { imagegallery } from './imagegallery';

export class test {    
    idCookieTest: number;
    idCookie: number;
    cookieName: string;
    idTestType: number;
    testTypeName: string;
    idCookieTestType: number;
    cookieTestTypeReference: string;
    idTemplateLabTest: number;
    idBatch: number;
    batchName: string;
    imageGallery: imagegallery;
    testtypevariables: testvariable[];
}  
