
export class granulometry {    
  idGranulometry: number;
  retainedPercentage: number;
  idSand: number;
  idGranulometryNorm: number;
  meshSize: number;
  idNormType: number;
  normTypeReference: number;

}
