import { pieceTypeAssign } from './PieceTypeAssign';

export class moldTypeElement {
  idMoldTypeElement: number;
  description: string;
  numArticle: number;
  idChemicalSystem: number;
  chemicalSystemReference: string;
  articleTypeReference: string;
  idMoldCompType: number;
  moldComTypeDescription: string;
  reference: string;
  piecesTypeAssign: pieceTypeAssign[];
}
