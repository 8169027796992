import { testtypevariable } from './testtypevariable';

export class testtype {    
    idTestType: number;
    reference: string;
    orden: number;
    description: string;
    idFinalStateType: number;
    finalStateTypeReference: string;
    testtypevariables: testtypevariable[];
}  
