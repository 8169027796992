

export class materialcharge {
  idMaterialCharge: number;
  idArticleStock: number;
  articleStock: string;
  idCasting: number;
  quantity: number;
}

