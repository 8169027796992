import { piecedefect } from './piecedefect';

export class pieceheattreatment {
  idPieceHeatTreatment: number;
  dtHeatTreatment: Date;
  description: string;
  numHeatCycle: number;
  idPiece: number;
  piece: string;
  idHeatTreatment: number;
  heatTreatment: string;
  piecedefects: piecedefect[];
}
