import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { EnvService } from './env.service';

import { User } from '@/_models';
import { Token } from '@/_models';
import { Message } from '@angular/compiler/src/i18n/i18n_ast';
import { site } from '../_models/site';
import { forEach } from '@angular/router/src/utils/collection';

@Injectable({ providedIn: 'root' })
export class SiteService {
  private currentSiteSubject: BehaviorSubject<site[]>;
  public currentSite: Observable<site[]>;
  
  constructor(private http: HttpClient, private env: EnvService) {
    this.currentSiteSubject = new BehaviorSubject<site[]>(JSON.parse(localStorage.getItem('site')));
    this.currentSite = this.currentSiteSubject.asObservable();
  }

  site(idUser: Number) {    
    return this.http.get<any>(this.env.apiUrl + `/api/Site/user/` + idUser.toString())
      .pipe(map(site => {             
          // store user details and jwt token in local storage to keep user logged in between page refreshes      
      
        this.currentSiteSubject.next(site);
        return site;
      }));          
    }

   

  getArea(idSite: Number, idUser: Number) {
    return this.http.get<any>(this.env.apiUrl + `/api/Site/area/` + idSite.toString() + `/` + idUser.toString())
      .pipe(map(temp => {
        // store user details and jwt token in local storage to keep user logged in between page refreshes      

        //this.currentTemplateLabSubject.next(temp);
        return temp;
      }));
    }

    getAreaById(idArea: Number) {
        return this.http.get<any>(this.env.apiUrl + `/api/Site/area/` + idArea.toString())
            .pipe(map(temp => {
                // store user details and jwt token in local storage to keep user logged in between page refreshes      

                //this.currentTemplateLabSubject.next(temp);
                return temp;
            }));
    }


  getEnvironmental(idArea: Number) {
    return this.http.get<any>(this.env.apiUrl + `/api/Site/environmental/` + idArea.toString())
      .pipe(map(temp => {
        // store user details and jwt token in local storage to keep user logged in between page refreshes      

        //this.currentEnvironmentalSubject.next(temp);
        return temp;
      }));
  }

    getEnvironmentalById(idEnvironmental: Number) {
        return this.http.get<any>(this.env.apiUrl + `/api/Site/environmental/environmental/` + idEnvironmental.toString())
            .pipe(map(temp => {
                // store user details and jwt token in local storage to keep user logged in between page refreshes      

                //this.currentEnvironmentalSubject.next(temp);
                return temp;
            }));
    }
  


  SaveArea(body) {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    console.log(body);
    console.log(headers);
    console.log(this.env.apiUrl + `/api/Site`);
    return this.http.post(this.env.apiUrl + `/api/Site/area`, body, { headers })
      .pipe(map(group => {
        return true;
      }));
    }

    DeleteArea(body) {
        console.log("Body " + body);
        let options = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
            }),
            body: body,
        };
        return this.http.delete(this.env.apiUrl + `/api/Site/area`, options)
            .pipe(map(area => {
                return true;
            }));
    } 

  SaveEnvironmental(body) {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    console.log(body);
    console.log(headers);
    console.log(this.env.apiUrl + `/api/Environmental`);
    return this.http.post(this.env.apiUrl + `/api/Site/environmental`, body, { headers })
      .pipe(map(enviromental => {
        return true;
      }));
    }

    DeleteEnvironmental(body) {
        console.log("Body " + body);
        let options = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
            }),
            body: body,
        };
        return this.http.delete(this.env.apiUrl + `/api/Site/environmental`, options)
            .pipe(map(enviromental => {
                return true;
            }));
    } 

}
