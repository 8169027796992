import { Component, ViewChild } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, FormArray, Validators } from '@angular/forms';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, first } from 'rxjs/operators';
import { EnvService } from '../../_services/env.service';
import { UserService, ArticleService, TypeService, SiteService, ChemistryService } from '@/_services';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { AlertService } from '@/shared/alerts';
import { User, articleFamily, type, chemistry, site, article } from '../../_models/index';
import { forEach } from '@angular/router/src/utils/collection';
import { Utils } from '../../shared/utils/utils'
import { utils } from 'protractor';
import { ActivatedRoute, Router} from "@angular/router";

@Component({ templateUrl: 'article.component.html' })

export class Article {
  formNameAddUpdate: string = "New Article";
  idArticle: number = 0;
  errorInfo = '';
  error2 = '';
  sites: any[] = [];
  families: any[] = [];
  types: any[] = [];
  chemistries: any[] = [];
  selectedSite: site;
  selectedFamily: articleFamily;
  selectedType: type;
  selectedChemistry: chemistry;
  articleResultado: article;
  currentUser: User;

  articleForm = this.fb.group({
    sites: [null, Validators.required],
    families: [null, Validators.required],
    types: [null, Validators.required],
    chemistries:[null],
    reference: ['', Validators.required],
    description: ['']
  });


  constructor(private fb: FormBuilder, private http: HttpClient, private env: EnvService,
    private articleService: ArticleService, private typeService: TypeService, private siteService: SiteService, private chemistryServvice: ChemistryService,
    private alertService: AlertService, private user: UserService,
    private router: Router, private route: ActivatedRoute) {
    this.articleResultado = new article();
    this.currentUser = this.user.currentUserInfoValue;
  }

  @ViewChild('warningModal', { read: false }) public warningModal: ModalDirective;

  iniciarControles() {
    this.articleForm.patchValue({
      sites: null,
      families: null,
      types: null,
      chemistries: null,
      reference: '',
      description: ''
    });
  }
    

  RowSelectedFamily() {
      if (this.selectedFamily != null && this.selectedSite) {
          this.getChemistry();
          this.getArticleType();
      }          
      else {
          this.chemistries = null;
          this.selectedChemistry = null;
          this.types = null;
          this.selectedType = null;
      }
    }


    getChemistry() {
        var idSite: number = 0;
        var idArticleFamily: number = 0;
        if (this.idArticle > 0) {
            idSite = this.articleResultado.idSite;
            idArticleFamily = this.articleResultado.idArticleFamily;
        }
        else if (this.selectedSite != null && this.selectedFamily != null){
            idSite = this.selectedSite.idSite;
            idArticleFamily = this.selectedFamily.idArticleFamily;
        }
      this.chemistryServvice.getChemistryBySiteType(idSite, idArticleFamily)
      .pipe(first())
      .subscribe(
        data => {
          if (data == false) {
            this.error2 = "Consulta errónea.";
          }
              this.chemistries = data;
              console.log(this.chemistries);
              if (this.articleResultado.idChemistry != 0)
                  this.selectedChemistry = this.chemistries.find(s => s.idChemistry == this.articleResultado.idChemistry);
        },
        error => {
          this.error = error;
        });
      
    this.selectedChemistry = null;
  }


    getArticleType() {
        if (this.selectedFamily != null) {
            this.articleService.getArticleType(this.selectedFamily.reference)
                .pipe(first())
                .subscribe(
                    data => {
                        if (data == false) {
                            this.errorInfo = "Consulta errónea.";
                        }
                        this.types = data;
                        console.log(this.types);
                        if (this.articleResultado.idArticleType > 0)
                            this.selectedType = this.types.find(s => s.idArticleType == this.articleResultado.idArticleType);
                    },
                    error => {
                        this.error = error;
                    });
        }
    }


    ngOnInit() {
      
    this.getParameter();
    this.iniciarControles();
    this.getSite();
    this.getFamily();
       

    this.selectedSite = null;
    this.selectedFamily = null;
    this.selectedType = null;
    this.selectedChemistry = null;
    }

    getSite() {

        this.siteService.site(this.currentUser.idUserApplication)
            .pipe(first())
            .subscribe(
                data => {
                    if (data == false) {
                        this.errorInfo = "Consulta errónea.";
                    }
                    this.sites = data;
                if (this.articleResultado.idSite > 0) {
                  this.selectedSite = this.sites.find(s => s.idSite == this.articleResultado.idSite);
                }
                else {
                  //Se selecciona por defecto el site del usuario
                  if (this.currentUser.idSiteDefault > 0)
                    this.selectedSite = this.sites.find(x => x.idSite == this.currentUser.idSiteDefault);
                  else
                    this.selectedSite = null;
                }

                },
                error => {
                    this.errorInfo = error;
                });

    }

    getParameter() {

        if (this.route.snapshot.paramMap.get('idArticle') != null) {
            this.idArticle = (parseInt)(this.route.snapshot.paramMap.get('idArticle'));
            //this.idSite = (parseInt)(this.route.snapshot.paramMap.get('idSite'));
            //this.idArea = (parseInt)(this.route.snapshot.paramMap.get('idArea'));
            console.log("ID Site " + this.idArticle /*+ "  " + this.router.snapshot.params.idTemplateLabTest*/);
            this.formNameAddUpdate = "Update Article";
            this.getArticleById();
            //this.myBtn = true;
            //this.myTab = true;
            ////this.state = "disabled";
            //this.pieceDefectForm.get('areas').disable();
            //this.pieceDefectForm.get('sites').disable();
            //this.pieceDefectForm.get('pieces').disable();
        }
    }

    getArticleById()
    {
        this.articleService.getArticleById(this.idArticle)
            .pipe(first())
            .subscribe(
                data => {
                    if (data == false) {
                        this.error2 = "Consulta errónea.";
                    }
                    this.articleResultado = data;
                    console.log(this.articleResultado);
                    if (this.articleResultado != null) {
                        this.articleForm.patchValue({
                            reference: this.articleResultado.reference,
                            description: this.articleResultado.description
                        });
                        this.getFamily();
                        this.getSite();
                        this.getChemistry();
                        this.getArticleType();
                    }
                },
                error => {
                    this.error = error;
                });
    }

    getFamily() {
        this.articleService.getFamily()
            .pipe(first())
            .subscribe(
                data => {
                    if (data == false) {
                        this.error2 = "Consulta errónea.";
                    }
                    this.families = data;
                    if (this.articleResultado.idArticleFamily > 0) {
                        this.selectedFamily = this.families.find(s => s.idArticleFamily == this.articleResultado.idArticleFamily);
                        this.getArticleType();
                        this.getChemistry();
                    }
                },
                error => {
                    this.error = error;
                });
    }

  onSubmit() {
    //Se limpia el mensaje de enviado correctamente si se estaba mostrando
      this.clear('SaveCorrect');
      console.log(this.articleForm.value);
    //Si el formulario es válido se envía la información
    if (this.articleForm.valid) {

      this.articleResultado.idArticle = this.idArticle;
      this.articleResultado.reference = this.articleForm.value.reference;
      this.articleResultado.description = this.articleForm.value.description;
      this.articleResultado.idArticleFamily = this.articleForm.value.families.idArticleFamily;
      this.articleResultado.idArticleType = this.articleForm.value.types.idArticleType;
      this.articleResultado.idSite = this.articleForm.value.sites.idSite;
      if (this.articleForm.value.chemistries != null)
            this.articleResultado.idChemistry = this.articleForm.value.chemistries.idChemistry;

        console.log("Id Chemistry " + this.articleResultado.idChemistry);
      
      Utils.remove_empty(this.articleResultado);

      var body = JSON.stringify(this.articleResultado);


      this.articleService.saveArticle(body)
        .pipe(first())
        .subscribe(
          result => {
            if (result == true) {
              this.cambiosGuardadosCorrectamente();
            }
          },
          error => {
            this.errorInfo = error;
            this.error(error, 'SaveCorrect');
          });

    }


  }

  
  //Limpia todos los campos del formulario y las variables que se muestran en las tablas
  resetFormulario() {
    this.articleForm.reset();
    this.formNameAddUpdate = "New Article";
    this.chemistries = null;

    this.selectedSite = null;
    this.selectedFamily = null;
    this.selectedType = null;
    this.selectedChemistry = null;
  }

  //Cuando se envía correctamente la información, se muestra el mensaje y se resetea el formulario
  cambiosGuardadosCorrectamente() {
    this.success('data saved correctly', 'SaveCorrect');
    //Se resetean todas la variables por si se desean insertar más datos
    if (this.idArticle == 0) this.resetFormulario();
  }

  success(message: string, id: string) {
    this.alertService.success(message, id);
  }

  error(message: string, id: string) {
    this.alertService.error(message, id);
  }

  info(message: string) {
    this.alertService.info(message);
  }

  warn(message: string) {
    this.alertService.warn(message);
  }

  clear(id: string) {
    this.alertService.clear(id);
  }

}
