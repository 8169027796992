import { group } from './group';
import { cookieevent } from './cookieevent';


export class shipment {    
  idPackage: number;
  reference: string;
  shipmentCompany: string;
  trackingNumber: string;
  dtDelivery: Date;
  dtReceived: Date;
  descriptionDelivery: string;
  descriptionReceived: string;
  idLaboratory: number;
  laboratory: string;
  idSite: number;
  site: string;
  containers: group[];
  cookiesReception: cookieevent[];
}
