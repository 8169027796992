import { Component } from '@angular/core';
import { first } from 'rxjs/operators';
import { Router } from '@angular/router';

import { User } from '@/_models';
import { UserService, AuthenticationService } from '@/_services';

@Component({ templateUrl: 'home.component.html' })
export class HomeComponent {
  currentUser: User;
  name: string;

    constructor(private user: AuthenticationService)
    {
      this.currentUser = this.user.currentUserValue;
      this.name = this.currentUser.name;
    }       


    ngOnInit() {
        //this.userService.getAll().pipe(first()).subscribe(users => {
        //    this.users = users;
        //});
    }
}
