import { Component, ViewChild } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, FormArray, Validators } from '@angular/forms';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, first } from 'rxjs/operators';
import { EnvService } from '../../_services/env.service';
import { UserService, SiteService } from '@/_services';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { AlertService } from '@/shared/alerts';
import { area, User } from '../../_models/index';
import { forEach } from '@angular/router/src/utils/collection';
import { Utils } from '../../shared/utils/utils'
import { utils } from 'protractor';
import { ActivatedRoute, Router} from "@angular/router";

@Component({ templateUrl: 'area.component.html' })

export class Area {
  formNameAddUpdate: string = "New Area";
    idArea: number = 0;
    idSite: number = 0;
  errorInfo = '';
  error2 = '';
  sites: any[] = [];
  selectedSite: any;
  areaResultado: area;
  currentUser: User;

  areaForm = this.fb.group({
    sites: [null, Validators.required],
    reference: ['', Validators.required],
    description: ['', Validators.required]
  });


  constructor(private fb: FormBuilder, private http: HttpClient, private env: EnvService,
    private siteService: SiteService, private alertService: AlertService, private user: UserService,
    private router: Router, private route: ActivatedRoute) {
    this.areaResultado = new area();
    this.currentUser = this.user.currentUserInfoValue;
  }

  @ViewChild('warningModal', { read: false }) public warningModal: ModalDirective;



    ngOnInit() {

        this.getParameter();
        this.getSite();

    }

    getSite() {
        this.siteService.site(this.currentUser.idUserApplication)
            .pipe(first())
            .subscribe(
                data => {
                    if (data == false) {
                        this.error2 = "Consulta errónea.";
                    }
                    this.sites = data;
                    if (this.idSite > 0)
                      this.selectedSite = this.sites.find(x => x.idSite == this.idSite);
                    else
                      this.selectedSite = null;

                },
                error => {
                    this.error = error;
                    this.error(error, 'Error');

                });
    }

    getParameter() {

        if (this.route.snapshot.paramMap.get('idArea') != null) {
            this.idArea = (parseInt)(this.route.snapshot.paramMap.get('idArea'));
            console.log("ID Site " + this.idSite /*+ "  " + this.router.snapshot.params.idTemplateLabTest*/);
            this.formNameAddUpdate = "Update Area";
            this.getAreaById();
            this.iniciarControlesArea();
        }
    }

    getAreaById()
    {
        this.siteService.getAreaById(this.idArea)
            .pipe(first())
            .subscribe(
                data => {
                    if (data == false) {
                        this.error2 = "Consulta errónea.";
                    }
                    this.areaResultado = data;
                    if (this.areaResultado != null) {
                        this.idSite = this.areaResultado.idSite;
                        this.getSite();
                    }
                },
                error => {
                    this.error = error;
                });
    }

    iniciarControlesArea() {
        this.areaForm.patchValue({
            reference: this.areaResultado.reference,
            description: this.areaResultado.description
        });
    }


  onSubmit() {
    //Se limpia el mensaje de enviado correctamente si se estaba mostrando
    this.clear('SaveCorrect');
    //Si el formulario es válido se envía la información
    if (this.areaForm.valid) {

      this.areaResultado.idArea = this.idArea;
      this.areaResultado.idSite = this.areaForm.value.sites.idSite;
      this.areaResultado.reference = this.areaForm.value.reference;
      this.areaResultado.description = this.areaForm.value.description;
      
      Utils.remove_empty(this.areaResultado);

      var body = JSON.stringify(this.areaResultado);

      
      this.siteService.SaveArea(body)
        .pipe(first())
        .subscribe(
          result => {
            if (result == true) {
              this.cambiosGuardadosCorrectamente();
            }
          },
          error => {
            this.errorInfo = error;
            this.error(error, 'SaveCorrect');
          });

    }


  }

  
  //Limpia todos los campos del formulario y las variables que se muestran en las tablas
  resetFormulario() {
    this.areaForm.reset();
    this.formNameAddUpdate = "New Area";
  }

  //Cuando se envía correctamente la información, se muestra el mensaje y se resetea el formulario
  cambiosGuardadosCorrectamente() {
    this.success('data saved correctly', 'SaveCorrect');
    //Se resetean todas la variables por si se desean insertar más datos        
    if (this.idArea == 0) this.resetFormulario();
  }

  success(message: string, id: string) {
    this.alertService.success(message, id);
  }

  error(message: string, id: string) {
    this.alertService.error(message, id);
  }

  info(message: string) {
    this.alertService.info(message);
  }

  warn(message: string) {
    this.alertService.warn(message);
  }

  clear(id: string) {
    this.alertService.clear(id);
  }

}
