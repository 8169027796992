import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { EnvService } from './env.service';

import { Message } from '@angular/compiler/src/i18n/i18n_ast';
import { site } from '../_models/site';
import { pieceType } from '../_models';

@Injectable({ providedIn: 'root' })
export class MoldTypeService {
    
  constructor(private http: HttpClient, private env: EnvService) {
    
  }

    getMoldCompType(site: Number) {    
        return this.http.get<any>(this.env.apiUrl + `/api/MoldType/all/` + site.toString())
        .pipe(map(moldCompType => {             
            // store user details and jwt token in local storage to keep user logged in between page refreshes      
        
          console.log(moldCompType);        
          return moldCompType;
        }));          
    }

    getById(idMoldType: Number) {
        return this.http.get<any>(this.env.apiUrl + `/api/MoldType/` + idMoldType.toString())
            .pipe(map(moldCompType => {
                // store user details and jwt token in local storage to keep user logged in between page refreshes      

                console.log(moldCompType);
                return moldCompType;
            }));
    }

    
    getCompType() {
        return this.http.get<any>(this.env.apiUrl + `/api/Article/componenttype`)
            .pipe(map(moldCompType => {
                // store user details and jwt token in local storage to keep user logged in between page refreshes      

                console.log(moldCompType);
                return moldCompType;
            }));
    }


  getComponentDragCore(moldtype: Number) {
      return this.http.get<any>(this.env.apiUrl + `/api/MoldType/componentdragcore/` + moldtype.toString())
            .pipe(map(moldCompType => {
                // store user details and jwt token in local storage to keep user logged in between page refreshes      

                console.log(moldCompType);
                return moldCompType;
            }));
    }

  getComponent(moldtype: Number) {
      return this.http.get<any>(this.env.apiUrl + `/api/MoldType/component/` + moldtype.toString())
            .pipe(map(moldCompType => {
                // store user details and jwt token in local storage to keep user logged in between page refreshes      

                console.log(moldCompType);
                return moldCompType;
            }));
    }
        
  getPieceAssign(component: Number) {
      return this.http.get<any>(this.env.apiUrl + `/api/MoldType/pieceassign/` + component.toString())
            .pipe(map(moldCompType => {
                // store user details and jwt token in local storage to keep user logged in between page refreshes      

                console.log(moldCompType);
                return moldCompType;
            }));
  }


  getChemicalSystem() {
    return this.http.get<any>(this.env.apiUrl + `/api/MoldType/chemicalsystem/` )
      .pipe(map(chemicalSystem => {
        // store user details and jwt token in local storage to keep user logged in between page refreshes      

        console.log(chemicalSystem);
        return chemicalSystem;
      }));
  }
    getChemicalSystemById(idChemicalSystem: number) {
        return this.http.get<any>(this.env.apiUrl + `/api/MoldType/chemicalsystem/` + idChemicalSystem.toString())
            .pipe(map(chemicalSystem => {
                // store user details and jwt token in local storage to keep user logged in between page refreshes      

                console.log(chemicalSystem);
                return chemicalSystem;
            }));
    }




  SaveChemicalSystem(body) {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    console.log(body);
    console.log(headers);
    console.log(this.env.apiUrl + `/api/Casting`);
    return this.http.post(this.env.apiUrl + `/api/MoldType/chemicalsystem`, body, { headers })
      .pipe(map(group => {
        return true;
      }));
  }

  SaveMoldType(body) {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    console.log(body);
    console.log(headers);
    console.log(this.env.apiUrl + `/api/MoldType`);
    return this.http.post(this.env.apiUrl + `/api/MoldType/moldtype`, body, { headers })
      .pipe(map(moldCompType => {        
        return true;
      }));  
    }

    DeleteMoldType(body) {
        console.log("Body " + body);
        let options = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
            }),
            body: body,
        };
        console.log(options);
        return this.http.delete(this.env.apiUrl + `/api/MoldType/`, options)
            .pipe(map(area => {
                return true;
            }));
    }

    DeleteChemicalSystem(body) {
        console.log("Body " + body);
        let options = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
            }),
            body: body,
        };
        return this.http.delete(this.env.apiUrl + `/api/MoldType/chemicalsystem/`, options)
            .pipe(map(area => {
                return true;
            }));
    }

}
