import { Component, ViewChild } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, FormArray, Validators } from '@angular/forms';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, first } from 'rxjs/operators';
import { EnvService } from '../../_services/env.service';
import { UserService, SiteService, CastingService } from '@/_services';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { AlertService } from '@/shared/alerts';
import { site, area, User, ladle } from '../../_models/index';
import { forEach } from '@angular/router/src/utils/collection';
import { Utils } from '../../shared/utils/utils'
import { utils } from 'protractor';
import { ActivatedRoute, Router} from "@angular/router";

@Component({ templateUrl: 'ladle.component.html' })

export class Ladle {
    formNameAddUpdate: string = "New Ladle";
    idSite: number = 0;
    idArea: number = 0;
  errorInfo = '';
  error2 = '';
  idLadle: number = 0;
  sites: any[] = [];
  areas: any[] = [];
  selectedSite: site;
  selectedArea: area;
  selectedRow: number;
  ladleResultado: ladle;
  currentUser: User;

  ladleForm = this.fb.group({
    areas: [null, Validators.required],
    sites: [null, Validators.required],
    reference: ['', Validators.required],
    capacity: ['', Validators.required],
    description: ['']
  });


  constructor(private fb: FormBuilder, private http: HttpClient, private env: EnvService,
    private siteService: SiteService, private alertService: AlertService, private user: UserService, private castingService: CastingService,
    private router: Router, private route: ActivatedRoute) {
    this.ladleResultado = new ladle();
    this.currentUser = this.user.currentUserInfoValue;
  }

  @ViewChild('warningModal', { read: false }) public warningModal: ModalDirective;
   

  iniciarControles() {
    this.ladleForm.patchValue({      
      reference: this.ladleResultado.reference,
      capacity: this.ladleResultado.capacity,
      description: this.ladleResultado.description
    });

      this.idSite = this.ladleResultado.idSite;
      this.idArea = this.ladleResultado.idArea;
      console.log(this.idSite, this.idArea);
      this.getSite();
  }


  RowSelectedSite() {
    if (this.selectedSite != null)
      this.getArea();
    else
    {
      this.areas = null;
      this.selectedArea = null;
    }
  }

  getArea() {
    this.siteService.getArea(this.selectedSite.idSite, this.currentUser.idUserApplication)
      .pipe(first())
      .subscribe(
        data => {
          if (data == false) {
            this.error2 = "Consulta errónea.";
          }
          this.areas = data;
          if (this.idArea > 0)
              this.selectedArea = this.areas.find(x => x.idArea == this.idArea);
        },
        error => {
          this.error = error;
          });
    }

    getSite() {
        this.siteService.site(this.currentUser.idUserApplication)
            .pipe(first())
            .subscribe(
                data => {
                    if (data == false) {
                        this.error2 = "Consulta errónea.";
                    }
                    this.sites = data;
                    

                if (this.idSite > 0) {
                  this.selectedSite = this.sites.find(x => x.idSite == this.idSite);
                  this.getArea();
                }
                else {
                  //Se selecciona por defecto el site del usuario
                  if (this.currentUser.idSiteDefault > 0) {
                    this.selectedSite = this.sites.find(x => x.idSite == this.currentUser.idSiteDefault);
                    this.getArea();
                  }
                }
                },
                error => {
                    this.error = error;
                    this.error(error, 'Error');

                });
    }

  ngOnInit() {
          
    this.getParameter();   

  }

    getParameter() {

        if (this.route.snapshot.paramMap.get('idLadle') != null) {
            this.idLadle = (parseInt)(this.route.snapshot.paramMap.get('idLadle'));
            this.formNameAddUpdate = "Update Ladle Record";
            this.getLadleById();           
        }
        else {
            this.iniciarControles();
            this.selectedSite = null;
            this.selectedArea = null;
            this.getSite();
        }
    }

    getLadleById() {
        this.castingService.getLadleById(this.idLadle)
            .pipe(first())
            .subscribe(
                data => {
                    if (data == false) {
                        this.error2 = "Consulta errónea.";
                    }
                    this.ladleResultado = data;
                    console.log(this.ladleResultado);
                    if (this.ladleResultado != null) {                        
                        this.iniciarControles();                        
                    }
                },
                error => {
                    this.error = error;
                });
    }


  onSubmit() {
    //Se limpia el mensaje de enviado correctamente si se estaba mostrando
    this.clear('SaveCorrect');
       
    //Si el formulario es válido se envía la información
    if (this.ladleForm.valid) {

      this.ladleResultado.idLadle = this.idLadle;
      this.ladleResultado.idArea = this.ladleForm.value.areas.idArea;
      this.ladleResultado.reference = this.ladleForm.value.reference;
      this.ladleResultado.capacity = this.ladleForm.value.capacity;
      this.ladleResultado.description = this.ladleForm.value.description;

      Utils.remove_empty(this.ladleResultado);           

      var body = JSON.stringify(this.ladleResultado);


      this.castingService.SaveLadle(body)
        .pipe(first())
        .subscribe(
          result => {
            if (result == true) {
              this.cambiosGuardadosCorrectamente();
            }
          },
          error => {
            this.errorInfo = error;
            this.error(error, 'SaveCorrect');
          });
    }

  }


  //Limpia todos los campos del formulario y las variables que se muestran en las tablas
  resetFormulario() {
    this.ladleForm.reset();
    this.formNameAddUpdate = "New Ladle";
    this.selectedSite = null;
    this.areas = null;
  }

  

  //Cuando se envía correctamente la información, se muestra el mensaje y se resetea el formulario
  cambiosGuardadosCorrectamente() {
    this.success('data saved correctly', 'SaveCorrect');
    //Se resetean todas la variables por si se desean insertar más datos
    if (this.idLadle == 0) this.resetFormulario();
  }

  success(message: string, id: string) {
    this.alertService.success(message, id);
  }

  error(message: string, id: string) {
    this.alertService.error(message, id);
  }

  info(message: string) {
    this.alertService.info(message);
  }

  warn(message: string) {
    this.alertService.warn(message);
  }

  clear(id: string) {
    this.alertService.clear(id);
  }

}
