

export class cookieevent {    
  idCookieEvent: number;
  dtEvent: Date;
  description: string;
  idEventType: number;
  eventTypeReference: string;
  idCookie: number;
  cookieReference: string;
  idBatch: number;
  batchReference: string;
}

        
