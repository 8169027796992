import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { EnvService } from './env.service';

import { Message } from '@angular/compiler/src/i18n/i18n_ast';
import { chemistry } from '../_models/chemistry';
import { forEach } from '@angular/router/src/utils/collection';

@Injectable({ providedIn: 'root' })
export class ChemistryService {
  private currentChemistrySubject: BehaviorSubject<chemistry[]>;
  public currentChemistry: Observable<chemistry[]>;

  constructor(private http: HttpClient, private env: EnvService) {
    this.currentChemistrySubject = new BehaviorSubject<chemistry[]>(JSON.parse(localStorage.getItem('chemistry')));
    this.currentChemistry = this.currentChemistrySubject.asObservable();
  }

    getChemistryBySiteType(idSite: Number, idType: Number) {
      return this.http.get<any>(this.env.apiUrl + `/api/Chemistry/site/` + idSite.toString() + `/` + idType.toString())
        .pipe(map(chemistry => {
          // store user details and jwt token in local storage to keep user logged in between page refreshes      
          
          this.currentChemistrySubject.next(chemistry);
          return chemistry;
        }));
    }

    getChemistryById(idChemistry: Number) {
       return this.http.get<any>(this.env.apiUrl + `/api/Chemistry/chemistry/` + idChemistry.toString())
            .pipe(map(chemistry => {
                // store user details and jwt token in local storage to keep user logged in between page refreshes      

                this.currentChemistrySubject.next(chemistry);
                return chemistry;
            }));
    }
    
    getChemistryByStock(idStock: Number) {
      return this.http.get<any>(this.env.apiUrl + `/api/Chemistry/stock/` + idStock.toString())
        .pipe(map(chemistryelement => {
          // store user details and jwt token in local storage to keep user logged in between page refreshes      
          
          this.currentChemistrySubject.next(chemistryelement);
          return chemistryelement;
        }));
    }
  
    getCompositonByChemistry(idChemistry: Number) {
        return this.http.get<any>(this.env.apiUrl + `/api/Chemistry/element/` + idChemistry.toString())
        .pipe(map(chemistryelement => {
          // store user details and jwt token in local storage to keep user logged in between page refreshes      

          this.currentChemistrySubject.next(chemistryelement);
          return chemistryelement;
        }));
    }

    getElement() {
      return this.http.get<any>(this.env.apiUrl + `/api/Chemistry/element`)
        .pipe(map(element => {
          // store user details and jwt token in local storage to keep user logged in between page refreshes      

          this.currentChemistrySubject.next(element);
          return element;
        }));
    }

    SaveChemistry(body) {
      let headers = new HttpHeaders({
        'Content-Type': 'application/json'
      });
      return this.http.post(this.env.apiUrl + `/api/Chemistry/chemistry`, body, { headers })
        .pipe(map(chemistry => {
          return true;
        }));
    }

    DeleteChemistry(body) {
        console.log("Body " + body);
        let options = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
            }),
            body: body,
        };
        return this.http.delete(this.env.apiUrl + `/api/Chemistry/chemistry`, options)
            .pipe(map(chemistry => {
                return true;
            }));
    } 

  SaveElement(body) {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    return this.http.post(this.env.apiUrl + `/api/Chemistry/element`, body, { headers })
      .pipe(map(element => {
        return true;
      }));
  }
}


