import { granulometry } from './granulometry';
import { articleStock } from './articleStock';

export class sand extends articleStock{    
  idSand: number;
  afs: number;
  surfaceArea: number;
  screenDistributionNumber: number;
  sandAnalysis: Date;      

  granulometries: granulometry[];
}
