import { Injectable, ViewChild } from '@angular/core';
import {
    HttpInterceptor,
    HttpRequest,
    HttpResponse,
    HttpHandler,
    HttpEvent,
    HttpErrorResponse
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

import { AuthenticationService } from '@/_services';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    constructor(private authenticationService: AuthenticationService) { }
        
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(catchError((err: HttpErrorResponse) => {
            console.log(err);
      
            const error = err.error || err.status.toString();
            console.log(error);
            return throwError(error);     
        }))

        //return next.handle(request).pipe(catchError(err => {
        //    console.log(err);
        //    if (err.status === 401) {
        //        // auto logout if 401 response returned from api
        //        //this.authenticationService.logout();
        //        //location.reload(true);
        //    }
        //    if (err.status === 422) {            
        //      err.message = "Usuario no encontrado";
        //    }          
        //  const error = err.message || err.statusText;
        //    console.log(err);
        //  return throwError(error);
        //}))
    }
   
}
